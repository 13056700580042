const initialState = {
  status: "invalid",
  filters: {
    open: true,
    query: {},
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "CARTS_TOGGLE_FILTERS":
      return {
        ...state,
        filters: {
          ...state.filters,
          open: !state.filters.open,
        },
      };
    case "CARTS_RESET_FILTERS":
      return {
        ...state,
        filters: {
          open: true,
          query: {},
        },
      };
    case "CARTS_SET_FILTERS_QUERY":
      return {
        ...state,
        filters: { ...state.filters, query: action.query },
      };
    case "CARTS_LOADING":
      return {
        ...state,
        status: "loading",
      };

    case "CARTS_SEARCHING":
      return {
        ...state,
        status: "searching",
      };
    case "CARTS_SUCCESS":
      return {
        ...state,
        status: "success",
        ...action,
      };
    case "CARTS_FAILURE":
      return {
        ...state,
        status: "failure",
      };
    case "CART_UPDATED": {
      const carts = [...state.data];
      const index = carts.indexOf(
        carts.find(({ id }) => id === action.id)
      );

      if (index > -1) {
        carts[index].is_occupied = action.is_occupied;
      }
      return {
        ...state,
        data: carts,
      };
    }
    default:
      return state;
  }
};
