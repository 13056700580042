import moment from "moment-timezone";
moment.tz.setDefault("Europe/Warsaw");


const getChartData = (chart_data, range) => {
  if (["yesterday", "today"].includes(range)) {
    return Object.keys(chart_data).map((key) => [
      moment.unix(key / 1000).format(`YYYY/MM/DD HH:mm`),
      chart_data[key] || 0,
    ]);
  }

  return Object.keys(chart_data).map((key) => [
    moment.unix(key / 1000).format(`YYYY/MM/DD`),
    chart_data[key] || 0,
  ]);
};

export default getChartData;
