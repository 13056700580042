import styled from "styled-components";

const StyledGdnLine = styled.div`
  margin-bottom: 20px;
  position: relative;
  &.disabled {
    opacity: 0.3;
    .action {
      button {
        display: none;
      }
    }
  }
  .overlay {
    background-image: linear-gradient(
      270deg,
      #ffffff 0%,
      #ffffff00 100%
    );
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  .gdn-line {
    &__main {
      position: relative;
      z-index: 0;
      padding: 10px 0 10px 40px;
      background-color: #fff;
      box-shadow: 0px 0px 13px rgba(0, 0, 0, 0.05);
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      &::before {
        content: "";
        position: absolute;
        width: 9px;
        top: 0;
        bottom: 0;
        left: 0;
        border-radius: 4px 0 0 4px;
      }
      .preview {
        .btn-preview {
          width: 130px;
          height: 130px;
          border-radius: 4px;
          background-position: center;
          background-size: contain;
          background-repeat: no-repeat;
          overflow: hidden;
          position: relative;
          &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: rgba(93, 120, 255, 0.8);
            opacity: 0;
            transition: 0.2s;
            z-index: 1;
          }
          &::after {
            content: url(${require("icons/zoom-big.svg")});
            position: absolute;
            z-index: 2;
            top: 50%;
            left: 50%;
            opacity: 0;
            transition: 0.2s;
            transform: translate(-50%, -50%);
          }
          &:hover {
            &::before,
            &::after {
              opacity: 1;
            }
          }
        }
      }
      .product-info {
        flex: 1;
        padding: 0 40px;
        .sku {
          font-size: 19px;
          line-height: 20px;
          margin-bottom: 4px;
          font-weight: 500;
          color: #646c9a;
        }
        .name {
          font-size: 15px;
          line-height: 20px;
          font-weight: 300;
          color: #566374;
        }
        .codes {
          font-size: 15px;
          line-height: 24px;
          font-weight: 300;
          color: rgba(89, 93, 110, 0.5);
          span {
            display: inline-block;
            padding-right: 12px;
          }
        }
      }
      .quantity {
        text-align: center;
        padding: 0px 12px;
        min-width: 55px;
        display: inline-block;
        border-radius: 4px;
        border: 2px solid #e2e5ec;
        &__value {
          font-size: 39px;
          line-height: 55px;
          font-weight: 500;
          color: #646c9a;
        }
      }
      .action {
        flex-basis: 285px;
        max-width: 285px;
        padding: 0 20px;
        .buttons-group {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          &-col {
            display: flex;
            flex-direction: column;
          }
          .btn {
            min-height: 32px;
            margin: 6px;
            padding: 3px 10px;
            span {
              color: #fff;
            }
            svg {
              margin: 0;
            }
            &.btn-icon {
              border: 1px solid #e2e5ec;
              justify-content: center;
              min-width: 38px;
              padding: 0;
            }
            &.btn-green {
              svg {
                margin-left: 10px;
              }
            }
            &.btn-warning {
              background-color: #ffb822;
              border-color: #ffb822;
              svg {
                margin: 0;
                [fill] {
                  fill: #fff;
                }
              }
            }
            &.btn-danger {
              background-color: #fd397a;
              border-color: #fd397a;
              svg {
                margin: 0 10px 0 0;
                [fill] {
                  fill: #fff;
                }
              }
            }
          }
        }
        .action-group {
          display: flex;
          align-items: center;
          justify-content: center;
          p {
            font-size: 20px;
            line-height: 25px;
            color: #0abb87;
            strong {
              font-weight: 900;
            }
          }
          .btn-default {
            padding: 0;
            font-size: 13px;
            line-height: 20px;
            font-weight: 300;
            color: #595d6e;
            border: none;
            &:hover {
              text-decoration: underline;
            }
          }
          .box-icon {
            flex-basis: 52px;
            min-width: 52px;
            max-width: 52px;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 52px;
            border-radius: 50%;
            border: 2px solid #595d6e;
          }
          .box-text {
            padding-left: 20px;
          }
          &.correct {
            .box-icon {
              border-color: rgba(10, 187, 135, 0.1);
            }
            .box-text {
              p {
                color: #0abb87;
              }
            }
          }
          &.missing {
            .box-icon {
              border-color: rgba(253, 57, 122, 0.1);
            }
            .box-text {
              p {
                color: #fd397a;
              }
            }
          }
        }
      }
    }
    &__statuses {
      display: flex;
      justify-content: flex-start;
      margin-left: 40px;
      .single-status {
        display: flex;
        align-items: center;
        margin-right: 15px;
        border-radius: 0px 0px 5px 5px;
        padding: 8px 15px;
        &.info {
          background-color: #44b2f1;
        }
        &.danger {
          background-color: #f5600a;
        }
        &__content {
          padding-left: 9px;
          font-size: 11px;
          line-height: 16px;
          color: #fff;
        }
      }
    }
  }
`;

export default StyledGdnLine;
