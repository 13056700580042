import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Button, ModalDelete } from "expano-components";
import { Main } from "layouts";
import {
  getSupplier,
  deleteSupplier,
} from "actions/suppliers";
import BasicInfo from "./components/BasicInfo";

import { ReactComponent as SupplierIcon } from "icons/supplier.svg";

const SupplierEdit = ({
  history,
  match: { params },
  supplier,
  getSupplier,
}) => {
  const [
    show_delete_modal,
    handleShowDeleteModal,
  ] = useState(false);

  useEffect(() => {
    getSupplier(params.id);
  }, [params.id]);

  const { data } = supplier;

  return (
    <Main
      is_loading={[
        "invalid",
        "loading",
        "failure",
      ].includes(supplier.status)}
      page={{
        name: data?.name,
        icon: <SupplierIcon />,
        breadcrumbs: [
          {
            name: "Dostawcy",
            path: "/suppliers",
          },
          { name: data?.name },
        ],
        buttons: (
          <Button
            type="delete"
            text="Usuń dostawcę"
            onClick={() => handleShowDeleteModal(true)}
          />
        ),
      }}
    >
      {show_delete_modal && (
        <ModalDelete
          handleDelete={() =>
            deleteSupplier(data.id).then(() =>
              history.push("/suppliers")
            )
          }
          handleClose={() => handleShowDeleteModal(false)}
          name={`dostawcę ${data?.name}`}
        />
      )}
      <div className="dashboard-wrapper-flex m-t-10">
        <div className="column-half">
          <BasicInfo />
        </div>
      </div>
    </Main>
  );
};

export default connect(
  ({ supplier }) => ({ supplier }),
  (dispatch) => ({
    getSupplier: (id) => dispatch(getSupplier(id)),
  })
)(SupplierEdit);
