import React from "react";
import { connect } from "react-redux";

import { ReactComponent as ConfirmGrnIcon } from "icons/confirm-grn.svg";

const Confirm = ({ data }) => {
  const is_disabled =
    data.grn_lines.filter(
      ({ dispatched_quantity, quantity }) =>
        dispatched_quantity === quantity
    ).length !== data.grn_lines.length;
  if (data?.status === "open" || data.status === "active") {
    return (
      <button
        disabled={is_disabled}
        type="submit"
        className="btn btn-sm btn-green">
        <ConfirmGrnIcon />
        <span>Potwierdź wydanie</span>
      </button>
    );
  }
  return null;
};

export default connect(
  ({ grn }) => ({ data: grn?.data }),

)(Confirm);
