import { shuffleArray } from "utils/helpers";
import colors_array from "utils/colors";
const instalguru_url = require("icons/ulotki/instalguru.jpg");
const domee_url = require("icons/ulotki/domee.jpg");
const domowezoo_url = require("icons/ulotki/domowezoo.jpg");

const initialState = {
  status: "invalid",
  is_blocked: false,
  is_open_take_photo_modal: false,
  is_open_issue_modal: false,
  is_open_picker_mistake_modal: false,
  is_open_cart_warning_modal: false,
  is_open_speditor_modal: false,
  is_leaflet_scanned: true,
  show_leaflet: false,
  leaflet: { image_url: null, ean: "" },
  scanned: null,
};

const enabled_leaflet_channels = [
  2,
  6,
  50,
  10,
  8,
  11,
  15,
  16,
  20,
];

const getLeafletData = (channel_id) => {
  switch (true) {
    case [2, 6, 50].includes(channel_id):
      return {
        image_url: instalguru_url,
        ean: "5905261596734",
      };
    case [10, 8, 11].includes(channel_id):
      return {
        image_url: domowezoo_url,
        ean: "5905155897862",
      };
    case [15, 16, 20].includes(channel_id):
      return { image_url: domee_url, ean: "5905155882042" };
    default:
      return { image_url: null, ean: "" };
  }
};

// InstalGuru x AllegroPL - 2
// InstalGuru x WsfInstalguruPL - 6
// InstalGuru x ErliPL - 50

// domowezoo x AllegroPL - 10
// domowezoo x WsfDomowezooPL - 8
// domowezoo x ErliPL - 11

// domee x AllegroPL - 15
// domee x ErliPL - 16
// domee x WsfDomeePL - 20

export default (state = initialState, action) => {
  switch (action.type) {
    case "PACK_GDN_TOGGLE_CART_WARNING": {
      return {
        ...state,
        is_open_cart_warning_modal: action.is_open,
      };
    }
    case "PACK_GDN_SET_BLOCKED": {
      return {
        ...state,
        is_blocked: action.is_blocked,
      };
    }
    case "OPEN_TAKE_PHOTO_MODAL": {
      return {
        ...state,
        is_open_take_photo_modal: action.is_open,
      };
    }
    case "OPEN_SPEDITOR_MODAL": {
      return {
        ...state,
        is_open_speditor_modal: action.is_open,
      };
    }
    case "OPEN_ISSUE_MODAL": {
      return {
        ...state,
        is_open_issue_modal: action.is_open,
      };
    }
    case "OPEN_PICKER_MISTAKE_MODAL": {
      return {
        ...state,
        is_open_picker_mistake_modal: action.is_open,
      };
    }
    case "PACK_GDN_LOADING":
      return {
        ...state,
        status: "loading",
      };
    case "PACK_GDN_SUCCESS": {
      return {
        ...state,
        status: "success",
        ...action,
      };
    }
    case "PACK_GDN_SHUFFLE_COLORS": {
      const color_array = shuffleArray(colors_array);
      const colors = [];
      let leaflet = { image_url: null, ean: "" };
      let is_leaflet_scanned = true;
      let show_leaflet = false;

      const channel_id = action?.data?.channel?.id;
      const gdn_status = action?.data?.status;

      if (
        action?.data?.company?.need_scan_leaflet &&
        enabled_leaflet_channels.includes(channel_id)
      ) {
        show_leaflet = true;
        is_leaflet_scanned = gdn_status !== "picked";

        leaflet = getLeafletData(
          action.data?.channel?.id
        );
      }

      [
        ...new Set(
          action.data.gdn_lines.map(
            ({ product }) => product.sku
          )
        ),
      ].forEach((sku, index) => {
        colors.push({ sku, color: color_array[index] });
      });

      return {
        ...state,
        status: "success",
        data: action.data,
        colors,
        leaflet,
        is_leaflet_scanned,
        show_leaflet,
      };
    }

    case "PACK_GDN_FAILURE":
      return {
        ...state,
        status: "failure",
      };
    case "UPDATE_GDN_LINE": {
      try {
        const gdn_lines = [...state.data.gdn_lines];
        const index = gdn_lines.indexOf(
          gdn_lines.find(
            ({ id }) => id === action.gdn_line.id
          )
        );

        if (index > -1) {
          gdn_lines[index] = {
            ...gdn_lines[index],
            ...action.gdn_line,
          };
        }

        return {
          ...state,
          scanned: null,
          data: {
            ...state.data,
            status: action.gdn.status,
            gdn_lines,
          },
        };
      } catch (error) {
        console.log(error);
        return state;
      }
    }
    case "SET_SCANNED": {
      return { ...state, scanned: action.data };
    }
    case "SET_LEAFLET_SCANNED": {
      return { ...state, is_leaflet_scanned: true };
    }
    default:
      return state;
  }
};
