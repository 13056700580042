import { toastr } from "react-redux-toastr";
import { client_v2 } from "utils/api";

export const getUser = (id) => async (dispatch) => {
  try {
    dispatch({ type: "USER_LOADING" });

    const { data } = await client_v2.get(`/users/${id}?context=ui_show`);

    dispatch({
      type: "USER_SUCCESS",
      data,
    });
  } catch (error) {
    dispatch({ type: "USER_FAILURE" });
    toastr.error(
      "Błąd",
      "Wystąpił błąd podczas pobierania"
    );
  }
};

export const updateUser = (id, values) => (dispatch) =>
  new Promise(async (resolve, reject) => {
    try {
      await client_v2.put(`/users/${id}`, values);
      const { data } = await client_v2.get(`/users/${id}?context=ui_show`);
      toastr.success(
        "Sukces",
        "Użytkownik został zaktualizowany"
      );

      dispatch({
        type: "USER_SUCCESS",
        data,
      });
      resolve();
    } catch (error) {
      toastr.error(
        "Błąd",
        "Wystąpił błąd podczas aktualizowania"
      );
      reject();
    }
  });
