import React from "react";
import moment from "moment";
import { connect } from "react-redux";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";
import { Line, Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Tooltip,
  Legend,
  Filler
);

function numberWithSpaces(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}

const getLabelDateFormat = (type) => {
  switch (type) {
    case "year":
      return "YYYY";
    case "month":
      return "MM-YYYY";
    case "hour":
      return "DD-MM-YYYY HH:mm";
    default:
      return "DD-MM-YYYY";
  }
};

const options = {
  responsive: false,
  interaction: {
    mode: "index",
    intersect: false,
  },
  stacked: false,
  scales: {
    y: {
      type: "linear",
      display: true,
      position: "left",
      min: 0,
    },
  },
};

const couriers = [
  {
    label: "DPD",
    id: "DPD",
    border_color: "rgb(220, 0, 50)",
    bg_color: "rgba(220, 0, 50, 0.2)",
  },
  {
    label: "DHL",
    id: "DHL",
    border_color: "rgb(255, 204, 0)",
    bg_color: "rgba(255, 204, 0, 0.2)",
  },
  {
    label: "UPS",
    id: "UPS",
    border_color: "rgb(53, 28, 21)",
    bg_color: "rgba(53, 28, 21 , 0.2)",
  },
  {
    label: "GLS",
    id: "GLS",
    border_color: "rgb(6, 26, 177)",
    bg_color: "rgba(6, 26, 177, 0.2)",
  },
  {
    label: "InPost",
    id: "INPOST",
    border_color: "rgb(255, 200, 40)",
    bg_color: "rgba(255, 200, 40, 0.2)",
  },
  {
    label: "Poczta Polska",
    id: "POCZTA_POLSKA",
    border_color: "rgb(217, 31, 5)",
    bg_color: "rgba(217, 31, 5, 0.2)",
  },
  {
    label: "Allegro",
    id: "ALLEGRO",
    border_color: "rgb(255, 89, 0)",
    bg_color: "rgba(255, 89, 0, 0.2)",
  },
  {
    label: "Erli",
    id: "ERLI",
    border_color: "rgb(0, 151, 188)",
    bg_color: "rgba(0, 151, 188, 0.2)",
  },
];

const PackagesChart = ({ data, active_courier_names, interval }) => {
  const packages_count = data.histogram.map(({ total }) => total);

  const packages_total_count = packages_count.reduce(
    (acc, value) => acc + value,
    0
  );

  const getCourierPackages = (data, name) =>
    data.map(({ couriers }) => couriers?.[name] || 0);

  const active_couriers = couriers.filter(({ id }) =>
    active_courier_names.includes(id)
  );

  const chart_data_total = {
    labels: data.histogram.map(({ date }) =>
      moment(date).format(getLabelDateFormat(interval))
    ),
    datasets:
      active_couriers.length > 0
        ? active_couriers.map(({ id, label, border_color, bg_color }) => ({
            label,
            data: getCourierPackages(data.histogram, id),
            fill: false,
            borderWidth: 2,
            borderColor: border_color,
            backgroundColor: bg_color,
            yAxisID: "y",
          }))
        : [
            {
              label: "Wszystkie",
              data: data.histogram.map(({ total }) => total),
              fill: false,
              borderWidth: 2,
              borderColor: "#5D78FF",
              backgroundColor: "#5D78FF",
            },
          ],
  };

  const chart_data_weights = {
    labels: data.weight_ranges.map(({ weight }) => `${weight}kg `),
    datasets:
      active_couriers.length > 0
        ? active_couriers.map(({ id, label, border_color, bg_color }) => ({
            label,
            data: getCourierPackages(data.weight_ranges, id),
            fill: false,
            borderWidth: 2,
            borderColor: border_color,
            backgroundColor: bg_color,
            type: "bar",
            yAxisID: "y",
          }))
        : [
            {
              label: "Wszystkie",
              data: data.weight_ranges.map(({ total }) => total),
              fill: false,
              borderWidth: 2,
              borderColor: "#5D78FF",
              backgroundColor: "#5D78FF",
            },
          ],
  };

  const chart_data_country_codes = {
    labels: data.country_code_ranges.map(({ country_code }) => country_code),
    datasets:
      active_couriers.length > 0
        ? active_couriers.map(({ id, label, border_color, bg_color }) => ({
            label,
            data: getCourierPackages(data.country_code_ranges, id),
            fill: false,
            borderWidth: 2,
            borderColor: border_color,
            backgroundColor: bg_color,
            type: "bar",
            yAxisID: "y",
          }))
        : [
            {
              label: "Wszystkie",
              data: data.country_code_ranges.map(({ total }) => total),
              fill: false,
              borderWidth: 2,
              borderColor: "#5D78FF",
              backgroundColor: "#5D78FF",
            },
          ],
  };

  return (
    <>
      <p>
        Ilość paczek: <strong>{numberWithSpaces(packages_total_count)}</strong>
      </p>
      <Line
        width={window.innerWidth - 160}
        height="350px"
        options={options}
        data={chart_data_total}
      />
      <Bar
        style={{ marginTop: 50 }}
        width={window.innerWidth - 160}
        height="350px"
        options={options}
        data={chart_data_weights}
      />
      <Bar
        style={{ marginTop: 50 }}
        width={window.innerWidth - 160}
        height="350px"
        options={options}
        data={chart_data_country_codes}
      />
    </>
  );
};

export default connect(({ packages_stats: { data, filters } }) => ({
  data,
  interval: filters.histogram_interval,
  active_courier_names: filters.histogram_courier_names,
}))(PackagesChart);
