import React from "react";
import { connect } from "react-redux";
import { Main } from "layouts";
import { MainList } from "components";

import { sort, filters } from "./const/data";

import getProductStatus from "utils/getProductStatus";
import getCompanyNameById from "utils/getCompanyNameById";

import { ReactComponent as WarehouseIcon } from "icons/warehouse.svg";
import { ReactComponent as EditIcon } from "icons/edit.svg";

const getVolumeLiters = (val) => {
  if (Number(val) > 0) {
    return (Number(val) / 1000).toFixed(2) + "l";
  }
  return 0;
};

const WarehouseDoublePositions = ({
  current_user,
  match: {
    params: { id },
  },
}) => {
  return (
    <Main
      page={{
        name: "Zdublowane pozycje",
        icon: <WarehouseIcon />,
        breadcrumbs: [
          {
            name: "Zarządzanie magazynem",
            path: "/warehouses",
          },
          { name: "Zdublowane pozycje" },
        ],
      }}>
      <MainList
        query_key="warehouse_double_positions"
        api_path="/warehouse_positions/double_positions"
        api_ransack={`warehouse_id=${id}`}
        sort_column="id"
        sort_direction="desc"
        sort={sort}
        filters={filters}
        renderItem={({
          id,
          identifier,
          depth,
          height,
          width,
          volume,
          product_main_locations,
        }) => (
          <>
            <td>{id}</td>
            <td>{identifier}</td>
            <td>{product_main_locations.length}</td>
            <td>{depth}</td>
            <td>{height}</td>
            <td>{width}</td>
            <td>{getVolumeLiters(volume)}</td>
          </>
        )}
        subListData={({ product_main_locations }) => {
          const data = product_main_locations?.map(
            ({ company, stock, product }) => ({
              stock,
              company_id: company?.id,
              ...product,
            })
          );

          return {
            head: [
              "ID",
              "SKU",
              "Nazwa",
              "Stan magazynowy",
              "Status",
              "",
            ],
            head_width: [150, 150, 400, 150, null, 70],
            colspan: 8,
            highlighted_indexes: [1],
            data,
            renderItem: ({
              id,
              sku,
              name,
              status,
              stock,
              company_id,
            }) => {
              const can_edit =
                current_user.company?.id === company_id;
                
              return [
                id || "-",
                sku || "-",
                name || "-",
                stock || 0,
                <div
                  className={`product_status product_status__big ${
                    getProductStatus(status).color
                  }`}>
                  <span>
                    {getProductStatus(status).name}
                  </span>
                </div>,
                can_edit ? (
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`/products/${id}/localization`}>
                    <EditIcon />
                  </a>
                ) : (
                  <span
                    data-tooltip={`Edycja dostępna dla firmy ${getCompanyNameById(
                      company_id
                    )}`}>
                    <EditIcon style={{ opacity: 0.5 }} />
                  </span>
                ),
              ];
            },
          };
        }}
      />
    </Main>
  );
};

export default connect(({ current_user }) => ({
  current_user,
}))(WarehouseDoublePositions);
