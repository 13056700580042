import { toastr } from "react-redux-toastr";
import { client_v2 } from "utils/api";

export const getPackage = (id) => async (dispatch) => {
  try {
    dispatch({ type: "PACKAGE_LOADING" });

    const { data } = await client_v2.get(`/packages/${id}?context=ui_show`);

    dispatch({
      type: "PACKAGE_SUCCESS",
      data,
    });
  } catch (error) {
    dispatch({ type: "PACKAGE_FAILURE" });
    toastr.error(
      "Błąd",
      "Wystąpił błąd podczas pobierania"
    );
    throw error;
  }
};

export const deletePackage = async (id) => {
  try {
    await client_v2.delete(`/packages/${id}`);
    toastr.success("Sukces", "Paczka została usunięta");
  } catch (error) {
    toastr.error("Błąd", "Wystąpił błąd podczas usuwania");
    throw error;
  }
};

export const exportPackage = async (id) => {
  try {
    await client_v2.post(`/packages/${id}/export`);
    toastr.success(
      "Sukces",
      "Etykieta została wyeksportowana"
    );
  } catch (error) {
    toastr.error(
      "Błąd",
      error?.response?.data?.error ||
        "Wystąpił błąd podczas eksportu etykiety"
    );
    throw error;
  }
};
