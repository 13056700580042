import React from "react";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import { setGrnDispatched } from "actions/grns";

import Confirm from "./components/Confirm";
import DiffList from "./components/DiffList";
import InfoText from "./components/InfoText";
import OtherErrorForm from "./components/OtherErrorForm";

const SummaryDispatch = ({
  data,
  handleSubmit,
  setGrnDispatched,
}) => {
  const onSubmit = (values) =>
    setGrnDispatched(data.id, values);

  return (
    <form
      noValidate
      onSubmit={handleSubmit(onSubmit)}
      className="grn-summary">
      <div className="grn-summary__left">
        <InfoText />
        <DiffList />
        <OtherErrorForm />
      </div>
      <div className="grn-summary__right">
        <Confirm />
      </div>
    </form>
  );
};

export default connect(
  ({ grn: { data } }) => ({
    data,
    initialValues: {
      dispatch_error: data?.dispatch_error || "",
    },
  }),
  (dispatch) => ({
    setGrnDispatched: (id, data) =>
      dispatch(setGrnDispatched(id, data)),
  })
)(
  reduxForm({
    form: "confirm-dispatch-form",
    enableReinitialize: true,
  })(SummaryDispatch)
);
