import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  Button,
  Input,
  ModalDelete,
} from "expano-components";
import { Main } from "layouts";
import {
  getIslandZipCode,
  deleteIslandZipCode,
} from "actions/island_zip_codes";

import { ReactComponent as DeliveryIcon } from "icons/delivery.svg";
import { ReactComponent as InfoIcon } from "icons/info.svg";

const IslandZipCodeEdit = ({
  history,
  match: { params },
  island_zip_code,
  getIslandZipCode,
}) => {
  const [
    show_delete_modal,
    handleShowDeleteModal,
  ] = useState(false);

  useEffect(() => {
    getIslandZipCode(params.id);
  }, [params.id]);

  const { data } = island_zip_code;

  const handleDelete = () =>
    deleteIslandZipCode(data.id).then(() =>
      history.push("/island-zip-codes")
    );

  return (
    <Main
      is_loading={[
        "invalid",
        "loading",
        "failure",
      ].includes(island_zip_code.status)}
      page={{
        name: data?.zip_code,
        icon: <DeliveryIcon />,
        breadcrumbs: [
          {
            name: "Kody pocztowe wysp",
            path: "/island-zip-codes",
          },
          { name: data?.zip_code },
        ],
        buttons: (
          <Button
            type="delete"
            text="Usuń kod pocztowy"
            onClick={() => handleShowDeleteModal(true)}
          />
        ),
      }}
    >
      {show_delete_modal && (
        <ModalDelete
          handleDelete={handleDelete}
          handleClose={() => handleShowDeleteModal(false)}
          name={`kod pocztowy ${data?.zip_code}`}
        />
      )}
      <div className="dashboard-wrapper-flex m-t-10">
        <div className="column-half">
          <div className="dashboard">
            <header className="dashboard__header">
              <div className="icon__container">
                <InfoIcon />
              </div>
              <h2 className="dashboard__title heading">
                Podstawowe dane
              </h2>
            </header>
            <div className="dashboard__form p-t-20">
              <div className="dashboard-wrapper-flex m-b-20">
                <div className="column-half">
                  <Input
                    label="Kod kraju"
                    input={{
                      name: "country_code",
                      value: data?.country_code,
                    }}
                    meta={{ touched: false }}
                  />
                </div>
                <div className="column-half">
                  <Input
                    label="Kod pocztowy"
                    input={{
                      name: "zip_code",
                      value: data?.zip_code,
                    }}
                    meta={{ touched: false }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Main>
  );
};

export default connect(
  ({ island_zip_code }) => ({ island_zip_code }),
  (dispatch) => ({
    getIslandZipCode: (id) =>
      dispatch(getIslandZipCode(id)),
  })
)(IslandZipCodeEdit);
