import React from "react";
import { useQueryClient } from "@tanstack/react-query";
import { Field, reduxForm } from "redux-form";
import { AsyncSelect, Modal } from "components";
import { Button, Checkbox, Input } from "expano-components";
import { createRack } from "actions/racks";
import { useMainList } from "components/MainList/context/provider";

import { ReactComponent as EditIcon } from "icons/edit.svg";
import getWarehouseRackSchemaOptions from "../../const/getWarehouseRackSchemaOptions";

const InputField = (props) => <Input {...props} />;
const CheckField = (props) => <Checkbox {...props} />;

const ModalAddRack = ({
  invalid,
  pristine,
  handleSubmit,
  handleClose,
  change,
}) => {
  const query = useQueryClient();
  const { refetch, data } = useMainList();
  const onSubmit = async (values) =>
    createRack({ ...values, position: data.length + 1 })
      .then(() => {
        refetch();
        query.refetchQueries(["warehouse_aisles"]);
        handleClose();
      })
      .catch((error) => console.log(error));

  return (
    <Modal
      size="md"
      handleClose={handleClose}
      header={{
        icon: <EditIcon />,
        title: "Utwórz",
      }}
      footer={
        <>
          <Button type="cancel" text="Anuluj" onClick={handleClose} />
          <button
            disabled={invalid || pristine}
            onClick={handleSubmit(onSubmit)}
            type="submit"
            className="btn btn-sm btn-green"
          >
            <span>Zapisz</span>
          </button>
        </>
      }
    >
      <form noValidate>
        <table className="modal-table">
          <tbody>
            <tr>
              <td>Nazwa</td>
              <td>
                <Field placeholder="Nazwa" name="name" component={InputField} />
              </td>
            </tr>
            <tr>
              <td>Skrót</td>
              <td>
                <Field
                  placeholder="Skrót"
                  name="identifier"
                  component={InputField}
                />
              </td>
            </tr>
            <tr>
              <td>Typ regału</td>
              <td>
                <Field
                  type="hidden"
                  name="warehouse_rack_schema_id"
                  component={InputField}
                />
                <AsyncSelect
                  getData={getWarehouseRackSchemaOptions}
                  onChange={({ value }) => {
                    change("warehouse_rack_schema_id", value);
                  }}
                />
              </td>
            </tr>
            <tr>
              <td colSpan={2}>
                <Field
                  label="Unikalność wariantów na lokalozacji"
                  name="is_variation_uniqueness_enabled"
                  component={CheckField}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </form>
    </Modal>
  );
};

const validate = (values) => {
  const errors = {};
  if (!values.name) {
    errors.name = "Pole wymagane!";
  }
  if (!values.identifier) {
    errors.identifier = "Pole wymagane!";
  }
  if (values.identifier?.length > 2) {
    errors.identifier = "Maksymalnie 2 znaki";
  }
  return errors;
};

export default reduxForm({
  form: "add-rack",
  validate,
  enableReinitialize: true,
})(ModalAddRack);
