const getProductStatus = (status) => {
  switch (status) {
    case "active":
      return { name: "Aktywny", color: "success" };
    case "inactive":
      return { name: "Nieaktywny", color: "danger" };
    case "discontinued":
      return { name: "Do wyprzedania", color: "warning" };
    default:
      return { name: "Brak", color: "" };
  }
};

export default getProductStatus;
