const initial_data = {
  warehouse: { id: null, name: null },
  warehouse_room: { id: null, name: null },
  warehouse_aisle: { id: null, name: null },
  warehouse_rack: { id: null, name: null },
  warehouse_shelf: { id: null, identifier: null },
  warehouse_position: { id: null, name: null },
};

const initialState = {
  status: "invalid",
  data: initial_data,
  options: null,
};

export default (state = initialState, { type, data }) => {
  switch (type) {
    case "WAREHOUSE_POSITION_LOADING":
      return {
        status: "loading",
        data: initial_data,
        options: null,
      };

    case "WAREHOUSE_POSITION_SUCCESS":
      const main_data = {
        warehouse: {
          id: data?.warehouse?.id,
          name: data?.warehouse?.name,
        },
        warehouse_room: {
          id:
            data?.warehouse_shelf?.warehouse_rack
              ?.warehouse_aisle?.warehouse_room?.id,
          name:
            data?.warehouse_shelf?.warehouse_rack
              ?.warehouse_aisle?.warehouse_room?.name,
        },
        warehouse_aisle: {
          id:
            data?.warehouse_shelf?.warehouse_rack
              ?.warehouse_aisle?.id,
          name:
            data?.warehouse_shelf?.warehouse_rack
              ?.warehouse_aisle?.name,
        },
        warehouse_rack: {
          id: data?.warehouse_shelf?.warehouse_rack?.id,
          name: data?.warehouse_shelf?.warehouse_rack?.name,
        },
        warehouse_shelf: {
          id: data?.warehouse_shelf?.id,
          identifier: data?.warehouse_shelf?.identifier,
        },
        warehouse_position: {
          id: data.id,
          identifier: data.identifier,
        },
      };
      const options = {};
      
      Object.keys(main_data).forEach((key) => {
        options[key] = {
          label:
            main_data?.[key]?.name ||
            main_data?.[key]?.identifier,
          value: main_data?.[key]?.id,
        };
      });

      return {
        status: "success",
        data: main_data,
        options,
      };

    case "WAREHOUSE_POSITION_FAILURE":
      return {
        status: "failure",
        data: initial_data,
        options: null,
      };
    default:
      return state;
  }
};
