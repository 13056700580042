import React from "react";

const Tooltip = ({ trigger, content }) => {
  return (
    <div className="tooltip">
      <div className="tooltip-trigger">
        {trigger || (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20">
            <rect fill="none" width="20" height="20" />
            <circle
              fill="#5d78ff"
              opacity="0.3"
              cx="8.333"
              cy="8.333"
              r="8.333"
              transform="translate(1.667 1.667)"
            />
            <rect
              fill="#5d78ff"
              width="1.667"
              height="5.833"
              rx="0.833"
              transform="translate(9.167 8.333)"
            />
            <rect
              fill="#5d78ff"
              width="1.667"
              height="1.667"
              rx="0.833"
              transform="translate(9.167 5.833)"
            />
          </svg>
        )}
      </div>
      <div className="tooltip-wrapper">
        <div className="tooltip-arrow" />
        <div className="tooltip-inner">{content}</div>
      </div>
    </div>
  );
};

export default Tooltip;
