import React from "react";

import { Main } from "layouts";
import { ReactComponent as InfoIcon } from "icons/info.svg";

const NotFound = () => (
  <Main
    page={{
      name: "Brak uprawnień",
    }}>
    <div className="dashboard">
      <header className="dashboard__header dashboard__header--edit">
        <div className="icon__container">
          <InfoIcon />
        </div>
        <h2 className="dashboard__title heading">Uwaga!</h2>
      </header>
      <div className="dashboard__form">
        <p>Nie masz dostępu do tego widoku!</p>
      </div>
    </div>
  </Main>
);

export default NotFound;
