import { useEffect } from "react";
import { toastr } from "react-redux-toastr";
import { Modal } from "components";
import { findUserIdByAuthToken } from "actions/account";

import { ReactComponent as WarningIcon } from "icons/warning.svg";

const ConfirmModal = ({ handleClose, handleConfirm }) => {
  useEffect(() => {
    document.addEventListener("scan", handleScan);
    return () => {
      document.removeEventListener("scan", handleScan);
    };
  }, []);

  const handleScan = async ({ detail: { scanCode } }) => {
    try {
      if (!scanCode.includes("auth_token|")) {
        toastr.error("Błąd", "Niepoprawny kod");
        return;
      }
      const token = scanCode.split("|")[1];
      const user_id = await findUserIdByAuthToken(token);
      handleConfirm(user_id);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <Modal
      size="md"
      handleClose={handleClose}
      header={{
        title: "UWAGA!",
        icon: <WarningIcon />,
      }}
    >
      <h4 className="text-center" style={{ fontSize: 20, lineHeight: "30px" }}>
        Zeskanuj swoją plakietkę, aby potwierdzić.
      </h4>
    </Modal>
  );
};

export default ConfirmModal;
