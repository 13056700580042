import React, { useState } from "react";
import { connect } from "react-redux";
import getStatus from "pages/completing/Packs/const/getStatus";
import { Select } from "expano-components";

const CartPanel = ({ data }) => {
  const [type, setType] = useState("all");
  const options = [
    { label: "Wszystkie", value: "all" },
    { label: "BOX_1", value: "box_1" },
    { label: "BOX_4", value: "box_4" },
    { label: "BOX_6", value: "box_6" },
    { label: "BOX_12", value: "box_12" },
    { label: "BOX_24", value: "box_24" },
  ];

  const cart_data = data?.carts_data.filter(
    ({ box_size }) => {
      if (type === "all") return true;
      return box_size === type;
    }
  );

  return (
    <div className="dashboard cart-box">
      <div className="dashboard__header">
        <h2 className="dashboard__title heading">Wózki</h2>
        <div style={{ flexBasis: 160 }}>
          <Select
            options={options}
            handleChange={(value) => setType(value)}
            value={options.find(
              ({ value }) => value === type
            )}
          />
        </div>
      </div>
      <table className="table table-sm">
        <thead className="table__header">
          <tr>
            <th width="25%">ID</th>
            <th width="25%">Pack</th>
            <th width="25%">Picker</th>
            <th width="25%">Packer</th>
          </tr>
        </thead>
        <tbody className="table__body">
          {cart_data
            ?.sort((a, b) => a.id - b.id)
            .map(
              ({
                id,
                box_size,
                gdn_pack_id,
                gdn_pack_status,
                picker_name,
                packer_name,
                gdns_total_count,
                gdns_picked_count,
                gdns_packed_count,
              }) => (
                <tr key={id}>
                  <td>
                    <p>{id}</p>
                    <p className="text-11">{box_size}</p>
                  </td>
                  <td>
                    {gdn_pack_id ? (
                      <>
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href={`/packing/gdn_pack/${gdn_pack_id}`}>
                          {gdn_pack_id}
                        </a>
                        {gdn_pack_status && (
                          <p>
                            <span
                              className={`label label--${
                                getStatus(gdn_pack_status)
                                  .color
                              }`}
                              style={{
                                padding: "2px 4px",
                              }}>
                              <span className="text-10">
                                {
                                  getStatus(gdn_pack_status)
                                    .name
                                }
                              </span>
                            </span>
                          </p>
                        )}
                      </>
                    ) : (
                      <p className="text-11">brak</p>
                    )}
                  </td>
                  <td>
                    <p>{picker_name || "-"}</p>
                    {gdns_total_count ? (
                      <p className="text-11">
                        {gdns_picked_count || 0}/
                        {gdns_total_count || "-"}
                      </p>
                    ) : null}
                  </td>
                  <td>
                    <p>{packer_name || "-"}</p>
                    {gdns_total_count ? (
                      <p className="text-11">
                        {gdns_packed_count || 0}/
                        {gdns_total_count || "-"}
                      </p>
                    ) : null}
                  </td>
                </tr>
              )
            )}
        </tbody>
      </table>
    </div>
  );
};

export default connect(({ dashboard: { data } }) => ({
  data,
}))(CartPanel);
