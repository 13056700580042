import React from "react";
import { connect } from "react-redux";
import { PackerStatisticsBar } from "components";
import getPackerPointsInfo from "utils/packer_points";

const ErrorsTable = ({ data }) => {
  return (
    <div className="dashboard">
      <div className="dashboard__modal-table m-t-0">
        <table>
          <tr className="sub-head">
            <th>Data</th>
            <th style={{ padding: 5, width: 220 }}>Postęp</th>
            <th style={{ padding: 5,width: 120}}>Próg</th>
            <th style={{ padding: 5 }}>Ilość błędów</th>
            <th style={{ padding: 5 }}>Ujemne punkty</th>
            <th style={{ padding: 5 }}>Zdobyte punkty</th>
            <th style={{ padding: 5 }}>Ilość przepracowanych godzin</th>
          </tr>

          {Object.keys(data).map((date) => {
            const errors_data = data[date].errors;
            const earned_points = data[date].points;
            const worked_hours = data[date].worked_hours;
            const total_error_points = errors_data.reduce(
              (acc, item) => acc + item?.points || 0,
              0
            );
            const { level_info } = getPackerPointsInfo(
              earned_points,
              worked_hours
            );
            return (
              <tr className="table-row">
                <td style={{ textAlign: "center" }}>
                  <strong>{date}</strong>
                </td>
                <td style={{ padding: "5px 10px" }}>
                  <PackerStatisticsBar
                    show_label={false}
                    worked_hours={worked_hours}
                    packer_gauge="small"
                    value={earned_points}
                  />
                </td>
                <td>{level_info}</td>
                <td
                  style={{
                    padding: 5,
                  }}
                >
                  <strong>{errors_data?.length || "-"}</strong>
                </td>
                <td
                  style={{
                    padding: 5,
                  }}
                >
                  <strong>{total_error_points || "-"}</strong>
                </td>
                <td style={{ padding: 5 }}>
                  <strong>{earned_points || "-"}</strong>
                </td>
                <td>{worked_hours}</td>
              </tr>
            );
          })}
        </table>
      </div>
    </div>
  );
};

export default connect(({ packer_dashboard: { data } }) => ({
  data,
}))(ErrorsTable);
