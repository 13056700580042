import React, { useEffect } from "react";
import { connect } from "react-redux";

import { Main } from "layouts";
import { ReactComponent as ProductIcon } from "icons/product.svg";
import Charts from "./components/Charts";
import { getPackagesStats } from "actions/packages_stats";

const PackagesStats = ({
  packages_stats,
  getPackagesStats,
}) => {
  useEffect(() => {
    getPackagesStats();
  }, [packages_stats.filters]);

  return (
    <Main
      is_loading={["invalid", "loading"].includes(
        packages_stats.status
      )}
      page={{
        name: "Paczki",
        icon: <ProductIcon />,
        breadcrumbs: [{ name: "Statystyki paczek" }],
      }}
    >
      <Charts  />
    </Main>
  );
};

export default connect(
  ({ packages_stats }) => ({
    packages_stats,
  }),
  (dispatch) => ({
    getPackagesStats: () => dispatch(getPackagesStats()),
  })
)(PackagesStats);
