import { client_v2 } from "utils/api";

const getWarehouses = (query) =>
  new Promise((resolve, reject) =>
    client_v2
      .get(
        `/warehouses?context=list&sort[order]=asc&sort[column]=id&page=1&per_page=10${
          query ? `&q[name_matches]=%25${query}%25` : ""
        }`
      )
      .then(({ data }) =>
        resolve(
          data?.map(({ id, name }) => ({
            label: name,
            value: id,
          }))
        )
      )
      .catch((err) => reject(err))
  );

export default getWarehouses;
