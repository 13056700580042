import React from "react";
import { useQueryClient } from "@tanstack/react-query";
import { ModalDelete } from "expano-components";
import { useMainList } from "components/MainList/context/provider";
import { deleteAisle } from "actions/aisles";

const ModalDeleteAisle = ({ name, id, handleClose }) => {
  const { refetch } = useMainList();
  const query = useQueryClient();
  const handleDelete = () =>
    deleteAisle(id)
      .then(async () => {
        refetch();
        query.refetchQueries(["warehouse_rooms"]);
        handleClose();
      })
      .catch((err) => console.log(err));

  return (
    <ModalDelete
      name={`alejkę ${name}?`}
      handleClose={handleClose}
      handleDelete={handleDelete}
    />
  );
};

export default ModalDeleteAisle;
