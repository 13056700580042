import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import ReactDOM from "react-dom";
import { Button, Input } from "expano-components";

import { ReactComponent as WarningIcon } from "icons/warning.svg";

const InputField = (props) => <Input {...props} />;

const secret_password = "wms_admin!#";

const validate = (values, { product }) => {
  const errors = {};
  const logistic_ean_codes = product.logistic_eans.map(
    ({ code }) => code
  );

  const codes = [...logistic_ean_codes, product.ean];

  if (
    values.ean?.length > 0 &&
    !codes.includes(values.ean)
  ) {
    errors.ean = "Błędny ean, lub kod logistyczny";
  }
  if (
    values.pass?.length > 0 &&
    values.pass !== secret_password
  ) {
    errors.pass = "Nieprawidłowe hasło";
  }

  return errors;
};

let timeout;
let new_timeout;

const PackConditionallyModal = ({
  handleSubmit,
  handleClose,
  handleConfirm,
  pristine,
}) => {
  const ref = useRef(null);
  const [active, setActive] = useState(false);
  const [is_loaded, setLoaded] = useState(false);

  useEffect(() => {
    const input = ref.current.querySelector("input");
    if (input) {
      input.focus();
    }
    timeout = setTimeout(() => {
      setActive(true);
    }, 200);
    new_timeout = setTimeout(() => {
      setLoaded(true);
    }, 1000);
    return () => {
      clearTimeout(timeout);
      clearTimeout(new_timeout);
    };
  }, []);

  const onSubmit = () => {
    handleConfirm();
    handleClose();
  };
  return ReactDOM.createPortal(
    <div className="popup-wrapper">
      <form
        autoComplete="off"
        noValidate
        onSubmit={handleSubmit(onSubmit)}
        className={`popup ${active ? "active" : ""}`}>
        <header className="popup__header">
          <WarningIcon />
          <h2 className="dashboard__title heading">
            Pakowanie warunkowe
          </h2>
          <Button type="close" onClick={handleClose} />
        </header>
        <div className="popup__body">
          <div className="popup-text" ref={ref}>
            <input
              style={{ display: "none" }}
              type="email"
              name="email"
            />
            <input
              style={{ display: "none" }}
              type="password"
              name="password"
            />
            <p>
              Produkt, który potwierdzasz, jest łatwy do
              kompletacji. Powinieneś móc go skompletować
              używając skanera.
            </p>
            <p style={{ margin: "10px 0" }}>
              Jeśli występuje problem przy skanowaniu,{" "}
              <strong>
                przepisz poniżej EAN produktu, lub kod
                logistyczny
              </strong>
            </p>
            <Field
              type="text"
              id="ean"
              name="ean"
              component={InputField}
            />
            <p style={{ margin: "10px 0" }}>
              <strong>lub wpisz hasło</strong>
            </p>
            <Field
              type={is_loaded ? "password" : "text"}
              id="pass"
              name="pass"
              component={InputField}
            />
          </div>
        </div>
        <div
          className="popup__footer"
          style={{
            padding: "10px 15px",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}>
          <Button
            type="cancel"
            text="Anuluj"
            onClick={handleClose}
          />
          <button
            disabled={pristine}
            type="submit"
            onClick={handleSubmit(onSubmit)}
            className="btn btn-sm btn-green">
            <span>Spakuj</span>
          </button>
        </div>
      </form>
    </div>,
    document.getElementById("root")
  );
};

export default connect(() => ({
  initialValues: {
    ean: "",
    pass: "",
  },
}))(
  reduxForm({
    form: "gdn-line-pack-conditionally",
    validate,
    enableReinitialize: true,
  })(PackConditionallyModal)
);
