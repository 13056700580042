import React from "react";
import { connect } from "react-redux";
import { ReactComponent as InfoIcon } from "icons/info.svg";
import AdminRoutes from "./roles/AdminRoutes";
import WarehousemanRoutes from "./roles/WarehousemanRoutes";
import PackerRoutes from "./roles/PackerRoutes";
import ManagerRoutes from "./roles/ManagerRoutes";

const Router = ({ role }) => {
  switch (role) {
    case "admin":
      return <AdminRoutes />;
    case "warehouseman":
      return <WarehousemanRoutes />;
    case "packer":
      return <PackerRoutes />;
    case "manager":
      return <ManagerRoutes />;
    default:
      return (
        <div className="dashboard">
          <header className="dashboard__header dashboard__header--edit">
            <div className="icon__container">
              <InfoIcon />
            </div>
            <h2 className="dashboard__title heading">
              Uwaga!
            </h2>
          </header>
          <div className="dashboard__form">
            <p>Nie masz uprawnień do aplikacji!</p>
            <p>Skontaktuj się z administratorem.</p>
          </div>
        </div>
      );
  }
};

export default connect(
  ({ current_user: { role } }) => ({ role }),
  null
)(Router);
