import React from "react";
import { render } from "react-dom";
import { Provider } from "react-redux";
import ReduxToastr from "react-redux-toastr";
import { ConnectedRouter } from "connected-react-router";
import store, { history } from "./store";
import App from "./app";
import moment from "moment";
import "moment/locale/pl";

moment.locale("pl");

import "sanitize.css/sanitize.css";
import "./styles/theme.scss";

render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <ReduxToastr
        timeOut={4000}
        newestOnTop={false}
        position="top-right"
        transitionIn="fadeIn"
        transitionOut="fadeOut"
        progressBar
        closeOnToastrClick
      />
      <App />
    </ConnectedRouter>
  </Provider>,
  document.querySelector("#root")
);
