import React, { useState } from "react";

import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { handleLogout } from "actions/account";
import { ReactComponent as AccountIcon } from "icons/account.svg";
import { ReactComponent as QrcodeIcon } from "icons/qrcode.svg";
import { QrCodeModal } from "components";
import { getCookie } from "utils/cookies";

const PackerAccount = ({ current_user }) => {
  const [is_open, setOpen] = useState(false);
  const [show_qrcode_modal, showQrCodeModal] = useState(false);

  const token_qr = `auth_token|${getCookie("token")}`;

  if (!["warehouseman", "packer"].includes(current_user.role)) {
    return null;
  }

  return (
    <div className="account-group">
      <button onClick={() => showQrCodeModal(true)} className="btn-qr">
        <QrcodeIcon />
      </button>
      <button
        type="button"
        className="account-group__trigger"
        onClick={() => setOpen(!is_open)}
      >
        <AccountIcon />
        <span>Konto</span>
      </button>
      {is_open && (
        <ul className="account-group__panel">
          <li>
            <strong>{current_user.email}</strong>
          </li>
          <li>
            <strong>
              {current_user.today_packing_station_user?.packing_station_name}
            </strong>
          </li>
          <li>
            <Link to="/">Statystyki</Link>
          </li>
          <li>
            <button type="button" onClick={handleLogout}>
              Wyloguj
            </button>
          </li>
        </ul>
      )}
      {show_qrcode_modal && (
        <QrCodeModal
          title="QR kod autoryzacji"
          value={token_qr}
          handleClose={() => showQrCodeModal(false)}
        />
      )}
    </div>
  );
};

export default connect(({ current_user }) => ({
  current_user,
}))(PackerAccount);
