import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Main } from "layouts";
import { TimeAgo } from "components";
import { getDashboard } from "actions/dashboard";
import { getAlerts } from "actions/alerts";
import { getPackageBoxes } from "actions/package_boxes";
import { getAveragePackages } from "actions/average_packages";
import { getAbsences } from "actions/absences";
import { ReactComponent as DashboardIcon } from "icons/dashboard.svg";
import CompletedBox from "./components/CompletedBox";
import InProgressBox from "./components/InProgressBox";
import StatsBox from "./components/StatsBox";
import LastCompletedChart from "./components/LastCompletedChart";
import GaugesChart from "./components/GaugesChart";
import PreparedChart from "./components/PreparedChart";
import CartPanel from "./components/CartPanel";
import Alerts from "./components/Alerts";
import Activity from "./components/Activity";
import LastPacked from "./components/LastPacked";
import NotCompletedOldestThanThreeDays from "./components/NotCompletedOldestThanThreeDays";
import PackageBoxesStats from "./components/PackageBoxesStats";
import WithMissingsOldestThanFiveDays from "./components/WithMissingsOldestThanFiveDays";
import SinglePickingMode from "./components/SinglePickingMode";

import AveragePackages from "./components/AveragePackages";
import Absences from "./components/Absences";

let interval;

const AdminDashboard = ({
  dashboard,
  getAlerts,
  getPackageBoxes,
  getAveragePackages,
  getDashboard,
  getAbsences,
}) => {
  useEffect(() => {
    getAlerts();
    getPackageBoxes();
    getAveragePackages();
    getAbsences();
    getData({
      histogram_packer: dashboard.data.completed_histogram.packer,
      histogram_type: dashboard.data.completed_histogram.type,
      histogram_range: dashboard.data.completed_histogram.range,
    });
    return () => clearInterval(interval);
  }, []);

  const getData = (data) =>
    new Promise(async (_, reject) => {
      clearInterval(interval);
      getDashboard(data)
        .then(() => {
          interval = setInterval(() => {
            getDashboard(data);
          }, 10000);
        })
        .catch((err) => {
          console.log(err);
          reject();
        });
    });

  const handleRefetch = () =>
    getData({
      histogram_packer: dashboard.data.completed_histogram.packer,
      histogram_type: dashboard.data.completed_histogram.type,
      histogram_range: dashboard.data.completed_histogram.range,
    });

  const { data, status } = dashboard;

  return (
    <Main
      is_loading={["invalid", "loading", "failure"].includes(status)}
      page={{
        name: "Dashboard",
        icon: <DashboardIcon />,
        breadcrumbs: [],
        buttons: (
          <>
            <span className="timestamp" onClick={handleRefetch}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
              >
                <path
                  fill="#5d78ff"
                  d="M10.73,7.5h.063a.375.375,0,0,1,.373.338l.341,3.412,2.436,1.392a.375.375,0,0,1,.189.326v.157a.286.286,0,0,1-.362.276l-3.464-.945a.375.375,0,0,1-.275-.391l.325-4.22A.375.375,0,0,1,10.73,7.5Z"
                  transform="translate(-2.508 -1.875)"
                />
                <path
                  fill="#5d78ff"
                  opacity="0.3"
                  d="M5.792,2.436a7.865,7.865,0,1,1-4.5,5.117l1.444.405A6.367,6.367,0,1,0,6.812,3.651L7.7,4.715a.375.375,0,0,1-.266.615l-3.65.207A.375.375,0,0,1,3.4,5.076l.846-3.548A.375.375,0,0,1,4.9,1.374Z"
                  transform="translate(-0.25 -0.31)"
                />
              </svg>
              <TimeAgo date={dashboard.timestamp} />
            </span>
          </>
        ),
      }}
    >
      <div
        className="dashboard-view m-t-10"
        style={status === "updating" ? { pointerEvents: "none" } : {}}
      >
        <div className="boxes-wrapper">
          <CompletedBox
            packers={data?.completed_per_packer}
            pickers={data?.completed_per_picker}
            companies={data?.completed_per_company}
            value={data?.completed_total}
            is_colored={true}
            show_progress={true}
            with_picker_boxes={true}
          />
          <InProgressBox />
          <StatsBox data={data} />
        </div>
        <div className="boxes-wrapper">
          <div className="box">
            <LastCompletedChart getData={getData} />
            <GaugesChart />
            <PreparedChart />
            <AveragePackages />
            <PackageBoxesStats />
          </div>
          <div className="box">
            <CartPanel />
          </div>
          <div className="box">
            <Alerts />
            <Absences />
            <Activity />
            <LastPacked />
            <SinglePickingMode />
            <NotCompletedOldestThanThreeDays />
            <WithMissingsOldestThanFiveDays />
          </div>
        </div>
      </div>
    </Main>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getDashboard: (data) => dispatch(getDashboard(data)),
  getAlerts: () => dispatch(getAlerts()),
  getPackageBoxes: () => dispatch(getPackageBoxes()),
  getAveragePackages: () => dispatch(getAveragePackages()),
  getAbsences: () => dispatch(getAbsences()),
});
export default connect(
  ({ dashboard }) => ({ dashboard }),
  mapDispatchToProps
)(AdminDashboard);
