import React from "react";
import { useMainList } from "components/MainList/context/provider";
import { Pagination as ExpanoPagination } from "expano-components";
import { withRouter } from "react-router";

const Pagination = ({ location }) => {
  const { meta, setMetaPagination } = useMainList();

  return (
    <div className="main-list__pagination">
      <ExpanoPagination
        navigate_after_change={false}
        data={meta}
        location={location}
        handlePaginate={(page, per_page) =>
          setMetaPagination({ page, per_page })
        }
      />
    </div>
  );
};

export default withRouter(Pagination);
