import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { MainTable, Flag } from "components";
import { Main } from "layouts";
import {
  getPacks,
  filterPacks,
  resetFilters,
  toggleFilters,
} from "actions/packs";
import { convertDate } from "utils/helpers";
import { parseQuery } from "utils/api";
import PackerModal from "./components/PackerModal";
import gauge_options from "utils/gauge_options";
import priority_options from "utils/priority_options";
import kind_options from "utils/kind_options";

import { ReactComponent as GdnPackIcon } from "icons/gdn_pack.svg";

import {
  status_options,
  getStatusLabelData,
} from "../const/statuses";
import getWarehouses from "utils/queries/getWarehouses";
import getUsers from "utils/queries/getUsers";

const PacksList = ({
  current_user,
  packs,
  getPacks,
  filterPacks,
  toggleFilters,
  resetFilters,
  location: { search },
}) => {
  const [is_loaded, setLoaded] = useState(false);
  const [show_packer_modal, showPackerModal] = useState(
    false
  );
  const [gdns_checked, setGdnChecked] = useState([]);
  const query = parseQuery(search);
  const sort = {
    "sort[column]": query["sort[column]"] || "created_at",
    "sort[order]": query["sort[order]"] || "desc",
  };
  useEffect(() => {
    return () => {
      setGdnChecked([]);
      resetFilters();
    };
  }, []);

  useEffect(() => {
    getPacks({ ...query, ...sort });
    setLoaded(true);
    setGdnChecked([]);
  }, [search]);

  useEffect(() => {
    if (is_loaded) {
      getPacks({ ...query, page: undefined });
      setGdnChecked([]);
    }
  }, [packs.filters.query]);

  if (packs.status === "failure") {
    return <p>Error</p>;
  }

  const { data, meta } = packs;

  return (
    <Main
      page={{
        name: "Paki wydań",
        icon: <GdnPackIcon />,
        breadcrumbs: [{ name: "Paki wydań" }],
      }}>
      {show_packer_modal && (
        <PackerModal
          data={data.filter(({ id }) =>
            gdns_checked.includes(id)
          )}
          handleClose={() => showPackerModal(false)}
          handleClear={() => {
            showPackerModal(false);
            setGdnChecked([]);
            getPacks({ ...query, ...sort });
          }}
        />
      )}
      <div className="dashboard">
        <MainTable
          is_loading={["invalid", "loading"].includes(
            packs.status
          )}
          is_searching={packs.status === "searching"}
          filters_open={packs.filters.open}
          toggleFilters={toggleFilters}
          data={data}
          meta={meta}
          sort={sort}
          head={[
            {
              label: "check_all",
              name: "check_all",
              checked:
                data?.length > 0 &&
                gdns_checked?.length === data?.length,
              onChange: ({ target: { checked } }) =>
                data?.length > 0
                  ? checked
                    ? setGdnChecked(
                        data.map(({ id }) => id)
                      )
                    : setGdnChecked([])
                  : null,
            },
            {
              label: "ID",
              name: "id",
              style: { width: 100 },
            },
            {
              label: "Flaga",
              name: "flag",
              sortable: false,
              style: { width: 120 },
            },
            {
              label: "Paker",
              name: "packing_person_name",
              style: { width: 150 },
            },
            {
              label: "Data utw.",
              name: "created_at",
              style: { width: 120 },
            },
            {
              label: "Magazyn",
              name: "warehouse_name",
              sortable: false,
              style: { width: 150 },
            },
            {
              label: "Metoda dostawy",
              name: "delivery_method_name",
            },
            {
              label: "Il. prod.",
              name: "total_products_quantity",
              sortable: false,
              style: { width: 170 },
            },
            {
              label: "Il. zam.",
              name: "gdns_count",
              sortable: false,
              style: { width: 150 },
            },
            {
              label: "",
              name: "status",
              sortable: false,
              style: { width: 170 },
            },
          ]}
          empty_text="Brak paków"
          renderRow={({
            id,
            created_at,
            warehouse,
            gdn_lines_total_quantity,
            delivery_method_name,
            gdns_count,
            gdns_completed_count,
            status,
            packer,
            kind,
            priority,
            gauge,
          }) => {
            const kind_value = kind_options
              .filter(({ value }) => value !== "normal")
              .find(({ value }) => value === kind)?.label;

            const priority_value = priority_options
              .filter(({ value }) => value !== "standard")
              .find(({ value }) => value === priority)
              ?.label;

            const gauge_value = gauge_options
              .filter(({ value }) => value !== "small")
              .find(({ value }) => value === gauge)?.label;

            const handleChange = () => {
              const is_checked = !!gdns_checked.find(
                (item) => item === id
              );
              !is_checked
                ? setGdnChecked([...gdns_checked, id])
                : setGdnChecked(
                    gdns_checked.filter(
                      (item) => item !== id
                    )
                  );
            };

            const label = getStatusLabelData(status);

            return (
              <tr key={id} style={{ cursor: "pointer" }}>
                <td>
                  <div className="form-group">
                    <div
                      className="check-group"
                      style={{ margin: 0 }}>
                      <input
                        id={`gdn_${id}`}
                        type="checkbox"
                        onChange={({
                          target: { checked },
                        }) =>
                          checked
                            ? setGdnChecked([
                                ...gdns_checked,
                                id,
                              ])
                            : setGdnChecked(
                                gdns_checked.filter(
                                  (item) => item !== id
                                )
                              )
                        }
                        checked={
                          !!gdns_checked.find(
                            (item) => item === id
                          )
                        }
                      />
                      <label
                        htmlFor={`gdn_${id}`}
                        className="label-form"
                        style={{ minHeight: 16, margin: 0 }}
                      />
                    </div>
                  </div>
                </td>
                {current_user.role === "admin" ? (
                  <td>
                    <a
                      className="link"
                      href={`/packing/gdn_pack/${id}`}
                      target="_blank"
                      rel="noopener noreferrer">
                      {id}
                    </a>
                  </td>
                ) : (
                  <td onClick={handleChange}>{id}</td>
                )}
                <td onClick={handleChange}>
                  {kind_value && <Flag text={kind_value} />}
                  {priority_value && (
                    <Flag text={priority_value} />
                  )}
                  {gauge_value && (
                    <Flag text={gauge_value} />
                  )}
                </td>
                <td onClick={handleChange}>
                  {packer?.full_name || "-"}
                </td>
                <td onClick={handleChange}>
                  {convertDate({ value: created_at })}
                </td>
                <td onClick={handleChange}>
                  {warehouse?.name}
                </td>
                <td onClick={handleChange}>
                  {delivery_method_name}
                </td>
                <td onClick={handleChange}>
                  {gdn_lines_total_quantity}
                </td>
                <td onClick={handleChange}>
                  {gdns_completed_count}/{gdns_count}
                </td>
                <td onClick={handleChange}>
                  <div
                    className={`status-label status-label--sm ${label.status}`}>
                    <span>{label.status_text}</span>
                  </div>
                </td>
              </tr>
            );
          }}
          filtersAction={filterPacks}
          filters={[
            {
              type: "input",
              label: "ID Paka",
              name: "id",
              search_type: "in",
            },
            {
              type: "multi-select",
              label: "Typ",
              name: "kind",
              search_type: "in",
              options: kind_options.map((item, index) => ({
                ...item,
                value: index,
              })),
            },
            {
              type: "multi-select",
              label: "Priorytet",
              name: "priority",
              search_type: "in",
              options: priority_options.map(
                (item, index) => ({
                  ...item,
                  value: index,
                })
              ),
            },
            {
              type: "multi-select",
              label: "Gabaryt",
              name: "gauge",
              search_type: "in",
              options: gauge_options.map((item, index) => ({
                ...item,
                value: index,
              })),
            },
            {
              type: "async-multi-select",
              label: "Paker",
              asyncAction: getUsers,
              name: "packer_id",
              search_type: "in",
            },
            {
              type: "async-multi-select",
              label: "Magazyn",
              search_type: "in",
              asyncAction: getWarehouses,
              name: "warehouse_id",
            },
            {
              type: "input",
              label: "Metoda dostawy",
              name: "delivery_method_name",
              search_type: "matches",
            },
            {
              type: "date",
              label: "Data utworzenia",
              name: "created_at",
              search_type: "date_matches",
            },
            {
              type: "multi-select",
              label: "Status",
              name: "status",
              search_type: "in",
              options: status_options.map(
                (item, index) => ({
                  ...item,
                  value: index,
                })
              ),
            },
          ]}
          buttons={[
            <button
              key="add_packer"
              disabled={gdns_checked.length === 0}
              onClick={() => showPackerModal(true)}
              type="button"
              className="btn btn-lg btn-blue">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                style={{ marginRight: 6 }}>
                <path
                  fill="#fff"
                  opacity="0.3"
                  d="M15.833,7.167H14.167a.833.833,0,0,1,0-1.667h1.667V3.833a.833.833,0,0,1,1.667,0V5.5h1.667a.833.833,0,1,1,0,1.667H17.5V8.833a.833.833,0,0,1-1.667,0Zm-7.5,2.5a3.333,3.333,0,1,1,3.333-3.333A3.333,3.333,0,0,1,8.333,9.667Z"
                  transform="translate(-0.833 -0.5)"
                />
                <path
                  fill="#fff"
                  d="M0,19c.323-3.977,3.551-6,7.486-6,3.99,0,7.268,1.911,7.512,6a.572.572,0,0,1-.626.667H.606A.923.923,0,0,1,0,19Z"
                  transform="translate(0 -2.167)"
                />
              </svg>
              <span>Przypisz pakera</span>
            </button>,
          ]}
        />
      </div>
    </Main>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getPacks: (data) => dispatch(getPacks(data)),
  filterPacks: (data) => dispatch(filterPacks(data)),
  toggleFilters: () => dispatch(toggleFilters()),
  resetFilters: () => dispatch(resetFilters()),
});
export default connect(
  ({ packs, current_user }) => ({
    packs,
    current_user,
  }),
  mapDispatchToProps
)(PacksList);
