import React from "react";
import { Tooltip } from "components";

import { ReactComponent as LocationIcon } from "icons/location.svg";
import { ReactComponent as RoomIcon } from "icons/room.svg";
import { ReactComponent as AisleIcon } from "icons/aisle.svg";
import { ReactComponent as RackIcon } from "icons/rack.svg";

import getLocationDataFromIdentifier from "utils/getLocationDataFromIdentifier";

const LocalizationInfo = ({
  show_tooltip = true,
  main = null,
  additional = [],
}) => {
  if (!main?.identifier) return <p>Brak lokalizacji</p>;
  const main_data = getLocationDataFromIdentifier(
    main?.identifier
  );
  const additional_locations_data = additional
    .slice(0, 2)
    .map(({ identifier }) =>
      getLocationDataFromIdentifier(identifier)
    );
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <span>{main?.identifier}</span>
      {show_tooltip && (
        <Tooltip
          trigger={<LocationIcon />}
          content={
            <>
              <div className="box-localization">
                <p>
                  <RoomIcon className="icon" />
                  <strong>
                    Pomieszczenie {main_data.room}
                  </strong>
                </p>
                <p>
                  <AisleIcon className="icon" />
                  <strong>Alejka {main_data.aisle}</strong>
                </p>
                <p>
                  <RackIcon className="icon" />
                  <span>Regał:</span>
                  <strong>{main_data.rack}</strong>
                </p>
                <p>
                  <span>Półka:</span>
                  <strong>{main_data.shelf}</strong>
                </p>
                <p>
                  <span>Miejsce:</span>
                  <strong>{main_data.position}</strong>
                </p>
              </div>
              {additional_locations_data.map(
                (item, index) => (
                  <div
                    className="box-localization"
                    key={index}>
                    <p>
                      <RoomIcon className="icon" />
                      <strong>
                        Pomieszczenie {item.room}
                      </strong>
                    </p>
                    <p>
                      <AisleIcon className="icon" />
                      <strong>Alejka {item.aisle}</strong>
                    </p>
                    <p>
                      <RackIcon className="icon" />
                      <span>Regał:</span>
                      <strong>{item.rack}</strong>
                    </p>
                    <p>
                      <span>Półka:</span>
                      <strong>{item.shelf}</strong>
                    </p>
                    <p>
                      <span>Miejsce:</span>
                      <strong>{item.position}</strong>
                    </p>
                  </div>
                )
              )}
            </>
          }
        />
      )}
    </div>
  );
};

export default LocalizationInfo;
