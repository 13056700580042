import React, { useState } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import {
  TopBar,
  Sidebar,
  PagePanel,
} from "expano-components";
import { QrCodeModal, PanelLoader } from "components";
import { handleLogout } from "actions/account";
import { getCookie } from "utils/cookies";
import { toggleSidebar } from "actions/sidebar";
import getNavByRole from "utils/nav";
import { url } from "utils/api";

const app = "wms";

const Main = ({
  is_loading = false,
  current_user,
  navigator,
  sidebar,
  children,
  location,
  toggleSidebar,
  page = {
    name: "",
    icon: null,
    buttons: [],
  },
}) => {
  const [
    show_qrcode_modal,
    handleShowQrCodeModal,
  ] = useState(false);

  const nav = getNavByRole(current_user.role);

  return (
    <>
      {show_qrcode_modal && (
        <QrCodeModal
          title={"QR kod autoryzacji"}
          value={`auth_token|${getCookie("token")}`}
          handleClose={() => handleShowQrCodeModal(false)}
        />
      )}
      <Sidebar
        app={app}
        Link={Link}
        nav={nav}
        is_open={sidebar.is_open}
        location={location}
      />
      <main className="main-container">
        <TopBar
          handleLogout={handleLogout}
          currentUser={{
            email: current_user.email,
            company: current_user.company?.name,
          }}
          navigator_download={
            navigator?.version && (
              <a
                className="topbar-navigator"
                href={`${url}/navigator/releases/${navigator.version}/navigator.apk`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <span>Navigator v{navigator.version}</span>
              </a>
            )
          }
          show_qrcode={true}
          is_open={sidebar.is_open}
          toggleSidebar={toggleSidebar}
          qrCodeAction={() => handleShowQrCodeModal(true)}
        />
        <div className="main-dashboard">
          <PagePanel app={app} Link={Link} data={page} />
          {is_loading ? (
            <PanelLoader />
          ) : (
            <div className="main-dashboard__container">
              {children}
            </div>
          )}
        </div>
      </main>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  toggleSidebar: () => dispatch(toggleSidebar()),
});

export default connect(
  ({ current_user, sidebar, navigator }) => ({
    sidebar,
    current_user,
    navigator,
  }),
  mapDispatchToProps
)(withRouter(Main));
