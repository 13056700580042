export const CURRENT_USER_LOADING = "CURRENT_USER_LOADING";
export const CURRENT_USER_SUCCESS = "CURRENT_USER_SUCCESS";
export const CURRENT_USER_FAILURE = "CURRENT_USER_FAILURE";
export const CURRENT_USER_UPDATE = "CURRENT_USER_UPDATE";

const initial_state = {
  status: "invalid",
  role: "none",
};

export default (state = initial_state, action) => {
  switch (action.type) {
    case CURRENT_USER_LOADING:
      return {
        ...state,
        status: "loading",
      };
    case CURRENT_USER_SUCCESS:
      return {
        ...state,
        status: "success",
        ...action.data,
      };
    case CURRENT_USER_FAILURE:
      return {
        ...state,
        status: "failure",
      };
    case CURRENT_USER_UPDATE:
      return {
        ...state,
        ...action.data,
      };
    default:
      return state;
  }
};
