import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { Button } from "expano-components";
import { AsyncSelect } from "components";
import { client_v2 } from "utils/api";
import { setPacker } from "actions/packs";

import { ReactComponent as DangerIcon } from "icons/danger.svg";
import { ReactComponent as AddPackerIcon } from "icons/add_packer.svg";

const getUsers = (q) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await client_v2.get(
        `/users?context=list&per_page=100${
          q
            ? `&q[first_name_or_last_name_matches]=%25${q}%25`
            : ""
        }`
      );
      resolve(
        data.map(({ id, full_name }) => ({
          label: full_name,
          value: id,
        }))
      );
    } catch (error) {
      console.log(error);
      reject([]);
    }
  });
};

const PackerModal = ({
  handleClose,
  handleClear,
  data,
}) => {
  const [active, setActive] = useState(false);
  const [packer, addPacker] = useState(null);
  useEffect(() => {
    setTimeout(() => {
      setActive(true);
    }, 200);
  }, []);
  return ReactDOM.createPortal(
    <div className="popup-wrapper packer-modal">
      <div className={`popup ${active ? "active" : ""}`}>
        <header className="popup__header">
          <div className="icon__container">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20">
              <path
                fill="#fff"
                opacity="0.3"
                d="M15.833,7.167H14.167a.833.833,0,0,1,0-1.667h1.667V3.833a.833.833,0,0,1,1.667,0V5.5h1.667a.833.833,0,1,1,0,1.667H17.5V8.833a.833.833,0,0,1-1.667,0Zm-7.5,2.5a3.333,3.333,0,1,1,3.333-3.333A3.333,3.333,0,0,1,8.333,9.667Z"
                transform="translate(-0.833 -0.5)"
              />
              <path
                fill="#fff"
                d="M0,19c.323-3.977,3.551-6,7.486-6,3.99,0,7.268,1.911,7.512,6a.572.572,0,0,1-.626.667H.606A.923.923,0,0,1,0,19Z"
                transform="translate(0 -2.167)"
              />
            </svg>
          </div>
          <h2 className="dashboard__title heading">
            Przypisz pakera
          </h2>
          <Button type="close" onClick={handleClose} />
        </header>
        <div className="popup__body">
          <p>Czy na pewno chcesz przypisać paczki nr:</p>
          <ul className="packs-list">
            {data.map(({ id, packer }) => (
              <li
                key={id}
                className={`item ${
                  packer?.full_name ? "danger" : ""
                }`}>
                {packer?.full_name && <DangerIcon />}
                {id}{" "}
                {packer?.full_name &&
                  `(przypisana do ${packer?.full_name})`}
              </li>
            ))}
          </ul>
          <AsyncSelect
            label="do pakera"
            getData={(q) => getUsers(q)}
            onChange={({ value }) => {
              addPacker(value);
            }}
          />
        </div>
        <div className="popup__footer">
          <Button type="cancel" onClick={handleClose} />
          <button
            type="button"
            disabled={!packer}
            className="btn btn-sm btn-green"
            onClick={() => {
              setPacker({
                ids: data.map(({ id }) => id),
                packer_id: packer,
              }).then(() => handleClear());
            }}>
            <AddPackerIcon />
            <span>Tak, przypisz</span>
          </button>
        </div>
      </div>
    </div>,
    document.getElementById("root")
  );
};

export default PackerModal;
