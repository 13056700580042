import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { MainTable } from "components";
import { Main } from "layouts";
import { parseQuery } from "utils/api";
import {
  getPackages,
  filterPackages,
  toggleFilters,
  resetFilters,
} from "actions/packages";

import moment from "moment";
import package_type_options, {
  getCourierByPackageType,
} from "utils/package_type_options";
import { Button } from "expano-components";
import { ReactComponent as ProductIcon } from "icons/product.svg";
import country_code_options from "utils/country_code_options";

const PackagesList = ({
  packages,
  getPackages,
  filterPackages,
  toggleFilters,
  resetFilters,
  location: { search },
  history,
}) => {
  const [is_loaded, setLoaded] = useState(false);
  const query = parseQuery(search);
  const sort = {
    "sort[column]": query["sort[column]"] || "created_at",
    "sort[order]": query["sort[order]"] || "desc",
  };

  useEffect(() => {
    return () => {
      resetFilters();
    };
  }, []);

  useEffect(() => {
    getPackages({ ...query, ...sort });
    setLoaded(true);
  }, [search]);

  useEffect(() => {
    if (is_loaded) {
      getPackages({ ...query, page: undefined });
    }
  }, [packages.filters.query]);

  if (packages.status === "failure") {
    return <p>Error</p>;
  }

  const { data, meta } = packages;
  return (
    <Main
      page={{
        name: "Paczki",
        icon: <ProductIcon />,
        breadcrumbs: [{ name: "Paczki" }],
        buttons: (
          <Button
            type="add"
            text="Nadaj paczkę"
            onClick={() => history.push("/packages/new")}
          />
        ),
      }}
    >
      <div className="dashboard">
        <MainTable
          is_loading={["invalid", "loading"].includes(
            packages.status
          )}
          is_searching={packages.status === "searching"}
          filters_open={packages.filters.open}
          toggleFilters={toggleFilters}
          data={data}
          meta={meta}
          sort={sort}
          head={[
            {
              label: "ID",
              name: "id",
              style: { width: 100 },
            },
            {
              label: "Kurier",
              name: "type",
              style: { width: 150 },
            },
            {
              label: "Numer listu",
              name: "courier_number",
            },
            {
              label: "ID zamówienia",
              name: "gdn_id",
            },
            {
              label: "Data utworzenia",
              name: "created_at",
            },
            {
              label: "Data exportu do OMS",
              name: "exported_to_oms_at",
            },
            {
              label: "Waga",
              name: "package_boxes_total_weight",
            },
            {
              label: "Waga gabarytowa",
              name: "package_boxes_total_gauge_weight",
            },
            {
              label: "",
              name: "actions",
              sortable: false,
            },
          ]}
          empty_text="Brak paczek"
          renderRow={({
            id,
            courier_number,
            created_at,
            exported_to_oms_at,
            type,
            gdn_id,
            waybill_url,
            package_boxes_total_weight,
            package_boxes_total_gauge_weight,
          }) => (
            <tr key={id}>
              <td>{id}</td>
              <td>{getCourierByPackageType(type)}</td>
              <td>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  className="link"
                  href={waybill_url}
                >
                  {courier_number}
                </a>
              </td>
              <td>
                <Link
                  className="link"
                  to={`/gdns/${gdn_id}`}
                >
                  {gdn_id}
                </Link>
              </td>
              <td>
                {created_at
                  ? moment(created_at).format(
                      "YYYY-MM-DD HH:mm"
                    )
                  : "-"}
              </td>
              <td>
                {exported_to_oms_at
                  ? moment(exported_to_oms_at).format(
                      "YYYY-MM-DD HH:mm"
                    )
                  : "-"}
              </td>
              <td>
                {package_boxes_total_weight
                  ? `${package_boxes_total_weight} kg`
                  : "-"}
              </td>
              <td>
                {package_boxes_total_gauge_weight
                  ? `${package_boxes_total_gauge_weight} kg`
                  : "-"}
              </td>
              <td>
                <Button
                  type="edit"
                  onClick={() =>
                    history.push(`/packages/${id}`)
                  }
                />
              </td>
            </tr>
          )}
          filtersAction={filterPackages}
          filters={[
            {
              type: "multi-select",
              label: "Kurier",
              name: "type",
              defaultValue: null,
              search_type: "in",
              options: package_type_options,
            },
            {
              type: "input",
              label: "Numer listu",
              name: "number_or_package_numbers",
              search_type: "matches",
            },
            {
              type: "input",
              label: "ID zamówienia",
              name: "gdn_id",
              search_type: "in",
            },
            {
              type: "date",
              label: "Data utworzenia",
              name: "created_at",
              search_type: "gt_eq",
            },
            {
              type: "date",
              label: "Data eksportu do OMS",
              name: "dispached_at",
              search_type: "gt_eq",
            },
            {
              type: "multi-select",
              label: "Kraj dostawy",
              name: "gdn_delivery_country_code",
              defaultValue: null,
              search_type: "in",
              options: country_code_options,
            },
          ]}
        />
      </div>
    </Main>
  );
};

export default connect(
  ({ packages }) => ({ packages }),
  (dispatch) => ({
    getPackages: (data) => dispatch(getPackages(data)),
    filterPackages: (data) =>
      dispatch(filterPackages(data)),
    toggleFilters: () => dispatch(toggleFilters()),
    resetFilters: () => dispatch(resetFilters()),
  })
)(PackagesList);
