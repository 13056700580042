const gdn_user_error_options = [
  { label: "Żle wykonane zdjęcie", value: "incorrect_photo" },
  { label: "Inny błąd w zamówieniu", value: "packing_other" },
  { label: "Inny błąd w zamówieniu", value: "picking_other" },
  { label: "Zły sposób zapakowania", value: "badly_packed" },
  { label: "Podwójne COD", value: "double_cod" },
  { label: "Niedostosowanie się do uwag zamówienia", value: "skip_note" },
];

export const gdn_line_user_error_options = [
  { label: "Blędny produkt", value: "wrong_product" },
  { label: "Błędna ilość produktu", value: "wrong_product_quantity" },
  { label: "Niekompletny produkt", value: "incomplete_product" },
];

export const gdn_all_user_error_options = [
  { label: "Blędny produkt", value: "wrong_product" },
  { label: "Błędna ilość produktu", value: "wrong_product_quantity" },
  { label: "Niekompletny produkt", value: "incomplete_product" },
  { label: "Żle wykonane zdjęcie", value: "incorrect_photo" },
  { label: "Inny błąd w zamówieniu", value: "packing_other" },
  { label: "Inny błąd w zamówieniu", value: "picking_other" },
  { label: "Zły sposób zapakowania", value: "badly_packed" },
  { label: "Podwójne COD", value: "double_cod" },
  { label: "Niedostosowanie się do uwag zamówienia", value: "skip_note" },
];

export const getUserErrorName = (type) =>
  gdn_user_error_options.find(({ value }) => value === type)?.label;

export const getGdnLineUserErrorName = (type) =>
  gdn_line_user_error_options.find(({ value }) => value === type)?.label;

export const getAllUserErrorName = (type) =>
  gdn_all_user_error_options.find(({ value }) => value === type)?.label;

export default gdn_user_error_options;
