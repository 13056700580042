import styled from "styled-components";

const StyledModalPhoto = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px;
  background-color: rgba(40, 42, 60, 0.76);
  .photo-wrapper {
    width: 100%;
    padding: 50px 60px;
    max-width: 870px;
    border-radius: 4px;
    box-shadow: 0px 0px 13px rgba(0, 0, 0, 0.05);
    background-color: #fff;
    display: flex;
    align-items: center;
    .box-qr {
      flex-basis: 250px;
      max-width: 250px;
      padding: 50px;
      border: 1px dashed rgba(226, 229, 236, 1);
      border-radius: 4px;
    }
    .box-text {
      padding-left: 50px;
      p {
        font-size: 19px;
        font-weight: 300;
        line-height: 30px;
        margin-bottom: 24px;
        color: #595d6e;
      }
      .title {
        font-size: 25px;
        font-weight: 500;
        line-height: 35px;
        margin-bottom: 20px;
        color: #646c9a;
      }
    }
  }
`;

export default StyledModalPhoto;
