import React, { useState } from "react";
import { withRouter } from "react-router";
import { MainList } from "components";

import ModalAddRack from "./components/ModalAddRack";
import ModalEditRack from "./components/ModalEditRack";

import { sort } from "./const/data";
import ModalDeleteRack from "./components/ModalDeleteRack";
import ModalGenerateQrCodesForRack from "./components/ModalGenerateQrCodesForRack";

const default_state = { type: null, data: null };

const RacksList = ({
  history,
  match: {
    params: {
      warehouse_id,
      warehouse_room_id,
      warehouse_aisle_id,
      warehouse_rack_id,
    },
  },
}) => {
  const [racks_modal, setRacksModal] = useState(default_state);
  const [qr_code_modal_rack_data, setQrCodeModalRackData] = useState(null);

  if (!warehouse_room_id || !warehouse_aisle_id) return null;

  const onClose = () => setRacksModal(default_state);

  return (
    <>
      {Boolean(qr_code_modal_rack_data) && (
        <ModalGenerateQrCodesForRack
          {...qr_code_modal_rack_data}
          handleClose={() => setQrCodeModalRackData(null)}
        />
      )}
      <div className="box-3">
        <MainList
          query_key="warehouse_racks"
          api_path="/warehouse_racks"
          api_ransack={`q[warehouse_aisle_id_eq]=${warehouse_aisle_id}`}
          api_context="ui_index"
          title="Lista regałów"
          sort_column="position"
          sort_direction="asc"
          hide_meta={true}
          sort={sort}
          activeIndexes={(data) => {
            const indexes = [];
            data.forEach((item, index) => {
              if (item.id === warehouse_rack_id) {
                indexes.push(index);
              }
            });
            return indexes;
          }}
          handleNavigate={({ id }) =>
            history.push(
              `/warehouses/${warehouse_id}/rooms/${warehouse_room_id}/aisles/${warehouse_aisle_id}/racks/${id}`
            )
          }
          renderActions={(item) =>
            [
              {
                type: "qr_code",
                handleClick: () => setQrCodeModalRackData(item),
              },
              {
                type: "edit",
                handleClick: () =>
                  setRacksModal({
                    type: "edit",
                    data: item,
                  }),
              },
              // !item.racks_count && {
              //   type: "remove",
              //   handleClick: () =>
              //     setRacksModal({
              //       type: "remove",
              //       data: item,
              //     }),
              // },
            ].filter(Boolean)
          }
          renderItem={({
            identifier,
            name,
            is_variation_uniqueness_enabled,
            warehouse_rack_schema,
          }) => (
            <>
              <td>{identifier}</td>
              <td>{name}</td>
              <td>{warehouse_rack_schema?.name || "-"}</td>
              <td>{is_variation_uniqueness_enabled ? "Tak" : "Nie"}</td>
            </>
          )}
          bottom_action={{
            name: "Dodaj regał",
            onClick: () => setRacksModal({ type: "create" }),
          }}
        >
          {racks_modal?.type === "create" && (
            <ModalAddRack
              handleClose={onClose}
              initialValues={{
                warehouse_aisle_id,
                name: "",
                identifier: "",
              }}
            />
          )}
          {racks_modal?.type === "edit" && (
            <ModalEditRack
              handleClose={onClose}
              {...racks_modal.data}
              initialValues={{
                name: racks_modal?.data?.name || "",
                identifier: racks_modal?.data?.identifier || "",
                is_variation_uniqueness_enabled:
                  racks_modal?.data?.is_variation_uniqueness_enabled || false,
              }}
            />
          )}
          {racks_modal?.type === "remove" && (
            <ModalDeleteRack handleClose={onClose} {...racks_modal.data} />
          )}
        </MainList>
      </div>
    </>
  );
};

export default withRouter(RacksList);
