const initialState = {
  status: "invalid",
  data: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "GDN_PACKS_LOADING":
      return {
        ...state,
        status: "loading",
      };
    case "GDN_PACKS_SUCCESS":
      return {
        ...state,
        status: "success",
        ...action,
      };
    case "GDNS_PACKS_MORE_SUCCESS": {
      return {
        ...state,
        meta: action.meta,
        data: [...state.data, ...action.data],
      };
    }
    case "GDN_PACKS_FAILURE":
      return {
        ...state,
        status: "failure",
      };
    default:
      return state;
  }
};
