import styled from "styled-components";

const StyledProductLine = styled.div`
  display: flex;
  justify-content: space-between;
  .product-holder {
    display: flex;
    align-items: center;
    flex: 1;
    .product-image {
      width: 130px;
      height: 130px;
      background-color: #a2a5b9;
      background-position: center;
      background-size: cover;
    }
    .product-details {
      flex: 1;
      padding: 0 20px;
      .sku {
        font-size: 19px;
        line-height: 20px;
        margin-bottom: 4px;
        font-weight: 500;
        color: #646c9a;
      }
      .name {
        font-size: 15px;
        line-height: 20px;
        font-weight: 300;
        color: #566374;
      }
      .codes {
        font-size: 15px;
        line-height: 24px;
        font-weight: 300;
        color: rgba(89, 93, 110, 0.5);
        span {
          display: inline-block;
          padding-right: 12px;
        }
      }
    }
    .quantity {
      text-align: center;
      padding: 0px 12px;
      min-width: 55px;
      display: inline-block;
      border-radius: 4px;
      border: 2px solid #e2e5ec;
      &__value {
        font-size: 39px;
        line-height: 55px;
        font-weight: 500;
        color: #646c9a;
      }
    }
  }
`;
export default StyledProductLine;
