import React, { useState } from "react";
import { api_url_v2 } from "utils/api";
import moment from "moment";
import { exportWaybill } from "actions/gdns";
import { connect } from "react-redux";

const Waybill = ({
  id,
  gdn_id,
  courier_number,
  exported_to_oms_at,
  package_boxes,
  is_created_manually,
  exportWaybill,
  can_export_waybill,
}) => {
  const [is_loading, setLoading] = useState(false);

  const handleExportWaybill = async () => {
    try {
      setLoading(true);
      await exportWaybill({ id, gdn_id });
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };
  return (
    <li>
      {package_boxes.map(
        ({
          id,
          size,
          width,
          height,
          depth,
          weight,
          gauge_weight,
          volume,
        }) => (
          <p key={id}>
            {size} ({width}x{height}x{depth}) w: {weight} kg
            | wg.: {gauge_weight || "-"} kg | obj.:{" "}
            {volume || "-"} cm
          </p>
        )
      )}
      {is_created_manually && <p>Utworzona ręcznie</p>}
      <p>
        <a
          href={`${api_url_v2}packages/${id}/download_label`}
          rel="noopener noreferrer"
          target="_blank"
        >
          {courier_number}
        </a>
      </p>
      <div className="m-b-5">
        {exported_to_oms_at ? (
          <div
            className={`status-label status-label--sm success`}
          >
            <span>
              Wyeksportowano do OMS (
              {moment(exported_to_oms_at).format(
                "DD.MM.YYYY HH:mm"
              )}
              )
            </span>
          </div>
        ) : (
          <div className="status-label status-label--sm danger">
            <span>Brak etykiety na OMS</span>
          </div>
        )}
      </div>
      <button
        disabled={!can_export_waybill || is_loading}
        className="btn btn-blue btn-sm"
        onClick={handleExportWaybill}
      >
        <span>
          {is_loading ? "Eksportowanie..." : "Eksportuj"}
        </span>
      </button>
    </li>
  );
};

export default connect(
  ({ gdn }) => ({
    can_export_waybill: gdn?.data?.status === "completed",
  }),
  (dispatch) => ({
    exportWaybill: (data) => dispatch(exportWaybill(data)),
  })
)(Waybill);
