import styled from "styled-components";

const StyledAddPack = styled.div`
  max-width: 650px;
  margin: 0 auto;
  text-align: center;
  button {
    width: 100%;
    justify-content: center;
    padding: 10px 20px;
    span {
      font-size: 14px;
      line-height: 18px;
      color: #fff;
    }
  }
  .btn-more {
    max-width: 120px;
    span {
      color: #595d6e;
    }
  }
`;

export default StyledAddPack;
