import React from "react";
import { Document, Image, Font, Page, Text, View, StyleSheet } from "@react-pdf/renderer";

import RobotoBlack from "fonts/Roboto-Black.ttf";

Font.register({
  family: "Roboto",
  fonts: [{ src: RobotoBlack }],
});

const Sticker3x2 = ({ data, format, image }) => {
  return (
    <Document>
      <Page size={{ width: 305, height: 57 }} style={styles.page}>
        <View style={styles.wrapper}>
          <View style={styles.box}>
            <View style={styles.text}>
              <Text style={styles.sku}>{data.sku}</Text>
            </View>
            <Image src={image} />
          </View>
          <View style={styles.box}>
            <View style={styles.text}>
              <Text style={styles.sku}>{data.sku}</Text>
            </View>
            <Image src={image} />
          </View>
          <View style={styles.box}>
            <View style={styles.text}>
              <Text style={styles.sku}>{data.sku}</Text>
            </View>
            <Image src={image} />
          </View>
        </View>
      </Page>
    </Document>
  );
};
export default Sticker3x2;

const styles = StyleSheet.create({
  page: {
    backgroundColor: "#fff",
    paddingTop: 5,
  },
  wrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  box: { flexBasis: 100 },
  text: {
    textAlign: "center",
  },
  sku: {
    fontFamily: "Roboto",
    fontSize: 8,
    textTransform: "uppercase",
    letterSpacing: 1,
    marginBottom: 3,
  },
});