import React from "react";
import { connect } from "react-redux";
import { ActionCableConsumer } from "react-actioncable-provider";
import GdnsList from "./GdnsList";
import { gdnPackStartPacking } from "actions/pack_gdns";

const GdnPackGdnsListActionCable = (props) => {
  return (
    <ActionCableConsumer
      channel={{
        channel: "PackerChannel",
        gdn_pack_id: props.match.params.pack_id,
      }}
      onReceived={(res) => {
        switch (res.event) {
          case "start_packing":
            props.gdnPackStartPacking(
              props.match.params.pack_id
            );
            break;
          case "stop_packing":
            props.history.push("/packing");
            break;
          default:
            break;
        }
      }}>
      <GdnsList {...props} />
    </ActionCableConsumer>
  );
};

export default connect(null, (dispatch) => ({
  gdnPackStartPacking: (pack_id) =>
    dispatch(gdnPackStartPacking(pack_id)),
}))(GdnPackGdnsListActionCable);
