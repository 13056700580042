import React, { useState } from "react";
import { connect } from "react-redux";
import { Button, Input, Select } from "expano-components";
import { Field, reduxForm } from "redux-form";
import { createGdnLineIssue } from "actions/gdns";

import { Modal } from "components";
import { gdn_line_user_error_options } from "utils/gdn_user_error_options";

import { ReactComponent as InfoIcon } from "icons/info.svg";

const default_kind = "wrong_product";

const SelectField = (props) => <Select {...props} />;

const CreateGdnLineIssueModal = ({
  gdn_line_id,
  invalid,
  change,
  handleSubmit,
  handleClose,
  createGdnLineIssue,
  gdn_lines,
}) => {
  const [is_loading, setLoading] = useState(false);

  const gdn_line = gdn_lines.find(({ id }) => id === gdn_line_id);

  const onSubmit = async (values) => {
    try {
      setLoading(true);
      await createGdnLineIssue(gdn_line_id, values);
      setLoading(false);
      handleClose();
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  return (
    <Modal
      prevent_click_outside={true}
      handleClose={handleClose}
      header={{
        title: "Zgłoś błąd pozycji zamówienia",
        icon: <InfoIcon />,
      }}
      footer={
        <>
          <Button type="cancel" text="Anuluj" onClick={handleClose} />
          <Button
            disabled={is_loading || invalid}
            type="save"
            text={is_loading ? "Trwa zgłaszanie..." : "Zgłoś"}
            onClick={handleSubmit(onSubmit)}
          />
        </>
      }
    >
      <form autoComplete="off" noValidate onSubmit={handleSubmit(onSubmit)}>
        <Input
          label="Pozycja zamówienia"
          meta={{ touched: false }}
          input={{ name: "gdn_line_id", value: gdn_line?.product?.sku }}
        />
        <Field
          label="Rodzaj błędu"
          name="kind"
          component={SelectField}
          handleChange={(value) => change("kind", value)}
          options={gdn_line_user_error_options}
          defaultValue={gdn_line_user_error_options.find(
            ({ value }) => value === default_kind
          )}
        />
      </form>
    </Modal>
  );
};

export default connect(
  ({
    gdn: {
      data: { gdn_lines },
    },
  }) => ({
    gdn_lines,
    initialValues: {
      kind: default_kind,
    },
  }),
  (dispatch) => ({
    createGdnLineIssue: (id, values) =>
      dispatch(createGdnLineIssue(id, values)),
  })
)(
  reduxForm({
    form: "create_gdn_line_user_error",
    enableReinitialize: true,
  })(CreateGdnLineIssueModal)
);
