import React, { useState } from "react";
import { connect } from "react-redux";
import { gdnPackClose } from "actions/pack_gdns";

import StyledPackEmpty from "./styles";

const PackEmpty = ({ id }) => {
  const [is_loading, setLoading] = useState(false);
  return (
    <StyledPackEmpty
      disabled={is_loading}
      type="button"
      onClick={async () => {
        try {
          setLoading(true);
          await gdnPackClose(id);
          setLoading(false);
          window.location.pathname = "/packing";
        } catch (error) {
          setLoading(false);
        }
      }}>
      <span>
        {is_loading
          ? "Trwa zamykanie. Proszę czekać..."
          : "Zamknij pustego paka"}
      </span>
    </StyledPackEmpty>
  );
};

export default connect(
  ({
    pack_gdns: {
      data: { id },
    },
  }) => ({
    id,
  })
)(PackEmpty);
