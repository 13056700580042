export const sort = [
  { label: "ID", name: "id", style: { width: "25%" } },
  { label: "Nazwa", name: "name", style: { width: "25%" } },
  {
    label: "Ulica",
    name: "street",
    style: { width: "20%" },
    sortable: false,
  },
  {
    label: "Miasto",
    name: "city",
    style: { width: "20%" },
    sortable: false,
  },
];

export const filters = [
  {
    type: "input",
    name: "id",
    sort_id: "id",
    search_type: "matches",
  },
  {
    type: "input",
    name: "name",
    sort_id: "name",
    search_type: "matches",
  },
  {
    type: "input",
    name: "street",
    sort_id: "street",
    search_type: "matches",
  },
  {
    type: "input",
    name: "city",
    sort_id: "city",
    search_type: "matches",
  },
];
