import React from "react";
import Select from "react-select";

const MultiSelect = ({ options = [], label, defaultValue, handleChange }) => {
  const customStyles = {
    control: (styles) => ({
      ...styles,
      borderColor: "#E2E5EC",
      borderWidth: 1,
      borderRadius: 4,
      minHeight: 40,
    }),
    option: (provided) => ({
      ...provided,
      fontFamily: "Poppins",
      fontSize: "13px",
    }),
    singleValue: (provided) => ({
      ...provided,
      fontFamily: "Poppins",
      fontSize: "13px",
      padding: "6px",
    }),
    indicatorSeparator: (styles) => ({ ...styles, display: "none" }),
    placeholder: (provided) => ({
      ...provided,
      fontFamily: "Poppins",
      fontSize: "13px",
    }),
    multiValue: (provided) => ({
      ...provided,
      flexDirection: "row-reverse",
      backgroundColor: "#F3F6F9",
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      paddingRight: "6px",
      fontSize: 11,
      color: "#595D6E",
    }),
  };
  return (
    <div className="inp-wrapper">
      {label && <label className="label-form m-b-5">{label}</label>}
      <Select
        onChange={handleChange}
        styles={customStyles}
        isMulti={true}
        placeholder={"Wybierz..."}
        options={options || []}
        defaultValue={defaultValue}
      />
    </div>
  );
};

export default MultiSelect;
