import { toastr } from "react-redux-toastr";
import { client_v2, stringifyQuery } from "utils/api";

export const toggleFilters = () => (dispatch) => {
  dispatch({ type: "PRODUCTS_TOGGLE_FILTERS" });
};

export const resetFilters = () => (dispatch) => {
  dispatch({ type: "PRODUCTS_RESET_FILTERS" });
};

export const filterProducts = (query) => async (dispatch) => {
  dispatch({ type: "PRODUCTS_SET_FILTERS_QUERY", query });
};

export const getProducts =
  ({ page = 1, per_page = 50, ...rest }) =>
  async (dispatch, getState) => {
    try {
      const {
        status,
        filters: { query },
      } = getState().products;

      dispatch({
        type: status === "invalid" ? "PRODUCTS_LOADING" : "PRODUCTS_SEARCHING",
      });

      const { data, meta } = await client_v2.get(
        `/products?${stringifyQuery({
          ...rest,
          context: "ui_index",
          page,
          per_page,
          ...query,
        })}`
      );
      dispatch({
        type: "PRODUCTS_SUCCESS",
        data,
        meta,
      });
    } catch (err) {
      dispatch({ type: "PRODUCTS_FAILURE" });
      toastr.error("Błąd", "Wystąpił błąd podczas pobierania");
    }
  };

export const getProduct = (id) => (dispatch, getState) =>
  new Promise(async (resolve, reject) => {
    try {
      const { status } = getState().edit_product;

      if (status === "invalid") {
        dispatch({ type: "EDIT_PRODUCT_LOADING" });
      }

      const { data } = await client_v2.get(`/products/${id}?context=ui_show`);
      dispatch({
        type: "EDIT_PRODUCT_SUCCESS",
        data,
      });
      resolve(data);
    } catch (error) {
      dispatch({ type: "EDIT_PRODUCT_FAILURE" });
      reject(error);
      toastr.error("Błąd", "Wystąpił błąd podczas pobierania");
    }
  });

export const updateProduct = (id, values) => (dispatch) =>
  new Promise(async (resolve, reject) => {
    try {
      await client_v2.put(`/products/${id}`, values);
      const { data } = await client_v2.get(`/products/${id}`);

      dispatch({
        type: "EDIT_PRODUCT_SUCCESS",
        data,
      });
      resolve(data);
      toastr.success("Sukces", "Produkt został zaktualizowany");
    } catch (error) {
      reject(error);
      toastr.error("Błąd", "Wystąpił błąd podczas pobierania");
    }
  });

export const createEan =
  ({ code, quantity, product_id }) =>
  (dispatch) =>
    new Promise(async (resolve, reject) => {
      try {
        const {
          data: { id },
        } = await client_v2.post("/logistic_eans", {
          code,
          quantity,
          product_id,
        });

        const { data } = await client_v2.get(`/logistic_eans/${id}`);

        dispatch({
          type: "EDIT_PRODUCT_ADD_EAN",
          data,
        });
        toastr.success("Sukces", "Kod został dodany");

        resolve();
      } catch (error) {
        toastr.error("Błąd", "Wystąpił błąd");
        reject(error?.response?.data?.error);
      }
    });

export const editEan =
  (id, { code, quantity }) =>
  (dispatch) =>
    new Promise(async (resolve, reject) => {
      try {
        await client_v2.put(`/logistic_eans/${id}`, {
          code,
          quantity,
        });

        const { data } = await client_v2.get(`/logistic_eans/${id}`);

        toastr.success("Sukces", "Kod został zaktualizowany");

        dispatch({
          type: "EDIT_PRODUCT_EDIT_EAN",
          data,
        });
        resolve();
      } catch (error) {
        toastr.error("Błąd", "Wystąpił błąd");
        reject(error?.response?.data?.error);
      }
    });

export const deleteEan = (id) => (dispatch) =>
  new Promise(async (resolve, reject) => {
    try {
      await client_v2.delete(`/logistic_eans/${id}`);
      dispatch({ type: "EDIT_PRODUCT_DELETE_EAN", id });
      toastr.success("Sukces", "Kod został usunięty");
      resolve();
    } catch (error) {
      toastr.error("Błąd", "Kod nie został usunięty");
      reject(error?.response?.data?.error);
    }
  });

export const createProductSupplier = (values) => (dispatch) =>
  new Promise(async (resolve, reject) => {
    try {
      const {
        data: { id },
      } = await client_v2.post("/product_suppliers", values);

      const { data } = await client_v2.get(
        `/product_suppliers/${id}?context=ui_product_show`
      );

      dispatch({
        type: "CREATE_PRODUCT_SUPPLIER",
        data,
      });

      toastr.success("Sukces", "Produkt dostawcy został dodany");
      resolve(data);
    } catch (error) {
      toastr.error(
        "Błąd",
        error?.response?.data?.errors?.[0]?.source?.pointer.includes(
          "product_id"
        )
          ? "Ten dostawca jest już dodany do produktu"
          : "Wystąpił błąd podczas dodawania produktu dostawcy"
      );
      reject(error);
    }
  });

export const updateProductSupplier = (id, values) => (dispatch) =>
  new Promise(async (resolve, reject) => {
    try {
      await client_v2.put(`/product_suppliers/${id}`, values);
      const { data } = await client_v2.get(
        `/product_suppliers/${id}?context=ui_product_show`
      );

      dispatch({
        type: "UPDATE_PRODUCT_SUPPLIER",
        data,
      });

      toastr.success("Sukces", "Produkt dostawcy został zaktualizowany");

      resolve(data);
    } catch (error) {
      toastr.error(
        "Błąd",
        error?.response?.data?.errors?.[0]?.source?.pointer.includes(
          "product_id"
        )
          ? "Ten dostawca jest już dodany do produktu"
          : "Wystąpił błąd podczas dodawania dostawcy produktu"
      );
      reject(error);
    }
  });

export const deleteProductSupplier = (id) => (dispatch) =>
  new Promise(async (resolve, reject) => {
    try {
      await client_v2.delete(`/product_suppliers/${id}`);
      toastr.success("Sukces", "Dostawca produktu został usuniety");

      dispatch({
        type: "DELETE_PRODUCT_SUPPLIER",
        id,
      });
      resolve(id);
    } catch (error) {
      toastr.error("Błąd", "Wystąpił błąd podczas usuwania dostawcy produktu");
      reject(error);
    }
  });

export const createInventarizationTask =
  ({ product_id, product_main_location_id }) =>
  async (dispatch) => {
    try {
      await client_v2.post("/warehouse_task/inventarization_tasks", {
        product_main_location_id,
      });
      toastr.success("Sukces", "Utworzono zadanie inwentaryzacyjne");
      await dispatch(getProduct(product_id));
    } catch (error) {
      toastr.error(
        "Błąd",
        error?.response?.data?.message || "Nie udało się utworzyć zadania"
      );
      throw error;
    }
  };

export const reportAboutOutOfDatePhoto = async (product_id) => {
  try {
    await client_v2.post(`/products/${product_id}/report_issue`, {
      issue: "out_of_date_photo",
    });
    toastr.success("Sukces", "Wysłano powiadomienie o nieaktualnm zdjęciu");
  } catch (error) {
    toastr.error("Błąd", "Wystąpił błąd");
    throw error;
  }
};

export const updateProductsByCsv = async (file) => {
  try {
    const form_data = new FormData();

    form_data.append("file", file);

    const { data } = await client_v2.post(
      `/products/update_by_csv`,
      form_data,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    toastr.success("Sukces", "Produkty zostały zaktualizowane");

    return data;
  } catch (error) {
    throw error?.response?.data?.error?.message || "Wystąpił błąd";
  }
};
