import React from "react";
import getPackerPointsInfo from "utils/packer_points";

const PackerStatisticsBar = ({
  show_label = true,
  value,
  worked_hours = 8,
}) => {
  const {
    daily_full_limit,
    daily_third_limit,
    daily_second_limit,
    daily_first_limit,
    level_info,
  } = getPackerPointsInfo(value, worked_hours);

  const daily_progress = (value * 100) / daily_full_limit;
  const daily_third_position = (daily_third_limit * 100) / daily_full_limit;
  const daily_second_position = (daily_second_limit * 100) / daily_full_limit;
  const daily_first_position = (daily_first_limit * 100) / daily_full_limit;
  if (worked_hours === 0) {
    return null;
  }
  return (
    <div className="packer-statistics-bar">
      {show_label && (
        <p className="packer-statistics-bar__label">
          Ilość zamówień {level_info ? `(Osiągnięto ${level_info})` : ""}
        </p>
      )}
      <div className="packer-statistics-bar__progress">
        <div
          className="progress-inner"
          style={{ width: `${daily_progress}%` }}
        />
        <div
          className="progress-separator"
          data-limit={daily_first_limit}
          style={{ left: `${daily_first_position}%` }}
        />
        <div
          className="progress-separator"
          data-limit={daily_second_limit}
          style={{ left: `${daily_second_position}%` }}
        />
        <div
          className="progress-separator"
          data-limit={daily_third_limit}
          style={{ left: `${daily_third_position}%` }}
        />
      </div>
    </div>
  );
};

export default PackerStatisticsBar;
