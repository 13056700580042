import React, { useCallback, useState } from "react";
import { connect } from "react-redux";

import { ReactComponent as InfoIcon } from "icons/info.svg";
import { reportAboutOutOfDatePhoto } from "actions/products";

const ImageForm = ({ data }) => {
  const [is_reporting, setReporting] = useState(false);
  const handleReport = useCallback(async () => {
    try {
      setReporting(true);
      await reportAboutOutOfDatePhoto(data.id);
    } catch (error) {
      console.log(error);
    } finally {
      setReporting(false);
    }
  }, [data?.id]);

  return (
    <div className="dashboard m-b-10">
      <header className="dashboard__header dashboard__header--edit">
        <div className="icon__container">
          <InfoIcon />
        </div>
        <h2 className="dashboard__title heading">
          Zdjęcie
        </h2>
        <button
          type="button"
          className="btn btn-sm btn-blue"
          disabled={is_reporting}
          onClick={handleReport}>
          <span>
            {is_reporting
              ? "Proszę czekać"
              : "Zdjęcie nieaktualne"}
          </span>
        </button>
      </header>
      <div className="dashboard__form">
        {data?.image_url && (
          <img
            style={{ width: "100%" }}
            src={data.image_url}
            alt=""
          />
        )}
      </div>
    </div>
  );
};

export default connect(({ edit_product }) => ({
  data: edit_product.data,
}))(ImageForm);
