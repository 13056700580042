import React from "react";
import ReactSelect from "react-select";

const Select = ({
  label,
  options = [],
  onChange,
  value = null,
  is_loading = false,
  is_multi = false,
}) => {
  const style = {
    control: (styles) => ({
      ...styles,
      borderColor: "#E2E5EC",
      borderWidth: 1,
      borderRadius: 4,
      minHeight: 30,
    }),
    option: (provided) => ({
      ...provided,
      fontFamily: "Poppins",
      fontSize: "13px",
    }),
    singleValue: (provided) => ({
      ...provided,
      fontFamily: "Poppins",
      fontSize: "13px",
      padding: 0,
    }),
    valueContainer: (provided) => ({
      ...provided,
      padding: "1px 10px",
    }),
    indicatorSeparator: (styles) => ({
      ...styles,
      display: "none",
    }),
    placeholder: (provided) => ({
      ...provided,
      fontFamily: "Poppins",
      fontSize: "13px",
    }),
    multiValue: (provided) => ({
      ...provided,
      flexDirection: "row-reverse",
      backgroundColor: "#F3F6F9",
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      paddingRight: "0 6px",
      fontSize: 10,
      color: "#595D6E",
    }),
  };
  return (
    <div className="inp-wrapper">
      {label && <label className="label-form">{label}</label>}
      <ReactSelect
        onChange={onChange}
        styles={style}
        placeholder={"Wybierz..."}
        options={options}
        value={value}
        isDisabled={is_loading}
        isLoading={is_loading}
        isMulti={is_multi}
      />
    </div>
  );
};

export default Select;
