import React from "react";
import AddItem from "./components/AddItem";
import { connect } from "react-redux";
import Items from "./components/Items";

const PackageBoxesArray = ({
  package_boxes,
  fields,
  gdn_weight,
  is_open_add_package,
  setOpenAddPackage,
}) => {
  const handleAdd = ({ id, name, sizes = {} }) => {
    fields.push({
      id,
      name,
      ...sizes,
      weight: fields.length === 0 ? gdn_weight : 0,
    });
    setOpenAddPackage(false);
  };

  return is_open_add_package ? (
    <AddItem
      handleAdd={handleAdd}
      handleBack={() => setOpenAddPackage(false)}
    />
  ) : (
    <Items
      setOpenAddPackage={setOpenAddPackage}
      fields={fields}
      package_boxes={package_boxes}
    />
  );
};

export default connect(
  ({
    pack_gdn: {
      data: { delivery_method_name, weight },
    },
  }) => ({ delivery_method_name, gdn_weight: weight || 0 })
)(PackageBoxesArray);
