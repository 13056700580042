import React from "react";
import moment from "moment";
import { Button } from "expano-components";
import { Modal } from "components";
import { connect } from "react-redux";
import { removeScanned, markAsPackedGdnLine } from "actions/pack_gdn";
import { getGdnLineUserErrorName } from "utils/gdn_user_error_options";

import { ReactComponent as WarningIcon } from "icons/warning.svg";
import { ReactComponent as DangerIcon } from "icons/danger.svg";

const GdnErrorsPreview = ({ gdn_lines, gdn_line_id, handleClose, mode }) => {
  const gdn_line = gdn_lines.find(({ id }) => id === gdn_line_id);

  const gdn_user_errors = gdn_line?.gdn_user_errors?.filter(
    ({ is_created_after_send }) =>
      mode === "packer" ? is_created_after_send : !is_created_after_send
  );

  return (
    <Modal
      size="lg"
      no_padding={true}
      header={{
        icon: mode === "picker" ? <WarningIcon /> : <DangerIcon />,
        title: `Błędy ${
          mode === "picker" ? "pikera" : "pakera"
        } zgłoszone dla produktu ${gdn_line?.product?.name}`,
      }}
      footer={<Button type="cancel" text="Zamknij" onClick={handleClose} />}
      handleClose={handleClose}
    >
      {gdn_user_errors?.length > 0 ? (
        <ul className="dashboard__list">
          {gdn_user_errors.map(({ created_at, kind, user, issuer, points }) => (
            <li className="dashboard__list-item">
              <div className="m-r-15">
                <p className="highlighted">{getGdnLineUserErrorName(kind)}</p>
                <p>
                  Data zgłoszenia:{" "}
                  {moment(created_at).format("DD-MM-YYYY HH:mm")}
                </p>
                <p>
                  Osoba odpowiedzialna: {user?.first_name} {user?.last_name}
                </p>
                <p>
                  Osoba zgłaszająca: {issuer?.first_name} {issuer?.last_name}
                </p>
                <p>Ilość punktów: {points}</p>
              </div>
            </li>
          ))}
        </ul>
      ) : (
        <div className="p-20">
          <p>Brak zgłoszonych błędów</p>
        </div>
      )}
    </Modal>
  );
};

const mapDispatchToProps = (dispatch) => ({
  markAsPackedGdnLine: (id) => dispatch(markAsPackedGdnLine(id)),
  removeScanned: () => dispatch(removeScanned()),
});

export default connect(
  ({
    gdn: {
      data: { gdn_lines },
    },
  }) => ({ gdn_lines }),
  mapDispatchToProps
)(GdnErrorsPreview);
