const shipment_warehouse_id = 2;
const empty_position_identifier = "B01B01-R01P01M01";

const initial_state = {
  status: "invalid",
  filters: { sku: "", location: "", warehouse_id: null },
  sort: { order: null, column: null },
  data: {},
};

const checkGrnLinesLocation = (data) => {
  const data_without_empty_position = data.map((grn_line) => ({
    ...grn_line,
    product: {
      ...grn_line.product,
      product_main_locations: grn_line.product.product_main_locations.filter(
        ({ warehouse_position }) =>
          warehouse_position.identifier !== empty_position_identifier
      ),
    },
  }));

  return data_without_empty_position.map((grn_line) => ({
    ...grn_line,
    has_location_in_shipment_warehouse: grn_line.product.product_main_locations
      .map(({ warehouse: { id } }) => id)
      .includes(shipment_warehouse_id),
  }));
};

const getLogisticCodes = (grn_lines) =>
  Object.fromEntries(
    grn_lines
      .map(({ product }) =>
        product.logistic_eans.map(({ code, quantity }) => [code, quantity])
      )
      .flat()
  );

export default (state = initial_state, action) => {
  const filterGrnLines = (items) => {
    let {
      filters: { sku, location, warehouse_id },
    } = state;
    return items.map((item) => {
      const current_location = item?.product?.product_main_locations?.find(
        (item) => item?.warehouse_id === Number(warehouse_id)
      );

      const logistic_ean_codes = item.product.logistic_eans.map(
        ({ code, quantity }) => ({ code, quantity })
      );
      const checkLogisticCodes = () =>
        !!logistic_ean_codes.find(({ code }) =>
          code.toLowerCase().includes(sku.toLowerCase())
        );

      if (
        (item.product?.sku?.toLowerCase().includes(sku.toLowerCase()) ||
          item.product?.producer_code
            ?.toLowerCase()
            .includes(sku.toLowerCase()) ||
          checkLogisticCodes() ||
          item.product?.name?.toLowerCase().includes(sku.toLowerCase())) &&
        (current_location?.identifier?.toLowerCase() || "").includes(
          location.toLowerCase()
        )
      ) {
        return { ...item, show: true };
      }
      return { ...item, show: false };
    });
  };

  const sortGrnLines = (data) => data.sort((a, b) => a.no - b.no);

  switch (action.type) {
    case "SET_GRN_LINES_SORT": {
      return { ...state, sort: action.data };
    }
    case "SORT_GRN_LINES": {
      let grn_lines = [...state.data.grn_lines];
      const { order, column } = state.sort;
      switch (column) {
        case "sku": {
          if (order === "desc") {
            grn_lines.sort(
              (item, next) =>
                Number(item.product.sku.replace("SE", "")) -
                Number(next.product.sku.replace("SE", ""))
            );
          } else {
            grn_lines.sort(
              (item, next) =>
                Number(next.product.sku.replace("SE", "")) -
                Number(item.product.sku.replace("SE", ""))
            );
          }
          return {
            ...state,
            data: { ...state.data, grn_lines },
          };
        }
        case "name": {
          if (order === "desc") {
            grn_lines = grn_lines.sort((item, next) =>
              item.product.name.localeCompare(next.product.name)
            );
          } else {
            grn_lines = grn_lines.sort((item, next) =>
              next.product.name.localeCompare(item.product.name)
            );
          }
          return {
            ...state,
            data: { ...state.data, grn_lines },
          };
        }
        case "zam": {
          if (order === "desc") {
            grn_lines = grn_lines.sort(
              (item, next) => item.quantity - next.quantity
            );
          } else {
            grn_lines = grn_lines.sort(
              (item, next) => next.quantity - item.quantity
            );
          }
          return {
            ...state,
            data: { ...state.data, grn_lines },
          };
        }
        case "no": {
          if (order === "desc") {
            grn_lines = grn_lines.sort((item, next) => item.no - next.no);
          } else {
            grn_lines = grn_lines.sort((item, next) => next.no - item.no);
          }
          return {
            ...state,
            data: { ...state.data, grn_lines },
          };
        }
        default:
          return state;
      }
    }
    case "SET_GRN_LINES_FILTER": {
      const { name, value } = action;
      const new_filters = { ...state.filters };
      new_filters[name] = value;
      return { ...state, filters: new_filters };
    }
    case "FILTER_GRN_LINES": {
      let grn_lines = filterGrnLines([...state.data.grn_lines]);

      return {
        ...state,
        data: { ...state.data, grn_lines },
      };
    }
    case "GRN_LOADING":
      return {
        ...state,
        status: "loading",
      };
    case "GRN_SUCCESS": {
      return {
        ...state,
        status: "success",
        filters: {
          ...state.filters,
          warehouse_id: action.data?.warehouses?.[0]?.id,
        },
        data: {
          ...action.data,
          grn_lines_to_download: sortGrnLines(action.data.grn_lines),
          grn_lines: filterGrnLines(
            sortGrnLines(checkGrnLinesLocation(action.data.grn_lines))
          ),
          logistic_codes: getLogisticCodes(action.data.grn_lines),
        },
      };
    }
    case "GRN_UPDATE_SUCCESS": {
      const grn_lines = [...state.data.grn_lines];
      return {
        ...state,
        data: {
          ...state.data,
          ...action.data,
          grn_lines,
        },
      };
    }
    case "GRN_FAILURE":
      return {
        ...state,
        status: "failure",
      };
    case "GRN_LINE_SUCCESS": {
      try {
        const grn_lines = [...state.data.grn_lines];
        const index = grn_lines.indexOf(
          grn_lines.find(({ id }) => id === action.grn_line.id)
        );
        if (index > -1) {
          grn_lines[index] = {
            ...grn_lines[index],
            ...action.grn_line,
            show: true,
          };
        }
        return {
          ...state,
          data: {
            ...state.data,
            status: action?.grn_status ? action.grn_status : state.data.status,
            progress: action?.grn_progress || 0,
            grn_lines: checkGrnLinesLocation(grn_lines),
          },
        };
      } catch (error) {
        console.log(error);
        return state;
      }
    }
    case "GRN_UPDATE_NOTE": {
      return {
        ...state,
        data: { ...state.data, note: action.note },
      };
    }
    case "GRN_UPDATE_GRN_LINE_CAPACITY": {
      const grn_lines = [...state.data.grn_lines];
      const grn_line_index = grn_lines.indexOf(
        grn_lines.find(({ id }) => id === action.grn_line_id)
      );
      if (grn_line_index > -1) {
        const product_main_locations =
          grn_lines[grn_line_index].product.product_main_locations;
        const product_main_location_index = product_main_locations.indexOf(
          product_main_locations.find(
            ({ id }) => id === action.product_main_location_id
          )
        );
        if (product_main_location_index > -1) {
          grn_lines[grn_line_index].product.product_main_locations[
            product_main_location_index
          ].capacity = action.capacity;
          grn_lines[grn_line_index].product.product_main_locations[
            product_main_location_index
          ].is_final_capacity = action.is_final_capacity;
        }
      }

      return {
        ...state,
        data: {
          ...state.data,
          grn_lines,
        },
      };
    }
    case "SYNC_GRN_LINE": {
      const { grn, ...grn_line } = action.data;
      const grn_lines = [...state.data.grn_lines];
      const grn_line_index = grn_lines.indexOf(
        grn_lines.find(({ id }) => id === grn_line.id)
      );

      if (grn_line_index > -1) {
        grn_lines[grn_line_index] = {
          ...grn_lines[grn_line_index],
          ...grn_line,
        };
      }

      return {
        ...state,
        data: {
          ...state.data,
          progress: grn.progress,
          status: grn.status,
          grn_lines,
        },
      };
    }
    default:
      return state;
  }
};
