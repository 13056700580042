import styled from "styled-components";

const StyledPackInit = styled.div`
  display: flex;
  align-items: flex-start;
  margin-top: 20px;
  .dashboard {
    margin: 10px;
    flex: 1;
  }
  @media screen and (max-width: 1300px) {
    flex-flow: wrap;
    .dashboard {
      flex-basis: 100%;
      max-width: 100%;
    }
  }
`;

export default StyledPackInit;
