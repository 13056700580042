import React, { useMemo } from "react";
import moment from "moment";
import { connect } from "react-redux";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Tooltip,
  Legend,
  Filler
);

const getLabelDateFormat = (type) => {
  switch (type) {
    case "year":
      return "YYYY";
    case "month":
      return "MM-YYYY";
    case "hour":
      return "DD-MM-YYYY HH:mm";
    default:
      return "DD-MM-YYYY";
  }
};

const options = {
  responsive: true,
  interaction: {
    mode: "index",
    intersect: false,
  },
  stacked: false,
  scales: {
    y: {
      type: "linear",
      display: true,
      position: "left",
      min: 0,
    },
  },
};

const warehouse_colors = {
  1: {
    borderColor: "rgba(247, 174, 99, 1)",
    backgroundColor: "rgba(247, 174, 99, 0.7)",
  },
  2: {
    borderColor: "rgba(59, 162, 235, 1)",
    backgroundColor: "rgba(59, 162, 235, 0.7)",
  },
};

const WarehouseStocksChart = ({ data, interval, active_warehouse_ids }) => {
  const warehouses = useMemo(
    () =>
      data.warehouses.map(({ name, value }) => ({
        label: name,
        value: value,
        borderColor: warehouse_colors[value]?.borderColor,
        backgroundColor: warehouse_colors[value]?.backgroundColor,
      })),
    []
  );

  const active_warehouse_histogram = warehouses.filter(({ value }) =>
    active_warehouse_ids.includes(value)
  );

  const getWarehouseStats = (warehouse_id) =>
    data.warehouse_histogram.map(
      ({ warehouses }) => warehouses?.[warehouse_id] || 0
    );

  const chart_data_warehouse_stock = {
    labels: data.warehouse_histogram.map(({ date }) =>
      moment(date).format(getLabelDateFormat(interval))
    ),
    datasets:
      active_warehouse_histogram.length > 0
        ? active_warehouse_histogram?.map(
            ({ label, value, borderColor, backgroundColor }) => ({
              label,
              data: getWarehouseStats(value),
              fill: false,
              borderWidth: 2,
              borderColor,
              backgroundColor,
              yAxisID: "y",
            })
          )
        : [
            {
              label: "Wszystkie",
              data: data.warehouse_histogram.map(({ total }) => total),
              fill: false,
              borderWidth: 2,
              borderColor: "#5D78FF",
              backgroundColor: "#5D78FF",
            },
          ],
  };

  return (
    <Line
      width={window.innerWidth - 160}
      height="350px"
      options={options}
      data={chart_data_warehouse_stock}
    />
  );
};

export default connect(({ warehouse_stock_stats: { data, filters } }) => ({
  data,
  interval: filters.histogram_interval,
  active_warehouse_ids: filters.histogram_warehouse_ids,
}))(WarehouseStocksChart);
