import React from "react";

const SubList = ({
  parent_data,
  head,
  head_width = [],
  colspan = 3,
  highlighted_indexes,
  data,
  renderItem,
  is_open,
}) => {
  if (!is_open) return null;
  return (
    <tr>
      <td colSpan={colspan}>
        <table className="sub-list">
          <thead>
            <tr>
              {head.map((name, index) => (
                <th
                  key={name}
                  style={
                    head_width[index]
                      ? { width: head_width[index] }
                      : {}
                  }>
                  {name}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.map((item) => (
              <tr key={item.id}>
                {renderItem?.(item, parent_data)?.map(
                  (value, index) => (
                    <td key={index}>
                      {highlighted_indexes.includes(
                        index
                      ) ? (
                        <strong>{value}</strong>
                      ) : (
                        value
                      )}
                    </td>
                  )
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </td>
    </tr>
  );
};

export default SubList;
