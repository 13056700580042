import React from "react";

export const convertDate = ({
  value = null,
  seperator = "/",
  reverse = false,
  time_color = false,
}) => {
  if (!value) return "-";
  try {
    let date = value.split("T")[0].split("-");
    if (reverse) {
      date = date.reverse();
    }
    date = date.join(seperator);
    const time = value
      .split("T")[1]
      .split(":")
      .slice(0, 2)
      .join(":");
    return (
      <>
        <span>{date}</span>{" "}
        <span
          style={time_color ? { color: "#BBBDC5" } : {}}>
          {time}
        </span>
      </>
    );
  } catch (error) {
    console.log(error);
    return value;
  }
};

export const createColor = () =>
  "#" + ((Math.random() * 0xffffff) << 0).toString(16);
export const mapOrder = (array, order, key) => {
  array.sort(function(a, b) {
    var A = a[key],
      B = b[key];

    if (order.indexOf(A) > order.indexOf(B)) {
      return 1;
    } else {
      return -1;
    }
  });

  return array;
};

export const shuffleArray = (array) => {
  for (var i = array.length - 1; i > 0; i--) {
    var j = Math.floor(Math.random() * (i + 1));
    var temp = array[i];
    array[i] = array[j];
    array[j] = temp;
  }
  return array;
};

export const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export const getUnix = (val) =>
  val
    ? Math.round(new Date(val).getTime() / 1000)
    : Math.round(new Date().getTime() / 1000);

export const numberWithSpaces = (x) => {
  if (x === undefined || x === null) return "";
  var parts = x.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  return parts.join(".");
};
