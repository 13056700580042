const initialState = {
  status: "invalid",
  filters: {
    open: true,
    query: { "q[is_bundle_true]": 0 },
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "PRODUCTS_TOGGLE_FILTERS":
      return {
        ...state,
        filters: {
          ...state.filters,
          open: !state.filters.open,
        },
      };
    case "PRODUCTS_RESET_FILTERS":
      return {
        ...state,
        filters: {
          open: true,
          query: {},
        },
      };
    case "PRODUCTS_SET_FILTERS_QUERY":
      return {
        ...state,
        filters: { ...state.filters, query: action.query },
      };
    case "PRODUCTS_LOADING":
      return {
        ...state,
        status: "loading",
      };

    case "PRODUCTS_SEARCHING":
      return {
        ...state,
        status: "searching",
      };
    case "PRODUCTS_SUCCESS":
      return {
        ...state,
        status: "success",
        ...action,
      };
    case "PRODUCTS_FAILURE":
      return {
        ...state,
        status: "failure",
      };
    default:
      return state;
  }
};
