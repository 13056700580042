export const sort = [
  {
    label: "Kraj",
    name: "country_code",
  },
  {
    label: "Kod pocztowy",
    name: "zip_code",
  },
];

export const filters = [
  {
    type: "input",
    name: "country_code",
    sort_id: "country_code",
    search_type: "matches",
  },

  {
    type: "input",
    name: "zip_code",
    sort_id: "zip_code",
    search_type: "matches",
  },
];
