import React from "react";
import { connect } from "react-redux";
import { setFilter } from "actions/gdn_errors_stats";
import { gdn_all_user_error_options } from "utils/gdn_user_error_options";
import Select from "../Select";
import DatePicker from "../DatePicker";
import ErrorsChart from "./components/ErrorsChart";
import { date_options, getHistogramDates } from "utils/histogram_ranges";

const interval_options = [
  { label: "Godzinny", value: "hour" },
  { label: "Dzienny", value: "day" },
  { label: "Tygodniowy", value: "week" },
  { label: "Miesięczny", value: "month" },
  { label: "Roczny", value: "year" },
];

const Charts = ({ status, data, filters, setFilter }) => {
  const packer_options = [
    ...(data.users?.map(({ name, value }) => ({
      label: name,
      value: value,
    })) || []),
  ];

  const is_updating = status === "updating";

  return (
    <div className="dashboard chart-panel">
      <div className="chart-panel__body">
        <div className="chart-panel__form">
          <Select
            is_multi={true}
            label="Pracownik"
            is_loading={is_updating}
            onChange={(values) =>
              setFilter({
                histogram_user_names:
                  values?.length > 0 ? values.map(({ value }) => value) : [],
              })
            }
            options={packer_options}
            value={packer_options.filter(({ value }) =>
              filters.histogram_user_names.includes(value)
            )}
          />
          <Select
            is_multi={true}
            label="Rodzaj"
            is_loading={is_updating}
            onChange={(values) =>
              setFilter({
                histogram_kinds:
                  values?.length > 0 ? values.map(({ value }) => value) : [],
              })
            }
            options={gdn_all_user_error_options}
            value={gdn_all_user_error_options.filter(({ value }) =>
              filters.histogram_kinds.includes(value)
            )}
          />
          <Select
            label="Typ"
            is_loading={is_updating}
            onChange={({ value: histogram_interval }) =>
              setFilter({ histogram_interval })
            }
            options={interval_options}
            value={interval_options.find(
              ({ value }) => value === filters.histogram_interval
            )}
          />
          <Select
            label="Zakres"
            disabled={is_updating}
            onChange={({ value }) =>
              setFilter({
                histogram_date_option: value,
                ...getHistogramDates(value),
              })
            }
            options={date_options}
            value={date_options.find(
              ({ value }) => value === filters.histogram_date_option
            )}
          />
          <DatePicker
            name="created_at"
            label="Data utworzenia"
            onChange={(value) =>
              setFilter({
                histogram_range_from: value?.[0],
                histogram_range_to: value?.[1],
              })
            }
            value={[filters.histogram_range_from, filters.histogram_range_to]}
          />
        </div>
        <div className="chart-panel__chart">
          <ErrorsChart />
        </div>
      </div>
    </div>
  );
};

export default connect(
  ({ gdn_errors_stats: { data, status, filters } }) => ({
    data,
    filters,
    status,
  }),
  (dispatch) => ({
    setFilter: (filter) => dispatch(setFilter(filter)),
  })
)(Charts);
