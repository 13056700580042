import React from "react";
import { connect } from "react-redux";
import { Table } from "../../../components";
import { Confirm, Item } from "./components";

const Simplified = ({ data, searching_value }) => {
  const isDisabledEasyToPackProduct = ({
    counting_state,
    product,
  }) => {
    const logistic_eans =
      product?.logistic_eans?.map(({ code }) => code) || [];

    const matches_searching_value =
      product.sku === searching_value ||
      product?.producer_code === searching_value ||
      product?.ean === searching_value ||
      logistic_eans.includes(searching_value);

    return (
      counting_state === "pending" &&
      product.has_ean &&
      !matches_searching_value
    );
  };
  return (
    <>
      <div className="dashboard m-t-0 m-b-0">
        <Table
          switch_warehouse={false}
          renderItems={() =>
            data?.grn_lines.map((line) => (
              <Item
                warehouse_id={data?.warehouse?.id}
                form={String(line?.id)}
                isBlocked={
                  !line.has_location_in_shipment_warehouse ||
                  isDisabledEasyToPackProduct(line) ||
                  [
                    "completed",
                    "with_errors",
                    "finished",
                  ].includes(data?.status)
                }
                key={line?.id}
                initialValues={{
                  product_id: line?.product?.id,
                  counted_quantity: line?.counted_quantity,
                }}
                data={line}
              />
            ))
          }
        />
      </div>
      <Confirm />
    </>
  );
};

export default connect(
  ({
    grn: {
      data,
      filters: { sku },
    },
  }) => ({
    data,
    searching_value: sku,
  })
)(Simplified);
