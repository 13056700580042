import React from "react";
import { connect } from "react-redux";

import { package_boxes_modal_list } from "utils/package_boxes";
import { ReactComponent as ArrowPrevIcon } from "icons/arrow-back.svg";
import classNames from "classnames";

const AddItem = ({
  delivery_method_name,
  handleAdd,
  handleBack,
}) => {
  const is_paczkomat =
    delivery_method_name.includes("Paczkomaty");

  const is_foreign = delivery_method_name.includes(
    "Kurier międzynarodowy"
  );

  const package_boxes_modal_list_to_show =
    package_boxes_modal_list.map((item) => ({
      ...item,
      show: item.is_gauge
        ? is_paczkomat
          ? false
          : true
        : true,
    }));

  const handleFocusOnAddedFormRow = () => {
    const el = document.querySelector(
      "#packages_form .form-group:last-child .box-3:first-child input"
    );
    if (el) {
      el.select();
    }
  };
  return (
    <div className="package-boxes">
      <button
        className="btn btn-sm btn-cancel"
        type="cancel"
        onClick={handleBack}
      >
        <ArrowPrevIcon
          style={{ transform: "scaleX(-1)" }}
        />
        <span>Wróć</span>
      </button>
      {package_boxes_modal_list_to_show.map(
        ({ show, label, items }) =>
          show ? (
            <div className="package-boxes__box" key={label}>
              <h3>{label}</h3>
              <ul className="box-list">
                <div className="box-list__image">
                  <img
                    src={require(`icons/speditor-${
                      label === "Koperty"
                        ? "envelope"
                        : "package"
                    }.png`)}
                    alt="Ikona"
                  />
                </div>
                {items.map(({ id, name, sizes }) => {
                  const is_disabled =
                    is_foreign && id === "k_20";

                  return (
                    <li
                      className={classNames(
                        "box-list__item",
                        {
                          disabled: is_disabled,
                        }
                      )}
                      key={id}
                      onClick={() => {
                        if (is_disabled) {
                          return;
                        }
                        handleAdd({ id, name, sizes });
                        setTimeout(() => {
                          handleFocusOnAddedFormRow();
                        }, 50);
                      }}
                    >
                      <span>{name}</span>
                    </li>
                  );
                })}
              </ul>
            </div>
          ) : null
      )}
    </div>
  );
};

export default connect(
  ({
    pack_gdn: {
      data: { delivery_method_name },
    },
  }) => ({ delivery_method_name })
)(AddItem);
