const box_or_envelope = "box_or_envelope";
const cant_use_stretch = "cant_use_stretch";

const package_warnings = {
  "Kurier UPS": {
    warnings: [box_or_envelope, cant_use_stretch],
    max_weight: 30,
    longest_side: 100,
    max_size: "d + (2 x sz) + (2 x w) <= 300",
  },
  "Allegro Kurier UPS": {
    warnings: [box_or_envelope, cant_use_stretch],
    max_weight: 30,
    longest_side: 100,
    max_size: "d + (2 x sz) + (2 x w) <= 300",
  },
  "Allegro One Box": {
    warnings: [box_or_envelope, cant_use_stretch],
    max_weight: 20,
    longest_side: 64,
    max_size: "64 cm x 41 cm x 38 cm",
  },
  "Allegro One Box, UPS": {
    warnings: [box_or_envelope, cant_use_stretch],
    max_weight: 20,
    longest_side: 64,
    max_size: "64 cm x 41 cm x 38 cm",
  },
  "Allegro One Box, DPD": {
    warnings: [box_or_envelope, cant_use_stretch],
    max_weight: 20,
    longest_side: 64,
    max_size: "64 cm x 41 cm x 38 cm",
  },
  "Allegro One Punkt": {
    warnings: [box_or_envelope, cant_use_stretch],
    max_weight: 20,
    longest_side: 64,
    max_size: "64 cm x 41 cm x 38 cm",
  },
  "Allegro One Punkt, UPS": {
    warnings: [box_or_envelope, cant_use_stretch],
    max_weight: 20,
    longest_side: 64,
    max_size: "64 cm x 41 cm x 38 cm",
  },
  "Allegro One Punkt, DPD": {
    warnings: [box_or_envelope, cant_use_stretch],
    max_weight: 20,
    longest_side: 64,
    max_size: "64 cm x 41 cm x 38 cm",
  },
  "Allegro International Kurier": {
    warnings: [box_or_envelope, cant_use_stretch],
    max_weight: 30,
    longest_side: 120,
    max_size: "d + sz + w <= 220 cm",
  },
  "Allegro International Odbiór w Punkcie": {
    warnings: [box_or_envelope, cant_use_stretch],
    max_weight: 15,
    longest_side: 120,
    max_size: "d + sz + w <= 220 cm",
  },
  "Allegro International Odbiór w Punkcie, One": {
    warnings: [box_or_envelope, cant_use_stretch],
    max_weight: 15,
    longest_side: 120,
    max_size: "d + sz + w <= 220 cm",
  },
  "Allegro International Automaty Paczkowe": {
    warnings: [box_or_envelope, cant_use_stretch],
    max_weight: 15,
    longest_side: 58,
    max_size: "64 cm x 41 cm x 38 cm",
  },
  "Allegro International Automaty Paczkowe, One": {
    warnings: [box_or_envelope, cant_use_stretch],
    max_weight: 15,
    longest_side: 58,
    max_size: "64 cm x 41 cm x 38 cm",
  },
  "Allegro Odbiór w Punkcie Pocztex": {
    warnings: [box_or_envelope, cant_use_stretch],
    max_weight: 20,
    longest_side: 70,
    max_size: "70 cm x 60 cm x 60 cm",
  },
  "Allegro Automat Pocztex": {
    warnings: [box_or_envelope, cant_use_stretch],
    max_weight: 20,
    longest_side: 65,
    max_size: "42 cm x 40 cm x 65 cm",
  },
  "Allegro Odbiór w Punkcie ORLEN Paczka": {
    warnings: [box_or_envelope, cant_use_stretch],
    max_weight: 25,
    longest_side: 60,
    max_size: "41 cm x 38 cm x 60 cm",
  },
  "Allegro Automat ORLEN Paczka": {
    warnings: [box_or_envelope, cant_use_stretch],
    max_weight: 25,
    longest_side: 60,
    max_size: "41 cm x 38 cm x 60 cm",
  },
  "Kurier GLS": {
    warnings: [],
    max_weight: 30,
    longest_side: 200,
    max_size: "200 cm x 80 cm x 60 cm | d + (2 x sz) + (2 x w) <= 300 cm",
  },
  "Allegro Automaty Paczkowe Packeta": {
    warnings: [box_or_envelope, cant_use_stretch],
    max_weight: 15,
    longest_side: 120,
    max_size: "61 cm x 36 cm x 45 cm | d + sz + w <= 150 cm",
  },
  "Allegro Odbiór w Punkcie Packeta": {
    warnings: [box_or_envelope, cant_use_stretch],
    max_weight: 15,
    longest_side: 120,
    max_size: "d + sz + w <= 150 cm",
  },
  "Kurier DHL": {
    warnings: [box_or_envelope, cant_use_stretch],
    max_weight: 30,
    longest_side: 120,
    max_size: "120 cm x 60 cm x 60 cm | d + (2 x sz) + (2 x w) <= 360",
  },
  "Allegro Kurier DHL": {
    warnings: [box_or_envelope, cant_use_stretch],
    max_weight: 30,
    longest_side: 120,
    max_size: "120 cm x 60 cm x 60 cm | d + (2 x sz) + (2 x w) <= 360",
  },
  "Allegro Odbiór w Punkcie DHL": {
    warnings: [box_or_envelope, cant_use_stretch],
    max_weight: 25,
    longest_side: 64,
    max_size: "64 cm x 41 cm x 38 cm | d + (2 x sz) + (2 x w) <= 220",
  },
  "Allegro Automat DHL POP BOX": {
    warnings: [box_or_envelope, cant_use_stretch],
    max_weight: 25,
    longest_side: 64,
    max_size: "64 cm x 41 cm x 38 cm | d + (2 x sz) + (2 x w) <= 220",
  },
  "Allegro Odbiór w Punkcie UPS": {
    warnings: [box_or_envelope, cant_use_stretch],
    max_weight: 20,
    longest_side: 97,
    max_size: "d + (2 x sz) + (2 x w) <= 300",
  },
  "Paczkomaty InPost": {
    warnings: [],
    max_weight: 20,
    longest_side: 64,
    max_size: "64 cm x 41 cm x 38 cm",
  },
  "Allegro Paczkomaty InPost": {
    warnings: [],
    max_weight: 20,
    longest_side: 64,
    max_size: "64 cm x 41 cm x 38 cm",
  },
  "Erli Paczkomaty InPost": {
    warnings: [],
    max_weight: 20,
    longest_side: 64,
    max_size: "64 cm x 41 cm x 38 cm",
  },
  "Allegro Kurier24 InPost": {
    warnings: [],
    max_weight: 30,
    longest_side: 80,
    max_size: null,
  },
  "Allegro miniKurier24 InPost": {
    warnings: [],
    max_weight: 10,
    longest_side: 80,
    max_size: null,
  },
  "Kurier DPD": {
    warnings: [],
    max_weight: 30,
    longest_side: 175,
    max_size: null,
  },
  "Allegro Kurier DPD": {
    warnings: [box_or_envelope, cant_use_stretch],
    max_weight: 30,
    longest_side: 150,
    max_size: "d + sz + w <= 300 cm",
  },
  "Odbiór w Punkcie DPD Pickup": {
    warnings: [box_or_envelope, cant_use_stretch],
    max_weight: 20,
    longest_side: 100,
    max_size: "d + (2 x sz.) + (2 x w) <= 250 cm PL | d + (2 x sz.) + (2 x w) <= 300 cm",
  },
  "Allegro Odbiór w Punkcie DPD Pickup": {
    warnings: [box_or_envelope, cant_use_stretch],
    max_weight: 20,
    longest_side: 100,
    max_size: "d + (2 x sz.) + (2 x w) <= 250 cm PL | d + (2 x sz.) + (2 x w) <= 300 cm",
  },
  "Kurier InPost": {
    warnings: [],
    max_weight: 30,
    longest_side: 120,
    max_size: "d + sz + w <= 220 cm",
  },
  "Erli Kurier InPost - 30 kg": {
    warnings: [],
    max_weight: 30,
    longest_side: 80,
    max_size: null,
  },
  "Erli Kurier InPost - 10 kg": {
    warnings: [],
    max_weight: 10,
    longest_side: 80,
    max_size: null,
  },
};

function getEqualValues(arr) {
  if (arr.length === 0) {
    return null;
  }

  const first_element = arr[0];
  if (arr.every((element) => element === first_element)) {
    return first_element;
  }
  return null;
}

function getEqualArrayValues(arr) {
  if (arr.length === 0) {
    return [];
  }
  if (arr.some((element) => element.length === 0)) {
    return [];
  }
  const shortest_array = arr.sort(
    (a, b) => a.length - b.length
  )[0];

  if (
    arr.every((element) =>
      element.every((el) => shortest_array.includes(el))
    )
  ) {
    return shortest_array;
  }
  return [];
}

const getPackageWarnings = (methods) => {
  if (!methods || methods.length === 0) {
    return null;
  }
  const data = methods
    .map((method) => package_warnings?.[method])
    .filter(Boolean);

  if (data.length === 1) {
    return data[0];
  }

  if (data.length > 1) {
    const warnings = getEqualArrayValues(
      data.map(({ warnings }) => warnings)
    );
    const max_weight = getEqualValues(
      data.map(({ max_weight }) => max_weight)
    );
    const longest_side = getEqualValues(
      data.map(({ longest_side }) => longest_side)
    );
    const max_size = getEqualValues(
      data.map(({ max_size }) => max_size)
    );
    if (
      warnings.length === 0 &&
      !max_weight &&
      !longest_side &&
      !max_size
    ) {
      return null;
    }
    return {
      warnings,
      max_weight,
      longest_side,
      max_size,
    };
  }

  return null;
};

export default getPackageWarnings;
