import React from "react";

import { connect } from "react-redux";
import { Button } from "expano-components";

import { Modal } from "components";

import { ReactComponent as LogIcon } from "icons/log.svg";

const LogsModal = ({ handleClose, logs = [] }) => {
  return (
    <Modal
      handleClose={handleClose}
      size="md"
      header={{ title: "Logi", icon: <LogIcon /> }}
      footer={
        <Button
          type="cancel"
          text="Zamknij"
          onClick={handleClose}
          style={{ marginRight: 15 }}
        />
      }>
      {logs?.length > 0 && (
        <ul className="logs-list">
          {logs
            .sort(
              (item, next) =>
                new Date(next.created_at).getTime() -
                new Date(item.created_at).getTime()
            )
            .map(({ id, created_at, content, user }) => (
              <li className="logs-list__item" key={id}>
                <p className="title">
                  {created_at} {user?.first_name}{" "}
                  {user?.last_name}
                </p>
                <p>{content}</p>
              </li>
            ))}
        </ul>
      )}
    </Modal>
  );
};

export default connect(({ grn: { data: { logs } } }) => ({
  logs,
}))(LogsModal);
