import React, { useState } from "react";
import { connect } from "react-redux";
import {
  markAsPackedGdnLine,
  markAsMissingGdnLine,
  notifyAboutWrongPick,
  unMarkAsPackedGdnLine,
  unMarkAsMissingGdnLine,
} from "actions/pack_gdn";

import { ButtonGroup } from "components";

import { ReactComponent as CheckIcon } from "icons/check.svg";
import { ReactComponent as DangerIcon } from "icons/danger.svg";
import { ReactComponent as ArrowIcon } from "icons/arrow-next.svg";
import { ReactComponent as CloseIcon } from "icons/close.svg";
import { ReactComponent as ProblemIcon } from "icons/problem.svg";

import MissingModal from "./MissingModal";
import PackConditionallyModal from "./PackConditionallyModal";

const Action = ({
  status,
  id,
  product,
  missing_quantity,
  markAsPackedGdnLine,
  markAsMissingGdnLine,
  unMarkAsPackedGdnLine,
  unMarkAsMissingGdnLine,
  pack_gdn,
}) => {
  const [show_issue_group, handleShowIssueGroup] = useState(false);
  const [show_missing_modal, handleShowMissingModal] = useState(false);
  const [show_pack_conditionally_modal, handleShowPackConditionallyModal] =
    useState(false);

  const is_disabled = pack_gdn.data.status === "completed";

  const handleNotify = (kind) =>
    notifyAboutWrongPick({ gdn_line_id: id, gdn_id: pack_gdn.data.id, kind });

  switch (status) {
    case "picked":
      return (
        <div className="action">
          {show_issue_group ? (
            <div className="buttons-group">
              {show_missing_modal && (
                <MissingModal
                  product={product}
                  id={id}
                  markAsMissingGdnLine={markAsMissingGdnLine}
                  handleClose={() => handleShowMissingModal(false)}
                />
              )}
              {show_pack_conditionally_modal && (
                <PackConditionallyModal
                  product={product}
                  id={id}
                  handleConfirm={() => markAsPackedGdnLine(id)}
                  handleClose={() => handleShowPackConditionallyModal(false)}
                />
              )}
              <div className="buttons-group-col">
                {(product?.has_ean || product.has_qr_code) && (
                  <button
                    className="btn btn-warning"
                    onClick={() => handleShowPackConditionallyModal(true)}
                  >
                    <span>Spakuj warunkowo</span>
                    <ArrowIcon style={{ marginLeft: 10 }} />
                  </button>
                )}
                <button
                  className="btn btn-danger"
                  onClick={() => handleShowMissingModal(true)}
                >
                  <DangerIcon />
                  <span>Brak produktu</span>
                </button>
                <ButtonGroup
                  trigger={{
                    icon: <ProblemIcon />,
                    text: "Zgłoś błąd",
                  }}
                  buttons={[
                    {
                      text: "Błędny produkt",
                      action: () => handleNotify("wrong_product"),
                    },
                    {
                      text: "Błędna ilość",
                      action: () => handleNotify("wrong_product_quantity"),
                    },
                    {
                      text: "Niekompletny produkt",
                      action: () => handleNotify("incomplete_product"),
                    },
                  ]}
                />
              </div>
              <button
                className="btn btn-icon"
                onClick={() => handleShowIssueGroup(false)}
              >
                <CloseIcon />
              </button>
            </div>
          ) : (
            <div className="buttons-group">
              {!product?.has_ean && !product.has_qr_code && (
                <button
                  className="btn btn-green"
                  onClick={() => markAsPackedGdnLine(id)}
                >
                  <span>Spakowane</span>
                  <ArrowIcon />
                </button>
              )}
              <button
                className="btn btn-icon btn-warning"
                onClick={() => handleShowIssueGroup(true)}
              >
                <DangerIcon />
              </button>
            </div>
          )}
        </div>
      );
    case "packed":
      return (
        <div className="action">
          <div className="action-group correct">
            <div className="box-icon">
              <CheckIcon />
            </div>
            <div className="box-text">
              <p>Potwierdzone</p>
              {!is_disabled && (
                <button
                  type="button"
                  className="btn-default"
                  onClick={() => unMarkAsPackedGdnLine(id)}
                >
                  Cofnij
                </button>
              )}
            </div>
          </div>
        </div>
      );
    case "missing":
      return (
        <div className="action">
          <div className="action-group missing">
            <div className="box-icon">
              <DangerIcon />
            </div>
            <div className="box-text">
              <p>
                Brak{" "}
                {!!missing_quantity && (
                  <strong>{parseInt(missing_quantity)?.toFixed(0)} szt.</strong>
                )}
              </p>
              {!is_disabled && (
                <button
                  type="button"
                  className="btn-default"
                  onClick={() => unMarkAsMissingGdnLine(id)}
                >
                  Cofnij
                </button>
              )}
            </div>
          </div>
        </div>
      );
    default:
      return null;
  }
};
const mapDispatchToProps = (dispatch) => ({
  markAsPackedGdnLine: (id) => dispatch(markAsPackedGdnLine(id)),
  markAsMissingGdnLine: (data) => dispatch(markAsMissingGdnLine(data)),
  unMarkAsPackedGdnLine: (id) => dispatch(unMarkAsPackedGdnLine(id)),
  unMarkAsMissingGdnLine: (id) => dispatch(unMarkAsMissingGdnLine(id)),
});

export default connect(
  ({ pack_gdn }) => ({ pack_gdn }),
  mapDispatchToProps
)(Action);
