import React from "react";
import { Field, reduxForm } from "redux-form";
import { Input, Button, Select } from "expano-components";
import { Main } from "layouts";
import { createIslandZipCode } from "actions/island_zip_codes";
import { connect } from "react-redux";

import { ReactComponent as DeliveryIcon } from "icons/delivery.svg";
import { ReactComponent as InfoIcon } from "icons/info.svg";
import country_code_options from "utils/country_code_options";

const InputField = (props) => <Input {...props} />;
const SelectField = (props) => <Select {...props} />;

const IslandZipCodeNew = ({
  pristine,
  invalid,
  submitting,
  change,
  history,
  handleSubmit,
}) => {
  const onSubmit = (values) =>
    createIslandZipCode(values).then((id) =>
      history.push(`/island-zip-codes/${id}`)
    );

  return (
    <Main
      page={{
        name: "Nowy kod pocztowy wyspy",
        icon: <DeliveryIcon />,
        breadcrumbs: [
          {
            name: "Kody pocztowe wysp",
            path: "/island-zip-codes",
          },
          { name: "Nowy kod pocztowy wyspy" },
        ],
      }}>
      <div className="dashboard-wrapper-flex">
        <div className="dashboard column-half m-t-10">
          <header className="dashboard__header">
            <div className="icon__container">
              <InfoIcon />
            </div>
            <h2 className="dashboard__title heading">
              Podstawowe dane
            </h2>
          </header>
          <form
            noValidate
            onSubmit={handleSubmit(onSubmit)}
            className="dashboard__form p-t-20">
            <div className="dashboard-wrapper-flex m-b-20">
              <div className="column-half">
                <Field
                  label="Kod kraju"
                  name="country_code"
                  component={SelectField}
                  handleChange={(value) =>
                    change("country_code", value)
                  }
                  options={country_code_options}
                />
              </div>
              <div className="column-half">
                <Field
                  name="zip_code"
                  label="Kod pocztowy"
                  component={InputField}
                />
              </div>
            </div>
          </form>
          <div className="dashboard__form__footer">
            <Button
              disabled={invalid || pristine || submitting}
              type="save"
              text="Dodaj"
              onClick={handleSubmit(onSubmit)}
            />
          </div>
        </div>
      </div>
    </Main>
  );
};

const validate = (values) => {
  const errors = {};

  if (!values.country_code) {
    errors.country_code = "Pole wymagane";
  }

  if (!values.zip_code) {
    errors.zip_code = "Pole wymagane";
  }

  return errors;
};

export default connect(() => ({
  initialValues: { country_code: "", zip_code: "" },
}))(
  reduxForm({
    form: "add-island-zip-code",
    validate,
  })(IslandZipCodeNew)
);
