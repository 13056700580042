import React, { useState } from "react";
import { withRouter } from "react-router";
import { MainList } from "components";

import ModalAddAisle from "./components/ModalAddAisle";
import ModalEditAisle from "./components/ModalEditAisle";
import ModalDeleteAisle from "./components/ModalDeleteAisle";

import { sort } from "./const/data";

const default_state = { type: null, data: null };

const AislesList = ({
  history,
  match: {
    params: { warehouse_id, warehouse_room_id, warehouse_aisle_id },
  },
}) => {
  const [aisles_modal, setAislesModal] = useState(default_state);

  if (!warehouse_room_id) return null;

  const onClose = () => setAislesModal(default_state);

  return (
    <div className="box-3">
      <MainList
        query_key="warehouse_aisles"
        api_path="/warehouse_aisles"
        api_context="ui_index"
        api_ransack={`q[warehouse_id_eq]=${warehouse_id}&q[warehouse_room_id_eq]=${warehouse_room_id}`}
        title="Lista alejek"
        sort_column="position"
        sort_direction="asc"
        hide_meta={true}
        sort={sort}
        activeIndexes={(data) => {
          const indexes = [];
          data.forEach((item, index) => {
            if (item.id === warehouse_aisle_id) {
              indexes.push(index);
            }
          });
          return indexes;
        }}
        handleNavigate={({ id }) =>
          history.push(
            `/warehouses/${warehouse_id}/rooms/${warehouse_room_id}/aisles/${id}`
          )
        }
        renderActions={(item) =>
          [
            {
              type: "edit",
              handleClick: () =>
                setAislesModal({
                  type: "edit",
                  data: item,
                }),
            },
            item.warehouse_racks_count === 0 && {
              type: "remove",
              handleClick: () =>
                setAislesModal({
                  type: "remove",
                  data: item,
                }),
            },
          ].filter(Boolean)
        }
        renderItem={({ identifier, name }) => (
          <>
            <td>{identifier}</td>
            <td>{name}</td>
          </>
        )}
        bottom_action={{
          name: "Dodaj alejkę",
          onClick: () => setAislesModal({ type: "create" }),
        }}
      >
        {aisles_modal?.type === "create" && (
          <ModalAddAisle
            handleClose={onClose}
            initialValues={{
              warehouse_room_id,
              name: "",
              identifier: "",
            }}
          />
        )}
        {aisles_modal?.type === "edit" && (
          <ModalEditAisle
            handleClose={onClose}
            {...aisles_modal.data}
            initialValues={{
              name: aisles_modal?.data?.name || "",
              identifier: aisles_modal?.data?.identifier || "",
            }}
          />
        )}
        {aisles_modal?.type === "remove" && (
          <ModalDeleteAisle handleClose={onClose} {...aisles_modal.data} />
        )}
      </MainList>
    </div>
  );
};

export default withRouter(AislesList);
