import React from "react";
import { connect } from "react-redux";
import { ActionCableConsumer } from "react-actioncable-provider";
import PackGdn from "./PackGdn";
import {
  openTakePhotoModal,
  openSpeditorModal,
} from "actions/pack_gdn";

const PackGdnActionCable = (props) => {
  return (
    <ActionCableConsumer
      channel={{
        channel: "PackerChannel",
        gdn_id: props.match.params.id,
      }}
      onReceived={(res) => {
        switch (res.event) {
          case "close_photo_modal":
            props.openTakePhotoModal(false);
            props.openSpeditorModal(true);
            break;
          default:
            break;
        }
      }}>
      <PackGdn {...props} />
    </ActionCableConsumer>
  );
};

export default connect(null, (dispatch) => ({
  openTakePhotoModal: (is_open) =>
    dispatch(openTakePhotoModal(is_open)),
  openSpeditorModal: (is_open) =>
    dispatch(openSpeditorModal(is_open)),
}))(PackGdnActionCable);
