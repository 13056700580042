import React from "react";
import { connect } from "react-redux";
import { Table } from "../../../components";
import Item from "./components/Item";
import Summary from "./components/Summary";

const DeliveryToMainWarehouse = ({ data }) => (
  <>
    <div className="dashboard m-t-0 m-b-0">
      <Table
        switch_warehouse={false}
        table_top_space={20}
        renderItems={() =>
          data?.grn_lines.map((line) => (
            <Item
              warehouse_id={data?.warehouse?.id}
              key={line?.id}
              data={line}
            />
          ))
        }
      />
    </div>
    <Summary />
  </>
);

export default connect(({ grn: { data } }) => ({
  data,
}))(DeliveryToMainWarehouse);
