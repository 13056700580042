import { useQuery } from "@tanstack/react-query";
import moment from "moment";

import React, {
  useMemo,
  useReducer,
  useContext,
} from "react";
import { toastr } from "react-redux-toastr";
import { client_v2 } from "utils/api";

import reducer, {
  CHANGE_SORT,
  SET_FILTER_VALUE,
  REMOVE_FILTER_VALUE,
  SET_PAGINATION_META,
} from "./reducer";

// CONTEXT ===================================
const MainListContext = React.createContext();

const createDefaultParams = (params) => {
  try {
    return Object.keys(params)
      .filter((key) => Boolean(params[key]))
      .map((key) => `${key}=${params[key]}`);
  } catch (error) {
    return "";
  }
};

const createRansackParams = (filters) => {
  const data = [];
  filters.forEach(({ search_type, values, name }) => {
    switch (search_type) {
      case "eq":
        values.forEach(({ value }) => {
          data.push(`q[${name}_eq]=${value}`);
        });
        break;
      case "in":
        values.forEach(({ value }) => {
          data.push(`q[${name}_in][]=${value}`);
        });
        break;
      case "matches":
        values.forEach(({ value }) => {
          data.push(`q[${name}_matches]=%25${value}%25`);
        });
        break;
      case "date":
        values.forEach(({ value }) => {
          const min_date = moment(value).format(
            "YYYY-MM-DD:00:00:00"
          );
          const max_date = moment(value).format(
            "YYYY-MM-DD:23:59:59"
          );
          data.push(`q[${name}_gteq]=${min_date}`);
          data.push(`q[${name}_lteq]=${max_date}`);
        });
        break;
      default:
        break;
    }
  });
  return data;
};

const createInitialState = (data) => {
  const filters = [];
  if (typeof data.subListData === "function") {
    // skip arrow
    filters.push(null);
  }
  for (const sort of data.sort) {
    const filter = data?.filters?.find(
      ({ sort_id }) => sort_id === sort.name
    );

    if (filter?.name) {
      filters.push({
        ...filter,
        values: filter.initialValues || [],
      });
    } else {
      filters.push(null);
    }
  }
  const state = {
    filters,
    sort: data.sort,
    active_sort: {
      column: data?.sort_column || "id",
      direction: data?.sort_direction || "desc",
    },
    meta: {
      page: 1,
      per_page: data?.default_per_page || 50,
    },
  };

  return state;
};

function MainListProvider({ props, children }) {
  const default_meta = {
    current_page: 1,
    next_page: null,
    per_page: props.default_per_page || 50,
    prev_page: null,
    total_count: 1,
    total_pages: 1,
  };

  const [state, dispatch] = useReducer(
    reducer,
    createInitialState(props)
  );

  const active_filters =
    state.filters?.filter(
      (filter) => filter?.values?.length > 0
    ) || [];

  const search_params = [
    ...createDefaultParams({
      "sort[column]": state.active_sort.column,
      "sort[order]": state.active_sort.direction,
      page: state.meta.page,
      per_page: state.meta.per_page,
      context: props.api_context,
    }),
    ...createRansackParams(active_filters),
  ]?.join("&");

  const query_include = props.api_include
    ? `include=${props.api_include}&`
    : "";

  const query_ransack = props.api_ransack
    ? `${props.api_ransack}&`
    : "";

  const {
    isLoading: is_loading,
    isFetching: is_fetching,
    data,
    refetch,
  } = useQuery(
    [
      props.query_key,
      state.active_sort,
      active_filters,
      state.meta,
      query_ransack,
    ],
    () =>
      client_v2.get(
        `${props.api_path}?${query_ransack}${query_include}${search_params}`
      ),
    {
      onError: (err) =>
        toastr.error(
          "Błąd",
          err?.response?.data?.message ||
            "Wystąpił błąd podczas pobierania"
        ),
      refetchInterval: props.refetch_interval || false,
      refetchIntervalInBackground:
        props.refetch_interval_in_background || false,
      enabled: Boolean(props.api_path),
    }
  );

  const changeSort = ({ column, direction }) =>
    dispatch({
      type: CHANGE_SORT,
      payload: { column, direction },
    });

  const setFilterValue = ({ name, value, label }) =>
    dispatch({
      type: SET_FILTER_VALUE,
      payload: { name, value, label },
    });

  const removeFilterValue = ({ name, value }) =>
    dispatch({
      type: REMOVE_FILTER_VALUE,
      payload: { name, value },
    });

  const setMetaPagination = ({ page, per_page }) =>
    dispatch({
      type: SET_PAGINATION_META,
      payload: { page, per_page },
    });

  const with_actions =
    typeof props?.renderActions === "function";

  const has_sub_list =
    typeof props?.subListData === "function";

  const value = useMemo(() => {
    return {
      title: props?.title || "",
      renderActions: props?.renderActions,
      bottom_action: props.bottom_action || null,
      activeIndexes: props?.activeIndexes,
      handleNavigate: props?.handleNavigate,
      is_loading: is_loading,
      is_fetching: is_fetching,
      with_actions,
      has_sub_list,
      state,
      data: data?.data || [],
      meta: data?.meta || default_meta,
      changeSort,
      setFilterValue,
      removeFilterValue,
      setMetaPagination,
      renderItem: props.renderItem,
      subListData: props?.subListData,
      refetch,
    };
  }, [
    state,
    is_loading,
    is_fetching,
    data,
    props.activeIndexes,
  ]);

  return (
    <MainListContext.Provider value={value}>
      {children}
    </MainListContext.Provider>
  );
}

const useMainList = () => useContext(MainListContext);
export { MainListContext, useMainList };
export default MainListProvider;
