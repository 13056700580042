import React, { useState } from "react";
import { connect } from "react-redux";
import { Button } from "expano-components";
import { createMM } from "actions/grns";

const InfoText = ({ createMM, data }) => {
  const [is_loading, setLoading] = useState(false);

  const handleCreateMM = async () => {
    try {
      setLoading(true);
      await createMM(data?.id);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };
  
  if (data?.status === "waiting_for_shift") {
    return (
      <p className="box-text">
        To zamówienie oczekuje na utworzenie MM, nie możesz
        go edytować.
        <br />
        <Button
          disabled={is_loading}
          type="add"
          text={
            is_loading ? "Trwa generowanie" : "Utwórz MM"
          }
          onClick={handleCreateMM}
        />
      </p>
    );
  }
  if (data?.status === "finished") {
    return (
      <p className="box-text">
        To zamówienie jest zakończone, nie możesz go
        edytować
        <br />
        Do zamówienia utworzono MM o numerze:{" "}
        {data?.shift_identifier}
      </p>
    );
  }
  if (
    data?.grn_lines.filter(
      ({ counting_state }) => counting_state === "pending"
    ).length > 0
  ) {
    return (
      <p className="box-text">
        Możesz potwierdzić tylko uzupełnione zamówienie
      </p>
    );
  }

  if (
    data?.grn_lines.filter(
      ({ counting_state }) => counting_state === "correct"
    ).length === data?.grn_lines?.length &&
    !data?.has_other_error
  ) {
    return (
      <p className="box-text">
        Wszystkie pozycje zostały poprawnie dostarczone i
        przyjęte
      </p>
    );
  }

  return null;
};

export default connect(
  ({ grn }) => ({ data: grn?.data }),
  (dispatch) => ({
    createMM: (id) => dispatch(createMM(id)),
  })
)(InfoText);
