const initialState = {
  version: "0.0.1",
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "NAVIGATOR_SUCCESS":
      return {
        version: action.version,
      };
    default:
      return state;
  }
};
