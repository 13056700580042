import React from "react";

import { ReactComponent as WarningIcon } from "icons/warning.svg";
import { Modal } from "components";

const WarningModalMultiPak = ({ handleClose }) => (
  <Modal
    prevent_click_outside
    handleClose={handleClose}
    header={{ title: "Uwaga", icon: <WarningIcon /> }}
    footer={
      <>
        <button
          type="submit"
          onClick={handleClose}
          className="btn btn-sm btn-green"
        >
          <span>OK</span>
        </button>
      </>
    }
  >
    <p>Zeskanowowano opakowanie zbiorcze!!</p>
  </Modal>
);

export default WarningModalMultiPak;
