import React from "react";
import { ModalDelete } from "expano-components";
import { useMainList } from "components/MainList/context/provider";
import { deleteRoom } from "actions/rooms";

const ModalDeleteRoom = ({ name, id, handleClose }) => {
  const { refetch } = useMainList();
  const handleDelete = () =>
    deleteRoom(id)
      .then(() => {
        refetch();
        handleClose();
      })
      .catch((err) => console.log(err));

  return (
    <ModalDelete
      name={`pomieszczenie ${name}?`}
      handleClose={handleClose}
      handleDelete={handleDelete}
    />
  );
};

export default ModalDeleteRoom;
