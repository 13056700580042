import React from "react";
import { connect } from "react-redux";
import getGdnStatus from "pages/Dashboard/const/getGdnStatus";

const SinglePickingMode = ({ data }) => {
  if (!data?.single_picking_mode || data?.single_picking_mode?.length < 1) {
    return null;
  }
  return (
    <div className="dashboard">
      <div className="dashboard__header">
        <h2 className="dashboard__title heading">Pojedyńcza kompletacja</h2>
      </div>
      <table className="table table-sm">
        <thead className="table__header">
          <tr>
            <th width="25%">ID</th>
            <th width="25%">Data</th>
            <th width="25%">Pack</th>
            <th width="25%">Packer</th>
          </tr>
        </thead>
        <tbody className="table__body">
          {data.single_picking_mode.map(
            ({
              id,
              identifier,
              prepared_at_date,
              gdn_pack_id,
              status,
              gdn_pack_packer_full_name,
            }) => {
              return (
                <tr key={identifier}>
                  <td>
                    <p>
                      <a
                        className="link"
                        target="_blank"
                        rel="noopener noreferrer"
                        href={`/packing/gdn/${id}`}
                      >
                        {identifier}
                      </a>
                    </p>
                    <p>
                      <span
                        className={`label label--${getGdnStatus(status).color}`}
                        style={{
                          padding: "2px 4px",
                        }}
                      >
                        <span className="text-10">
                          {getGdnStatus(status).name}
                        </span>
                      </span>
                    </p>
                  </td>
                  <td>
                    <p>{prepared_at_date}</p>
                  </td>
                  <td>
                    {gdn_pack_id ? (
                      <>
                        <a
                          className="link"
                          target="_blank"
                          rel="noopener noreferrer"
                          href={`/packing/gdn_pack/${gdn_pack_id}`}
                        >
                          {gdn_pack_id}
                        </a>
                      </>
                    ) : (
                      <p className="text-11">brak</p>
                    )}
                  </td>
                  <td>
                    <p>{gdn_pack_packer_full_name || "-"}</p>
                  </td>
                </tr>
              );
            }
          )}
        </tbody>
      </table>
    </div>
  );
};

export default connect(({ dashboard: { data } }) => ({
  data,
}))(SinglePickingMode);
