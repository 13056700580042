import React from "react";
import { connect } from "react-redux";
import Item from "./components/Item";

import StyledList from "./styles";

const List = ({ gdns }) => {
  if (gdns?.length === 0) {
    return null;
  }

  const gdns_not_ready = gdns
    .filter(({ status }) =>
      ["picked", "packed"].includes(status)
    )
    .sort((a, b) =>
      a.picking_box?.localeCompare(b?.picking_box)
    );

  const gdns_ready = gdns
    .filter(
      ({ status }) => !["picked", "packed"].includes(status)
    )
    .sort((a, b) =>
      a.picking_box?.localeCompare(b?.picking_box)
    );

  return (
    <StyledList>
      {[...gdns_not_ready, ...gdns_ready].map((item) => (
        <Item key={item.id} {...item} />
      ))}
    </StyledList>
  );
};

export default connect(
  ({
    pack_gdns: {
      data: { gdns },
    },
  }) => ({ gdns })
)(List);
