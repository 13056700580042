import React from "react";
import Select from "react-select";

const MultiSelect = ({ data, handleChange }) => {
  const customStyles = {
    control: (styles) => ({
      ...styles,
      borderColor: "#E2E5EC",
      borderWidth: 1,
      borderRadius: 4,
      minHeight: 30,
    }),
    option: (provided) => ({
      ...provided,
      fontFamily: "Poppins",
      fontSize: "13px",
    }),
    singleValue: (provided) => ({
      ...provided,
      fontFamily: "Poppins",
      fontSize: "13px",
      padding: 0,
    }),
    valueContainer: (provided) => ({
      ...provided,
      padding: "1px 10px",
    }),
    indicatorSeparator: (styles) => ({
      ...styles,
      display: "none",
    }),
    placeholder: (provided) => ({
      ...provided,
      fontFamily: "Poppins",
      fontSize: "13px",
    }),
    multiValue: (provided) => ({
      ...provided,
      flexDirection: "row-reverse",
      backgroundColor: "#F3F6F9",
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      paddingRight: "0 6px",
      fontSize: 10,
      color: "#595D6E",
    }),
  };
  return (
    <div className="inp-wrapper">
      {data?.label && <label>{data.label}</label>}
      <Select
        onChange={handleChange}
        styles={customStyles}
        isMulti={true}
        placeholder={"Wybierz..."}
        options={data.options || []}
        defaultValue={data.defaultValue || null}
      />
    </div>
  );
};

export default MultiSelect;
