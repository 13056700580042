import React, { useState } from "react";
import AddItem from "./components/AddItem";
import Items from "./components/Items";
import { Button } from "expano-components";
import { ReactComponent as ArrowPrevIcon } from "icons/arrow-back.svg";

const PackageBoxesArray = ({ package_boxes, fields }) => {
  const [is_open_add_package, setOpenAddPackage] = useState(
    true
  );
  const handleAdd = ({ id, name, sizes = {} }) => {
    fields.push({
      id,
      name,
      ...sizes,
      weight: 0,
    });
    setOpenAddPackage(false);
  };
  return (
    <div className="dashboard">
      <div className="dashboard__header">
        <h2 className="dashboard__title heading">
          Paczki
        </h2>
        <div className="dashboard__aside">
          {is_open_add_package ? (
            <button
              className="btn btn-sm btn-cancel"
              type="cancel"
              onClick={() => setOpenAddPackage(false)}>
              <ArrowPrevIcon
                style={{ transform: "scaleX(-1)" }}
              />
              <span>Wróć do listy</span>
            </button>
          ) : (
            <Button
              type="add"
              onClick={() => setOpenAddPackage(true)}
              text={
                fields.length > 0
                  ? "Dodaj podpaczkę"
                  : "Dodaj paczkę"
              }
            />
          )}
        </div>
      </div>
      <div className="dashboard__form">
        {is_open_add_package ? (
          <AddItem handleAdd={handleAdd} />
        ) : (
          <Items
            fields={fields}
            package_boxes={package_boxes}
          />
        )}
      </div>
    </div>
  );
};

export default PackageBoxesArray;
