import { toastr } from "react-redux-toastr";
import { client_v2 } from "utils/api";

export const getPackageBoxes = () => async (dispatch) => {
  try {
    dispatch({
      type: "PACKAGE_BOXES_LOADING",
    });
    const { data } = await client_v2.get(
      "/package_boxes/stats"
    );
    dispatch({
      type: "PACKAGE_BOXES_SUCCESS",
      data,
    });
  } catch (error) {
    dispatch({ type: "PACKAGE_BOXES_FAILURE" });
    toastr.error(
      "Błąd",
      "Wystąpił błąd podczas pobierania danych o paczkach"
    );
    throw error;
  }
};
