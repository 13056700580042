import React from "react";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { Input, Button } from "expano-components";
import { Modal } from "components";
import { createEan } from "actions/products";

const InputField = (props) => <Input {...props} />;

const AddEan = ({
  createEan,
  handleSubmit,
  pristine,
  handleClose,
  product_id,
}) => {
  const submit = async ({ code, quantity }) => {
    await createEan({ code, quantity, product_id });
    handleClose();
  };

  return (
    <Modal
      handleClose={handleClose}
      header={{ title: "Kody logistyczne" }}
      footer={
        <>
          <Button type="cancel" onClick={handleClose} />
          <Button
            type="add-green"
            text="Dodaj"
            disabled={pristine}
            onClick={handleSubmit(submit)}
          />
        </>
      }>
      <form noValidate onSubmit={handleSubmit(submit)}>
        <Field
          label="Kod"
          name="code"
          component={InputField}
          labelAside
        />
        <Field
          label="Ilość"
          name="quantity"
          component={InputField}
          labelAside
        />
      </form>
    </Modal>
  );
};

export default connect(
  ({ edit_product }) => ({
    product_id: edit_product?.data?.id,
    initialValues: {
      code: "brak",
      quantity: 1,
    },
  }),
  (dispatch) => ({
    createEan: (data) => dispatch(createEan(data)),
  })
)(reduxForm({ form: "add-ean" })(AddEan));
