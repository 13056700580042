import { toastr } from "react-redux-toastr";
import { client_v2, stringifyQuery } from "utils/api";

export const getAllSpeditors = () => async (dispatch) => {
  try {
    dispatch({
      type: "SPEDITORS_LOADING",
    });

    const { data } = await client_v2.get(
      `/speditors?${stringifyQuery({
        context: "speditor_prices_ui",
        per_page: 1000,
      })}`
    );

    dispatch({
      type: "SPEDITORS_SUCCESS",
      data,
    });
  } catch (error) {
    dispatch({ type: "SPEDITORS_FAILURE" });
    toastr.error(
      "Błąd",
      "Wystąpił błąd podczas pobierania"
    );
  }
};

export const updateSpeditorPrice = (
  id,
  speditor_id,
  values
) => async (dispatch) => {
  try {
    await client_v2.put(
      `/speditor_country_prices/${id}`,
      values
    );
    dispatch({
      type: "SPEDITORS_UPDATE_PRICE",
      data: { id, speditor_id, values },
    });
    toastr.success("Sukces", "Cena została zaktualizowana");
  } catch (error) {
    toastr.error(
      "Błąd",
      "Wystąpił błąd podczas aktualizacji ceny"
    );
  }
};

export const setFilter = (value) => (dispatch) => {
  dispatch({ type: "SPEDITORS_SET_FILTER", data: value });
  dispatch({ type: "SPEDITORS_SET_FILTER_DATA" });
};

export const uploadSpeditorPrices = async (file) => {
  try {
    const form_data = new FormData();

    form_data.append("file", file);

    await client_v2.post(
      "/speditor_country_prices/upload",
      form_data,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    toastr.success("Sukces", "Ceny zostały zaktualizowane");
  } catch (error) {
    toastr.error(
      "Błąd",
      error?.response?.data?.error?.message || "Wystąpił błąd"
    );
    throw error;
  }
};
