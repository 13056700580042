import React, { useState, useEffect } from "react";
import moment from "moment";
import { connect } from "react-redux";
import DatePicker from "react-date-picker";
import { Main } from "layouts";

import { getPackerDashboard } from "actions/packer_dashboard";
import ErrorsTable from "./components/ErrorsTable";
import SummaryTable from "./components/SummaryTable";

import { ReactComponent as DashboardIcon } from "icons/dashboard.svg";
import { ReactComponent as CalendarIcon } from "icons/calendar.svg";

const tabs = [
  { id: "summary", name: "Podsumowanie" },
  { id: "errors", name: "Raport błędów" },
];

const PackerDashboard = ({
  packer_dashboard: { status },
  getPackerDashboard,
  current_user,
}) => {
  const today = moment();
  const [active_tab, setActiveTab] = useState("summary");
  const [date, setDate] = useState(today);

  useEffect(() => {
    if (date) {
      getPackerDashboard(date.format());
    }
  }, [date]);

  return (
    <Main
      is_loading={["invalid", "loading", "failure"].includes(status)}
      page={{
        name: "Dashboard",
        icon: <DashboardIcon />,
      }}
    >
      <div className="dashboard m-b-0">
        <div className="dashboard__header">
          <h2 className="dashboard__title heading">
            Statystyki - {current_user?.full_name}
          </h2>
          <h2 className="dashboard__title heading">
            {moment(date).locale("pl-PL").format("MMMM YYYY")}
          </h2>
          <div className="chart-box__header__aside">
            <div className="calendar-group">
              <DatePicker
                calendarIcon={<CalendarIcon />}
                onChange={(val) => setDate(moment(val))}
                value={date.toDate()}
                showLeadingZeros={false}
                maxDetail="year"
                clearIcon={null}
                locale="pl-PL"
                format="MMMM"
              />
            </div>
          </div>
        </div>
      </div>
      <ul className="dashboard__tabs">
        {tabs.map(({ id, name }) => (
          <li
            key={id}
            className={`tab dashboard-tab-text ${
              id === active_tab ? "active" : ""
            }`}
            onClick={() => setActiveTab(id)}
          >
            <span>{name}</span>
          </li>
        ))}
      </ul>
      {active_tab === "errors" && <ErrorsTable />}
      {active_tab === "summary" && <SummaryTable />}
    </Main>
  );
};

export default connect(
  ({ packer_dashboard, current_user }) => ({ packer_dashboard, current_user }),
  (dispatch) => ({
    getPackerDashboard: (date) => dispatch(getPackerDashboard(date)),
  })
)(PackerDashboard);
