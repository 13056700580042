import React from "react";
import { Field } from "redux-form";
import { Button, Input } from "expano-components";
import { connect } from "react-redux";

const InputField = (props) => <Input {...props} />;

const InputLocked = ({ label, name, value, postfix }) => (
  <div className="form-group">
    <div className="inp-wrapper">
      <label for={name} className="label-form">
        {label}
      </label>
      <div className="inp-group">
        <input
          placeholder="cm"
          id="width"
          name={name}
          type="number"
          value={value}
          readOnly={true}
        />
        <span className="form-span postfix">{postfix}</span>
      </div>
    </div>
  </div>
);

const Items = ({
  delivery_method_name,
  package_boxes,
  fields,
  setOpenAddPackage,
}) => {
  const is_foreign =
    delivery_method_name === "Kurier międzynarodowy";

  return (
    <>
      <div
        className="package-boxes__form"
        id="packages_form"
      >
        {fields.map((item, index) => {
          const name = package_boxes[index].name;
          const is_locked =
            is_foreign &&
            ["D14", "G17", "K20"].includes(name);

          return (
            <div className="form-group" key={index}>
              <div className="form-group__header">
                <h3>Rozmiar: {name}</h3>
                <Button
                  type="delete-bin"
                  onClick={() => fields.remove(index)}
                />
              </div>
              {is_locked && (
                <div className="alert warning">
                  Dla kurier międzynarodowy nie zmieniamy
                  rozmiarów kopert.
                </div>
              )}
              <div className="row">
                <div className="box-3">
                  {is_locked ? (
                    <InputLocked
                      label="Szerokość"
                      name="width"
                      postfix="cm"
                      value={package_boxes[index]?.width}
                    />
                  ) : (
                    <Field
                      name={`${item}.width`}
                      type="number"
                      component={InputField}
                      placeholder="cm"
                      label="Szerokość"
                      postfix="cm"
                    />
                  )}
                </div>
                <div className="box-3">
                  {is_locked ? (
                    <InputLocked
                      label="Wysokość"
                      name="height"
                      postfix="cm"
                      value={package_boxes[index]?.height}
                    />
                  ) : (
                    <Field
                      name={`${item}.height`}
                      type="number"
                      placeholder="cm"
                      component={InputField}
                      label="Wysokość"
                      postfix="cm"
                    />
                  )}
                </div>
                <div className="box-3">
                  {is_locked ? (
                    <InputLocked
                      label="Głębokość"
                      name="depth"
                      postfix="cm"
                      value={package_boxes[index]?.depth}
                    />
                  ) : (
                    <Field
                      name={`${item}.depth`}
                      type="number"
                      placeholder="cm"
                      component={InputField}
                      label="Głębokość"
                      postfix="cm"
                    />
                  )}
                </div>
                <div className="box-3">
                  <Field
                    name={`${item}.weight`}
                    type="number"
                    placeholder="kg"
                    component={InputField}
                    label="Waga"
                    postfix="kg"
                  />
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <Button
        type="add"
        onClick={() => setOpenAddPackage(true)}
        text={
          fields.length > 0
            ? "Dodaj podpaczkę"
            : "Dodaj paczkę"
        }
      />
    </>
  );
};

export default connect(
  ({
    gdn: {
      data: { delivery_method_name },
    },
  }) => ({ delivery_method_name })
)(Items);
