const initialState = {
  status: "invalid",
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "MISSINGS_LOADING":
      return {
        ...state,
        status: "loading",
      };
    case "MISSINGS_SUCCESS": {
      return {
        ...state,
        status: "success",
        data: action.data.sort((item, next) => item.product.id - next.product.id),
      };
    }

    case "MISSINGS_FAILURE":
      return {
        ...state,
        status: "failure",
      };
    default:
      return state;
  }
};
