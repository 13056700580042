import { toastr } from "react-redux-toastr";
import { client } from "utils/api";
import queryString from "query-string";

export const getDashboard = (query) => (
  dispatch,
  getState
) =>
  new Promise(async (resolve, reject) => {
    try {
      const { dashboard } = getState();
      dispatch({
        type: `DASHBOARD_${
          dashboard.status === "success"
            ? "UPDATING"
            : "LOADING"
        }`,
      });
      const { data } = await client.get(
        `/stats/dashboard?${queryString.stringify(query, {
          skipNull: true,
        })}`
      );
      dispatch({
        type: "DASHBOARD_SUCCESS",
        data,
      });

      resolve();
    } catch (err) {
      console.log(err);
      dispatch({ type: "DASHBOARD_FAILURE" });
      toastr.error(
        "Błąd",
        "Wystąpił błąd podczas pobierania danych"
      );
      reject();
    }
  });
