import React, { useState } from "react";
import { connect } from "react-redux";
import { unMarkGdnAsProblem } from "actions/pack_gdn";

import { ReactComponent as ArrowIcon } from "icons/arrow-next.svg";
import { ReactComponent as PackageInCircleIcon } from "icons/package_in_circle.svg";

const Problem = ({
  id,
  unMarkGdnAsProblem,
  packer_note,
  status,
}) => {
  const [is_loading, setLoading] = useState(false);

  if (status !== "problem") return null;

  const handleUnMarkAsProblem = async () => {
    try {
      setLoading(true);

      await unMarkGdnAsProblem(id);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="status-panel warning">
      <div className="status-panel__info">
        <div className="info-icon">
          <PackageInCircleIcon />
        </div>
        <div className="info-text">
          <p className="title">Zgłoszono problem</p>
          <p className="desc">{packer_note}</p>
        </div>
      </div>
      <button
        disabled={is_loading}
        className="btn btn-sm btn-next"
        onClick={handleUnMarkAsProblem}>
        {is_loading ? (
          <span>Proszę czekać...</span>
        ) : (
          <>
            <span>Oznacz problem jako rozwiązany</span>
            <ArrowIcon />
          </>
        )}
      </button>
    </div>
  );
};

export default connect(
  ({
    pack_gdn: {
      data: {
        id,
        packer_note,
        gdn_pack,
        status,
      },
    },
  }) => ({
    id,
    packer_note,
    gdn_pack,
    status,
  }),
  (dispatch) => ({
    unMarkGdnAsProblem: (id) =>
      dispatch(unMarkGdnAsProblem(id)),
  })
)(Problem);
