import React from "react";
import LazyLoad from "react-lazy-load";
import { ReactComponent as WarningIcon } from "icons/warning.svg";
import { ReactComponent as DangerIcon } from "icons/danger.svg";

const Item = ({
  id,
  openModal,
  quantity,
  volume,
  product,
  gdn_user_errors,
  setGdnLinePickerErrorsId,
  setGdnLinePackerErrorsId,
  setReportGdnLineErrorId,
  is_gdn_completed,
}) => {
  const errors_before_send_length =
    gdn_user_errors.filter(
      ({ is_created_after_send }) => !is_created_after_send
    )?.length || 0;

  const errors_after_send_length =
    gdn_user_errors.filter(({ is_created_after_send }) => is_created_after_send)
      ?.length || 0;

  return (
    <tr>
      <td>{quantity} szt.</td>
      <td>{volume} cm</td>
      <td className="image-box">
        <LazyLoad>
          <button
            className="modal__trigger"
            onClick={() =>
              openModal({
                src: product?.image_url,
                alt: product?.name,
              })
            }
            style={{
              backgroundImage: `url('${
                product?.image_thumb_url || product?.image_url
              }')`,
            }}
          />
        </LazyLoad>
      </td>
      <td>
        <a
          className="link"
          target="_blank"
          rel="noopener noreferrer"
          href={`https://pim.tradesk.pl/product_specification_by_sku?sku=${product?.sku}`}
        >
          {product?.sku}
        </a>
      </td>
      <td>{product?.name}</td>
      <td>
        {(errors_before_send_length > 0 || errors_after_send_length > 0) && (
          <div className="m-b-10">
            {errors_before_send_length > 0 && (
              <button
                className="m-r-5"
                type="button"
                onClick={() => setGdnLinePickerErrorsId(id)}
              >
                <WarningIcon />
              </button>
            )}
            {errors_after_send_length > 0 && (
              <button
                type="button"
                onClick={() => setGdnLinePackerErrorsId(id)}
              >
                <DangerIcon />
              </button>
            )}
          </div>
        )}
        {is_gdn_completed && (
          <button
            type="button"
            className="btn btn-sm btn-blue"
            onClick={() => setReportGdnLineErrorId(id)}
          >
            <span>Zgłoś błąd</span>
          </button>
        )}
      </td>
    </tr>
  );
};

export default Item;
