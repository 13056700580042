import { connect } from "react-redux";
import Item from "./components/Item";

const GrnLineBatches = ({ grn_status, grn_line_id, data = [] }) => {
  if (data.length < 1) {
    return null;
  }

  const is_disabled = !["open", "active"].includes(grn_status);

  return (
    <div className="grn-line-batches">
      {data.map((el) => (
        <Item
          is_disabled={is_disabled}
          key={el.id}
          grn_line_id={grn_line_id}
          {...el}
        />
      ))}
    </div>
  );
};

export default connect(
  ({
    grn: {
      data: { status },
    },
  }) => ({ grn_status: status })
)(GrnLineBatches);
