import React, { useState } from "react";
import { Button, Label } from "expano-components";
import moment from "moment";
import Waybill from "./components/Waybill";
import UploadWaybill from "./components/UploadWaybill";
import CreateWaybill from "./components/CreateWaybill";

const Details = ({ data, reload }) => {
  const [
    is_open_create_waybill,
    setOpenCreateWaybill,
  ] = useState(false);
  return (
    <div className="dashboard m-b-10">
      <header className="dashboard__header dashboard__header--edit">
        <div className="icon">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
          >
            <g transform="translate(20) rotate(90)">
              <rect fill="none" width="20" height="20" />
              <path
                fill="#5d78ff"
                opacity="0.3"
                d="M14.5,7.666A.833.833,0,1,1,14.5,6h.833a3.333,3.333,0,0,1,3.333,3.333V16a3.333,3.333,0,0,1-3.333,3.333h-10A3.333,3.333,0,0,1,2,16V9.326A3.333,3.333,0,0,1,5.333,5.993h.833a.833.833,0,0,1,0,1.667H5.333A1.667,1.667,0,0,0,3.667,9.326V16a1.667,1.667,0,0,0,1.667,1.667h10A1.667,1.667,0,0,0,17,16V9.332a1.667,1.667,0,0,0-1.667-1.667Z"
                transform="translate(-0.333 -0.999)"
              />
              <rect
                fill="#5d78ff"
                opacity="0.3"
                width="1.667"
                height="10"
                rx="0.833"
                transform="translate(10.833 11.667) rotate(180)"
              />
              <path
                fill="#5d78ff"
                d="M11.333-2.988l1.911,1.911a.833.833,0,0,0,1.179,0,.833.833,0,0,0,0-1.179l-2.5-2.5a.833.833,0,0,0-1.179,0l-2.5,2.5a.833.833,0,0,0,0,1.179.833.833,0,0,0,1.179,0Z"
                transform="translate(-1.333 5)"
              />
            </g>
          </svg>
        </div>
        <h2 className="dashboard__title heading">
          Wydanie nr {data?.expano_oms_id}
        </h2>
      </header>
      <div className="release-details">
        <div className="column-half">
          <div className="box">
            <p className="title">Źródło</p>
            <div className="row">
              <Label
                type="fill"
                color={data?.channel?.shop?.label_color}
                text={data?.channel?.shop?.label_text}
              />
              <p>{data?.channel?.shop?.name}</p>
            </div>
            <div className="row">
              <Label
                type="bordered"
                color={`${data?.channel?.platform?.label_color}`}
                text={data?.channel?.platform?.label_text}
              />
              <p>{data?.channel?.platform?.name}</p>
            </div>
          </div>
          <div className="box">
            <p className="title">Adres dostawy</p>
            <p>{data?.delivery_fullname}</p>
            {data?.delivery_company && (
              <p>{data.delivery_company}</p>
            )}
            <p>{data?.delivery_street}</p>
            <p>
              {data?.delivery_postcode}{" "}
              {data?.delivery_city},{" "}
              {data?.delivery_country}{" "}
              {data?.delivery_country_code}
            </p>
          </div>
          <div className="box">
            <p className="title">Pack</p>
            <p>
              {data?.gdn_pack?.id ? (
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`/packing/gdn_pack/${data.gdn_pack.id}`}
                >
                  {data.gdn_pack.id}
                </a>
              ) : (
                "-"
              )}
            </p>
          </div>
          {data?.expano_oms_id && (
            <div className="box">
              <p className="title">OMS</p>
              <p>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`https://oms.tradesk.pl/orders/${data.expano_oms_id}`}
                >
                  {data.expano_oms_id}
                </a>
              </p>
            </div>
          )}
          <div className="box">
            <p className="title">Paker</p>
            <p>
              {data?.gdn_pack?.packer?.full_name || "-"}
            </p>
          </div>
          {data?.packer_note && (
            <div className="box">
              <p className="title">Problem</p>
              <p>{data.packer_note}</p>
            </div>
          )}
          {data?.problem_station_note && (
            <div className="box">
              <p className="title">Dodatkowe informacje</p>
              <p>{data.problem_station_note}</p>
            </div>
          )}
        </div>
        <div className="column-half">
          {data?.prepared_at && (
            <div className="box">
              <p className="title">Data zamówienia</p>
              <p>
                {moment(data?.prepared_at).format(
                  "DD-MM-YYYY HH:mm"
                )}
              </p>
            </div>
          )}
          {data?.dispached_at && (
            <div className="box">
              <p className="title">Data wydania</p>
              <p>
                {moment(data?.dispached_at).format(
                  "DD-MM-YYYY HH:mm"
                )}
              </p>
            </div>
          )}
          <div className="box">
            <p className="title">Metoda dostawy</p>
            <p>{data?.delivery_method_name}</p>
          </div>
          <div className="box">
            <p className="title">Objętość zamówienia</p>
            <p>{data?.volume || "-"}</p>
          </div>
          <div className="box">
            <p className="title">
              Zadeklarowana ilość paczek
            </p>
            <p>{data?.declared_packages_count}</p>
          </div>
          <div className="box">
            <p className="title">Listy przewozowe</p>
            {data?.packages?.length > 0 ? (
              <ul className="packages-list">
                {data.packages.map((waybill) => (
                  <Waybill
                    key={waybill.id}
                    gdn_id={data?.id}
                    {...waybill}
                  />
                ))}
              </ul>
            ) : (
              <p>brak</p>
            )}
            <UploadWaybill />
            <br />
            <Button
              style={{ marginTop: 10 }}
              type="add"
              text="Nadaj paczkę"
              onClick={() => setOpenCreateWaybill(true)}
            />
            {is_open_create_waybill && (
              <CreateWaybill
                reload={reload}
                handleClose={() =>
                  setOpenCreateWaybill(false)
                }
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Details;
