import React from "react";
import {
  Document,
  Image,
  Font,
  Page,
  Text,
  View,
  StyleSheet,
} from "@react-pdf/renderer";

import RobotoBlack from "fonts/Roboto-Black.ttf";

Font.register({
  family: "Roboto",
  fonts: [{ src: RobotoBlack, fontWeight: 900 }],
});

const StickerQrCodeWarehousePositions = ({
  data,
  name,
  id,
}) => {
  return (
    <Document>
      <Page
        size={{ width: 305, height: 60 }}
        style={styles.page}>
        <View style={styles.wrapper} wrap={true}>
          <View style={styles.box}>
            <View style={styles.text_wrapper}>
              <Text style={styles.text_normal}>{name}</Text>
              <Text style={styles.text_normal}>
                ID: {id}
              </Text>
            </View>
          </View>
          {data.map(({ qr_code_base64, identifier }) => (
            <View style={styles.box} key={identifier}>
              <View style={styles.box_image}>
                <Image
                  src={qr_code_base64}
                  style={{
                    width: 60,
                    height: 60,
                  }}
                />
              </View>
              <View style={styles.box_sku}>
                <Text style={styles.box_sku__text}>
                  {identifier?.split("-")[0]}
                </Text>
                <Text style={styles.box_sku__text}>
                  {identifier?.split("-")[1]}
                </Text>
              </View>
            </View>
          ))}
        </View>
      </Page>
    </Document>
  );
};

export default StickerQrCodeWarehousePositions;

const styles = StyleSheet.create({
  page: {
    backgroundColor: "#fff",
    paddingTop: 0,
    paddingLeft: 5,
  },
  wrapper: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "flex-start",
  },
  box: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: 100,
  },

  text_wrapper: {
    justifyContent: "flex-end",
    paddingBottom: 5,
    paddingLeft: 12,
  },
  text_normal: {
    fontFamily: "Roboto",
    fontSize: 7,
    fontWeight: 900,
    marginBottom: 3,
  },
  box_image: {
    flexBasis: 60,
  },
  box_sku: {
    width: 45,
    transform: "rotate(-90deg)",
    transformOrigin: "50% 50%",
    left: -10,
    bottom: 5,
  },
  box_sku__text: {
    fontFamily: "Roboto",
    fontSize: 6,
    fontWeight: 900,
  },
});
