import { combineReducers } from "redux";
import { reducer as form } from "redux-form";
import { reducer as toastr } from "react-redux-toastr";
import sidebar from "./sidebar";
import products from "./products";
import edit_product from "./edit_product";
import grn from "./grn";
import packs from "./packs";
import gdns from "./gdns";
import gdn from "./gdn";
import gdn_packs from "./gdn_packs";
import pack_gdns from "./pack_gdns";
import gdn_pack from "./gdn_pack";
import pack_gdn from "./pack_gdn";
import missings from "./missings";
import current_user from "./current_user";
import dashboard from "./dashboard";
import sorting_gdns from "./sorting_gdns";
import suppliers from "./suppliers";
import supplier from "./supplier";
import warehouse_position from "./warehouse_position";
import navigator from "./navigator";
import carts from "./carts";
import user from "./user";
import stations from "./stations";
import alerts from "./alerts";
import warehouse_tasks from "./warehouse_tasks";
import speditors from "./speditors";
import island_zip_code from "./island_zip_code";
import package_boxes from "./package_boxes";
import settings from "./settings";
import packages from "./packages";
import _package from "./_package";
import average_packages from "./average_packages";
import packages_stats from "./packages_stats";
import absences from "./absences";
import packer_dashboard from "./packer_dashboard";
import gdn_errors_stats from "./gdn_errors_stats";
import warehouse_stock_stats from "./warehouse_stock_stats";

export default combineReducers({
  absences,
  alerts,
  average_packages,
  current_user,
  carts,
  dashboard,
  form,
  sidebar,
  toastr,
  products,
  edit_product,
  grn,
  packs,
  gdns,
  gdn,
  gdn_packs,
  island_zip_code,
  pack_gdns,
  package_boxes,
  gdn_pack,
  pack_gdn,
  missings,
  sorting_gdns,
  suppliers,
  supplier,
  warehouse_position,
  navigator,
  user,
  stations,
  speditors,
  warehouse_tasks,
  settings,
  packages,
  _package,
  packages_stats,
  packer_dashboard,
  gdn_errors_stats,
  warehouse_stock_stats
});
