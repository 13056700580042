import React, { useState } from "react";
import { MainList } from "components";
import { Main } from "layouts";
import { convertDate } from "utils/helpers";
import { ReactComponent as DeliveryIcon } from "icons/delivery.svg";

import EstimateMissingsModal from "./components/EstimateMissingsModal";

import { getStatusLabelData } from "../const/statuses";
import { sort, filters } from "./const/data";
import { Select } from "expano-components";

const GrnsList = ({ history }) => {
  const [is_open_estimate_missings_modal, setOpenEstimateMissingsModal] =
    useState(false);

  return (
    <Main
      page={{
        name: "Dostawy",
        icon: <DeliveryIcon />,
        breadcrumbs: [
          {
            name: "Dostawy",
          },
        ],
        buttons: (
          <button
            type="button"
            className="btn btn-blue btn-sm"
            onClick={() => setOpenEstimateMissingsModal(true)}
          >
            <span>Wybrakowane pozycje</span>
          </button>
        ),
      }}
    >
      {is_open_estimate_missings_modal && (
        <EstimateMissingsModal
          handleClose={() => setOpenEstimateMissingsModal(false)}
        />
      )}
      <MainList
        query_key="grns"
        api_path="/grns"
        sort_column="prepared_at"
        sort_direction="desc"
        sort={sort}
        filters={filters}
        handleNavigate={(item, e) =>
          e?.button === 1
            ? window.open(`/grns/${item.id}`, "_blank")
            : history.push(`/grns/${item.id}`)
        }
        renderItem={({
          prepared_at,
          identifier,
          kind,
          from_name,
          to_name,
          status,
          is_dispatched,
          progress,
        }) => {
          const label = getStatusLabelData({
            status,
            is_dispatched,
          });
          return (
            <>
              <td>{convertDate({ value: prepared_at })}</td>
              <td>
                <strong>{identifier}</strong>
              </td>
              <td>{kind === "shift" ? "Przesunięcie (MM)" : "Dostawa"}</td>
              <td>{from_name}</td>
              <td>{to_name}</td>
              <td>
                <div className="progress-bar">
                  <div
                    className="progress-bar__inner"
                    style={{ width: `${progress || 0}%` }}
                  />
                </div>
              </td>
              <td>
                <div
                  className={`status-label status-label--sm ${label.status}`}
                >
                  <span>{label.status_text}</span>
                </div>
              </td>
            </>
          );
        }}
      />
    </Main>
  );
};

export default GrnsList;
