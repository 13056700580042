import { client_v2 } from "utils/api";

const getGdns = (q) =>
  new Promise(async (resolve, reject) => {
    client_v2
      .get(
        `/gdns?context=list&per_page=10${
          q ? `&q[id_or_expano_oms_id_eq]=${q}` : ""
        }`
      )
      .then(({ data }) =>
        resolve(
          data?.map(({ id, expano_oms_id, delivery_fullname }) => ({
            label: `OMS: ${expano_oms_id} | WMS: ${id} | ${delivery_fullname}`,
            value: id,
          }))
        )
      )
      .catch((err) => reject(err));
  });

export default getGdns;
