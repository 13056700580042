import React, { useState } from "react";
import { connect } from "react-redux";
import { Button } from "expano-components";
import { AddEan } from "./modals";
import EanListItem from "./components/EanListItem";

const LogisticEans = ({
  current_user,
  logistic_eans,
  product_id,
}) => {
  const [show_add_ean, handleShowAddEan] = useState(false);
  return (
    <>
      {show_add_ean && (
        <AddEan
          product_id={product_id}
          handleClose={() => handleShowAddEan(false)}
        />
      )}
      <div className="dashboard m-b-10">
        <header className="dashboard__header dashboard__header--edit">
          <div className="icon__container">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20">
              <rect fill="none" width="20" height="20" />
              <path
                fill="#5d78ff"
                d="M11.167,5h1.667V17.5H11.167ZM4.5,5V17.5H2.833A.833.833,0,0,1,2,16.667V5.833A.833.833,0,0,1,2.833,5Zm9.167,0h1.667V17.5H13.667ZM17,5h.833a.833.833,0,0,1,.833.833V16.667a.833.833,0,0,1-.833.833H17Z"
                transform="translate(-0.333 -0.833)"
              />
              <path
                fill="#5d78ff"
                opacity="0.3"
                d="M8.667,5V17.5H7V5Z"
                transform="translate(-1.167 -0.833)"
              />
            </svg>
          </div>
          <h2 className="dashboard__title heading">
            Kody logistyczne
          </h2>
        </header>
        {logistic_eans?.length > 0 && (
          <ul className="dashboard__list">
            {logistic_eans.map((item) => (
              <EanListItem key={item.id} {...item} />
            ))}
          </ul>
        )}
        {["admin", "manager"].includes(
          current_user.role
        ) && (
          <div className="dashboard__button-section">
            <Button
              type="add"
              onClick={() => handleShowAddEan(true)}
              text="Dodaj kod"
            />
          </div>
        )}
      </div>
    </>
  );
};

const mapStateToProps = ({
  current_user,
  edit_product,
}) => ({
  current_user,
  logistic_eans: edit_product?.data?.logistic_eans,
  product_id: edit_product?.data?.id,
});

export default connect(mapStateToProps, null)(LogisticEans);
