import React from "react";
import { Button } from "expano-components";
import { Modal } from "components";
import { ReactComponent as DangerIcon } from "icons/danger.svg";
import { connect } from "react-redux";
import {
  removeScanned,
  markAsPackedGdnLine,
} from "actions/pack_gdn";

import StyledProductLine from "./styles";

const ConfirmModal = ({
  removeScanned,
  markAsPackedGdnLine,
  scanned,
}) => {
  if (!scanned) return null;
  const image_url = scanned?.product?.image_url;

  return (
    <Modal
      size="lg"
      header={{
        icon: <DangerIcon />,
        title: "Upewnij się, czy na pewno spakowałeś/aś",
      }}
      footer={
        <>
          <Button
            type="cancel"
            text="Zamknij"
            onClick={() => removeScanned()}
          />
          <button
            type="submit"
            onClick={() => markAsPackedGdnLine(scanned?.id)}
            className="btn btn-green">
            <span>Tak, spakowane</span>
          </button>
        </>
      }
      handleClose={() => removeScanned()}>
      <div className="popup__body p-30">
        {scanned && (
          <StyledProductLine>
            <div className="product-holder">
              <div
                className="product-image"
                style={{
                  backgroundImage: `url(${image_url})`,
                }}
              />
              <div className="product-details">
                <p className="sku">
                  {scanned?.product?.sku}
                </p>
                <p className="name">
                  {scanned?.product?.name}
                </p>
                <p className="codes">
                  <span>
                    KOD:{" "}
                    {scanned?.product?.producer_code || "-"}
                  </span>
                </p>
              </div>
              <div className="quantity">
                <span className="quantity__value">
                  {scanned?.quantity
                    ? Number(scanned.quantity).toFixed(0)
                    : "-"}
                </span>
              </div>
            </div>
          </StyledProductLine>
        )}
      </div>
    </Modal>
  );
};

const mapDispatchToProps = (dispatch) => ({
  markAsPackedGdnLine: (id) =>
    dispatch(markAsPackedGdnLine(id)),
  removeScanned: () => dispatch(removeScanned()),
});

export default connect(
  ({ pack_gdn: { scanned } }) => ({ scanned }),
  mapDispatchToProps
)(ConfirmModal);
