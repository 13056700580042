import { toastr } from "react-redux-toastr";
import { client_v2 } from "utils/api";

export const getAlerts = () => async (dispatch) => {
  try {
    dispatch({
      type: `ALERTS_LOADING`,
    });
    const { data } = await client_v2.get(
      "/gdn_packs/alerts"
    );
    dispatch({
      type: "ALERTS_SUCCESS",
      data,
    });
  } catch (error) {
    dispatch({ type: "ALERTS_FAILURE" });
    toastr.error(
      "Błąd",
      "Wystąpił błąd podczas pobierania uwag"
    );
    throw error;
  }
};

export const assignPicker = ({
  user_id,
  gdn_pack_id,
}) => async (dispatch) => {
  await client_v2.put(`/gdn_packs/${gdn_pack_id}`, {
    picker_id: user_id,
  });
  toastr.success("Sukces", "Piker został przypisany");
  dispatch(getAlerts());
  try {
  } catch (error) {
    toastr.error(
      "Błąd",
      "Wystąpił błąd podczas przypisywania pikera"
    );
    throw error;
  }
};

export const assignPacker = ({
  user_id,
  gdn_pack_id,
}) => async (dispatch) => {
  await client_v2.put(`/gdn_packs/${gdn_pack_id}`, {
    packer_id: user_id,
  });
  toastr.success("Sukces", "Paker został przypisany");
  dispatch(getAlerts());
  try {
  } catch (error) {
    toastr.error(
      "Błąd",
      "Wystąpił błąd podczas przypisywania pakera"
    );
    throw error;
  }
};
