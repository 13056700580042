import React, { useState } from "react";
import { Chart } from "react-google-charts";
import { connect } from "react-redux";
import { Select } from "expano-components";

import { ReactComponent as InfoIcon } from "icons/info.svg";

const getTotal = (data = {}) => {
  const total_data = Object.values(data).map(
    ({ count }) => count
  );
  return total_data.reduce((a, acc) => a + acc, 0);
};

const getLabel = (key) => {
  switch (key) {
    case "heavy":
      return "ciężkie";
    case "none":
      return "box_1";
    default:
      return key;
  }
};

const createChartData = (data) => {
  const chart_data = [
    ["Element", "Ilość zamówień", "Ilość zamówień"],
  ];
  try {
    if (!data) return chart_data;

    const keys = Object.keys(data);

    keys.forEach((key) => {
      chart_data.push([
        `${getLabel(key)}\n${data[key]?.latency} H`,
        key === "heavy" ? data[key]?.count : null,
        key !== "heavy" ? data[key]?.count : null,
      ]);
    });
  } catch (error) {
    console.log(error);
  }
  return chart_data;
};

const infoByType = (type) => {
  switch (type) {
    case "new_cart_box_size":
      return "Zamówienia w statusach new pogrupowane na podstawie cart_box_size";
    case "in_progress_cart_box_size":
      return "Zamówienia w statusach picked, packed pogrupowane na podstawie cart_box_size";
    case "completed_cart_box_size":
      return "Zamówienia w statusie completed z dzisiejszą datą skompletowania, pogrupowane na podstawie cart_box_size";
    default:
      return "";
  }
};

const GaugeChart = ({ data }) => {
  const [type, setType] = useState("new_cart_box_size");

  const chart_data = createChartData(data?.stats?.[type]);

  const total_new_cart_box_size = getTotal(
    data?.stats?.new_cart_box_size
  );

  const total_in_progress_cart_box_size = getTotal(
    data?.stats?.in_progress_cart_box_size
  );

  const total_completed_cart_box_size = getTotal(
    data?.stats?.completed_cart_box_size
  );

  const options = [
    {
      label: `Nowe (${total_new_cart_box_size})`,
      value: "new_cart_box_size",
    },
    {
      label: `W trakcie (${total_in_progress_cart_box_size})`,
      value: "in_progress_cart_box_size",
    },
    {
      label: `Zrealizowane (${total_completed_cart_box_size})`,
      value: "completed_cart_box_size",
    },
  ];

  const info = infoByType(type);

  return (
    <div className="dashboard">
      <div className="dashboard__header chart-box__header">
        <h2 className="dashboard__title heading">
          Według rozmiaru
        </h2>
        <div className="chart-box__header__aside">
          <Select
            options={options}
            handleChange={(value) => setType(value)}
            value={options.find(
              ({ value }) => value === type
            )}
          />
          <div className="info-icon" data-tooltip={info}>
            <InfoIcon />
          </div>
        </div>
      </div>
      <Chart
        options={{
          isStacked: true,
          chartArea: { width: "85%" },
          vAxis: { minValue: 0, maxValue: 15 },
          hAxis: {
            textStyle: {
              fontSize: 13,
              color: "#595D6E",
            },
          },
          legend: { position: "none" },
          colors: ["#ffb822", "#5D78FF"],
        }}
        chartType="ColumnChart"
        loader={null}
        width="100%"
        height="300px"
        data={chart_data}
      />
    </div>
  );
};

export default connect(({ dashboard: { data } }) => ({
  data,
}))(GaugeChart);
