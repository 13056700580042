import React, { useState } from "react";

import { ReactComponent as SaveIcon } from "icons/save.svg";

const Input = ({
  input,
  form_name,
  type,
  status,
  submit,
  defaultValue,
  isBlocked,
}) => {
  const [focus, setFocus] = useState(false);

  return (
    <div className="form-group save-group">
      <div className="inp-wrapper">
        <div
          className={`inp-group  ${
            status !== null ? status : ""
          }`}>
          <input
            {...input}
            disabled={isBlocked}
            onFocus={() => setFocus(true)}
            onBlur={() => {
              Number(defaultValue) ===
                Number(input.value) &&
                setTimeout(() => {
                  setFocus(false);
                }, 200);
            }}
            data-form={form_name}
            data-value={defaultValue}
            step="1"
            min="0"
            placeholder="Ilość"
            type={type}
          />
        </div>
        <button
          tabIndex="-1"
          onClick={() => {
            submit();
            setFocus(false);
          }}
          className="btn-save"
          disabled={!focus || isBlocked}>
          <SaveIcon />
        </button>
      </div>
    </div>
  );
};

export default Input;
