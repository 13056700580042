import { Button } from "expano-components";
import React, { useState } from "react";
import { connect } from "react-redux";
import CheapestSpeditorModal from "./components/CheapestSpeditorModal";

const labels = [
  {
    name: "Gabaryt",
    key: "none",
  },
  {
    name: "D14",
    key: "d_14",
  },
  {
    name: "G17",
    key: "g_17",
  },
  {
    name: "K20",
    key: "k_20",
  },
  {
    name: "A3",
    key: "a_3",
  },
  {
    name: "A4",
    key: "a_4",
  },
  {
    name: "B5",
    key: "b_5",
  },
  {
    name: "B6",
    key: "b_6",
  },
  {
    name: "B9",
    key: "b_9",
  },
  {
    name: "C1",
    key: "c_1",
  },
  {
    name: "C2",
    key: "c_2",
  },
  {
    name: "C4",
    key: "c_4",
  },
  {
    name: "T1",
    key: "t_1",
  },
  {
    name: "T2",
    key: "t_2",
  },
  {
    name: "E1",
    key: "e_1",
  },
  {
    name: "E2",
    key: "e_2",
  },
  {
    name: "R1",
    key: "r_1",
  },
];

const PackageBoxesStats = ({ data }) => {
  const [is_open, setOpen] = useState(false);
  return (
    <div className="dashboard">
      {is_open && (
        <CheapestSpeditorModal
          handleClose={() => setOpen(false)}
        />
      )}
      <div className="dashboard__header">
        <h2 className="dashboard__title heading">
          Statystyki paczek
        </h2>
        <Button
          text="Najtańszy kurier"
          onClick={() => setOpen(true)}
        />
      </div>
      <table className="table table-sm">
        <thead className="table__header">
          <tr>
            <th width="25%">Rodzaj</th>
            <th width="25%">7 dni</th>
            <th width="25%">14 dni</th>
            <th width="25%">28 dni </th>
          </tr>
        </thead>
        <tbody className="table__body">
          {labels.map(({ key, name }) => (
            <tr key={key}>
              <td>{name}</td>
              <td>{data?.week?.[key] || "-"}</td>
              <td>{data?.two_weeks?.[key] || "-"}</td>
              <td>{data?.month?.[key] || "-"}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default connect(
  ({ package_boxes: { data } }) => ({
    data,
  })
)(PackageBoxesStats);
