import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { Button } from "expano-components";
import QRCode from "react-qr-code";
import { ReactComponent as QrIcon } from "icons/qr.svg";

const QrCodeModal = ({
  identifier,
  handleClose,
  title = "QR kod paka",
  value = "",
}) => {
  const [active, setActive] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setActive(true);
    }, 200);
  }, []);

  return ReactDOM.createPortal(
    <div className="popup-wrapper qr-code-modal">
      <div className={`popup ${active ? "active" : ""}`}>
        <header className="popup__header">
          <div className="icon__container">
            <QrIcon />
          </div>
          <h2 className="dashboard__title heading">
            {title} {identifier}
          </h2>
          <Button type="close" onClick={handleClose} />
        </header>
        <section
          className="popup__body"
          style={{
            padding: "50px 30px",
            textAlign: "center",
          }}
        >
          <QRCode value={value} size={128} />
        </section>
        <div className="popup__footer">
          <Button type="cancel" text="Zamknij" onClick={handleClose} />
        </div>
      </div>
    </div>,
    document.getElementById("root")
  );
};

export default QrCodeModal;
