import { client_v2 } from "utils/api";

const getUsers = (q) =>
  new Promise(async (resolve, reject) => {
    client_v2
      .get(
        `/users?context=list&per_page=20&q[is_active_eq]=true&${
          q
            ? `&q[first_name_or_last_name_matches]=%25${q}%25`
            : ""
        }`
      )
      .then(({ data }) =>
        resolve(
          data?.map(({ id, full_name }) => ({
            label: full_name,
            value: id,
          }))
        )
      )
      .catch((err) => reject(err));
  });

export default getUsers;
