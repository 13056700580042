import React, { useState } from "react";
import moment from "moment";
import { getUserErrorName } from "utils/gdn_user_error_options";
import { Button } from "expano-components";
import { cancelIssue } from "actions/gdns";
import { connect } from "react-redux";

const Item = ({
  id,
  kind,
  points,
  user,
  issuer,
  description,
  created_at,
  is_deleted,
  deleted_at,
  deleted_by,
  cancelIssue,
}) => {
  const [is_canceling, setCanceling] = useState(false);

  const handleCancel = async () => {
    try {
      setCanceling(true);
      await cancelIssue(id);
      setCanceling(false);
    } catch (error) {
      setCanceling(false);
    }
  };
  return (
    <li className="dashboard__list-item">
      <div className="m-r-15">
        {is_deleted && (
          <div className="status-label status-label--sm danger m-b-10">
            <span>Anulowane</span>
          </div>
        )}
        <p className="highlighted">{getUserErrorName(kind)}</p>
        <p>Data zgłoszenia: {moment(created_at).format("DD-MM-YYYY HH:mm")}</p>
        <p>
          Osoba odpowiedzialna: {user?.first_name} {user?.last_name}
        </p>
        <p>
          Osoba zgłaszająca: {issuer?.first_name} {issuer?.last_name}
        </p>
        <p>Opis: {description || '-'}</p>
        <p>Ilość punktów: {points}</p>
        {is_deleted ? (
          <>
            <p>
              Data anulowania: {moment(deleted_at).format("DD-MM-YYYY HH:mm")}
            </p>
            <p>Osoba anulująca: {deleted_by}</p>
          </>
        ) : (
          <div className="m-t-5">
            <Button
              type="default"
              disabled={is_canceling}
              text={is_canceling ? "Proszę czekać" : "Anuluj"}
              onClick={handleCancel}
            />
          </div>
        )}
      </div>
    </li>
  );
};

export default connect(null, (dispatch) => ({
  cancelIssue: (id) => dispatch(cancelIssue(id)),
}))(Item);
