import { toastr } from "react-redux-toastr";
import { client_v2, stringifyQuery } from "utils/api";

export const toggleFilters = () => (dispatch) => {
  dispatch({ type: "PACKS_TOGGLE_FILTERS" });
};

export const resetFilters = () => (dispatch) => {
  dispatch({ type: "PACKS_RESET_FILTERS" });
};

export const filterPacks = (query) => async (dispatch) => {
  dispatch({ type: "PACKS_SET_FILTERS_QUERY", query });
};

export const getPacks = ({
  page = 1,
  per_page = 50,
  ...rest
}) => async (dispatch, getState) => {
  try {
    const {
      status,
      filters: { query },
    } = getState().packs;

    dispatch({
      type:
        status === "invalid"
          ? "PACKS_LOADING"
          : "PACKS_SEARCHING",
    });

    const { data, meta } = await client_v2.get(
      `/gdn_packs?${stringifyQuery({
        ...rest,
        page,
        per_page,
        context: "ui_index",
        ...query,
      })}`
    );

    dispatch({
      type: "PACKS_SUCCESS",
      data,
      meta,
    });
  } catch (error) {
    dispatch({ type: "PACKS_FAILURE" });
    toastr.error(
      "Błąd",
      "Wystąpił błąd podczas pobierania"
    );
  }
};

export const setPacker = (values) =>
  new Promise(async (resolve, reject) => {
    try {
      await client_v2.put("/gdn_packs/bulk_update", values);
      resolve();
    } catch (error) {
      toastr.error(
        "Błąd",
        "Wystąpił błąd podczas przypisywania paka"
      );
      reject(error);
    }
  });

export const autoGeneratePack = (values) =>
  new Promise(async (resolve, reject) => {
    try {
      await client_v2.post(
        "/gdn_packs/auto_generate",
        values
      );
      resolve(null);
    } catch (error) {
      reject(error);
      toastr.error(
        "Błąd",
        error?.response?.data?.message ||
          "Wystąpił błąd podczas generowania paka"
      );
    }
  });
