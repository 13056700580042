import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Button, Checkbox, Select } from "expano-components";
import { Main } from "layouts";
import { getUser, updateUser } from "actions/users";

import { Field, reduxForm } from "redux-form";

import { ReactComponent as InfoIcon } from "icons/info.svg";
import { ReactComponent as UserIcon } from "icons/user.svg";
import packer_gauge_options from "utils/packer_gauge_options";
import { user_role_options_text } from "utils/user_role_options";

const SelectField = (props) => <Select {...props} />;
const CheckField = (props) => <Checkbox {...props} />;

const UserEdit = ({
  submitting,
  pristine,
  change,
  handleSubmit,
  match: {
    params: { id },
  },
  user,
  getUser,
  updateUser,
}) => {
  useEffect(() => {
    getUser(id);
  }, [id]);

  const onSubmit = (values) => updateUser(id, values);

  return (
    <Main
      is_loading={["invalid", "loading", "failure"].includes(user.status)}
      page={{
        name: user.data?.full_name,
        icon: <UserIcon />,
        breadcrumbs: [
          {
            name: "Użytkownicy",
            path: "/users",
          },
          { name: user.data?.full_name },
        ],
      }}
    >
      <div className="dashboard-wrapper-flex m-t-10">
        <div className="column-half">
          <form
            noValidate
            onSubmit={handleSubmit(onSubmit)}
            className="dashboard"
          >
            <header className="dashboard__header">
              <div className="icon__container">
                <InfoIcon />
              </div>
              <h2 className="dashboard__title heading">Podstawowe dane</h2>
            </header>
            <div className="dashboard__form p-t-20">
              <div className="dashboard-wrapper-flex">
                <div className="column-half">
                  <Field
                    name="role"
                    label="Rola"
                    component={SelectField}
                    options={user_role_options_text}
                    handleChange={(value) => change("role", value)}
                    defaultValue={user_role_options_text.find(
                      ({ value }) => value === user.data?.role
                    )}
                  />
                </div>
                <div className="column-half">
                  <Field
                    name="packer_gauge"
                    label="Gabaryt pakowacza"
                    component={SelectField}
                    options={packer_gauge_options}
                    handleChange={(value) => change("packer_gauge", value)}
                    defaultValue={packer_gauge_options.find(
                      ({ value }) => value === user.data?.packer_gauge
                    )}
                  />
                </div>
              </div>
              <div className="dashboard-wrapper-flex">
                <div className="column-half">
                  <Field
                    name="is_working_from_office"
                    label="Pracownik biurowy"
                    component={CheckField}
                  />
                </div>
              </div>
            </div>
            <div className="dashboard__form__footer">
              <Button
                disabled={pristine || submitting}
                type="save"
                text="Zapisz"
                onClick={handleSubmit(onSubmit)}
              />
            </div>
          </form>
        </div>
      </div>
    </Main>
  );
};

export default connect(
  ({ user }) => ({
    user,
    initialValues: {
      role: user.data?.role,
      packer_gauge: user.data?.packer_gauge,
      is_working_from_office: user.data?.is_working_from_office,
    },
  }),
  (dispatch) => ({
    getUser: (id) => dispatch(getUser(id)),
    updateUser: (id, values) => dispatch(updateUser(id, values)),
  })
)(
  reduxForm({
    form: "edit-user",
    enableReinitialize: true,
  })(UserEdit)
);
