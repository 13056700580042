import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Label } from "expano-components";
import { MainTable, Flag, Tooltip } from "components";
import { Main } from "layouts";
import { getGdns, filterGdns, toggleFilters, resetFilters } from "actions/gdns";
import { convertDate } from "utils/helpers";
import { client_v2, parseQuery } from "utils/api";
import gauge_options from "utils/gauge_options";
import priority_options from "utils/priority_options";
import kind_options from "utils/kind_options";
import onScan from "utils/scan_handler";

import { getStatusLabelData, status_options } from "../const/statuses";

import { ReactComponent as GdnIcon } from "icons/gdn.svg";
import { toastr } from "react-redux-toastr";

const GdnsList = ({
  gdns,
  getGdns,
  filterGdns,
  toggleFilters,
  resetFilters,
  history,
  location: { search },
}) => {
  const [is_loaded, setLoaded] = useState(false);
  const query = parseQuery(search);
  const sort = {
    "sort[column]": query["sort[column]"] || "prepared_at",
    "sort[order]": query["sort[order]"] || "desc",
  };
  useEffect(() => {
    onScan.attachTo(document);
    document.addEventListener("scan", handleScan);
    return () => {
      resetFilters();
      onScan.detachFrom(document);
      document.removeEventListener("scan", handleScan);
    };
  }, []);

  useEffect(() => {
    getGdns({ ...query, ...sort });
    setLoaded(true);
  }, [search]);

  useEffect(() => {
    if (is_loaded) {
      getGdns({ ...query, page: undefined });
    }
  }, [gdns.filters.query]);

  const handleScan = ({ detail }) => {
    try {
      const [type, gdn_id] = detail.scanCode.split("|");
      if (type !== "gdn_id") {
        throw new Error("Nieprawidłowy kod QR");
      }
      history.push(`/gdns/${gdn_id}`);
    } catch (error) {
      toastr.error("Błąd", error?.message || "Wystąpił błąd");
    }
  };

  if (gdns.status === "failure") {
    return <p>Error</p>;
  }

  const { data, meta } = gdns;

  return (
    <Main
      page={{
        name: "Wydania",
        icon: <GdnIcon />,
        breadcrumbs: [{ name: "Wydania" }],
      }}
    >
      <div className="dashboard">
        <MainTable
          is_loading={["invalid", "loading"].includes(gdns.status)}
          is_searching={gdns.status === "searching"}
          filters_open={gdns.filters.open}
          toggleFilters={toggleFilters}
          data={data}
          meta={meta}
          sort={sort}
          head={[
            { label: "Numer", name: "identifier" },
            {
              label: "Pack (ID)",
              name: "gdn_pack_identifier",
              sortable: false,
            },
            {
              label: "Flaga",
              name: "flag",
              sortable: false,
              style: { width: 120 },
            },
            { label: "Kanał", name: "channel_name" },
            { label: "Data utw.", name: "prepared_at" },
            { label: "Data wyd.", name: "dispached_at" },
            {
              label: "Metoda dostawy",
              name: "delivery_method_name",
            },
            {
              label: "",
              name: "tooltip",
              sortable: false,
              style: { width: 40 },
            },
            {
              label: "Status",
              name: "status",
              sortable: false,
              style: { width: 170 },
            },
          ]}
          empty_text="Brak wydań"
          renderRow={({
            id,
            prepared_at,
            channel,
            dispached_at,
            gdn_pack,
            expano_oms_id,
            delivery_method_name,
            status,
            kind,
            priority,
            gauge,
            service_note,
          }) => {
            const label = getStatusLabelData(status);

            const kind_value = kind_options
              .filter(({ value }) => value !== "normal")
              .find(({ value }) => value === kind)?.label;

            const priority_value = priority_options
              .filter(({ value }) => value !== "standard")
              .find(({ value }) => value === priority)?.label;

            const gauge_value = gauge_options
              .filter(({ value }) => value !== "small")
              .find(({ value }) => value === gauge)?.label;

            return (
              <tr
                key={id}
                style={{ cursor: "pointer" }}
                onMouseDown={(event) => {
                  if (event.button === 1) {
                    window.open(`/gdns/${id}`, "_blank");
                  }
                }}
                onClick={() => history.push(`/gdns/${id}`)}
              >
                <td>{expano_oms_id}</td>
                <td>{gdn_pack?.id || "-"}</td>
                <td>
                  {kind_value && <Flag text={kind_value} />}
                  {priority_value && <Flag text={priority_value} />}
                  {gauge_value && <Flag text={gauge_value} />}
                </td>
                <td>
                  {channel?.identifier && (
                    <Label
                      type="double"
                      left={{
                        color: channel?.shop?.label_color || "",
                        text: channel?.shop?.label_text,
                      }}
                      right={{
                        color: channel?.platform?.label_color || "",
                        text: channel?.platform?.label_text,
                      }}
                    />
                  )}
                </td>
                <td>{convertDate({ value: prepared_at })}</td>
                <td>{convertDate({ value: dispached_at })}</td>
                <td>{delivery_method_name}</td>
                <td
                  style={{
                    padding: 0,
                    textAlign: "center",
                  }}
                >
                  {!!service_note && (
                    <Tooltip content={<p>{service_note}</p>} />
                  )}
                </td>
                <td
                  style={{
                    textAlign: "right",
                    paddingRight: 10,
                  }}
                >
                  <div
                    className={`status-label status-label--sm ${label.status}`}
                  >
                    <span>{label.status_text}</span>
                  </div>
                </td>
              </tr>
            );
          }}
          filtersAction={filterGdns}
          filters={[
            {
              type: "input",
              label: "EXPANO OMS ID",
              name: "expano_oms_id",
              search_type: "in",
            },
            {
              type: "input",
              label: "Identyfikator",
              name: "identifier",
              search_type: "matches",
            },
            {
              type: "multi-select",
              label: "Typ",
              name: "kind",
              search_type: "in",
              options: kind_options.map((item, index) => ({
                ...item,
                value: index,
              })),
            },
            {
              type: "multi-select",
              label: "Priorytet",
              name: "priority",
              search_type: "in",
              options: priority_options.map((item, index) => ({
                ...item,
                value: index,
              })),
            },
            {
              type: "multi-select",
              label: "Gabaryt",
              name: "gauge",
              search_type: "in",
              options: gauge_options.map((item, index) => ({
                ...item,
                value: index,
              })),
            },
            {
              type: "async-multi-select",
              label: "Sklep",
              name: "channel_shop_id",
              search_type: "in",
              asyncAction: () =>
                new Promise(async (resolve, reject) => {
                  try {
                    const { data } = await client_v2.get(
                      "/shops?context=list&per_page=100"
                    );
                    resolve(
                      data.map(({ id, name }) => ({
                        label: name,
                        value: id,
                      }))
                    );
                  } catch (error) {
                    console.log(error);
                    reject([]);
                  }
                }),
            },
            {
              type: "async-multi-select",
              label: "Platforma",
              search_type: "in",
              name: "channel_platform_id",
              asyncAction: () =>
                new Promise(async (resolve, reject) => {
                  try {
                    const { data } = await client_v2.get(
                      "/platforms?context=list&per_page=100"
                    );
                    resolve(
                      data.map(({ id, name }) => ({
                        label: name,
                        value: id,
                      }))
                    );
                  } catch (error) {
                    console.log(error);
                    reject([]);
                  }
                }),
            },
            {
              type: "async-multi-select",
              label: "Magazyn",
              search_type: "in",
              asyncAction: () =>
                new Promise(async (resolve, reject) => {
                  try {
                    const { data } = await client_v2.get(
                      `/warehouses?context=list&per_page=100`
                    );

                    resolve(
                      data.map(({ name }) => ({
                        label: name,
                        value: name,
                      }))
                    );
                  } catch (error) {
                    console.log(error);
                    reject([]);
                  }
                }),
              name: "warehouse_name",
            },
            {
              type: "input",
              label: "Metoda dostawy",
              name: "delivery_method_name",
              search_type: "matches",
            },
            {
              type: "date",
              label: "Data utworzenia",
              name: "prepared_at",
              search_type: "gt_eq",
            },
            {
              type: "date",
              label: "Data wydania",
              name: "dispached_at",
              search_type: "gt_eq",
            },
            {
              type: "multi-select",
              label: "Status",
              name: "status",
              defaultValue: null,
              search_type: "in",
              options: status_options.map((item, index) => ({
                ...item,
                value: index,
              })),
            },
            {
              type: "select",
              label: "Wymagana weryfikacja",
              name: "packages_need_verification",
              search_type: "true",
              options: [
                { label: "Tak", value: 1 },
                { label: "Nie", value: 0 },
              ],
              defaultValue: { label: "Nie", value: 0 },
            },
          ]}
        />
      </div>
    </Main>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getGdns: (data) => dispatch(getGdns(data)),
  filterGdns: (data) => dispatch(filterGdns(data)),
  toggleFilters: () => dispatch(toggleFilters()),
  resetFilters: () => dispatch(resetFilters()),
});
export default connect(({ gdns }) => ({ gdns }), mapDispatchToProps)(GdnsList);
