import React, { useState } from "react";
import { ModalPreview } from "expano-components";

const Image = ({ image, name }) => {
  const [is_modal_open, openModal] = useState(false);

  return (
    <div className="dashboard">
      <header className="dashboard__header dashboard__header--edit">
        <div className="icon">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20">
            <path fill="none" d="M0,0H20V20H0Z" />
            <rect
              opacity="0.3"
              fill="#5d78ff"
              width="16.667"
              height="13.333"
              rx="2"
              transform="translate(1.667 3.333)"
            />
            <path
              opacity="0.3"
              fill="#5d78ff"
              d="M4,18.5,9.417,11l5.417,7.5Z"
              transform="translate(-0.667 -1.833)"
            />
            <path
              fill="#5d78ff"
              d="M11,19l3.75-5,3.75,5Z"
              transform="translate(-1.833 -2.333)"
            />
            <circle
              opacity="0.3"
              fill="#5d78ff"
              cx="1.25"
              cy="1.25"
              r="1.25"
              transform="translate(14.167 5.833)"
            />
          </svg>
        </div>
        <h2 className="dashboard__title heading">
          Zdjęcie zamówienia
        </h2>
      </header>
      {image && (
        <div className="image-preview">
          <button
            className="btn-preview"
            onClick={() => openModal(true)}
            style={{
              backgroundImage: `url('${image}')`,
            }}
          />
        </div>
      )}
      {is_modal_open ? (
        <ModalPreview
          handleClose={() => {
            openModal(false);
          }}
          src={image}
          alt={name}
        />
      ) : null}
    </div>
  );
};

export default Image;
