import { toastr } from "react-redux-toastr";
import { client_v2 } from "utils/api";

export const getWarehouse = (id) =>
  new Promise(async (resolve, reject) => {
    try {
      const { data } = await client_v2.get(
        `/warehouses/${id}`
      );
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });

export const updateWarehouse = (
  id,
  { name, street, city }
) =>
  new Promise(async (resolve, reject) => {
    try {
      await client_v2.put(`/warehouses/${id}`, {
        name,
        street,
        city,
      });
      resolve();
      toastr.success(
        "Sukces",
        "magazyn został zaktualizowany"
      );
    } catch (error) {
      reject(error);
      toastr.error(
        "Błąd",
        "Wystąpił błąd podczas aktualizowania"
      );
    }
  });
