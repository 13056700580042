import React, { useState } from "react";
import { reduxForm, formValueSelector } from "redux-form";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Button, Select } from "expano-components";

import { AsyncSelect, Modal } from "components";
import { createMainLocation } from "actions/product_main_locations";
import AislePreview from "../components/AislePreview";

import getWarehouses from "../const/getWarehouses";
import getWarehouseRooms from "../const/getWarehouseRooms";
import getWarehouseAisles from "../const/getWarehouseAisles";
import getWarehouseRacks from "../const/getWarehouseRacks";
import getWarehouseShelfs from "../const/getWarehouseShelfs";
import getWarehousePositions from "../const/getWarehousePositions";
import clearFields from "../const/clearFields";

import { ReactComponent as LocationIcon } from "icons/location.svg";

const AddLocation = ({
  handleClose,
  handleSubmit,
  change,
  match,
  createMainLocation,
  type,
  warehouse_id,
  warehouse_room_id,
  warehouse_aisle_id,
  warehouse_rack_id,
  warehouse_shelf_id,
  warehouse_position_id,
}) => {
  const [
    warehouse_aisle_name,
    setWarehouseAisleName,
  ] = useState(null);

  const onSubmit = (values) => {
    const data = {
      ...values,
      product_id: match.params.id,
    };

    switch (type) {
      case "main":
        return createMainLocation(data).then(() =>
          handleClose()
        );
      default:
        return null;
    }
  };

  return (
    <Modal
      size="md"
      handleClose={handleClose}
      header={{
        icon: <LocationIcon />,
        title: "Dodaj lokalizację",
      }}
      footer={
        <>
          <Button type="cancel" onClick={handleClose} />
          <Button
            type="add-green"
            text="Dodaj"
            disabled={!Boolean(warehouse_position_id)}
            onClick={handleSubmit(onSubmit)}
          />
        </>
      }>
      <div className="dashboard-wrapper-flex">
        <div className="column-half">
          <form
            noValidate
            onSubmit={handleSubmit(onSubmit)}>
            <AsyncSelect
              label="Magazyn"
              isDisabled={false}
              getData={getWarehouses}
              onChange={({ value }) => {
                clearFields(change, "warehouse_id");
                setTimeout(() => {
                  change("warehouse_id", value);
                }, 200);
              }}
            />
            {!!warehouse_id ? (
              <AsyncSelect
                label="Pomieszczenie"
                getData={(q) =>
                  getWarehouseRooms(q, warehouse_id)
                }
                onChange={({ value }) => {
                  clearFields(change, "warehouse_room_id");
                  setTimeout(() => {
                    change("warehouse_room_id", value);
                  }, 200);
                }}
              />
            ) : (
              <Select
                label="Pomieszczenie"
                isDisabled={true}
              />
            )}

            {!!warehouse_room_id ? (
              <AsyncSelect
                label="Alejka"
                getData={(q) =>
                  getWarehouseAisles(q, warehouse_room_id)
                }
                onChange={({ value, label }) => {
                  clearFields(change, "warehouse_aisle_id");
                  setWarehouseAisleName(label);
                  setTimeout(() => {
                    change("warehouse_aisle_id", value);
                  }, 200);
                }}
              />
            ) : (
              <Select label="Alejka" isDisabled={true} />
            )}
            {!!warehouse_aisle_id ? (
              <AsyncSelect
                label="Regał"
                getData={(q) =>
                  getWarehouseRacks(q, warehouse_aisle_id)
                }
                onChange={({ value }) => {
                  clearFields(change, "warehouse_rack_id");
                  setTimeout(() => {
                    change("warehouse_rack_id", value);
                  }, 200);
                }}
              />
            ) : (
              <Select label="Regał" isDisabled={true} />
            )}
            {!!warehouse_rack_id ? (
              <AsyncSelect
                label="Półka"
                getData={(q) =>
                  getWarehouseShelfs(q, warehouse_rack_id)
                }
                onChange={({ value }) => {
                  clearFields(change, "warehouse_shelf_id");
                  setTimeout(() => {
                    change("warehouse_shelf_id", value);
                  }, 200);
                }}
              />
            ) : (
              <Select label="Półka" isDisabled={true} />
            )}
            {!!warehouse_shelf_id ? (
              <AsyncSelect
                label="Miejsce"
                getData={(q) =>
                  getWarehousePositions(
                    q,
                    warehouse_shelf_id
                  )
                }
                onChange={({ value }) => {
                  change("warehouse_position_id", value);
                }}
              />
            ) : (
              <Select label="Miejsce" isDisabled={true} />
            )}
          </form>
        </div>
        <div className="column-half">
          <AislePreview
            type={type}
            warehouse_position_id={warehouse_position_id}
            aisle_name={warehouse_aisle_name}
          />
        </div>
      </div>
    </Modal>
  );
};

const selector = formValueSelector("add-product-location");

const mapStateToProps = (state) => ({
  initialValues: {
    warehouse_id: null,
    warehouse_room_id: null,
    warehouse_aisle_id: null,
    warehouse_rack_id: null,
    warehouse_shelf_id: null,
    warehouse_position_id: null,
  },
  warehouse_id: selector(state, "warehouse_id"),
  warehouse_room_id: selector(state, "warehouse_room_id"),
  warehouse_aisle_id: selector(state, "warehouse_aisle_id"),
  warehouse_rack_id: selector(state, "warehouse_rack_id"),
  warehouse_shelf_id: selector(state, "warehouse_shelf_id"),
  warehouse_position_id: selector(
    state,
    "warehouse_position_id"
  ),
});

const mapDispatchToProps = (dispatch) => ({
  createMainLocation: (data) =>
    dispatch(createMainLocation(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: "add-product-location",
    enableReinitialize: true,
  })(withRouter(AddLocation))
);
