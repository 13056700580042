import React from "react";
import { connect } from "react-redux";
import getActivityStatus from "pages/Dashboard/const/getActivityStatus";

const Activity = ({ data }) => {
  return (
    <div className="dashboard">
      <div className="history-box__header">
        <span className="title">Aktywność</span>
      </div>
      {data?.user_activity?.length > 0 && (
        <ul className="history-box__body">
          {data.user_activity
            .sort((a, b) =>
              a.full_name?.localeCompare(b?.full_name)
            )
            .map(
              ({
                full_name,
                packing_station_name,
                status,
                gdn_pack_id,
              }) => (
                <li
                  key={full_name}
                  style={
                    !gdn_pack_id
                      ? {
                          pointerEvents: "none",
                        }
                      : {}
                  }
                  onClick={() =>
                    gdn_pack_id
                      ? window.open(
                          `/packing/gdn_pack/${gdn_pack_id}`,
                          "_blank"
                        )
                      : undefined
                  }>
                  <span>
                    {full_name} (
                    {packing_station_name || "-"})
                  </span>
                  <span>
                    {getActivityStatus(status, gdn_pack_id)}
                  </span>
                </li>
              )
            )}
        </ul>
      )}
    </div>
  );
};

export default connect(({ dashboard: { data } }) => ({
  data,
}))(Activity);
