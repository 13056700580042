import { useState } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { removeGrnLineBatch } from "actions/grns";
import { ModalDelete } from "expano-components";

import { ReactComponent as WarehouseIcon } from "icons/warehouse.svg";
import { ReactComponent as RoomIcon } from "icons/room.svg";
import { ReactComponent as UserIcon } from "icons/user.svg";
import { ReactComponent as ClockIcon } from "icons/clock.svg";
import { ReactComponent as ProductIcon } from "icons/product.svg";
import { ReactComponent as RemoveIcon } from "icons/remove.svg";
import ConfirmModal from "./components/ConfirmModal";

const Item = ({
  id,
  grn_line_id,
  quantity,
  confirmed_at,
  warehouse_position,
  user,
  removeGrnLineBatch,
  deleted_at,
  deleted_by,
  is_deleted,
  is_disabled,
}) => {
  const [is_removing, setRemoving] = useState(false);
  const [is_open_confirm_modal, setOpenConfirmModal] = useState(false);
  const [deleting_user_id, setDeletingUserId] = useState(null);

  const is_confirmed = !!confirmed_at;

  const handleDelete = async () => {
    try {
      setRemoving(true);
      await removeGrnLineBatch({
        grn_line_id,
        grn_line_batch_id: id,
        deleting_user_id,
      });
      setRemoving(false);
      setDeletingUserId(null)
    } catch (error) {
      setRemoving(false);
    }
  };

  return (
    <div className={`grn-line-batch${is_deleted ? " deleted" : ""}`} key={id}>
      <span>
        {is_confirmed
          ? `${warehouse_position?.warehouse?.name} x${quantity}`
          : "W trakcie"}
      </span>

      {!is_deleted && !is_disabled && (
        <button
          className="grn-line-batch__remove"
          type="button"
          onClick={() => setOpenConfirmModal(true)}
          disabled={is_removing}
        >
          x
        </button>
      )}
      <div className="grn-line-batch__info">
        <p>
          <span className="icon">
            <UserIcon />
          </span>
          <span className="text">{user.full_name}</span>
        </p>
        {confirmed_at && (
          <p>
            <span className="icon">
              <ClockIcon />
            </span>
            <span className="text">
              {moment(confirmed_at).format("YYYY.MM.DD HH:mm")}
            </span>
          </p>
        )}
        {warehouse_position?.id && (
          <>
            <p>
              <span className="icon">
                <WarehouseIcon />
              </span>
              <span>{warehouse_position?.warehouse?.name}</span>
            </p>
            <p>
              <span className="icon">
                <RoomIcon />
              </span>
              <span>{warehouse_position?.identifier}</span>
            </p>
          </>
        )}
        {quantity > 0 && (
          <p>
            <span className="icon">
              <ProductIcon />
            </span>
            <span className="text">{quantity} szt</span>
          </p>
        )}
        {is_deleted && (
          <p className="danger">
            <span className="icon">
              <RemoveIcon />
            </span>
            <span className="text">
              Usunięty{" "}
              <strong>{moment(deleted_at).format("YYYY.MM.DD HH:mm")}</strong>{" "}
              przez<strong> {deleted_by}</strong>
            </span>
          </p>
        )}
      </div>
      {is_open_confirm_modal && (
        <ConfirmModal
          handleClose={() => setOpenConfirmModal(false)}
          handleConfirm={(user_id) => {
            setOpenConfirmModal(false);
            setDeletingUserId(user_id);
          }}
        />
      )}
      {deleting_user_id && (
        <ModalDelete
          handleDelete={handleDelete}
          handleClose={() => setDeletingUserId(null)}
          name={`${warehouse_position?.warehouse?.name} x${quantity}?`}
        />
      )}
    </div>
  );
};

export default connect(null, (dispatch) => ({
  removeGrnLineBatch: (data) => dispatch(removeGrnLineBatch(data)),
}))(Item);
