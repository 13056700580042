import React from "react";
import { connect } from "react-redux";

const InfoText = ({ data }) => {
  if (data?.status === "waiting_for_shift") {
    return (
      <p className="box-text">
        To zamówienie oczekuje na utworzenie MM, nie możesz
        go edytować
      </p>
    );
  }
  if (data?.status === "finished") {
    return (
      <p className="box-text">
        To zamówienie jest zakończone, nie możesz go
        edytować
      </p>
    );
  }
  if (
    data?.grn_lines.filter(
      ({ dispatched_quantity }) =>
        !Boolean(dispatched_quantity)
    ).length > 0
  ) {
    return (
      <p className="box-text">
        Możesz potwierdzić tylko gdy przyjęcie jest
        uzpełnione
      </p>
    );
  }

  if (
    data?.grn_lines.filter(
      ({ dispatched_quantity, quantity }) =>
        dispatched_quantity === quantity
    ).length === data?.grn_lines?.length &&
    !data?.has_other_error
  ) {
    return (
      <p className="box-text">
        Wszystkie pozycje zostały poprawnie wydane
      </p>
    );
  }

  return null;
};

export default connect(({ grn }) => ({ data: grn?.data }))(
  InfoText
);
