import React from "react";
import { connect } from "react-redux";

import { reopenGrn } from "actions/grns";

import Status from "./components/Status";

import { ReactComponent as ConfirmGrnIcon } from "icons/confirm-grn.svg";

const Confirm = ({ data, reopenGrn }) => {
  const is_disabled =
    data.grn_lines.filter(
      ({ counting_state }) => counting_state === "pending"
    ).length > 0;
  if (data?.status === "open" || data.status === "active") {
    return (
      <button
        disabled={is_disabled}
        type="submit"
        className="btn btn-sm btn-green">
        <ConfirmGrnIcon />
        <span>Potwierdź przyjęcie</span>
      </button>
    );
  }
  return (
    <>
      {data?.status !== "finished" && (
        <button
          type="button"
          className="btn-revert"
          onClick={() => reopenGrn(data.id)}>
          Cofnij
        </button>
      )}

      <Status />
    </>
  );
};

export default connect(
  ({ grn }) => ({ data: grn?.data }),
  (dispatch) => ({
    reopenGrn: (id) => dispatch(reopenGrn(id)),
  })
)(Confirm);
