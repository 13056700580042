import { toastr } from "react-redux-toastr";
import { client_v2 } from "utils/api";

export const createRackSchema = ({
  name,
  identifier,
  width,
  depth,
  warehouse_shelf_schemas_attributes,
}) =>
  new Promise(async (resolve, reject) => {
    try {
      await client_v2.post("/warehouse_rack_schemas", {
        name,
        identifier,
        width,
        depth,
        warehouse_shelf_schemas_attributes,
      });
      resolve();
      toastr.success("Sukces", "Schemat został dodany");
    } catch (error) {
      reject(error);
      toastr.error("Błąd", "Wystąpił błąd");
    }
  });

export const updateRackSchema = (
  id,
  { name, identifier }
) =>
  new Promise(async (resolve, reject) => {
    try {
      await client_v2.put(`/warehouse_rack_schemas/${id}`, {
        name,
        identifier,
      });
      resolve();
      toastr.success(
        "Sukces",
        "Schemat został zaktualizowany"
      );
    } catch (error) {
      reject(error);
      toastr.error("Błąd", "Wystąpił błąd");
    }
  });

export const deleteRackSchema = (id) =>
  new Promise(async (resolve, reject) => {
    try {
      await client_v2.delete(
        `/warehouse_rack_schemas/${id}`
      );
      resolve();
      toastr.success("Sukces", "Schemat został usunięty");
    } catch (error) {
      reject(error);
      toastr.error("Błąd", "Wystąpił błąd");
    }
  });
