import React, { useState, useEffect } from "react";
import { Field, reduxForm } from "redux-form";
import ReactDOM from "react-dom";
import { Button, Input } from "expano-components";

import { ReactComponent as DangerIcon } from "icons/danger.svg";

const InputField = (props) => <Input {...props} />;

const validate = (values) => {
  const errors = {};
  if (!values.missing_quantity) {
    errors.missing_quantity = "Wymagane";
  } else if (isNaN(Number(values.missing_quantity))) {
    errors.missing_quantity = "Musi być liczbą";
  } else if (Number(values.missing_quantity) < 1) {
    errors.missing_quantity = "Wartość musi być większa od zera";
  }
  return errors;
};

let timeout;
const MissingModal = ({
  id,
  handleSubmit,
  handleClose,
  product,
  markAsMissingGdnLine,
  pristine,
}) => {
  const [active, setActive] = useState(false);
  useEffect(() => {
    timeout = setTimeout(() => {
      setActive(true);
    }, 200);
    return () => clearTimeout(timeout);
  }, []);

  const onSubmit = ({ missing_quantity }) =>
    markAsMissingGdnLine({ id, missing_quantity });
  return ReactDOM.createPortal(
    <div className="popup-wrapper">
      <form
        noValidate
        onSubmit={handleSubmit(onSubmit)}
        className={`popup ${active ? "active" : ""}`}>
        <header className="popup__header">
          <DangerIcon />
          <h2 className="dashboard__title heading">Zgłoś brak</h2>
          <Button type="close" onClick={handleClose} />
        </header>
        <div className="popup__body">
          <div className="popup-text">
            <p>Zgłaszasz brak produktu</p>
            <p>{product.sku}</p>
            <p>{product.name}</p>
            <div style={{ maxWidth: 230, marginTop: 30 }}>
              <Field
                type="number"
                id="missing_quantity"
                name="missing_quantity"
                component={InputField}
                label="Podaj ilość brakujących produktów"
              />
            </div>
          </div>
        </div>
        <div
          className="popup__footer"
          style={{
            padding: "10px 15px",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}>
          <Button type="cancel" text="Anuluj" onClick={handleClose} />
          <button
            disabled={pristine}
            type="submit"
            onClick={handleSubmit(onSubmit)}
            className="btn btn-sm btn-green">
            <span>Zgłoś</span>
          </button>
        </div>
      </form>
    </div>,
    document.getElementById("root")
  );
};

export default reduxForm({
  form: "gdn-line-missing",
  validate,
  enableReinitialize: true,
})(MissingModal);
