import React from "react";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { Input, Button } from "expano-components";
import { Modal } from "components";
import { editEan } from "actions/products";

const InputField = (props) => <Input {...props} />;

const EditEan = ({
  ean_id,
  handleClose,
  editEan,
  handleSubmit,
  pristine,
}) => {
  const submit = async ({ code, quantity }) => {
    await editEan(ean_id, { code, quantity });
    handleClose();
  };

  return (
    <Modal
      handleClose={handleClose}
      header={{ title: "Kody logistyczne" }}
      footer={
        <>
          <Button type="cancel" onClick={handleClose} />
          <Button
            type="save"
            text="Zapisz"
            disabled={pristine}
            onClick={handleSubmit(submit)}
          />
        </>
      }>
      <form noValidate onSubmit={handleSubmit(submit)}>
        <Field
          label="Kod"
          name="code"
          component={InputField}
          labelAside
        />
        <Field
          label="Ilość"
          name="quantity"
          component={InputField}
          labelAside
        />
      </form>
    </Modal>
  );
};

const mapDispatchToProps = (dispatch) => ({
  editEan: (id, data) => dispatch(editEan(id, data)),
});

export default connect(
  null,
  mapDispatchToProps
)(reduxForm({ form: "edit-ean" })(EditEan));
