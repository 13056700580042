import React from "react";
import { connect } from "react-redux";
import { setFilter } from "actions/packages_stats";
import { getCountryNameByCountryCode } from "utils/getCountryName";
import { date_options, getHistogramDates } from "utils/histogram_ranges";

import Select from "../Select";
import DatePicker from "../DatePicker";
import PackagesChart from "./components/PackagesChart";

const interval_options = [
  { label: "Godzinny", value: "hour" },
  { label: "Dzienny", value: "day" },
  { label: "Tygodniowy", value: "week" },
  { label: "Miesięczny", value: "month" },
  { label: "Roczny", value: "year" },
];

const Charts = ({ status, data, filters, setFilter }) => {
  const country_options = [
    ...(data.country_codes?.map(({ name, value }) => ({
      label: `${getCountryNameByCountryCode(name)} (${name})`,
      value: value,
    })) || []),
  ];

  const delivery_method_options = [
    ...(data.delivery_methods?.map(({ name, value }) => ({
      label: name,
      value: value,
    })) || []),
  ];

  const courier_name_options = [
    ...(data?.courier_names?.map(({ name, value }) => ({
      label: name,
      value: value,
    })) || []),
  ];

  const is_updating = status === "updating";

  return (
    <div className="dashboard chart-panel">
      <div className="chart-panel__body">
        <div className="chart-panel__form">
          <Select
            label="Kurier"
            is_multi={true}
            is_loading={is_updating}
            onChange={(values) =>
              setFilter({
                histogram_courier_names:
                  values?.length > 0 ? values.map(({ value }) => value) : [],
              })
            }
            options={courier_name_options}
            value={courier_name_options.filter(({ value }) =>
              filters.histogram_courier_names.includes(value)
            )}
          />
          <Select
            label="Kraj"
            is_multi={true}
            is_loading={is_updating}
            onChange={(values) =>
              setFilter({
                histogram_country_codes:
                  values?.length > 0 ? values.map(({ value }) => value) : [],
              })
            }
            options={country_options}
            value={country_options.filter(({ value }) =>
              filters.histogram_country_codes.includes(value)
            )}
          />
          <Select
            is_multi={true}
            label="Metoda dostawy"
            is_loading={is_updating}
            onChange={(values) =>
              setFilter({
                histogram_delivery_methods:
                  values?.length > 0 ? values.map(({ value }) => value) : [],
              })
            }
            options={delivery_method_options}
            value={delivery_method_options.filter(({ value }) =>
              filters.histogram_delivery_methods.includes(value)
            )}
          />
          <Select
            label="Typ"
            is_loading={is_updating}
            onChange={({ value: histogram_interval }) =>
              setFilter({ histogram_interval })
            }
            options={interval_options}
            value={interval_options.find(
              ({ value }) => value === filters.histogram_interval
            )}
          />
          <Select
            label="Zakres"
            disabled={is_updating}
            onChange={({ value }) =>
              setFilter({
                histogram_date_option: value,
                ...getHistogramDates(value),
              })
            }
            options={date_options}
            value={date_options.find(
              ({ value }) => value === filters.histogram_date_option
            )}
          />
          <DatePicker
            name="created_at"
            label="Data utworzenia"
            onChange={(value) =>
              setFilter({
                histogram_range_from: value?.[0],
                histogram_range_to: value?.[1],
              })
            }
            value={[filters.histogram_range_from, filters.histogram_range_to]}
          />
        </div>
        <div className="chart-panel__chart">
          <PackagesChart />
        </div>
      </div>
    </div>
  );
};

export default connect(
  ({ packages_stats: { data, status, filters } }) => ({
    data,
    filters,
    status,
  }),
  (dispatch) => ({
    setFilter: (filter) => dispatch(setFilter(filter)),
  })
)(Charts);
