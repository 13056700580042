import React, { useState } from "react";
import { connect } from "react-redux";
import { Button, Label, ModalDelete } from "expano-components";
import { deleteProductSupplier } from "actions/products";
import { EditSupplier } from "../modals";

const ListItem = ({
  id,
  code,
  supplier,
  is_master,
  is_product_decomposed,
  decomposed_quantity,
  is_product_composed,
  composed_quantity,
  supplier_custom_unit,
  supplier_package_quantity_list,
  deleteProductSupplier,
}) => {
  const [show_delete_supplier, handleShowDeleteSupplier] = useState(false);

  const [show_edit_supplier, handleShowEditSupplier] = useState(false);

  return (
    <>
      {show_delete_supplier && (
        <ModalDelete
          handleDelete={() => deleteProductSupplier(id)}
          handleClose={() => handleShowDeleteSupplier(false)}
          name={`dostawcę ${supplier?.name}`}
        />
      )}
      {show_edit_supplier && (
        <EditSupplier
          id={id}
          initialValues={{
            code,
            is_master,
            supplier_id: supplier?.id,
            is_product_decomposed,
            decomposed_quantity,
            is_product_composed,
            composed_quantity,
            supplier_custom_unit,
            supplier_package_quantity_list:
              supplier_package_quantity_list.join(","),
          }}
          default_select_supplier_value={{
            label: supplier.name,
            value: supplier?.id,
          }}
          default_select_supplier_package_quantity_list_value={supplier_package_quantity_list.map(
            (val) => ({ label: val, value: val })
          )}
          handleClose={() => handleShowEditSupplier(false)}
        />
      )}
      <li className="dashboard__list-item">
        <div className="m-r-15">
          <p>
            <a href={`/suppliers/${supplier?.id}`}>{supplier?.name}</a>
          </p>
          <p>Kod dostawcy: {code || "-"}</p>
          <p>Jednostka dostawcy: {supplier_custom_unit || "-"}</p>
          {is_product_decomposed && (
            <p>Ilość dekompletowana: {decomposed_quantity}</p>
          )}
          {is_product_composed && (
            <p>Ilość kompletowana: {composed_quantity}</p>
          )}
          {supplier_package_quantity_list.length > 0 && (
            <p>
              Wielokrotności paczek: {supplier_package_quantity_list.join(", ")}
            </p>
          )}
        </div>
        {is_master && <Label type="fill" color="#567cfb" text="Główny" />}
        <span className="country country-label country-label--hidden" />
        <Button type="edit" onClick={() => handleShowEditSupplier(true)} />
        <Button
          type="delete-bin"
          onClick={() => handleShowDeleteSupplier(true)}
        />
      </li>
    </>
  );
};

export default connect(null, (dispatch) => ({
  deleteProductSupplier: (id) => dispatch(deleteProductSupplier(id)),
}))(ListItem);
