import React from "react";
import { Modal, PackerStatisticsBar } from "components";
import getCompanyNameById from "utils/getCompanyNameById";

const cart_box_sizes = [
  "heavy",
  "none",
  "box_4",
  "box_6",
  "box_12",
  "box_24",
];

const CompletedBoxModal = ({
  handleClose,
  completed_per_pickers_and_packers = {},
  completed_per_company = {},
}) => {
  const completed_data =
    Object.keys(completed_per_pickers_and_packers).length >
    0
      ? Object.keys(completed_per_pickers_and_packers).map(
          (key) => ({
            name: key,
            ...completed_per_pickers_and_packers[key],
          })
        )
      : [];

  const companies_data =
    Object.keys(completed_per_company).length > 0
      ? Object.keys(completed_per_company).map((key) => ({
          name: key,
          value: completed_per_company[key],
        }))
      : [];

  return (
    <Modal
      size="full"
      handleClose={handleClose}
      header={{
        icon: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24">
            <circle
              fill="#0abb87"
              opacity="0.3"
              cx="10"
              cy="10"
              r="10"
              transform="translate(2 2)"
            />
            <path
              fill="#0abb87"
              d="M16.769,7.818a1,1,0,0,1,1.462,1.365l-7,7.5a1,1,0,0,1-1.382.077l-3.5-3a1,1,0,0,1,1.3-1.519l2.772,2.376Z"
            />
          </svg>
        ),
        title: "Zrealizowane",
      }}>
      {companies_data?.length > 0 && (
        <ul className="dashboard__modal-list">
          <li className="title">
            <span>Firmy</span>
          </li>
          {companies_data.map(({ name, value }, index) => (
            <li key={index}>
              <span>{getCompanyNameById(name)}</span>
              <span>{value}</span>
            </li>
          ))}
        </ul>
      )}
      {completed_data?.length > 0 && (
        <div className="dashboard__modal-table">
          <p className="title">Pikerzy i pakerzy</p>
          <table>
            <tr className="main-head">
              <th width="220"></th>
              <th width="25%">Zbieranie</th>
              <th>Pakowanie</th>
              <th width="120"></th>
            </tr>
            <tr className="sub-head">
              <th>Imię i nazwisko</th>
              <th>
                <table className="sub-head__table">
                  <tr>
                    <th width="33%">Ilość lokalizacji</th>
                    <th width="33%">Ilość sztuk</th>
                    <th width="33%">Punkty</th>
                  </tr>
                </table>
              </th>
              <th>
                <table className="sub-head__table with-padding">
                  <tr>
                    {cart_box_sizes.map((item) => (
                      <th key={item} width="14%">
                        {item === "none" ? "box_1" : item}
                        <table className="sub-head__table__table">
                          <tr>
                            <th width="50%">*</th>
                            <th width="50%">1</th>
                          </tr>
                        </table>
                      </th>
                    ))}
                    <th>Punkty</th>
                  </tr>
                </table>
              </th>
              <th>
                <strong>Suma</strong>
              </th>
            </tr>
            {completed_data
              .sort(
                (a, b) => b.total_points - a.total_points
              )
              .map(
                ({
                  name,
                  total_points,
                  picking,
                  packing,
                }) => (
                  <tr className="table-row">
                    <td>
                      {name}
                      <PackerStatisticsBar
                        show_label={false}
                        packer_gauge="small"
                        value={total_points}
                      />
                    </td>
                    <td>
                      <table>
                        <tr>
                          <td width="33%">
                            {picking.gdn_lines_count}
                          </td>
                          <td width="33%">
                            {picking.gdn_lines_quantity}
                          </td>
                          <td width="33%">
                            {picking.points}
                          </td>
                        </tr>
                      </table>
                    </td>
                    <td>
                      <table>
                        <tr>
                          {cart_box_sizes.map((key) => (
                            <td key={key} width="14%">
                              <table>
                                <tr>
                                  <td width="50%">
                                    {packing[key] || "-"}{" "}
                                  </td>
                                  <td width="50%">
                                    {packing[`${key}_s`] ||
                                      "-"}
                                  </td>
                                </tr>
                              </table>
                            </td>
                          ))}
                          <td>{packing.points}</td>
                        </tr>
                      </table>
                    </td>
                    <td>
                      <strong>{total_points}</strong>
                    </td>
                  </tr>
                )
              )}
          </table>
        </div>
      )}
    </Modal>
  );
};

export default CompletedBoxModal;
