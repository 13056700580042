import React, { useState } from "react";
import {
  Field,
  reduxForm,
  SubmissionError,
  reset,
} from "redux-form";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Button, Textarea } from "expano-components";
import { updateProduct } from "actions/products";
import { ReactComponent as InfoIcon } from "icons/info.svg";
import { ReactComponent as NoteIcon } from "icons/note.svg";

const TextAreaField = (props) => <Textarea {...props} />;

const NoteForm = ({
  updateProduct,
  match,
  handleSubmit,
  submiting,
  pristine,
  dispatch,
  initialValues,
}) => {
  const [is_enabled, enable] = useState(false);
  const onSubmit = (values) => {
    return updateProduct(match.params.id, values)
      .then(() => enable(false))
      .catch((err) => {
        throw new SubmissionError(err);
      });
  };
  const handleCancel = () => {
    dispatch(reset("note-form"));
    enable(false);
  };
  return (
    <div className="dashboard m-b-0">
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <header className="dashboard__header dashboard__header--edit">
          <div className="icon__container">
            <InfoIcon />
          </div>
          <h2 className="dashboard__title heading">
            Notatki
          </h2>
          {!is_enabled ? (
            <button
              className="btn btn-default btn-note"
              type="button"
              onClick={() => enable(true)}>
              <NoteIcon />
              <span>Edytuj</span>
            </button>
          ) : (
            <>
              <Button
                type="cancel"
                onClick={handleCancel}
                style={{ marginRight: 15 }}
              />
              <Button
                disabled={pristine || submiting}
                type="save"
                text="Zapisz"
                onClick={handleSubmit(onSubmit)}
              />
            </>
          )}
        </header>
        <div className="dashboard__form">
          {is_enabled ? (
            <Field name="note" component={TextAreaField} />
          ) : (
            <p>{initialValues.note || "Brak"}</p>
          )}
        </div>
      </form>
    </div>
  );
};

const mapStateToProps = ({ edit_product }) => ({
  initialValues: {
    note: edit_product?.data?.note,
  },
});

const mapDispatchToProps = (dispatch) => ({
  updateProduct: (id, data) =>
    dispatch(updateProduct(id, data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: "note-form",
    enableReinitialize: true,
  })(withRouter(NoteForm))
);
