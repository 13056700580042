import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import XLSX from "sheetjs-style";
import { Button } from "expano-components";
import { MultiSelect, Modal } from "components";
import { ReactComponent as DownloadIcon } from "icons/download.svg";
import { saveAs } from "file-saver";

const options = [
  { label: "Nazwa produktu", value: "product_name" },
  { label: "Kod dostawcy", value: "supplier_code" },
  { label: "Ilość sztuki", value: "quantity" },
  { label: "Ilość opakowanie", value: "package_quantity" },
  { label: "Jednostka", value: "unit" },
  { label: "Jednostka dostawcy", value: "supplier_custom_unit" },
  { label: "Ean logistyczny", value: "logistic_ean" },
];

const GenerateExcelModal = ({ name, data, handleClose }) => {
  const [excel_data, setExcelData] = useState([]);
  const [columns, setColumns] = useState([]);

  useEffect(() => {
    if (columns?.length > 0) {
      const excel = data.map((item) => {
        const obj = {};
        columns.forEach(({ label, value }) => {
          obj[label] = item[value];
        });
        return obj;
      });
      console.log(data);
      setExcelData(excel);
    }
  }, [columns]);

  const handleExport = () => {
    const ws = XLSX.utils.json_to_sheet(excel_data);
    const wb = {
      Sheets: { data: ws },
      SheetNames: ["data"],
    };
    const buffer = XLSX.write(wb, {
      bookType: "xlsx",
      type: "array",
    });

    const buffer_data = new Blob([buffer], {
      type: "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64",
    });
    saveAs(buffer_data, `${name}.xlsx`);
  };

  const wrong_products = data.filter(
    ({ is_decomposed, supplier_quantity }) =>
      is_decomposed && supplier_quantity > 0
  );

  return (
    <Modal
      header={{
        title: "Eksportuj do EXCEL",
        icon: <DownloadIcon />,
      }}
      footer={
        <>
          <Button type="cancel" onClick={handleClose} />
          <button
            type="button"
            onClick={handleExport}
            className="btn btn-sm btn-green"
            disabled={wrong_products.length > 0 && columns?.length < 1}
          >
            <span>Eksportuj</span>
          </button>
        </>
      }
      handleClose={handleClose}
    >
      {wrong_products.length > 0 && (
        <div className="m-b-20">
          <p className="text-danger m-b-5">
            <strong>Poniższe produkty powinny być w pełnych kartonach!</strong>
          </p>
          <ul className="dotted-list">
            {wrong_products.map(
              ({ product_name, product_sku, decomposed_missing_qty }) => (
                <li>
                  <strong>[{product_sku}]</strong> {product_name} - brakuje{" "}
                  {decomposed_missing_qty} szt.
                </li>
              )
            )}
          </ul>
        </div>
      )}
      <MultiSelect
        label="Wybierz kolumny"
        options={options}
        handleChange={(data) => setColumns(data)}
      />
    </Modal>
  );
};

const createData = (data) =>
  data.map(
    ({
      supplier_code,
      product: { name, sku, logistic_eans },
      supplier_quantity,
      quantity,
    }) => ({
      supplier_code,
      product_name: name,
      product_sku: sku,
      quantity: quantity,
      unit: supplier_quantity.unit,
      supplier_package_quantity: supplier_quantity.package_qty,
      is_decomposed: supplier_quantity.is_decomposed,
      is_composed: supplier_quantity.is_composed,
      supplier_quantity: supplier_quantity.unit_qty,
      package_quantity:
        supplier_quantity.is_decomposed || supplier_quantity.is_composed
          ? supplier_quantity.package_qty
          : quantity,
      decomposed_missing_qty:
        supplier_quantity.decomposed_qty - supplier_quantity.unit_qty,
      supplier_custom_unit:
        supplier_quantity.supplier_custom_unit || supplier_quantity.unit,
      logistic_ean: logistic_eans?.[0]?.code || "",
    })
  );

export default connect(
  ({
    grn: {
      data: { grn_lines_to_download, identifier },
    },
  }) => ({
    name: identifier,
    data: createData(grn_lines_to_download),
  }),
  null
)(GenerateExcelModal);
