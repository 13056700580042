import styled from "styled-components";

const StyledGdnLineBrand = styled.div`
  margin-bottom: 20px;
  &.disabled {
    opacity: 0.3;
    .action {
      button {
        display: none;
      }
    }
  }
  .overlay {
    background-image: linear-gradient(
      270deg,
      #ffffff 0%,
      #ffffff00 100%
    );
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  .gdn-line {
    &__main {
      position: relative;
      z-index: 0;
      padding: 10px 0 10px 40px;
      background-color: #fff;
      box-shadow: 0px 0px 13px rgba(0, 0, 0, 0.05);
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      &::before {
        content: "";
        position: absolute;
        width: 9px;
        top: 0;
        bottom: 0;
        left: 0;
        border-radius: 4px 0 0 4px;
        background-color: #ff1a66;
      }
      .preview {
        .btn-preview {
          width: 130px;
          height: 130px;
          border-radius: 4px;
          background-position: center;
          background-size: contain;
          background-repeat: no-repeat;
          overflow: hidden;
          position: relative;
          &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: rgba(93, 120, 255, 0.8);
            opacity: 0;
            transition: 0.2s;
            z-index: 1;
          }
          &::after {
            content: url(${require("icons/zoom-big.svg")});
            position: absolute;
            z-index: 2;
            top: 50%;
            left: 50%;
            opacity: 0;
            transition: 0.2s;
            transform: translate(-50%, -50%);
          }
          &:hover {
            &::before,
            &::after {
              opacity: 1;
            }
          }
        }
      }
      .info {
        flex: 1;
        padding: 0 40px;
        .title {
          font-size: 19px;
          line-height: 20px;
          margin-bottom: 4px;
          font-weight: 500;
          color: #646c9a;
        }
      }
      .quantity {
        text-align: center;
        padding: 0px 12px;
        min-width: 55px;
        display: inline-block;
        border-radius: 4px;
        border: 2px solid #e2e5ec;
        &__value {
          font-size: 39px;
          line-height: 55px;
          font-weight: 500;
          color: #646c9a;
        }
      }
      .action {
        flex-basis: 285px;
        max-width: 285px;
        padding: 0 20px;
        .action-group {
          display: flex;
          align-items: center;
          justify-content: center;
          .btn {
            min-height: 32px;
            margin: 6px;
            padding: 3px 10px;
            span {
              color: #fff;
            }
            svg {
              margin: 0;
            }
            &.btn-warning {
              background-color: #ffb822;
              border-color: #ffb822;
              svg {
                margin: 0;
                [fill] {
                  fill: #fff;
                }
              }
            }
          }
          p {
            font-size: 20px;
            line-height: 25px;
            color: #0abb87;
            strong {
              font-weight: 900;
            }
          }
          .box-icon {
            flex-basis: 52px;
            min-width: 52px;
            max-width: 52px;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 52px;
            border-radius: 50%;
            border: 2px solid rgba(10, 187, 135, 0.1);
          }
          .box-text {
            padding-left: 20px;
            p {
              color: #0abb87;
            }
          }
        }
      }
    }
  }
`;

export default StyledGdnLineBrand;
