import { toastr } from "react-redux-toastr";
import { client_v2 } from "utils/api";

export const getSettings = (load = true) => async (
  dispatch
) => {
  try {
    if (load) {
      dispatch({ type: "SETTINGS_LOADING" });
    }

    const { data } = await client_v2.get("/settings");

    dispatch({
      type: "SETTINGS_SUCCESS",
      data,
    });
  } catch (error) {
    dispatch({ type: "SETTINGS_FAILURE" });
    toastr.error(
      "Błąd",
      "Wystąpił błąd podczas pobierania"
    );
  }
};

export const updateSettings = (id, values) => async (
  dispatch
) => {
  try {
    await client_v2.put(`/companies/${id}`, values);

    await dispatch(getSettings(false));
    toastr.success(
      "Sukces",
      "Ustawienia zostały zaktualizowane"
    );
  } catch (error) {
    toastr.error(
      "Błąd",
      "Wystąpił błąd podczas aktualizowania"
    );
    throw error;
  }
};
