import React, { useState } from "react";
import { connect } from "react-redux";
import { numberWithSpaces } from "utils/helpers";
import Modal from "./components/Modal";

const CompletedBox = ({ data, user_role }) => {
  const [show_modal, handleShow] = useState(false);

  return (
    <>
      {user_role === "admin" && show_modal && (
        <Modal
          handleClose={() => handleShow(false)}
          {...data}
        />
      )}
      <div
        onClick={() =>
          user_role === "admin"
            ? handleShow(true)
            : undefined
        }
        className="summary-box"
        style={{
          cursor:
            user_role === "admin" ? "pointer" : "default",
        }}
      >
        <div className="summary-box__name colored">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
          >
            <circle
              fill="#0abb87"
              opacity="0.3"
              cx="10"
              cy="10"
              r="10"
              transform="translate(2 2)"
            />
            <path
              fill="#0abb87"
              d="M16.769,7.818a1,1,0,0,1,1.462,1.365l-7,7.5a1,1,0,0,1-1.382.077l-3.5-3a1,1,0,0,1,1.3-1.519l2.772,2.376Z"
            />
          </svg>
          <span>Zrealizowane</span>
        </div>
        <div className="summary-box__value">
          <span>
            {numberWithSpaces(data?.totals?.completed)}
          </span>
        </div>
      </div>
    </>
  );
};

export default connect(
  ({ dashboard: { data }, current_user: { role } }) => ({
    data,
    user_role: role,
  })
)(CompletedBox);
