import { toastr } from "react-redux-toastr";
import { client_v2, stringifyQuery } from "utils/api";

export const getPackagesStats = () => async (
  dispatch,
  getState
) => {
  try {
    const { status, filters } = getState().packages_stats;

    const query = stringifyQuery(filters);

    dispatch({
      type:
        status === "invalid"
          ? "PACKAGES_STATS_LOADING"
          : "PACKAGES_STATS_UPDATING",
    });
    const { data } = await client_v2.get(
      `/packages/stats?${query}`
    );
    dispatch({
      type: "PACKAGES_STATS_SUCCESS",
      data,
    });
  } catch (error) {
    dispatch({ type: "PACKAGES_STATS_FAILURE" });
    toastr.error(
      "Błąd",
      "Wystąpił błąd podczas pobierania danych"
    );
    throw error;
  }
};

export const setFilter = (filter) => (dispatch) =>
  dispatch({
    type: "PACKAGES_STATS_SET_FILTER",
    filter,
  });
