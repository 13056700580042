import React, { useState } from "react";
import classNames from "classnames";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { saveAs } from "file-saver";
import {
  getPackingGdn,
  completePackingGdn,
  openSpeditorModal,
  downloadGdnControlLabel,
} from "actions/pack_gdn";
import { api_url_v2 } from "utils/api";

import SpeditorModal from "./components/SpeditorModal";
import PackageVerifyWarningModal from "./components/PackageVerifyWarningModal";

const ConfirmPanel = ({
  disabled_delivery_methods,
  completePackingGdn,
  data,
  history,
  is_open_speditor_modal,
  openSpeditorModal,
}) => {
  const [show_package_verify_alert, setShowPackageVerifyAlert] =
    useState(false);

  if (!["packed", "completed"].includes(data.status)) {
    return null;
  }

  const is_completed = data.status === "completed";

  const has_packages = data?.packages?.length > 0;

  const handleShowAlertModal = ({ validation_result, packages_dimensions }) => {
    downloadGdnControlLabel({
      expano_oms_id: data.expano_oms_id,
      id: data.id,
      validation_result,
      packages_dimensions,
    });
    setShowPackageVerifyAlert(true);
  };

  const handleConfirm = async () => {
    try {
      const {
        packages_need_verification,
        validation_result,
        packages_dimensions,
      } = await completePackingGdn(data.id);
      packages_need_verification
        ? handleShowAlertModal({ validation_result, packages_dimensions })
        : history.push(`/packing/gdn_pack/${data.gdn_pack.id}`);
    } catch (error) {
      console.log(error);
    }
  };

  const can_confirm =
    ([
      "Allegro Odbiór w Punkcie Packeta",
      "Allegro Automaty Paczkowe Packeta",
    ].includes(data?.delivery_method_name) &&
      data.cod) ||
    has_packages;

  const is_delivery_method_disabled = disabled_delivery_methods.includes(
    data.delivery_method_name
  );

  return (
    <>
      {show_package_verify_alert && (
        <PackageVerifyWarningModal
          handleClose={() => {
            setShowPackageVerifyAlert(false);
            history.push(`/packing/gdn_pack/${data.gdn_pack.id}`);
          }}
        />
      )}
      <div
        className={classNames("confirm-panel", {
          is_completed,
        })}
      >
        {is_completed && (
          <div className="confirm-panel__info">
            <p className="title">Zamówienie zrealizowane</p>
          </div>
        )}
        {!is_completed && (
          <>
            <button
              disabled={is_delivery_method_disabled}
              className="confirm-panel__create-label"
              onClick={() => openSpeditorModal(true)}
            >
              {is_delivery_method_disabled ? (
                <span className="sm">
                  Tymczasowo zablokowana możliwość nadawania paczek dla{" "}
                  {data.delivery_method_name}.
                  <br /> Zgłoś problem z zamówieniem podając{" "}
                  <strong>wymiary paczki</strong>.
                </span>
              ) : (
                <span>Nadaj {has_packages ? "kolejną" : ""} paczkę</span>
              )}
            </button>
            <button
              disabled={!can_confirm}
              className="confirm-panel__confirm"
              onClick={handleConfirm}
            >
              <span>Potwierdź zamówienie</span>
            </button>
          </>
        )}
        {is_open_speditor_modal && (
          <SpeditorModal handleClose={() => openSpeditorModal(false)} />
        )}
      </div>
      {data?.packages?.length > 0 && (
        <ul className="confirm-panel__labels">
          {data.packages.map(
            ({ courier_number, id, is_created_manually, package_boxes }) => (
              <li key={id}>
                <div className="box-name">
                  <p>{courier_number}</p>
                </div>

                <div className="box-packages">
                  {is_created_manually ? (
                    <p style={{ marginTop: 9 }}>Utworzona ręcznie</p>
                  ) : (
                    <>
                      <p>Podpaczki:</p>
                      <ul>
                        {package_boxes.map(
                          ({
                            id,
                            size,
                            width,
                            height,
                            depth,
                            weight,
                            gauge_weight,
                          }) => (
                            <li key={id}>
                              {size} ({width}x{height}x{depth}
                              ) <br />
                              w: {weight} kg | wg.: {gauge_weight || "-"} kg
                            </li>
                          )
                        )}
                      </ul>
                    </>
                  )}
                </div>
                <button
                  type="button"
                  onClick={() => {
                    saveAs(
                      `${api_url_v2}packages/${id}/download_label`,
                      `${courier_number}.pdf`
                    );
                  }}
                >
                  <span>Pobierz etykietę</span>
                </button>
              </li>
            )
          )}
        </ul>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getPackingGdn: (data) => dispatch(getPackingGdn(data)),
  completePackingGdn: (id) => dispatch(completePackingGdn(id)),
  openSpeditorModal: (is_open) => dispatch(openSpeditorModal(is_open)),
});

export default connect(
  ({ current_user, pack_gdn: { data, is_open_speditor_modal } }) => ({
    data,
    is_open_speditor_modal,
    disabled_delivery_methods:
      current_user?.company?.disabled_delivery_methods || [],
  }),
  mapDispatchToProps
)(withRouter(ConfirmPanel));
