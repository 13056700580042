const available_warehouse_ids = [1, 6];

const checkDeliveryKind = ({
  is_simplified,
  kind,
  warehouse_id,
}) => {
  switch (true) {
    case is_simplified:
      // Tradecron Milto
      return "simplified";
    case kind === "shift":
      return "shift";
    case kind === "delivery" &&
      available_warehouse_ids.includes(warehouse_id):
      return "delivery_to_main_warehouse";
    case kind === "delivery":
      return "delivery";
    default:
      return null;
  }
};

export default checkDeliveryKind;
