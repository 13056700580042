import React from "react";
import { Link } from "react-router-dom";

const Item = ({
  id,
  gdn_identifier,
  gdn_prepared_at,
  gdn_warehouse_name,
  reported_missing_user_name,
  reported_missing_at,
  gdn_id,
  missing_quantity,
}) => {
  return (
    <>
      <tr key={id}>
        <td>{missing_quantity}</td>
        <td>
          <Link className="link" to={`/gdns/${gdn_id}`}>
            {gdn_identifier}
          </Link>
        </td>
        <td>{gdn_prepared_at || "-"}</td>
        <td>{gdn_warehouse_name}</td>
        <td>{reported_missing_at || "-"}</td>
        <td>{reported_missing_user_name || "-"}</td>
      </tr>
    </>
  );
};

export default Item;
