import { toastr } from "react-redux-toastr";
import { client_v2 } from "utils/api";

export const createAisle = ({
  name,
  identifier,
  warehouse_room_id,
}) =>
  new Promise(async (resolve, reject) => {
    try {
      await client_v2.post("/warehouse_aisles", {
        name,
        identifier,
        warehouse_room_id,
      });
      resolve();
      toastr.success("Sukces", "Alejka została dodana");
    } catch (error) {
      reject(error);
      toastr.error("Błąd", "Wystąpił błąd");
    }
  });

export const updateAisle = (id, { name, identifier }) =>
  new Promise(async (resolve, reject) => {
    try {
      await client_v2.put(`/warehouse_aisles/${id}`, {
        name,
        identifier,
      });
      resolve();
      toastr.success(
        "Sukces",
        "Alejka została zaktualizowana"
      );
    } catch (error) {
      reject(error);
      toastr.error("Błąd", "Wystąpił błąd");
    }
  });

export const deleteAisle = (id) =>
  new Promise(async (resolve, reject) => {
    try {
      await client_v2.delete(`/warehouse_aisles/${id}`);
      resolve();
      toastr.success("Sukces", "Alejka została usunięta");
    } catch (error) {
      reject(error);
      toastr.error("Błąd", "Wystąpił błąd");
    }
  });
