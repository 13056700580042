import React, { useState } from "react";
import QRCode from "qrcode";
import { saveAs } from "file-saver";
import { Loader, Modal } from "components";
import { Button } from "expano-components";
import { ReactComponent as QrcodeIcon } from "icons/qrcode.svg";
import { useEffect } from "react";
import { client_v2 } from "utils/api";
import StickerQrCodeWarehousePositions from "sticker_templates/StickerQrCodeWarehousePositions";
import { pdf } from "@react-pdf/renderer";

const ModalGenerateQrCodesForRack = ({
  id,
  name,
  handleClose,
}) => {
  const [rack_data, setRackData] = useState([]);
  const [is_loading, setIsLoading] = useState(true);
  useEffect(() => {
    getRackDate(id);
  }, [id]);

  const getRackDate = async (rack_id) => {
    try {
      const { data: shelves } = await client_v2.get(
        `/warehouse_shelves?context=list&q[warehouse_rack_id_eq]=${rack_id}&per_page=1000`
      );
      const shelves_ids = shelves.map(({ id }) => id);
      const warehouse_positions_query = shelves_ids
        .map((id) => `q[warehouse_shelf_id_in][]=${id}`)
        .join("&");
      const { data } = await client_v2.get(
        `/warehouse_positions?context=list&${warehouse_positions_query}&per_page=1000`
      );
      for (const item of data) {
        item.qr_code_base64 = await QRCode.toDataURL(
          `WP|${item.id}`
        );
      }
      setRackData(data);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleGenerate = async () => {
    pdf(
      <StickerQrCodeWarehousePositions
        data={rack_data}
        name={name}
        id={id}
      />
    )
      .toBlob()
      .then((blob) => {
        saveAs(blob, `${id}-qr.pdf`);
        handleClose();
      });
  };
  return (
    <Modal
      size="md"
      handleClose={handleClose}
      header={{
        icon: <QrcodeIcon />,
        title: `Generuj QR dla ${name}`,
      }}
      footer={
        <>
          <Button
            type="cancel"
            text="Anuluj"
            onClick={handleClose}
          />
          <button
            disabled={is_loading || rack_data.length === 0}
            type="submit"
            className="btn btn-sm btn-green"
            onClick={handleGenerate}>
            <span>Generuj</span>
          </button>
        </>
      }>
      {is_loading ? (
        <div className="wrapper jc-c">
          <Loader />
        </div>
      ) : rack_data?.length > 0 ? (
        <p>
          {rack_data
            .map(({ identifier }) => identifier)
            .join(", ")}
        </p>
      ) : (
        <p>Brak pozycji dla {name}</p>
      )}
    </Modal>
  );
};

export default ModalGenerateQrCodesForRack;
