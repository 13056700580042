export const sort = [
  { label: "ID", name: "id" },
  {
    label: "Pozycja",
    name: "identifier",
    style: { width: "25%" },
  },
  {
    label: "Ilość produktów",
    name: "product_count",
    style: { width: "200px" },
  },
  {
    label: "Głębokość",
    name: "depth",
  },
  {
    label: "Wysokość",
    name: "height",
  },
  {
    label: "Szerokość",
    name: "width",
  },
  {
    label: "Pojemność",
    name: "volume",
  },
];

export const filters = [
  {
    type: "input",
    name: "id",
    sort_id: "id",
    search_type: "matches",
  },
  {
    type: "input",
    name: "identifier",
    sort_id: "identifier",
    search_type: "matches",
  },
];
