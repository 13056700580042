import React from "react";
import { connect } from "react-redux";
import { ActionCableConsumer } from "react-actioncable-provider";
import GrnEdit from "./GrnEdit";
import { syncGrnLine } from "actions/grns";

const GrnEditActionCable = (props) => {
  return (
    <ActionCableConsumer
      channel={{
        channel: "GrnChannel",
        grn_id: props.match.params.id,
      }}
      onReceived={(res) => props.syncGrnLine(res)}
    >
      <GrnEdit {...props} />
    </ActionCableConsumer>
  );
};

export default connect(null, (dispatch) => ({
  syncGrnLine: (data) => dispatch(syncGrnLine(data)),
}))(GrnEditActionCable);
