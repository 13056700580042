import React from "react";
import { Document, Font, Image, Page, Text, View, StyleSheet } from "@react-pdf/renderer";
import { url } from "utils/api";

import RobotoBold from "fonts/Roboto-Bold.ttf";
import RobotoBlack from "fonts/Roboto-Black.ttf";

Font.register({
  family: "Roboto",
  fonts: [
    { src: RobotoBold, fontWeight: 700 },
    { src: RobotoBlack, fontWeight: 900 },
  ],
});

const Sticker10x8 = ({ data }) => (
  <Document>
    <Page size={{ width: 283.5, height: 227 }} style={styles.page}>
      <View style={styles.text}>
        <Text style={styles.sku}>{data.sku}</Text>
        <Text style={styles.name}>{data.name}</Text>
      </View>
      <View style={styles.image}>
        <Image src={`${url}${data.image}`} />
      </View>
    </Page>
  </Document>
);

const styles = StyleSheet.create({
  page: {
    backgroundColor: "#fff",
    padding: 5,
  },
  text: {
    textAlign: "center",
  },
  sku: {
    fontFamily: "Roboto",
    fontSize: 25,
    fontWeight: 900,
    textTransform: "uppercase",
    letterSpacing: 1,
    marginBottom: 5,
  },
  name: {
    fontFamily: "Roboto",
    fontWeight: 600,
    fontSize: 8,
  },
  image: {
    height: 172,
    padding: 10,
  },
});

export default Sticker10x8;