import { getCountryNameByCountryCode } from "utils/getCountryName";

const initialState = {
  status: "invalid",
  all_data: [],
  data: [],
  filters: {
    speditor: null,
    country_code: null,
    kg: null,
  },
  options: {
    speditors: [],
    countries: [],
    kgs: Array(40)
      .fill(null)
      .map((_, index) => ({
        label: (index + 1).toFixed(2),
        value: `kg_${index + 1}`,
      })),
  },
};

const filterData = (data, filters) => {
  let data_copy = [...data];

  if (filters.speditor) {
    data_copy = data_copy.filter(
      ({ id }) => id === filters.speditor
    );
  }
  if (filters.country_code) {
    data_copy = data_copy.map((item) => ({
      ...item,
      speditor_country_prices: item.speditor_country_prices.filter(
        ({ country_code }) =>
          country_code === filters.country_code
      ),
    }));
  }
  return data_copy;
};

export default (state = initialState, { type, data }) => {
  switch (type) {
    case "SPEDITORS_LOADING":
      return {
        ...state,
        status: "loading",
      };
    case "SPEDITORS_SUCCESS":
      return {
        ...state,
        status: "success",
        all_data: data,
        data: data,
        options: {
          ...state.options,
          speditors: data.map(({ id, name }) => ({
            label: name,
            value: id,
          })),
          countries: data?.[0].speditor_country_prices.map(
            ({ country_code }) => ({
              label: getCountryNameByCountryCode(
                country_code
              ),
              value: country_code,
            })
          ),
        },
      };
    case "SPEDITORS_FAILURE":
      return {
        ...state,
        status: "failure",
      };
    case "SPEDITORS_SET_FILTER": {
      return {
        ...state,
        filters: { ...state.filters, ...data },
      };
    }
    case "SPEDITORS_SET_FILTER_DATA": {
      const data = filterData(
        state.all_data,
        state.filters
      );
      return {
        ...state,
        data,
      };
    }
    case "SPEDITORS_UPDATE_PRICE": {
      const state_data = [...state.data];
      const state_all_data = [...state.all_data];

      const data_index = state_data.indexOf(
        state_data.find(
          ({ id }) =>
            Number(data.speditor_id) === Number(id)
        )
      );

      const all_data_index = state_all_data.indexOf(
        state_all_data.find(
          ({ id }) =>
            Number(data.speditor_id) === Number(id)
        )
      );

      if (data_index > -1) {
        const speditor_country_prices =
          state_data[data_index].speditor_country_prices;
        const speditor_country_price_index = speditor_country_prices.indexOf(
          speditor_country_prices.find(
            ({ id }) => Number(id) === Number(data.id)
          )
        );
        if (speditor_country_price_index > -1) {
          Object.assign(
            state_data[data_index].speditor_country_prices[
              speditor_country_price_index
            ],
            { ...data.values }
          );
        }
      }

      if (all_data_index > -1) {
        const speditor_country_prices =
          state_all_data[all_data_index].speditor_country_prices;
        const speditor_country_price_index = speditor_country_prices.indexOf(
          speditor_country_prices.find(
            ({ id }) => Number(id) === Number(data.id)
          )
        );
        if (speditor_country_price_index > -1) {
          Object.assign(
            state_all_data[all_data_index].speditor_country_prices[
              speditor_country_price_index
            ],
            { ...data.values }
          );
        }
      }

      return {
        ...state,
        data: state_data,
        all_data: state_all_data,
      };
    }
    default:
      return state;
  }
};
