import React, { useState } from "react";
import { withRouter } from "react-router";
import { MainList } from "components";

import ModalEditWarehouse from "./components/ModalEditWarehouse";
import { sort, filters } from "./const/data";

const default_state = { type: null, data: null };

const WarehousesList = ({ history }) => {
  const [
    warehouse_edit_modal,
    setWarehouseEditModal,
  ] = useState(default_state);

  const onClose = () =>
    setWarehouseEditModal(default_state);

  return (
    <div className="box-7">
      <MainList
        query_key="warehouses"
        api_path="/warehouses"
        title="Lista Magazynów"
        sort_column="id"
        sort_direction="desc"
        sort={sort}
        filters={filters}
        renderActions={(item) => [
          {
            type: "empty_position",
            handleClick: () =>
              history.push(
                `/warehouses/${item.id}/empty_positions`
              ),
          },
          {
            type: "double_position",
            handleClick: () =>
              history.push(
                `/warehouses/${item.id}/double_positions`
              ),
          },
          {
            type: "close_position",
            handleClick: () =>
              history.push(
                `/warehouses/${item.id}/close_positions`
              ),
          },
          {
            type: "preview",
            handleClick: () =>
              history.push(`/warehouses/${item.id}`),
          },
          {
            type: "edit",
            handleClick: () =>
              setWarehouseEditModal({
                type: "edit",
                data: item,
              }),
          },
        ]}
        renderItem={({ id, name, city, street }) => (
          <>
            <td>{id}</td>
            <td>{name}</td>
            <td>{street}</td>
            <td>{city}</td>
          </>
        )}>
        {warehouse_edit_modal?.type === "edit" && (
          <ModalEditWarehouse
            handleClose={onClose}
            {...warehouse_edit_modal.data}
            initialValues={{
              name: warehouse_edit_modal?.data?.name || "",
              street:
                warehouse_edit_modal?.data?.street || "",
              city: warehouse_edit_modal?.data?.city || "",
            }}
          />
        )}
      </MainList>
    </div>
  );
};

export default withRouter(WarehousesList);
