import React, { useState } from "react";
import { connect } from "react-redux";
import { Select, Button } from "expano-components";
import { useQuery } from "@tanstack/react-query";
import { Modal } from "components";
import { autoGeneratePack } from "actions/packs";
import { ReactComponent as PackIcon } from "icons/pack.svg";
import picking_mode_options from "utils/picking_mode_options";
import { client_v2 } from "utils/api";

const picking_tag_options_initial = [
  { label: "Wszystkie", value: null },
];

const ModalAddPackTag = ({
  handleClose,
  handleReload,
  current_user,
}) => {
  const [picking_mode, setPickingMode] = useState(
    current_user?.picking_mode
  );
  const { data: picking_tag_options } = useQuery(
    ["picking_tag_options"],
    () => client_v2.get("/gdns/sorting_room_tags"),
    {
      initialData: picking_tag_options_initial,
      placeholderData: picking_tag_options_initial,
      retry: false,
      select: (res) => [
        ...picking_tag_options_initial,
        ...(res?.aggs?.tag_list || []).map(
          ({ value, count }) => ({
            label: `${value} (${count})`,
            value,
          })
        ),
      ],
    }
  );

  const [picking_tag, setPickingTag] = useState(
    picking_tag_options
      ?.map(({ value }) => value)
      .includes(current_user?.picking_tag)
      ? current_user?.picking_tag
      : null
  );

  const handleAddPack = async () => {
    try {
      await autoGeneratePack({
        picking_mode,
        picking_tag,
      });

      handleClose();
      handleReload();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Modal
      handleClose={handleClose}
      header={{ icon: <PackIcon />, title: "Utwórz paka" }}
      footer={
        <>
          <Button type="cancel" onClick={handleClose} />
          <Button
            text="Utwórz"
            disabled={!picking_mode}
            type="save"
            onClick={handleAddPack}
          />
        </>
      }>
      <Select
        label="Wybierz taga"
        defaultValue={
          picking_tag_options?.find(
            ({ value }) => value === picking_tag
          ) || null
        }
        options={picking_tag_options}
        handleChange={(value) => setPickingTag(value)}
      />
      <Select
        label="Wybierz rozmiar"
        defaultValue={picking_mode_options.find(
          ({ value }) => value === picking_mode
        )}
        options={picking_mode_options}
        handleChange={(value) => setPickingMode(value)}
      />
    </Modal>
  );
};

export default connect(({ current_user }) => ({
  current_user,
}))(ModalAddPackTag);
