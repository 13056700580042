import React from "react";
import { connect } from "react-redux";

import { ReactComponent as ArrowIcon } from "icons/arrow-next.svg";
import { ReactComponent as PackageInCircleIcon } from "icons/package_in_circle.svg";
import { Link } from "react-router-dom";

const ErrorPanel = ({ status }) => {
  if (status !== "error") return null;

  return (
    <div className="status-panel error">
      <div className="status-panel__info">
        <div className="info-icon">
          <PackageInCircleIcon />
        </div>
        <div className="info-text">
          <p className="title">Uwaga!</p>
          <p className="desc">
            To zamówienie nie nadaje się do pakowania.
          </p>
        </div>
      </div>
      <Link className="btn btn-sm btn-next" to={`/packing`}>
        <span>Wróć do pakowalni</span>
        <ArrowIcon />
      </Link>
    </div>
  );
};

export default connect(
  ({
    pack_gdn: {
      data: { status },
    },
  }) => ({ status })
)(ErrorPanel);
