import styled from "styled-components";

const StyledQrCodeBox = styled.div`
  flex-basis: 750px;
  max-width: 750px;
  @media screen and (max-width: 1300px) {
    flex-basis: 100%;
    max-width: 100%;
  }
  margin: 10px;
  padding: 50px 60px;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0px 0px 13px rgba(0, 0, 0, 0.05);
  display: flex;
  align-items: center;
  .box-qr {
    min-width: 250px;
    height: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px dashed #e2e5ec;
    border-radius: 4px;
  }
  .box-content {
    padding-left: 20px;
    h3 {
      font-size: 25px;
      line-height: 35px;
      margin-bottom: 9px;
      font-weight: 500;
      color: #0abb87;
    }
    p {
      font-size: 19px;
      line-height: 30px;
      font-weight: 300;
      color: #595d6e;
    }
    .btn-holder {
      margin-top: 20px;
      .btn-success {
        padding: 6px 12px;
      }
    }
  }
`;

export default StyledQrCodeBox;
