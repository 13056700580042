import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { gdnsPackComplete } from "actions/pack_gdns";

import StyledStatus from "./styles";

const checkStatusesForShow = ({ gdns }) => {
  const gdns_statuses = gdns.map(({ status }) => status);
  const gdns_ready = gdns_statuses.filter((gdn_status) =>
    ["completed", "with_missings", "problem"].includes(
      gdn_status
    )
  );
  return gdns_ready.length === gdns.length;
};

const Status = ({ gdns, status, pack_id, history }) => {
  if (!checkStatusesForShow({ gdns })) {
    return null;
  }
  if (status === "completed") {
    return (
      <StyledStatus as="div">
        <span>Pak zakończony</span>
      </StyledStatus>
    );
  }
  return (
    <StyledStatus
      type="button"
      onClick={() =>
        gdnsPackComplete(pack_id)
          .then(() => {
            history.push("/packing");
          })
          .catch((err) => console.error(err))
      }>
      <span>Zamknij paka</span>
    </StyledStatus>
  );
};

export default connect(
  ({
    pack_gdns: {
      data: { status, gdns, id },
    },
  }) => ({
    gdns,
    pack_id: id,
    status,
  })
)(withRouter(Status));
