import React from "react";
import { connect } from "react-redux";
import moment from "moment";

const absence_kinds = {
  vacation: "Urlop",
  vacation_on_request: "Urlop na żądanie",
  sick_leave: "Chorobowe",
};

const renderDate = (date_from, date_to) =>
  [
    moment(date_from).format("DD.MM"),
    moment(date_to).format("DD.MM.YYYY"),
  ].join("-");

const renderStatus = (date_from, date_to) => {
  const today = moment();
  const date_start = moment(date_from);
  const date_end = moment(date_to);
  
  if (today.isBefore(date_start)) {
    return "Zaplanowany";
  }
  if (today.isAfter(date_end)) {
    return "Zakończony";
  }
  if (today.isBetween(date_start, date_end)) {
    return "W trakcie";
  }

  return "-";
};

const Absences = ({ data }) => (
  <div className="dashboard warning">
    <div className="dashboard__header">
      <h2 className="dashboard__title heading">Nieobecności</h2>
    </div>
    <table className="table table-sm">
      <thead className="table__header">
        <tr>
          <th width="20%">Rodzaj</th>
          <th width="20%">Pracownik</th>
          <th width="20%">Data</th>
          <th width="20%">Zaakceptowano</th>
          <th width="20%">Status</th>
        </tr>
      </thead>
      <tbody className="table__body">
        {data.map(
          ({ id, kind, user_name, date_from, date_to, approver_name }) => (
            <tr key={id}>
              <td>
                <a
                  href={`https://board.tradesk.pl/absences/${id}`}
                  className="link"
                  target="_blank"
                >
                  {absence_kinds[kind]}
                </a>
              </td>
              <td>{user_name}</td>
              <td>{renderDate(date_from, date_to)}</td>
              <td>{approver_name ? approver_name : "Nie zaakceptowano"}</td>
              <td>{renderStatus(date_from, date_to)}</td>
            </tr>
          )
        )}
      </tbody>
    </table>
  </div>
);

export default connect(({ absences: { data } }) => ({
  data,
}))(Absences);
