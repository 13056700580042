export const status_options = [
  { label: "Nowe", value: "new" },
  { label: "W kompletacji", value: "picking" },
  { label: "Skompletowane", value: "picked" },
  { label: "W pakowaniu", value: "packing" },
  { label: "W zwrocie", value: "returning" },
  { label: "Zakończone", value: "completed" },
  { label: "Błąd", value: "error" },
  {
    label: "Zgłoszone braki",
    value: "completed_with_missings",
  },
];

export const getStatusLabelData = (status) => {
  switch (status) {
    case "new":
      return { status_text: "Nowe", status: "info" };
    case "picking":
      return { status_text: "W kompletacji", status: "warning" };
    case "picked":
      return { status_text: "Skompletowane", status: "warning" };
    case "packing":
      return { status_text: "W pakowaniu", status: "warning" };
    case "returning":
      return { status_text: "W zwrocie", status: "warning" };
    case "completed":
      return { status_text: "Zakończone", status: "success" };
    case "completed_with_missings":
      return { status_text: "Zgłoszone braki", status: "danger" };
    case "error":
      return { status_text: "Błąd", status: "danger" };
    default:
      return { status_text: "Brak", status: "dark" };
  }
};
