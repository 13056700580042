import moment from "moment";
import { toastr } from "react-redux-toastr";
import { client_v2 } from "utils/api";
import { removeCookie, setCookie } from "utils/cookies";

const getPathnameByRole = (role) => {
  switch (role) {
    case "admin":
    case "manager":
      return "/";
    case "warehouseman":
      return "/grns";
    case "packer":
      return "/packing";
    default:
      return "/";
  }
};

export const handleLogin = async ({ user }) => {
  try {
    const { auth_token, user_role } = await client_v2.post(
      "users/sign_in",
      user
    );
    toastr.success("Sukces", "Zalogowano pomyślnie");

    await setCookie(
      "token",
      auth_token,
      ["packer", "warehouseman", "manager"].includes(user_role)
        ? moment().endOf("day").toDate()
        : moment().add(1, "month").toDate()
    );
    window.location.pathname = getPathnameByRole(user_role);
  } catch (error) {
    toastr.error("Błąd", "Wystąpił błąd podczas logowania");
  }
};

export const handleLoginByAuthToken = async (code) => {
  try {
    const type = code.split("|")[0];
    const token = code.split("|")[1];
    if (type !== "auth_token") {
      throw new Error("Nieprawidłowy kod autoryzacyjny");
    }
    const { auth_token, user_role } = await client_v2.post(
      "users/sign_in_by_token",
      {
        token,
      }
    );
    toastr.success("Sukces", "Zalogowano pomyślnie");

    await setCookie(
      "token",
      auth_token,
      ["packer", "warehouseman", "manager"].includes(user_role)
        ? moment().endOf("day").toDate()
        : moment().add(1, "month").toDate()
    );
    window.location.pathname = getPathnameByRole(user_role);
  } catch (error) {
    toastr.error("Błąd", error?.message || "Wystąpił błąd");
  }
};

export const findUserIdByAuthToken = async (token) => {
  try {
    const {
      data: { user_id },
    } = await client_v2.post("users/find_user_by_token", {
      token,
    });

    return user_id;
  } catch (error) {
    toastr.error("Błąd", error?.response?.data?.error || "Wystąpił błąd");
    throw error;
  }
};

export const handleLogout = async () => {
  await removeCookie("token");
  window.location.reload();
};
