import React, { useEffect } from "react";
import { connect } from "react-redux";
import {
  skipScanningPackingStation,
  assignPackingStation,
} from "actions/current_user";
import { handleLogout } from "actions/account";

import { Main } from "layouts";
import onScan from "utils/scan_handler";
import { ReactComponent as PackIcon } from "icons/pack.svg";
import { Modal } from "components";
import { Button } from "expano-components";

const StationScanner = ({
  can_skip,
  assignPackingStation,
  skipScanningPackingStation,
}) => { 
  useEffect(() => {
    onScan.attachTo(document);
    document.addEventListener("scan", handleScan);
    return () => {
      onScan.detachFrom(document);
      document.removeEventListener("scan", handleScan);
    };
  }, []);

  const handleScan = ({ detail: { scanCode } }) =>
    assignPackingStation(scanCode);

  return (
    <Main>
      <Modal
        handleClose={handleLogout}
        header={{
          title: "Stanowisko pakowacza",
          icon: <PackIcon />,
        }}
      >
        <h3
          style={{
            textAlign: "center",
            fontSize: 25,
            padding: 20,
          }}
        >
          Zeskanuj stanowisko
        </h3>
        {can_skip ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: 10,
            }}
          >
            <Button
              type="button"
              text="Pomiń"
              onClick={skipScanningPackingStation}
            />
          </div>
        ) : null}
      </Modal>
    </Main>
  );
};

export default connect(
  ({ current_user }) => ({
    can_skip: current_user?.role === "warehouseman",
  }),
  (dispatch) => ({
    assignPackingStation: (code) => dispatch(assignPackingStation(code)),
    skipScanningPackingStation: (code) =>
      dispatch(skipScanningPackingStation(code)),
  })
)(StationScanner);
