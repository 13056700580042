const initialState = {
  status: "invalid",
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "SUPPLIER_LOADING":
      return {
        ...state,
        status: "loading",
      };
    case "SUPPLIER_SUCCESS":
      return {
        ...state,
        status: "success",
        ...action,
      };
    case "SUPPLIER_FAILURE":
      return {
        ...state,
        status: "failure",
      };
    case "CREATE_SUPPLIER_PRODUCT": {
      return {
        ...state,
        data: {
          ...state.data,
          product_suppliers: [
            ...state.data.product_suppliers,
            action.data,
          ],
        },
      };
    }
    case "DELETE_SUPPLIER_PRODUCT": {
      return {
        ...state,
        data: {
          ...state.data,
          product_suppliers: state.data.product_suppliers.filter(
            ({ id }) => id !== action.id
          ),
        },
      };
    }
    default:
      return state;
  }
};
