import React, { useState } from "react";
import { connect } from "react-redux";
import LazyLoad from "react-lazy-load";
import classNames from "classnames";
import {
  Tooltip,
  QrCodeStickerModal,
  LocalizationInfo,
  QrCodeModal,
} from "components";
import { Label, ModalPreview } from "expano-components";

import { GrnLineBatches, WarningModal } from "../../../../../components";
import { setGrnLinesFilter } from "actions/grns";

import { ReactComponent as QRcodeIcon } from "icons/qrcode.svg";
import { ReactComponent as Sticker } from "icons/sticker.svg";
import { ReactComponent as WarningIcon } from "icons/light-warning.svg";
import { ReactComponent as PreviewIcon } from "icons/preview.svg";

const ItemRow = ({ search_value, data, warehouse_id, setGrnLinesFilter }) => {
  const [is_warning_modal_open, openWarningModal] = useState(false);
  const [is_modal_open, openModal] = useState(false);
  const [is_qr_codes_modal_open, openQrCodesModal] = useState(false);
  const [is_open_grn_qr_modal, setOpenGrnQrModal] = useState(false);

  const stock = data?.product?.product_main_locations?.find(
    (item) => item.warehouse?.id === Number(warehouse_id)
  )?.stock;

  const image_url = data.product?.image_url;

  const main_location =
    data?.product?.product_main_locations.find(
      (item) => item.warehouse?.id === Number(warehouse_id)
    ) || null;

  return (
    <tr
      data-focus-id={data.id}
      className={classNames(data?.counting_state, {
        no_location: !data.has_location_in_shipment_warehouse,
        is_decomposed:
          data?.supplier_quantity?.is_decomposed ||
          data?.supplier_quantity?.is_composed,
        is_warned: data?.is_warned,
      })}
      style={{
        display: data?.show ? "table-row" : "none",
      }}
    >
      <td className="number">
        <span>{data?.no}</span>
      </td>
      <td className="image-box">
        {image_url && (
          <LazyLoad>
            <button
              tabIndex="-1"
              className="modal__trigger"
              onClick={() => openModal(true)}
              style={{
                backgroundImage: `url('${
                  data?.product?.image_thumb_url || image_url
                }')`,
              }}
            />
          </LazyLoad>
        )}
      </td>
      <td>{data?.product?.sku}</td>
      <td>
        <p>
          <a
            className="text-link"
            href={`/products/${data?.product?.id}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {data?.product?.name}
          </a>
        </p>
        <p className="text-info">Kod dostawcy: {data?.supplier_code || "-"}</p>
        {!data.has_location_in_shipment_warehouse && (
          <Label
            type="fill"
            color="#fd397a"
            text="Brak lokalizacji na magazynie wysyłkowym"
          />
        )}
      </td>
      <td>
        <LocalizationInfo main={main_location?.warehouse_position} />
      </td>
      <td>{typeof stock === "number" ? `${stock} szt.` : "Brak danych"}</td>
      <td>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
          }}
        >
          <button
            className="m-r-5"
            type="button"
            onClick={() => setOpenGrnQrModal(true)}
            tabIndex="-1"
          >
            <PreviewIcon />
          </button>
          {data.product?.has_ean && <Sticker style={{ marginRight: 5 }} />}
          {data.product?.sku && data?.product?.has_qr_code && (
            <button
              type="button"
              onClick={() => openQrCodesModal(true)}
              tabIndex="-1"
            >
              <QRcodeIcon />
            </button>
          )}
          {data.counting_state === "pending" &&
            search_value !== data.product.sku &&
            search_value !== data.product.ean &&
            data?.product?.has_ean && (
              <button
                type="button"
                style={{ padding: 5 }}
                onClick={() => openWarningModal(true)}
              >
                <WarningIcon />
              </button>
            )}
        </div>
      </td>
      <td>
        {data?.supplier_quantity?.is_decomposed ||
        data?.supplier_quantity?.is_composed ? (
          <>
            <p>
              <strong>
                {data.supplier_quantity.package_qty}{" "}
                {data.supplier_quantity?.supplier_custom_unit || "op."}
              </strong>
            </p>
            {data.supplier_quantity.unit_qty > 0 ? (
              <p>
                <strong>{data.supplier_quantity.unit_qty} szt.</strong>
              </p>
            ) : null}
            <p className="text-muted">{data?.quantity} szt.</p>
          </>
        ) : (
          <>
            <p>
              <strong>{data.quantity}</strong>
            </p>
            <p>{data?.unit}</p>
          </>
        )}
      </td>
      <td>
        <GrnLineBatches grn_line_id={data?.id} data={data?.grn_line_batches} />
      </td>
      <td>
        <strong>
          {data.counted_quantity || 0} {data?.unit}
        </strong>
      </td>
      <td>
        {data?.is_warned && (
          <Tooltip trigger={<WarningIcon />} content={<p>Sprawdź produkt</p>} />
        )}
        {data?.product?.is_warehouseman_alert_enabled &&
          data?.product?.warehouseman_alert && (
            <Tooltip content={<p>{data.product.warehouseman_alert}</p>} />
          )}
      </td>
      {is_modal_open && (
        <ModalPreview
          handleClose={() => {
            openModal(false);
          }}
          src={image_url}
          alt={data?.product?.name}
        />
      )}
      {is_qr_codes_modal_open && (
        <QrCodeStickerModal
          data={data.product}
          handleClose={() => openQrCodesModal(false)}
          initialValues={{ quantity: data?.quantity || 1 }}
        />
      )}
      {is_warning_modal_open && (
        <WarningModal
          handleConfirm={() => {
            openWarningModal(false);
            setGrnLinesFilter("sku", data.product.ean || data.product.sku);
          }}
          handleClose={() => openWarningModal(false)}
          product={data.product}
        />
      )}
      {is_open_grn_qr_modal && (
        <QrCodeModal
          identifier={data.product.sku}
          handleClose={() => setOpenGrnQrModal(false)}
          title="Roznieś produkt"
          value={`grn_line_id|${data.id}`}
        />
      )}
    </tr>
  );
};

export default connect(
  ({
    grn: {
      filters: { warehouse_id, sku },
    },
  }) => ({
    warehouse_id,
    search_value: sku,
  }),
  (dispatch) => ({
    setGrnLinesFilter: (name, value) =>
      dispatch(setGrnLinesFilter(name, value)),
  })
)(ItemRow);
