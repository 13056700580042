import React, { useEffect } from "react";
import classNames from "classnames";
import { connect } from "react-redux";
import { PageLoader } from "components";
import { Header, List, Status, PackInit, PackEmpty } from "./components";
import { checkGdnsForErrors, getPackGdns } from "actions/pack_gdns";
import onScan from "utils/scan_handler";
import { toastr } from "react-redux-toastr";

// Statusy
// new, picked, packed, completed, error, with_missings, to_cancel
// return, put_aside
// gdn_line new, picked, packed, missing

const GdnsList = ({
  current_user,
  pack_gdns,
  getPackGdns,
  history,
  match: {
    params: { pack_id },
  },
}) => {
  useEffect(() => {
    getPackData();
  }, []);

  useEffect(() => {
    onScan.attachTo(document);
    document.addEventListener("scan", handleScan);

    return () => {
      onScan.detachFrom(document);
      document.removeEventListener("scan", handleScan);
    };
  }, [pack_gdns]);

  const getPackData = async () => {
    try {
      if (current_user?.role === "admin") {
        getPackGdns({ pack_id });
      } else {
        const errors = await checkGdnsForErrors(pack_id);
        if (Object.keys(errors).length > 0) {
          const gdn_id = Object.keys(errors)[0];
          const error_status = Object.values(errors)[0];
          history.push({
            pathname: `/packing/gdn/${gdn_id}`,
            state: { error_status },
          });
        } else {
          getPackGdns({ pack_id });
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleScan = ({ detail }) => {
    // cart_box|17#A2
    try {
      if (!detail?.scanCode?.includes("|")) {
        throw new Error(`${detail.scanCode} - niepoprawny kod!`);
      }
      const [type, identifier] = detail.scanCode.split("|");
      if (type !== "cart_box") {
        throw new Error(`${detail.scanCode} - niepoprawny kod kuwety!`);
      }
      const [cart_id, cart_box_identifier] = identifier.split("#");

      if (Number(pack_gdns?.data?.cart?.id) !== Number(cart_id)) {
        throw new Error(`${detail.scanCode} - zły wózek!`);
      }

      const gdn = pack_gdns?.data?.gdns.find(
        ({ picking_box, status }) =>
          picking_box === cart_box_identifier && status !== "completed"
      );

      if (!gdn) {
        throw new Error(
          `Nie znaleziono zamówienia dla kuwety ${cart_box_identifier} lub jest już zrealizowane`
        );
      }

      history.push(`/packing/gdn/${gdn.id}`);
    } catch (error) {
      toastr.error("Błąd", error.message);
    }
  };

  if (pack_gdns.status === "invalid" || pack_gdns.status === "loading")
    return <PageLoader />;

  if (pack_gdns.status === "failure") {
    return <p>Error</p>;
  }

  const { data } = pack_gdns;
  const is_not_ready = ["new", "picking", "picked"].includes(data?.status);

  const is_empty = data?.gdns?.length === 0;

  return (
    <div className="packs-view">
      <Header />
      <div
        className={classNames("packs-container", {
          lg: !is_empty && is_not_ready,
        })}
      >
        {is_empty ? (
          <PackEmpty />
        ) : is_not_ready ? (
          <PackInit />
        ) : (
          <>
            <Status />
            <List />
          </>
        )}
      </div>
    </div>
  );
};

export default connect(
  ({ pack_gdns, current_user }) => ({
    pack_gdns,
    current_user,
  }),
  (dispatch) => ({
    getPackGdns: (pack_id) => dispatch(getPackGdns(pack_id)),
  })
)(GdnsList);
