import React from "react";
import CreatableSelect from "react-select/creatable";

const Select = ({ label, placeholder = "", onChange, defaultValue = [] }) => {
  return (
    <div className="form-group">
      <div className="inp-wrapper">
        {label && <label>{label}</label>}
        <CreatableSelect
          components={{ DropdownIndicator: null }}
          isMulti
          onChange={(value) => {
            let data = "";
            if (value?.length > 0) {
              data = value.map(({ value }) => value).join(",");
            }
            return onChange(data);
          }}
          placeholder={placeholder}
          styles={customStyles}
          defaultValue={defaultValue}
          formatCreateLabel={(val) => `Dodaj ${val}`}
        />
      </div>
    </div>
  );
};

export default Select;

const customStyles = {
  control: (styles) => ({
    ...styles,
    borderColor: "#E2E5EC",
    minHeight: 30,
  }),
  option: (provided) => ({
    ...provided,
    fontFamily: "Poppins",
    fontSize: "13px",
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: "0 10px",
  }),
  singleValue: (provided) => ({
    ...provided,
    fontFamily: "Poppins",
    fontSize: "13px",
    padding: 0,
  }),
  indicatorSeparator: (styles) => ({
    ...styles,
    display: "none",
  }),
  placeholder: (provided) => ({
    ...provided,
    fontFamily: "Poppins",
    fontSize: "13px",
  }),
};
