import { toastr } from "react-redux-toastr";
import { client } from "utils/api";

export const getAveragePackages = () => async (
  dispatch
) => {
  try {
    dispatch({
      type: "AVERAGE_PACKAGES_LOADING",
    });
    const {
      data: { data },
    } = await client.get(
      "/stats/average_packages_to_products_ratio"
    );
    dispatch({
      type: "AVERAGE_PACKAGES_SUCCESS",
      data,
    });
  } catch (error) {
    dispatch({ type: "AVERAGE_PACKAGES_FAILURE" });
    toastr.error(
      "Błąd",
      "Wystąpił błąd podczas pobierania danych o średnim tygodniowym stosunku objętości paczek do objętości produktów"
    );
    throw error;
  }
};
