import React from "react";
import {
  Document,
  Image,
  Font,
  Page,
  Text,
  View,
  StyleSheet,
} from "@react-pdf/renderer";

import RobotoBlack from "fonts/Roboto-Black.ttf";
import getLocationDataFromIdentifier from "utils/getLocationDataFromIdentifier";

Font.register({
  family: "Roboto",
  fonts: [{ src: RobotoBlack, fontWeight: 900 }],
});

const createLetterGroups = (text) => {
  if (!text) return [];
  return text
    .split("")
    .reduce((resultArray, item, index) => {
      const chunkIndex = Math.floor(index / 13);

      if (!resultArray[chunkIndex]) {
        resultArray[chunkIndex] = []; // start a new chunk
      }
      resultArray[chunkIndex].push(item);

      return resultArray;
    }, []);
};

const StickerQrCode = ({ data }) => (
  <Document>
    <Page
      size={{ width: 305, height: 60 }}
      style={styles.page}>
      <View style={styles.wrapper} wrap={true}>
        {data?.length > 0 &&
          data.map(
            (
              {
                sku,
                name,
                quantity,
                product_location_identifier,
                qr_code_base64,
              },
              index
            ) => {
              const name_grouped = createLetterGroups(name);
              let name_grouped_sliced = [];
              if (name_grouped.length >= 4) {
                name_grouped_sliced = name_grouped.slice(
                  0,
                  4
                );
              } else {
                name_grouped_sliced = [
                  ...name_grouped,
                  ...Array(4 - name_grouped.length).fill(
                    " "
                  ),
                ];
              }
              const location_details = getLocationDataFromIdentifier(
                product_location_identifier
              );
              return (
                <React.Fragment key={index}>
                  <View style={styles.box}>
                    <View style={styles.text_wrapper}>
                      <Text style={styles.text_normal}>
                        {sku}
                      </Text>
                      <Text style={styles.text_normal}>
                        {quantity} szt.
                      </Text>
                      {location_details && (
                        <Text style={styles.text_normal}>
                          {`${location_details?.aisle ||
                            "-"} / R${location_details?.rack ||
                            "-"} / P${location_details?.shelf ||
                            "-"} / P${location_details?.position ||
                            "-"}`}
                        </Text>
                      )}
                    </View>
                  </View>
                  {Array(Number(quantity))
                    .fill(null)
                    .map((item, index) => (
                      <View style={styles.box} key={index}>
                        <View style={styles.box_image}>
                          <Image
                            src={qr_code_base64}
                            style={{
                              width: 50,
                              height: 50,
                            }}
                          />
                        </View>
                        <View style={styles.box_sku}>
                          {name_grouped_sliced.map(
                            (item, i) => (
                              <Text
                                key={i}
                                style={
                                  styles.box_sku__text
                                }>
                                {item}
                              </Text>
                            )
                          )}
                          {createLetterGroups(sku).map(
                            (item, i) => (
                              <Text
                                key={i}
                                style={
                                  styles.box_sku__text
                                }>
                                {item}
                              </Text>
                            )
                          )}
                        </View>
                      </View>
                    ))}
                </React.Fragment>
              );
            }
          )}
      </View>
    </Page>
  </Document>
);

export default StickerQrCode;

const styles = StyleSheet.create({
  page: {
    backgroundColor: "#fff",
    paddingTop: 5,
  },
  wrapper: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "flex-start",
  },
  box: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: 100,
  },

  text_wrapper: {
    justifyContent: "flex-end",
    paddingBottom: 5,
    paddingLeft: 12,
  },
  text_normal: {
    fontFamily: "Roboto",
    fontSize: 7,
    fontWeight: 900,
    marginBottom: 3,
  },
  box_image: {
    flexBasis: 50,
  },
  box_sku: {
    width: 45,
    transform: "rotate(-90deg)",
    transformOrigin: "50% 50%",
    left: -5,
  },
  box_sku__text: {
    fontFamily: "Roboto",
    fontSize: 6,
    fontWeight: 900,
  },
});
