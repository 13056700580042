const initialState = {
  status: "invalid",
  filters: {
    open: true,
    query: {},
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "SORTING_TOGGLE_FILTERS":
      return {
        ...state,
        filters: { ...state.filters, open: !state.filters.open },
      };
    case "SORTING_RESET_FILTERS":
      return {
        ...state,
        filters: {
          open: true,
          query: {},
        },
      };
    case "SORTING_SET_FILTERS_QUERY":
      return {
        ...state,
        filters: { ...state.filters, query: action.query },
      };
    case "SORTING_LOADING":
      return {
        ...state,
        status: "loading",
      };
    case "SORTING_SEARCHING":
      return {
        ...state,
        status: "searching",
      };
    case "SORTING_SUCCESS":
      return {
        ...state,
        status: "success",
        ...action,
      };
    case "SORTING_FAILURE":
      return {
        ...state,
        status: "failure",
      };
    case "CREATE_PACK_SUCCESS": {
      return {
        ...state,
        data: [...state.data].filter(({ id }) => !action.gdn_ids.includes(id)),
      };
    }
    default:
      return state;
  }
};
