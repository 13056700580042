const initialState = {
  status: "invalid",
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "GDN_INVALID":
      return {
        status: "invalid",
      };
    case "GDN_LOADING":
      return {
        ...state,
        status: "loading",
      };
    case "GDN_SUCCESS": {
      return {
        ...state,
        status: "success",
        data: action.data,
      };
    }
    case "GDN_UPDATE_SUCCESS": {
      return {
        ...state,
        data: { ...state.data, ...action.data },
      };
    }
    case "GDN_FAILURE":
      return {
        ...state,
        status: "failure",
      };
    default:
      return state;
  }
};
