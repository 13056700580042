import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { MainTable, Loader, Modal } from "components";
import { Button, ModalUpload } from "expano-components";
import { Main } from "layouts";
import { client_v2, parseQuery } from "utils/api";
import {
  getProducts,
  filterProducts,
  resetFilters,
  toggleFilters,
  updateProductsByCsv,
} from "actions/products";
import LineItem from "./components/LineItem";
import { ReactComponent as ProductIcon } from "icons/product.svg";

const ProductsList = ({
  products,
  filterProducts,
  toggleFilters,
  resetFilters,
  getProducts,
  location: { search },
}) => {
  const [is_loaded, setLoaded] = useState(false);
  const [updated_products_data, setUpdatedProductsData] = useState([]);
  const [update_products_error, setUpdateProductsError] = useState(null);
  const [is_uploading, setUploading] = useState(false);
  const [is_open_upload_modal, setOpenUploadModal] = useState(false);
  const [is_open_upload_status_modal, setOpenUploadStatusModal] =
    useState(false);

  const query = parseQuery(search);
  const sort = {
    "sort[column]": query["sort[column]"] || "id",
    "sort[order]": query["sort[order]"] || "asc",
  };

  const setCursorInSearchInput = () => {
    const el = document.getElementById("sku_or_name_or_producer_code_or_ean");
    if (el) {
      el.focus();
    }
  };

  useEffect(() => {
    setCursorInSearchInput();
    return () => {
      resetFilters();
    };
  }, []);

  useEffect(() => {
    getProducts({ ...query, ...sort });
    setLoaded(true);
  }, [search]);

  useEffect(() => {
    if (is_loaded) {
      getProducts({ ...query, page: undefined });
    }
  }, [products.filters.query]);

  if (products.status === "failure") {
    return <p>Error</p>;
  }

  const handleUpload = async (file) => {
    try {
      setUpdateProductsError(null)
      setUploading(true);
      setOpenUploadStatusModal(true);
      const data = await updateProductsByCsv(file);
      setUpdatedProductsData(data);
    } catch (error) {
      setUpdateProductsError(error);
    } finally {
      setUploading(false);
    }
  };

  const { data, meta } = products;

  return (
    <Main
      breadcrumbs={[{ name: "Produkty" }]}
      page={{
        name: "Produkty",
        icon: <ProductIcon />,
        breadcrumbs: [{ name: "Produkty" }],
        buttons: (
          <Button
            text="Aktualizuj produkty (.csv)"
            onClick={() => setOpenUploadModal(true)}
          />
        ),
      }}
    >
      {is_open_upload_status_modal && (
        <Modal
          size="md"
          handleClose={() => setOpenUploadStatusModal(false)}
          header={{
            icon: <ProductIcon />,
            title: "Aktualizacja produktów",
          }}
          footer={
            <>
              <Button
                type="cancel"
                text="Zamknij"
                onClick={() => setOpenUploadStatusModal(false)}
              />
            </>
          }
        >
          {is_uploading ? (
            <div className="wrapper jc-c">
              <Loader />
            </div>
          ) : updated_products_data?.length > 0 ? (
            <>
              <p>Zaktualizowano produkty:</p>
              <br />
              {updated_products_data.map(({ id, sku, name }) => (
                <p key={id}>
                  {name} -{" "}
                  <a className="link" href={`/products/${id}`} target="_blank">
                    {sku}
                  </a>
                </p>
              ))}
            </>
          ) : (
            <p>Brak zaktualizowanych produktów</p>
          )}
          {update_products_error && <p className="text-danger m-t-10">{update_products_error}</p>}
        </Modal>
      )}
      {is_open_upload_modal && (
        <ModalUpload
          handleUpload={handleUpload}
          headerText="Wgraj plik (.csv)"
          accept=".csv"
          handleClose={() => setOpenUploadModal(false)}
        />
      )}
      <div className="dashboard">
        <MainTable
          is_loading={
            products.status === "invalid" || products.status === "loading"
          }
          is_searching={products.status === "searching"}
          filters_open={products.filters.open}
          toggleFilters={toggleFilters}
          data={data}
          meta={meta}
          sort={sort}
          head={[
            {
              label: "ID",
              name: "id",
              style: { width: 100 },
            },
            {
              label: "SKU",
              name: "sku",
              style: { width: 150 },
            },
            {
              label: "EAN",
              name: "ean",
              style: { width: 150 },
            },
            {
              label: "Kod producenta",
              name: "producer_code",
              style: { width: 150 },
            },
            { label: "Nazwa", name: "name" },
            {
              label: "Lokalizacja Mag. Wysyłkowy",
              name: "location",
              sortable: false,
            },
            {
              label: "Oznakowanie",
              name: "icons",
              style: { width: 150 },
              sortable: false,
            },
            {
              label: "Producent",
              name: "producer_name",
              style: { width: 150 },
            },
            {
              label: "Status",
              name: "status",
              style: { width: 170 },
            },
          ]}
          empty_text="Brak produktów"
          renderRow={(item) => <LineItem key={item.id} {...item} />}
          filtersAction={filterProducts}
          filters={[
            {
              type: "input",
              label: "SKU, nazwa produktu, lub kod producenta",
              name: "sku_or_name_or_producer_code_or_ean_or_logistic_eans_code",
              search_type: "matches",
            },
            {
              type: "input",
              label: "Producent",
              search_type: "matches",
              name: "producer_name",
            },
            {
              type: "async-multi-select",
              label: "Dostawca",
              name: "product_suppliers_supplier_id",
              search_type: "in",
              asyncAction: (q) =>
                new Promise(async (resolve, reject) => {
                  try {
                    const { data } = await client_v2.get(
                      `/suppliers?q[name_matches]=%25${q}%25&context=list&per_page=10`
                    );
                    resolve(
                      data.map(({ id, name }) => ({
                        label: name,
                        value: id,
                      }))
                    );
                  } catch (error) {
                    console.log(error);
                    reject([]);
                  }
                }),
            },
            {
              type: "select",
              label: "Bundle",
              name: "is_bundle",
              search_type: "true",
              options: [
                { label: "Tak", value: 1 },
                { label: "Nie", value: 0 },
              ],
              defaultValue: { label: "Nie", value: 0 },
            },
          ]}
        />
      </div>
    </Main>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getProducts: (data) => dispatch(getProducts(data)),
  filterProducts: (data) => dispatch(filterProducts(data)),
  toggleFilters: () => dispatch(toggleFilters()),
  resetFilters: () => dispatch(resetFilters()),
});
export default connect(
  ({ products }) => ({ products }),
  mapDispatchToProps
)(ProductsList);
