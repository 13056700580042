import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { ModalPreview } from "expano-components";
import { ButtonGroup } from "components";
import ModalIssue from "./components/ModalIssue";
import ModalPhoto from "./components/ModalPhoto";
import ModalPickerMistake from "./components/ModalPickerMistake";

import {
  notifyAboutWrongGauge,
  notifyAboutWrongWeight,
  openTakePhotoModal,
  openSpeditorModal,
  openIssueModal,
  openPickerMistakeModal,
  downloadGdnLabel,
} from "actions/pack_gdn";

import { useCable } from "providers/cable";

import { ReactComponent as CameraIcon } from "icons/camera.svg";
import { ReactComponent as ImageIcon } from "icons/image.svg";
import { ReactComponent as ProblemIcon } from "icons/problem.svg";
import { ReactComponent as PacksIcon } from "icons/packs.svg";
import { ReactComponent as PackIcon } from "icons/pack.svg";
import { ReactComponent as ArrowPrevIcon } from "icons/arrow-back.svg";

const Header = ({
  data,
  getData,
  is_open_take_photo_modal,
  is_open_issue_modal,
  is_open_picker_mistake_modal,
  openTakePhotoModal,
  openIssueModal,
  openSpeditorModal,
  openPickerMistakeModal
}) => {
  const image_url = data?.photo_url || null;

  const gdn_id = data.id;

  const { cable } = useCable();

  const [channel, setChannel] = useState(null);
  const [is_downloading, setDownloading] = useState(false);

  const [is_modal_preview_open, openPreviewModal] = useState(false);

  useEffect(() => {
    const cable_channel = cable.subscriptions.create({
      channel: "PackerChannel",
    });

    setChannel(cable_channel);

    return () => {
      if (cable_channel) {
        setChannel(null);
        cable_channel.unsubscribe();
      }
    };
  }, [gdn_id]);

  const can_back_to_pack = [
    "completed",
    "problem",
    "with_missings",
    "to_cancel",
    "error",
  ].includes(data.status);

  const sendMessage = (gdn_id) =>
    channel?.perform("init_gdn_photo", { gdn_id });

  return (
    <>
      {is_open_issue_modal && (
        <ModalIssue handleClose={() => openIssueModal(false)} />
      )}
      {is_open_picker_mistake_modal && (
        <ModalPickerMistake handleClose={() => openPickerMistakeModal(false)} />
      )}
      {is_open_take_photo_modal && (
        <ModalPhoto
          sendMessage={sendMessage}
          getData={getData}
          gdn_id={gdn_id}
          handleClose={() => openTakePhotoModal(false)}
          openSpeditorModal={openSpeditorModal}
        />
      )}
      {is_modal_preview_open &&
        ReactDOM.createPortal(
          <ModalPreview
            handleClose={() => {
              openPreviewModal(false);
            }}
            src={image_url}
            name={""}
          />,
          document.getElementById("root")
        )}
      <div className="packs-topbar">
        <div className="packs-topbar__container">
          <div className="packs-topbar__left">
            {can_back_to_pack ? (
              <Link
                className="btn btn-default m-r-15"
                to={
                  data?.gdn_pack?.id
                    ? `/packing/gdn_pack/${data.gdn_pack.id}`
                    : `/packing`
                }
              >
                <ArrowPrevIcon style={{ transform: "scaleX(-1)" }} />
                <span>Wroć do paka</span>
              </Link>
            ) : (
              <button
                className="btn btn-default btn-problem m-r-15"
                onClick={() => openIssueModal(true)}
              >
                <ProblemIcon />
                <span>Zgłoś problem</span>
              </button>
            )}
            <ButtonGroup
              trigger={{
                icon: <ProblemIcon />,
                text: "Zgłoś błędy",
              }}
              buttons={[
                {
                  text: "Zła wielkość zamówienia",
                  action: () => notifyAboutWrongGauge(gdn_id),
                },
                {
                  text: "Źle skompletowane zamówienie",
                  action: () => openPickerMistakeModal(true),
                },
                {
                  text: "Zła waga zamówienia",
                  action: () => notifyAboutWrongWeight(gdn_id),
                },
              ]}
            />
            <div className="pack-number">
              <div className="pack-number__icon">
                <PacksIcon />
              </div>
              <div className="pack-number__text">
                {is_downloading ? (
                  <p>Proszę czekać...</p>
                ) : (
                  <p
                    className="link"
                    onClick={async () => {
                      try {
                        setDownloading(true);
                        await downloadGdnLabel({
                          expano_oms_id: data.expano_oms_id,
                          id: data.id,
                        });
                      } catch (error) {
                        console.log(error);
                      } finally {
                        setDownloading(false);
                      }
                    }}
                  >
                    {data?.expano_oms_id}
                  </p>
                )}
              </div>
            </div>
            {data?.picking_box && (
              <div className="packing-box-group">
                <span>{data.picking_box}</span>
              </div>
            )}
            <div className="speditor-group">
              <p className="speditor-group__title">Metoda dostawy</p>
              <p className="speditor-group__info">
                <span>
                  {data?.delivery_country_code
                    ? `[${data.delivery_country_code}]`
                    : ""}{" "}
                  {data?.delivery_price
                    ? `[${data.delivery_price} ${data?.currency}]`
                    : ""}{" "}
                  {data?.declared_packages_count
                    ? `${data?.declared_packages_count} x`
                    : ""}{" "}
                  {data?.delivery_method_name}
                </span>
              </p>
            </div>
            {!!data.packing_box_size && (
              <div className="pack-group">
                <div className="pack-group__image">
                  <PackIcon />
                </div>
                <div className="pack-group__text">
                  <p className="pack-group__title">Spakuj zamówienie w</p>
                  <p className="pack-group__value">{data.packing_box_size}</p>
                </div>
              </div>
            )}
          </div>
          <div className="packs-topbar__right">
            <div className="image-upload">
              {image_url ? (
                <div
                  onClick={() => openPreviewModal(true)}
                  className="preview image"
                  style={{
                    backgroundImage: `url(${image_url})`,
                  }}
                />
              ) : (
                <div className="image">
                  <ImageIcon />
                </div>
              )}
              <button
                className="btn btn-blue btn-picture"
                onClick={() => openTakePhotoModal(true)}
              >
                <CameraIcon />
                <span>Zrób zdjęcie</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default connect(
  ({
    pack_gdn: {
      data,
      is_open_take_photo_modal,
      is_open_issue_modal,
      is_open_picker_mistake_modal,
    },
  }) => ({
    data,
    is_open_take_photo_modal,
    is_open_issue_modal,
    is_open_picker_mistake_modal,
  }),
  (dispatch) => ({
    openTakePhotoModal: (is_open) => dispatch(openTakePhotoModal(is_open)),
    openSpeditorModal: (is_open) => dispatch(openSpeditorModal(is_open)),
    openIssueModal: (is_open) => dispatch(openIssueModal(is_open)),
    openPickerMistakeModal: (is_open) =>
      dispatch(openPickerMistakeModal(is_open)),
  })
)(Header);
