import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { PackerStatisticsBar } from "components";
import { ReactComponent as BackIcon } from "icons/arrow-back.svg";
import PackerAccount from "./components/PackerAccount";

const Header = ({ current_user, statistics, history }) => (
  <div className="packs-header">
    <div className="packs-header__container">
      <div className="header-info">
        {current_user.role !== "packer" && (
          <button
            className="btn btn-default btn-back"
            onClick={() => history.push("/packs")}>
            <BackIcon />
            <span>Wróć do panelu</span>
          </button>
        )}
        <div className="title-group">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20">
            <rect fill="none" width="20" height="20" />
            <circle
              fill="#5D78FF"
              opacity="0.3"
              cx="8.333"
              cy="8.333"
              r="8.333"
              transform="translate(1.667 1.667)"
            />
            <path
              fill="#5D78FF"
              d="M11.595,15.37l2.53-5.061a.625.625,0,0,0-.559-.9H11.244V7.052a.625.625,0,0,0-1.184-.28l-2.53,5.061a.625.625,0,0,0,.559.9h2.322V15.09a.625.625,0,0,0,1.184.28Z"
              transform="translate(-1.244 -1.071)"
            />
          </svg>
          <span className="title">Obsługa wydań</span>
        </div>
      </div>
      {["warehouseman", "packer", "manager"].includes(
        current_user.role
      ) && (
        <PackerStatisticsBar
          value={statistics?.points || 0}
        />
      )}
      <PackerAccount />
    </div>
  </div>
);

export default connect(
  ({ current_user, gdn_packs: { statistics } }) => ({
    current_user,
    statistics,
  })
)(withRouter(Header));
