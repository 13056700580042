const user_role_options = [
  { label: "brak", value: 0 },
  { label: "Admin", value: 1 },
  { label: "Magazynier", value: 2 },
  { label: "Paker", value: 3 },
  { label: "Manager", value: 4 },
];

export const user_role_options_text = [
  { label: "brak", value: "none" },
  { label: "Admin", value: "admin" },
  { label: "Magazynier", value: "warehouseman" },
  { label: "Paker", value: "packer" },
  { label: "Manager", value: "manager" },
];

export const getUserRoleName = (role) =>
  user_role_options_text.find(({ value }) => value === role)
    ?.label;

export default user_role_options;
