import React from "react";
import { Main } from "layouts";
import { MainList } from "components";

import { sort, filters } from "./const/data";

import { ReactComponent as WarehouseIcon } from "icons/warehouse.svg";
import getProductStatus from "utils/getProductStatus";

const WarehouseClosePositions = ({
  match: {
    params: { id },
  },
}) => {
  return (
    <Main
      page={{
        name: "Pozycje z produktami do zamknięcia",
        icon: <WarehouseIcon />,
        breadcrumbs: [
          {
            name: "Zarządzanie magazynem",
            path: "/warehouses",
          },
          { name: "Pozycje z produktami do zamknięcia" },
        ],
      }}>
      <MainList
        query_key="warehouse_close_positions"
        api_path="/product_main_locations/close_positions"
        api_ransack={`warehouse_id=${id}`}
        sort_column="id"
        sort_direction="desc"
        sort={sort}
        filters={filters}
        renderItem={({
          id,
          stock,
          warehouse_position,
          product,
        }) => (
          <>
            <td>{id}</td>
            <td>{warehouse_position?.identifier}</td>
            <td>{product?.sku}</td>
            <td>{product?.name}</td>
            <td>{stock || 0}</td>
            <td>
              <div
                className={`product_status product_status__big ${
                  getProductStatus(product?.status)?.color
                }`}>
                <span>
                  {getProductStatus(product?.status)?.name}
                </span>
              </div>
            </td>
          </>
        )}
        renderActions={({ product }) => [
          {
            type: "edit",
            handleClick: () =>
              window.open(
                `/products/${product?.id}/localization`,
                "_blank"
              ),
          },
        ]}
      />
    </Main>
  );
};

export default WarehouseClosePositions;
