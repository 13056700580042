import React from "react";
import { MainList, Modal } from "components";
import { Button } from "expano-components";
import { getStatusLabelData } from "pages/grns/const/statuses";
import { convertDate } from "utils/helpers";
import { withRouter } from "react-router";

export const sort = [
  {
    label: "Ilość wybrakowanych pozycji",
    name: "skus_to_resolve_count",
    style: { width: 220 },
    sortable: false,
  },
  {
    label: "Data",
    name: "prepared_at",
    style: { width: 130 },
    sortable: false,
  },
  {
    label: "Numer",
    name: "identifier",
    sortable: false,
  },
  {
    label: "Rodzaj",
    name: "kind",
    style: { width: 200 },
    sortable: false,
  },
  {
    label: "Wydanie",
    name: "from_name",
    style: { width: 180 },
    sortable: false,
  },
  {
    label: "Odbiór",
    name: "to_name",
    style: { width: 180 },
    sortable: false,
  },
  {
    label: "Postęp",
    name: "progress",
    style: { width: 100 },
    sortable: false,
  },
  {
    label: "Status",
    name: "status",
    style: { width: 170 },
    sortable: false,
  },
];

const EstimateMissingsModal = ({
  handleClose,
  history,
}) => {
  return (
    <Modal
      className="estimate-missings"
      prevent_click_outside={true}
      handleClose={handleClose}
      header={{
        title: "Estymacja braków",
        icon: null,
      }}
      footer={
        <>
          <Button
            type="cancel"
            text="Anuluj"
            onClick={handleClose}
          />
        </>
      }
    >
      <MainList
        query_key="estimate_missings"
        api_path="/grns/estimate_missings"
        sort_column="prepared_at"
        sort_direction="desc"
        sort={sort}
        filters={[]}
        handleNavigate={(item, e) =>
          e?.button === 1
            ? window.open(`/grns/${item.id}`, "_blank")
            : history.push(`/grns/${item.id}`)
        }
        renderItem={({
          prepared_at,
          identifier,
          kind,
          from_name,
          to_name,
          status,
          is_dispatched,
          progress,
          skus_to_resolve_count,
        }) => {
          const label = getStatusLabelData({
            status,
            is_dispatched,
          });
          return (
            <>
              <td>
                {skus_to_resolve_count > 0 ? (
                  <span style={{ color: "#FF1A66" }}>
                    {" "}
                    Dotyczy{" "}
                    <strong>
                      {skus_to_resolve_count}
                    </strong>{" "}
                    wybrakowanych pozycji
                  </span>
                ) : (
                  "-"
                )}
              </td>
              <td>{convertDate({ value: prepared_at })}</td>
              <td>
                <strong>{identifier}</strong>
              </td>
              <td>
                {kind === "shift"
                  ? "Przesunięcie (MM)"
                  : "Dostawa"}
              </td>
              <td>{from_name}</td>
              <td>{to_name}</td>
              <td>
                <div className="progress-bar">
                  <div
                    className="progress-bar__inner"
                    style={{ width: `${progress || 0}%` }}
                  />
                </div>
              </td>
              <td>
                <div
                  className={`status-label status-label--sm ${label.status}`}
                >
                  <span>{label.status_text}</span>
                </div>
              </td>
            </>
          );
        }}
      />
    </Modal>
  );
};

export default withRouter(EstimateMissingsModal);
