import React from "react";
import {
  Document,
  Font,
  Image,
  Page,
  Text,
  View,
  StyleSheet,
} from "@react-pdf/renderer";

import RobotoBold from "fonts/Roboto-Bold.ttf";
import RobotoBlack from "fonts/Roboto-Black.ttf";

Font.register({
  family: "Roboto",
  fonts: [
    { src: RobotoBold, fontWeight: 700 },
    { src: RobotoBlack, fontWeight: 900 },
  ],
});

const StickerStation = ({ data }) => (
  <Document>
    <Page
      orientation="landscape"
      size={{ width: 302.36220472, height: 170.07874016 }}
      style={styles.page}>
      <View style={styles.container}>
        <View style={styles.text}>
          <Text style={styles.id}>{data.id}</Text>
        </View>
        <View style={styles.image}>
          <View style={styles.image_box}>
            <Image src={data.code} />
          </View>
        </View>
      </View>
    </Page>
  </Document>
);

const styles = StyleSheet.create({
  page: {
    backgroundColor: "#fff",
  },
  container: {
    height: "100%",
    padding: 5,
    border: "1pt solid #000",
  },
  text: {
    textAlign: "center",
  },
  id: {
    fontFamily: "Roboto",
    fontSize: 80,
    fontWeight: 900,
    marginVertical: 20,
  },
  image: {
    alignItems: "center",
  },
  image_box: {
    width: "85%",
  },
});

export default StickerStation;
