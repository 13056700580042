import React from "react";
import Loader from "react-loader-spinner";

const PanelLoader = () => (
  <div className="dashboard p-50 ">
    <div className="row jc-c">
      <Loader
        type="Watch"
        color="#2959F8"
        height={80}
        width={80}
      />
    </div>
  </div>
);

export default PanelLoader;
