const gls_image = require("icons/gls.png");
const dpd_image = require("icons/dpd.png");
const dhl_image = require("icons/dhl.jpg");
const ups_image = require("icons/ups.png");
const inpost_image = require("icons/inpost.png");
const poczta_polska_image = require("icons/poczta-polska.png");

const delivery_methods = [
  {
    name: "Kurier GLS",
    image: gls_image,
    company_ids: [1, 2],
    is_automat: false,
  },
  {
    name: "Kurier DHL",
    image: dhl_image,
    company_ids: [1, 2],
    is_automat: false,
  },
  {
    name: "Kurier UPS",
    image: ups_image,
    company_ids: [1],
    is_automat: false,
  },
  {
    name: "Kurier DPD",
    image: dpd_image,
    company_ids: [1, 2],
    is_automat: false,
  },
  {
    name: "Odbiór w Punkcie DPD Pickup",
    image: dpd_image,
    company_ids: [1, 2],
    is_automat: true,
  },
  {
    name: "Paczkomaty InPost",
    image: inpost_image,
    company_ids: [1, 2],
    is_automat: true,
  },
  {
    name: "Kurier InPost",
    image: inpost_image,
    company_ids: [1,2],
    is_automat: false,
  },
  {
    name: "Odbiór w Punkcie Pocztex",
    image: poczta_polska_image,
    company_ids: [1, 2],
    is_automat: true,
  },
  {
    name: "Automat Pocztex",
    image: poczta_polska_image,
    company_ids: [1, 2],
    is_automat: true,
  },
];

export default delivery_methods;
