import { client_v2 } from "utils/api";
import { toastr } from "react-redux-toastr";

export const createMainLocation = ({
  product_id,
  warehouse_position_id,
}) => (dispatch) =>
  new Promise(async (resolve, reject) => {
    try {
      const {
        data: { id },
      } = await client_v2.post("/product_main_locations", {
        product_id,
        warehouse_position_id,
      });
      const { data } = await client_v2.get(
        `/product_main_locations/${id}?context=ui_product_show`
      );

      dispatch({
        type: "EDIT_PRODUCT_ADD_MAIN_LOCATION",
        data,
      });

      toastr.success(
        "Sukces",
        "Lokalizacja została dodana"
      );

      resolve();
    } catch (error) {
      console.log(error);
      toastr.error("Błąd", "Wystąpił błąd");
      reject();
    }
  });

export const updateMainLocation = (id, values) => (
  dispatch
) =>
  new Promise(async (resolve, reject) => {
    try {
      await client_v2.put(
        `/product_main_locations/${id}`,
        values
      );
      const { data } = await client_v2.get(
        `/product_main_locations/${id}?context=ui_product_show`
      );
      dispatch({
        type: "EDIT_PRODUCT_UPDATE_MAIN_LOCATION",
        data,
      });
      toastr.success(
        "Sukces",
        "Lokalizacja została zaktualizowana"
      );
      resolve();
    } catch (error) {
      console.log(error);
      toastr.error("Błąd", "Wystąpił błąd");
      reject();
    }
  });

export const deleteMainLocation = (id) => (dispatch) =>
  new Promise(async (resolve, reject) => {
    try {
      await client_v2.delete(
        `/product_main_locations/${id}`
      );
      dispatch({
        type: "EDIT_PRODUCT_DELETE_MAIN_LOCATION",
        id,
      });
      toastr.success(
        "Sukces",
        "Lokalizacja została usunięta"
      );
      resolve();
    } catch (error) {
      console.log(error);
      toastr.error("Błąd", "Wystąpił błąd");
      reject();
    }
  });
