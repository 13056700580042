import React, { useState } from "react";
import { connect } from "react-redux";
import { Button, Input, Select } from "expano-components";
import { reduxForm, Field } from "redux-form";
import country_code_options from "utils/country_code_options";

import { Modal } from "components";

import { ReactComponent as PackIcon } from "icons/pack.svg";
import getCheapestCourier from "utils/queries/getCheapestCourier";

const InputField = (props) => <Input {...props} />;
const SelectField = (props) => <Select {...props} />;

const CheapestSpeditorModal = ({
  change,
  handleSubmit,
  handleClose,
}) => {
  const [cheapest_courier, setCheapestCourier] = useState(
    false
  );
  const [is_loading, setLoading] = useState(false);

  const onSubmit = async (values) => {
    try {
      setCheapestCourier(null);
      setLoading(true);
      const data = await getCheapestCourier(values);
      setCheapestCourier(`${data?.speditor_name} (${data?.speditor_price}zł)`);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  return (
    <Modal
      size="sm"
      handleClose={handleClose}
      header={{
        title: "Sprawdź najtańszego kuriera",
        icon: <PackIcon />,
      }}
      footer={
        <>
          <Button
            type="cancel"
            text="Anuluj"
            onClick={handleClose}
          />

          <button
            type="button"
            onClick={handleSubmit(onSubmit)}
            className="btn btn-sm btn-green">
            <span>
              {is_loading
                ? "Trwa sprawdzanie..."
                : "Sprawdź"}
            </span>
          </button>
        </>
      }>
      <form
        autoComplete="off"
        noValidate
        onSubmit={handleSubmit(onSubmit)}>
        <input
          type="submit"
          value="Submit"
          style={{ display: "none" }}
        />

        <p className="m-b-20">Najtańszy kurier: <strong>{cheapest_courier || "-"}</strong></p>
        <Field
          label="Kod kraju"
          name="country_code"
          component={SelectField}
          handleChange={(value) =>
            change("country_code", value)
          }
          options={country_code_options}
        />
        <div className="row row--with-spaces m-b-10">
          <div className="box-6">
            <Field
              name="width"
              type="number"
              component={InputField}
              placeholder="cm"
              label="Szerokość"
              postfix="cm"
            />
          </div>
          <div className="box-6">
            <Field
              name="height"
              type="number"
              placeholder="cm"
              component={InputField}
              label="Wysokość"
              postfix="cm"
            />
          </div>
        </div>
        <div className="row row--with-spaces">
          <div className="box-6">
            <Field
              name="depth"
              type="number"
              placeholder="cm"
              component={InputField}
              label="Głębokość"
              postfix="cm"
            />
          </div>
          <div className="box-6">
            <Field
              name="weight"
              type="number"
              placeholder="kg"
              component={InputField}
              label="Waga"
              postfix="kg"
            />
          </div>
        </div>
      </form>
    </Modal>
  );
};

const validate = (fields) => {
  const errors = {};

  if (!fields.country_code) {
    errors.country_code = "Pole wymagane";
  }

  ["depth", "height", "width", "weight"].forEach((el) => {
    if (!fields[el]) {
      errors[el] = "Pole wymagane";
    } else if (!(fields[el] > 0)) {
      errors[el] = "Wartość musi być większa od 0";
    }
  });
  return errors;
};

const form_name = "cheapest-speditor-form";

export default connect(() => ({
  initialValues: {
    country_code: "",
    width: "",
    height: "",
    depth: "",
    weight: "",
  },
}))(
  reduxForm({
    form: form_name,
    validate,
    enableReinitialize: true,
  })(CheapestSpeditorModal)
);
