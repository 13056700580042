import { client_v2 } from "utils/api";

const getWarehouseShelfs = (query, rack_id) =>
  new Promise((resolve, reject) =>
    client_v2
      .get(
        `/warehouse_shelves?context=list&sort[order]=asc&sort[column]=position&q[warehouse_rack_id_eq]=${rack_id}&page=1&per_page=50&${
          query
            ? `&q[name_or_identifier_matches]=%25${query}%25`
            : ""
        }`
      )
      .then(({ data }) =>
        resolve(
          data?.map(({ id, identifier }) => ({
            label: identifier,
            value: id,
          }))
        )
      )
      .catch((err) => reject(err))
  );

export default getWarehouseShelfs;
