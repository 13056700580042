import { toastr } from "react-redux-toastr";
import { client } from "utils/api";

export const getPackerDashboard = (date) => async (dispatch) => {
  try {
    dispatch({
      type: "PACKER_DASHBOARD_LOADING",
    });
    const {
      data: { data },
    } = await client.get(`/stats/packer_dashboard?date=${date}`);
    dispatch({
      type: "PACKER_DASHBOARD_SUCCESS",
      data,
    });
  } catch (error) {
    dispatch({ type: "PACKER_DASHBOARD_FAILURE" });
    toastr.error("Błąd", "Wystąpił błąd podczas pobierania danych");
  }
};
