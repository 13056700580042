import { client_v2 } from "utils/api";

const getWarehouses = (q) =>
  new Promise(async (resolve, reject) => {
    client_v2
      .get(
        `/warehouses?context=list&per_page=50${
          q ? `&q[name_matches]=%25${q}%25` : ""
        }`
      )
      .then(({ data }) =>
        resolve(
          data?.map(({ id, name }) => ({
            label: name,
            value: id,
          }))
        )
      )
      .catch((err) => reject(err));
  });

export default getWarehouses;
