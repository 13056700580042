import React from "react";
import { connect } from "react-redux";

import { ReactComponent as WarningIcon } from "icons/light-warning.svg";
const ErrorText = ({ data }) => {
  if (
    (data?.status === "with_errors" ||
      data?.status === "completed") &&
    data?.has_other_error
  ) {
    return (
      <p className="box-text">
        <WarningIcon /> {data?.other_error || "Brak alertu"}
      </p>
    );
  }
  return null;
};

export default connect(({ grn }) => ({ data: grn?.data }))(
  ErrorText
);
