import React, { useState, useEffect } from "react";
import { Login } from "expano-components";
import {
  handleLogin,
  handleLoginByAuthToken,
} from "actions/account";
import onScan from "utils/scan_handler";

let timer;

const AppLogin = () => {
  const [token, setToken] = useState("");
  useEffect(() => {
    onScan.attachTo(document);
    document.addEventListener("scan", handleScan);
    return () => {
      onScan.detachFrom(document);
      document.removeEventListener("scan", handleScan);
    };
  }, []);

  useEffect(() => {
    if (token.length > 0) {
      clearTimeout(timer);
      timer = setTimeout(sendToken, 500);
    }
  }, [token]);

  const handleScan = ({ detail: { scanCode } }) =>
    setToken((current_token) => current_token + scanCode);

  const sendToken = () => {
    handleLoginByAuthToken(token);
    setToken("");
  };

  return (
    <Login
      app="wms"
      handleLogin={(data) => {
        handleLogin({ user: { ...data } });
      }}
    />
  );
};

export default AppLogin;
