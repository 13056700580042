import React, { useState, useEffect } from "react";
import QRCode from "qrcode";
import { connect } from "react-redux";
import { pdf } from "@react-pdf/renderer";
import { saveAs } from "file-saver";
import { MainTable } from "components";
import { Main } from "layouts";
import { StickerStation } from "sticker_templates";
import { parseQuery } from "utils/api";
import { getStations } from "actions/stations";
import { ReactComponent as QRcodeIcon } from "icons/qrcode.svg";

import { ReactComponent as StationIcon } from "icons/user.svg";

const StationsList = ({
  stations,
  getStations,
  location: { search },
}) => {
  const [is_loading, setLoading] = useState(false);
  const [is_loaded, setLoaded] = useState(false);
  const query = parseQuery(search);
  const sort = {
    "sort[column]": query["sort[column]"] || "id",
    "sort[order]": query["sort[order]"] || "asc",
  };

  useEffect(() => {
    getStations({ ...query, ...sort });
    setLoaded(true);
  }, [search]);

  useEffect(() => {
    if (is_loaded) {
      getStations({ ...query, page: undefined });
    }
  }, [stations.filters.query]);

  const generateSticker = async ({ id, name }) => {
    try {
      setLoading(true);
      const code = await QRCode.toDataURL(
        `packing_station|${id}`
      );
      const blob = await pdf(
        <StickerStation
          data={{
            id,
            code,
          }}
        />
      ).toBlob();
      saveAs(blob, `${name}.pdf`);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  if (stations.status === "failure") {
    return <p>Error</p>;
  }

  const { data, meta } = stations;
  return (
    <Main
      page={{
        name: "Stanowiska",
        icon: <StationIcon />,
        breadcrumbs: [{ name: "Stanowiska" }],
      }}>
      <div className="dashboard">
        <MainTable
          is_loading={["invalid", "loading"].includes(
            stations.status
          )}
          is_searching={stations.status === "searching"}
          filters_open={stations.filters.open}
          data={data}
          meta={meta}
          sort={sort}
          head={[
            {
              label: "ID",
              name: "id",
              style: { width: 100 },
            },
            {
              label: "Nazwa",
              name: "name",
              sortable: false,
            },
            {
              label: "Akcje",
              name: "actions",
              sortable: false,
              width: 100,
            },
          ]}
          empty_text="Brak wózków"
          renderRow={({ id, name }) => (
            <tr key={id}>
              <td>{id}</td>
              <td>{name}</td>
              <td>
                <button
                  disabled={is_loading}
                  type="button"
                  onClick={() =>
                    generateSticker({ id, name })
                  }
                  tabIndex="-1">
                  <QRcodeIcon />
                </button>
              </td>
            </tr>
          )}
        />
      </div>
    </Main>
  );
};

export default connect(
  ({ stations }) => ({ stations }),
  (dispatch) => ({
    getStations: (data) => dispatch(getStations(data)),
  })
)(StationsList);
