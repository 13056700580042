import React from "react";
import { connect } from "react-redux";

import { Table } from "../../../components";

import Item from "./components/Item";
import Summary from "./components/Summary";

const Delivery = ({ data }) => (
  <>
    <div className="dashboard m-t-0 m-b-0">
      <Table
        renderItems={() =>
          data?.grn_lines?.map((grn_line) => (
            <Item key={grn_line.id} warehouse_id={data?.warehouse?.id} data={grn_line} />
          ))
        }
      />
    </div>
    <Summary />
  </>
);

export default connect(({ grn: { data } }) => ({
  data,
}))(Delivery);
