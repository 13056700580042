import React from "react";
import { numberWithSpaces } from "utils/helpers";

const StatsBox = ({ data }) => (
  <div className="summary-box stats">
    <div className="summary-box__name">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24">
        <path
          fill="#5d78ff"
          d="M5,19H20a1,1,0,0,1,0,2H4a1,1,0,0,1-1-1V4A1,1,0,0,1,5,4Z"
        />
        <path
          fill="#5d78ff"
          opacity="0.3"
          d="M8.73,14.684A1,1,0,0,1,7.27,13.316l3.75-4A1,1,0,0,1,12.4,9.239l2.96,2.526L19.215,6.88a1,1,0,0,1,1.57,1.239l-4.5,5.7a1,1,0,0,1-1.434.141l-3.024-2.58Z"
        />
      </svg>
      <span>Statystyki</span>
    </div>
    <div className="summary-box__values">
      <div className="single-value">
        <p className="info">Prognoza</p>
        <p className="main-value">
          {numberWithSpaces(data?.prediction?.estimation)}
        </p>
      </div>
      <div className="single-value">
        <p className="info">Wydajność</p>
        <p className="main-value">
          {numberWithSpaces(data?.prediction?.efficiency)}
        </p>
        <p className="info info--bottom">na dzień</p>
      </div>
      <div className="single-value">
        <p className="info">Szybkość</p>
        <p className="main-value">
          {numberWithSpaces(
            data?.prediction?.completed_per_hour
          )}
        </p>
        <p className="info info--bottom">na godzinę</p>
      </div>
    </div>
  </div>
);

export default StatsBox;
