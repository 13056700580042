import React from "react";
import { connect } from "react-redux";
import { setFilter } from "actions/warehouse_stock_stats";
import Select from "../Select";
import DatePicker from "../DatePicker";
import WarehouseStocksChart from "./components/WarehouseStocksChart";
import { date_options, getHistogramDates } from "utils/histogram_ranges";

const Charts = ({ status, data, filters, setFilter }) => {
  const warehouse_options = [
    ...(data.warehouses?.map(({ name, value }) => ({
      label: name,
      value: value,
    })) || []),
  ];

  const is_updating = status === "updating";

  return (
    <div className="dashboard chart-panel">
      <div className="chart-panel__body">
        <div className="chart-panel__form">
          <Select
            is_multi={true}
            label="Magazyn"
            is_loading={is_updating}
            onChange={(values) =>
              setFilter({
                histogram_warehouse_ids:
                  values?.length > 0 ? values.map(({ value }) => value) : [],
              })
            }
            options={warehouse_options}
            value={warehouse_options.filter(({ value }) =>
              filters.histogram_warehouse_ids.includes(value)
            )}
          />
          <Select
            label="Zakres"
            disabled={is_updating}
            onChange={({ value }) =>
              setFilter({
                histogram_date_option: value,
                ...getHistogramDates(value),
              })
            }
            options={date_options}
            value={date_options.find(
              ({ value }) => value === filters.histogram_date_option
            )}
          />
          <DatePicker
            name="created_at"
            label="Data utworzenia"
            onChange={(value) =>
              setFilter({
                histogram_range_from: value?.[0],
                histogram_range_to: value?.[1],
              })
            }
            value={[filters.histogram_range_from, filters.histogram_range_to]}
          />
        </div>
        <div className="chart-panel__chart">
          <WarehouseStocksChart />
        </div>
      </div>
    </div>
  );
};

export default connect(
  ({ warehouse_stock_stats: { data, status, filters } }) => ({
    data,
    filters,
    status,
  }),
  (dispatch) => ({
    setFilter: (filter) => dispatch(setFilter(filter)),
  })
)(Charts);
