import classNames from "classnames";
import React, { useState, useEffect, useRef } from "react";
import { ReactComponent as CollapseArrowIcon } from "icons/collapse.svg";

const ButtonGroup = ({ trigger, buttons }) => {
  const ref = useRef(null);
  const [is_open, setOpen] = useState(false);
  useEffect(() => {
    function handleClickOutside(event) {
      if (is_open && ref.current && !ref.current.contains(event.target)) {
        setOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [is_open]);

  return (
    <div
      ref={ref}
      className={classNames("button-group", {
        open: is_open,
      })}
    >
      <button
        className="button-group__trigger"
        onClick={() => setOpen(!is_open)}
      >
        {trigger?.icon}
        <span>{trigger?.text}</span>
        <CollapseArrowIcon className="collapse-arrow" />
      </button>
      <ul className="button-group__content">
        {buttons.map((button, index) => (
          <li
            key={index}
            onClick={() => {
              setOpen(false);
              button.action();
            }}
          >
            {button?.text}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ButtonGroup;
