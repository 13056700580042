import React, { useState } from "react";
import { connect } from "react-redux";
import { Button } from "expano-components";

import StyledAddPack from "./styles";
import ModalAddPackScope from "./components/ModalAddPackScope";
import ModalAddPackTag from "./components/ModalAddPackTag";

const AddPack = ({ current_user, handleReload }) => {
  const [
    is_open_add_pack_modal,
    handleOpenAddPackModal,
  ] = useState(false);

  return (
    <StyledAddPack>
      <Button
        text="Utwórz paka"
        type="add"
        onClick={() => handleOpenAddPackModal(true)}
      />
      {is_open_add_pack_modal && (
        <>
          {current_user?.company?.id === 1 && (
            <ModalAddPackScope
              handleReload={handleReload}
              handleClose={() =>
                handleOpenAddPackModal(false)
              }
            />
          )}
          {current_user?.company?.id === 2 && (
            <ModalAddPackTag
              handleReload={handleReload}
              handleClose={() =>
                handleOpenAddPackModal(false)
              }
            />
          )}
        </>
      )}
    </StyledAddPack>
  );
};

export default connect(
  ({ current_user }) => ({ current_user }),
  null
)(AddPack);
