import React, { useState } from "react";
import { connect } from "react-redux";
import { Button } from "expano-components";
import { AddLocation } from "./modals";
import LineItem from "./components/LineItem";
import { deleteMainLocation } from "actions/product_main_locations";

import { ReactComponent as LocationIcon } from "icons/location.svg";

const Localizations = ({ data, deleteMainLocation }) => {
  const [shown, showModal] = useState(false);
  const [type, setType] = useState(null);
  
  const handleDelete = (id) => deleteMainLocation(id);

  return (
    <>
      {shown && (
        <AddLocation
          type={type}
          handleClose={() => {
            showModal(false);
            setType(null);
          }}
        />
      )}
      <div className="dashboard m-b-20">
        <header className="dashboard__header dashboard__header--edit">
          <div className="icon__container">
            <LocationIcon />
          </div>
          <h2 className="dashboard__title heading">
            Lokalizacje główne
          </h2>
          <Button
            type="add"
            text="Dodaj lokalizacje"
            onClick={() => {
              showModal(true);
              setType("main");
            }}
          />
        </header>
        <ul className="product-localizations">
          {data?.product_main_locations?.map((item) => (
            <LineItem
              handleDelete={handleDelete}
              key={item.id}
              {...item}
              product_id={data.id}
              type="main"
            />
          ))}
        </ul>
      </div>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  deleteMainLocation: (id) =>
    dispatch(deleteMainLocation(id)),
});

export default connect(
  null,
  mapDispatchToProps
)(Localizations);
