import React from "react";

const Status = ({ type = "danger", text }) => (
  <div className={`single-status ${type}`}>
    {type === "danger" ? (
      <svg xmlns='http://www.w3.org/2000/svg' width='26' height='26' viewBox='0 0 26 26' style={{ transform: "translateY(-2px)" }}>
        <rect fill='none' width='26' height='26' />
        <path
          fill='#fff'
          opacity='0.3'
          d='M11.873,4.542,2.836,20.809a1.083,1.083,0,0,0,.947,1.609H22.76a1.083,1.083,0,0,0,.924-1.648L13.744,4.5a1.083,1.083,0,0,0-1.871.039Z'
          transform='translate(0.225 0.332)'
        />
        <rect fill='#fff' width='2.167' height='7.583' rx='1' transform='translate(11.917 9.75)' />
        <rect fill='#fff' width='2.167' height='2.167' rx='1' transform='translate(11.917 18.417)' />
      </svg>
    ) : (
      <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' style={{ transform: "scale(1.4)" }}>
        <rect fill='none' width='20' height='20' />
        <circle fill='#fff' opacity='0.3' cx='8.333' cy='8.333' r='8.333' transform='translate(1.667 1.667)' />
        <rect fill='#fff' width='1.667' height='5.833' rx='0.833' transform='translate(9.167 8.333)' />
        <rect fill='#fff' width='1.667' height='1.667' rx='0.833' transform='translate(9.167 5.833)' />
      </svg>
    )}
    <div className='single-status__content'>
      <p>{text}</p>
    </div>
  </div>
);

export default Status;
