const initialState = {
  status: "invalid",
  filters: {
    open: true,
    query: {},
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "GDNS_TOGGLE_FILTERS":
      return {
        ...state,
        filters: {
          ...state.filters,
          open: !state.filters.open,
        },
      };
    case "GDNS_RESET_FILTERS":
      return {
        ...state,
        filters: {
          open: true,
          query: {},
        },
      };
    case "GDNS_SET_FILTERS_QUERY":
      return {
        ...state,
        filters: { ...state.filters, query: action.query },
      };
    case "GDNS_LOADING":
      return {
        ...state,
        status: "loading",
      };
    case "GDNS_SEARCHING":
      return {
        ...state,
        status: "searching",
      };
    case "GDNS_SUCCESS":
      return {
        ...state,
        status: "success",
        data: action.data,
        meta: action.meta,
      };
    case "GDNS_FAILURE":
      return {
        ...state,
        status: "failure",
      };
    default:
      return state;
  }
};
