import { toastr } from "react-redux-toastr";
import { client_v2 } from "utils/api";

export const getAbsences = () => async (dispatch) => {
  try {
    dispatch({
      type: "ABSENCES_LOADING",
    });
    const { data } = await client_v2.get("/absences/recent");
    dispatch({
      type: "ABSENCES_SUCCESS",
      data,
    });
  } catch (error) {
    dispatch({ type: "ABSENCES_FAILURE" });
    toastr.error(
      "Błąd",
      "Wystąpił błąd podczas pobierania danych o nieobecnościach"
    );
    throw error;
  }
};
