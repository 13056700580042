import React, { useState } from "react";
import {
  Button,
  ModalUpload,
  Input,
  Select,
} from "expano-components";
import {
  Field,
  formValueSelector,
  reduxForm,
  reset
} from "redux-form";
import Loader from "react-loader-spinner";
import { Modal } from "components";
import { connect } from "react-redux";
import {upload_package_options} from "utils/package_options";
import { createWaybill } from "actions/gdns";

const InputField = (props) => <Input {...props} />;
const SelectField = (props) => <Select {...props} />;

const form_name = "upload-waybill";

const UploadWaybill = ({
  waybill,
  handleSubmit,
  change,
  createWaybill,
  type,
  package_numbers,
  dispatch
}) => {
  const [is_loading, setLoading] = useState(false);
  const [is_open_data_modal, setOpenDataModal] = useState(
    false
  );

  const [
    is_open_upload_modal,
    setOpenUploadModal,
  ] = useState(false);

  const handleUpload = (file) => {
    change("waybill", file);
    setOpenUploadModal(false);
    setOpenDataModal(true);
  };

  const invalid = !package_numbers || !type || !waybill;

  const onSubmit = async (values) => {
    try {
      setLoading(true);
      await createWaybill(values);
      dispatch(reset(form_name))
      setOpenDataModal(false);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Button
        onClick={() => setOpenDataModal(true)}
        style={{ marginTop: 10 }}
        type="add"
        text="Dodaj list przewozowy"
      />
      {is_open_upload_modal && (
        <ModalUpload
          handleUpload={handleUpload}
          headerText="Wgraj list przewozowy (.pdf)"
          accept="application/pdf"
          handleClose={() => {
            setOpenUploadModal(false);
            setOpenDataModal(true);
          }}
        />
      )}
      {is_open_data_modal && (
        <Modal
          header={{
            title: "List przewozowy",
          }}
          footer={
            <>
              <Button
                type="cancel"
                text="Anuluj"
                onClick={() => setOpenDataModal(false)}
              />
              <Button
                type="save"
                text={
                  is_loading ? (
                    <Loader
                      type="Oval"
                      color="#fff"
                      height={15}
                      width={15}
                    />
                  ) : (
                    "Zapisz"
                  )
                }
                disabled={is_loading || invalid}
                onClick={handleSubmit(onSubmit)}
              />
            </>
          }
          handleClose={() => setOpenDataModal(false)}>
          <form noValidate>
            <div className="row">
              <div className="box-12">
                <Field
                  label="Nr paczki"
                  name="package_numbers"
                  type="text"
                  component={InputField}
                />
                <Field
                  label="Kurier"
                  name="type"
                  component={SelectField}
                  handleChange={(value) =>
                    change("type", value)
                  }
                  defaultValue={upload_package_options.find(
                    ({ value }) => value === type
                  )}
                  options={upload_package_options}
                />
              </div>
            </div>
            <div style={{ marginTop: 20 }}>
              {waybill ? (
                <div className="row">
                  <p>{waybill?.name}</p>
                  <Button
                    style={{ padding: 0, marginLeft: 5 }}
                    type="delete-bin"
                    onClick={() => change("waybill", null)}
                  />
                </div>
              ) : (
                <Button
                  onClick={() => {
                    setOpenDataModal(false);
                    setOpenUploadModal(true);
                  }}
                  type="add"
                  text="Wgraj list przewozowy"
                />
              )}
            </div>
          </form>
        </Modal>
      )}
    </>
  );
};
const validate = ({ package_numbers, type, waybill }) => {
  const errors = {};
  if (!package_numbers) {
    errors.package_numbers = "Pole wymagane!";
  }
  if (!type) {
    errors.type = "Pole wymagane!";
  }
  if (!waybill) {
    errors.waybill = "Pole wymagane!";
  }
  return errors;
};


const selector = formValueSelector(form_name);

export default connect(
  (state) => ({
    initialValues: {
      waybill: null,
      gdn_id: state.gdn.data.id,
      package_numbers: "",
      type: "",
    },
    waybill: selector(state, "waybill"),
    type: selector(state, "type"),
    package_numbers: selector(state, "package_numbers"),
  }),
  (dispatch) => ({
    createWaybill: (data) => dispatch(createWaybill(data)),
  })
)(
  reduxForm({
    form: form_name,
    enableReinitialize: true,
    validate,
  })(UploadWaybill)
);
