import React from "react";
import { connect } from "react-redux";
import { Checkbox, Textarea } from "expano-components";
import { Field } from "redux-form";

const CheckField = (props) => <Checkbox {...props} />;

const TextAreaField = (props) => (
  <Textarea
    {...props}
    input={{
      ...props.input,
      placeholder: "Treść alertu...",
    }}
  />
);

const OtherErrorForm = ({ data, has_other_error }) => {
  if (
    data.grn_lines.filter(
      ({ counting_state }) => counting_state === "pending"
    ).length === 0 &&
    (data?.status === "open" || data.status === "active")
  ) {
    return (
      <div className="grn-summary__form">
        <Field
          label="Inne niezgodności w dostawie"
          name="has_other_error"
          component={CheckField}
        />
        {has_other_error && (
          <Field
            name="other_error"
            component={TextAreaField}
          />
        )}
      </div>
    );
  }
  return null;
};

export default connect((state) => ({
  data: state?.grn?.data,
}))(OtherErrorForm);
