import React, { useMemo, useRef } from "react";
import moment from "moment";
import { connect } from "react-redux";
import { toastr } from "react-redux-toastr";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";
import { Line, Bar, getElementAtEvent } from "react-chartjs-2";
import { getAllUserErrorName } from "utils/gdn_user_error_options";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Tooltip,
  Legend,
  Filler
);

function numberWithSpaces(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}

const randomNum = () => Math.floor(Math.random() * (235 - 52 + 1) + 52);

const randomRGB = () =>
  Array(3)
    .fill(null)
    .map(() => randomNum());

const getLabelDateFormat = (type) => {
  switch (type) {
    case "year":
      return "YYYY";
    case "month":
      return "MM-YYYY";
    case "hour":
      return "DD-MM-YYYY HH:mm";
    default:
      return "DD-MM-YYYY";
  }
};

const options = {
  responsive: false,
  interaction: {
    mode: "index",
    intersect: false,
  },
  stacked: false,
  scales: {
    y: {
      type: "linear",
      display: true,
      position: "left",
      min: 0,
    },
  },
};

const product_options = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
  },
};

const kinds_before_send = [
  "wrong_product",
  "wrong_product_quantity",
  "incomplete_product",
  "picking_other",
];

const kinds_after_send = [
  "wrong_product",
  "wrong_product_quantity",
  "incomplete_product",
  "incorrect_photo",
  "packing_other",
  "badly_packed",
  "double_cod",
  "skip_note",
];

const ErrorsChart = ({ data, interval, active_user_names }) => {
  const product_chart_ref = useRef();

  const user_errors_count = data.user_histogram.map(({ total }) => total);
  const user_errors_total_count = user_errors_count.reduce(
    (acc, value) => acc + value,
    0
  );

  const chart_products = data.products.filter(({ count }) => count > 2);

  const product_colors = useMemo(
    () =>
      Array(chart_products.length)
        .fill(null)
        .map(() => randomRGB()),
    []
  );

  const worker_colors = useMemo(
    () =>
      Array(data.user_errors.length)
        .fill(null)
        .map(() => randomRGB()),
    []
  );

  const users = useMemo(
    () =>
      data.users.map(({ name }) => {
        const [r, g, b] = randomRGB();
        return {
          label: name,
          value: name,
          borderColor: `rgba(${r},${g},${b},1)`,
          backgroundColor: `rgba(${r},${g},${b},0.7)`,
        };
      }),
    []
  );

  const kinds = useMemo(
    () =>
      data.kinds.map(({ name }) => {
        const [r, g, b] = randomRGB();
        return {
          label: getAllUserErrorName(name),
          value: name,
          borderColor: `rgba(${r},${g},${b},1)`,
          backgroundColor: `rgba(${r},${g},${b},0.7)`,
        };
      }),
    []
  );

  const getUserErrors = (name) =>
    data.user_histogram.map(({ users }) => users?.[name] || 0);

  const getKindBeforeSendErrors = (name) =>
    data.kind_histogram_before_send.map(({ kinds }) => kinds?.[name] || 0);

  const getKindAfterSendErrors = (name) =>
    data.kind_histogram_after_send.map(({ kinds }) => kinds?.[name] || 0);

  const active_user_histogram = users.filter(({ value }) =>
    active_user_names.includes(value)
  );

  const chart_data_user = {
    labels: data.user_histogram.map(({ date }) =>
      moment(date).format(getLabelDateFormat(interval))
    ),
    datasets:
      active_user_histogram.length > 0
        ? active_user_histogram?.map(
            ({ label, value, borderColor, backgroundColor }) => ({
              label,
              data: getUserErrors(value),
              fill: false,
              borderWidth: 2,
              borderColor,
              backgroundColor,
              yAxisID: "y",
            })
          )
        : [
            {
              label: "Wszystkie",
              data: data.user_histogram.map(({ total }) => total),
              fill: false,
              borderWidth: 2,
              borderColor: "#5D78FF",
              backgroundColor: "#5D78FF",
            },
          ],
  };

  const chart_data_kind_before_send = {
    labels: data.kind_histogram_before_send.map(({ date }) =>
      moment(date).format(getLabelDateFormat(interval))
    ),
    datasets: kinds
      ?.filter(({ value }) => kinds_before_send.includes(value))
      ?.map(({ label, value, borderColor, backgroundColor }) => ({
        label,
        data: getKindBeforeSendErrors(value),
        fill: false,
        borderWidth: 2,
        borderColor,
        backgroundColor,
        yAxisID: "y",
      })),
  };

  const chart_data_kind_after_send = {
    labels: data.kind_histogram_after_send.map(({ date }) =>
      moment(date).format(getLabelDateFormat(interval))
    ),
    datasets: kinds
      ?.filter(({ value }) => kinds_after_send.includes(value))
      ?.map(({ label, value, borderColor, backgroundColor }) => ({
        label,
        data: getKindAfterSendErrors(value),
        fill: false,
        borderWidth: 2,
        borderColor,
        backgroundColor,
        yAxisID: "y",
      })),
  };

  const chart_data_products = {
    labels: chart_products.map(({ name }) => name),
    datasets: [
      {
        label: null,
        data: chart_products.map(({ count }) => count),
        borderColor: product_colors.map(
          ([r, g, b]) => `rgba(${r},${g},${b},1)`
        ),
        backgroundColor: product_colors.map(
          ([r, g, b]) => `rgba(${r},${g},${b},0.7)`
        ),
      },
    ],
  };

  const chart_data_workers = {
    labels: data.user_errors.map(({ name }) => name),
    datasets: [
      {
        label: null,
        data: data.user_errors.map(({ count }) => count),
        borderColor: worker_colors.map(
          ([r, g, b]) => `rgba(${r},${g},${b},1)`
        ),
        backgroundColor: worker_colors.map(
          ([r, g, b]) => `rgba(${r},${g},${b},0.7)`
        ),
      },
    ],
  };

  const onProductClick = (event) => {
    const [el] = getElementAtEvent(product_chart_ref.current, event);
    if (el?.index > -1) {
      const copy_text = chart_products?.[el.index]?.name;
      if (copy_text) {
        navigator.clipboard.writeText(copy_text);
        toastr.success("Sukces", `Skopiowano do schowka: ${copy_text}`);
      }
    }
  };

  return (
    <>
      <p>
        Ilość błędów:{" "}
        <strong>{numberWithSpaces(user_errors_total_count)}</strong>
      </p>
      <Line
        width={window.innerWidth - 160}
        height="350px"
        options={options}
        data={chart_data_user}
      />
      <h2 className="main-title center">Błędy podczas kompletacji</h2>
      <Bar
        width={window.innerWidth - 160}
        height="350px"
        options={options}
        data={chart_data_kind_before_send}
      />
      <h2 className="main-title center">Błędy podczas pakowania</h2>
      <Bar
        width={window.innerWidth - 160}
        height="350px"
        options={options}
        data={chart_data_kind_after_send}
      />
      <h2 className="main-title center">Błędy pracowników</h2>
      <Bar
        style={{ marginTop: 50 }}
        width={window.innerWidth - 160}
        height="350px"
        options={product_options}
        data={chart_data_workers}
      />
      <h2 className="main-title center">Najbardziej problematyczne produkty</h2>
      <Bar
        ref={product_chart_ref}
        style={{ marginTop: 50 }}
        width={window.innerWidth - 160}
        height="350px"
        options={product_options}
        data={chart_data_products}
        onClick={onProductClick}
      />
    </>
  );
};

export default connect(({ gdn_errors_stats: { data, filters } }) => ({
  data,
  interval: filters.histogram_interval,
  active_user_names: filters.histogram_user_names,
}))(ErrorsChart);
