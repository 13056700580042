export const getStatusLabelData = ({
  status,
  is_dispatched,
}) => {
  switch (status) {
    case "open":
      return {
        status_text: is_dispatched ? "Wydane" : "Nowe",
        status: "info",
      };
    case "active":
      return {
        status_text: "Rozpoczęte",
        status: "warning",
      };

    case "completed":
      return {
        status_text: "Potwierdzone",
        status: "success",
      };
    case "with_errors":
      return {
        status_text: "Potwierdzone z błędami",
        status: "danger",
      };
    case "finished":
      return { status_text: "Zakończone", status: "dark" };
    case "waiting_for_shift":
      return { status_text: "Oczekuje na MM", status: "light-warning" };
    default:
      return { status_text: null, status: null };
  }
};
