import React, { useState } from "react";
import { connect } from "react-redux";
import UserModal from "./components/UserModal";
import { assignPicker, assignPacker } from "actions/alerts";

const Alerts = ({ data, assignPicker, assignPacker }) => {
  const [assign_picker_pack_id, setAssignPickerPackId] = useState(null);

  const [assign_packer_pack_id, setAssignPackerPackId] = useState(null);

  const handleAssignPicker = async (user_id) => {
    await assignPicker({
      user_id,
      gdn_pack_id: assign_picker_pack_id,
    });
    setAssignPickerPackId(null);
  };

  const handleAssignPacker = async (user_id) => {
    await assignPacker({
      user_id,
      gdn_pack_id: assign_packer_pack_id,
    });
    setAssignPackerPackId(null);
  };
  return (
    <>
      {assign_picker_pack_id && (
        <UserModal
          title="Przypisz pikera"
          handleConfirm={handleAssignPicker}
          handleClose={() => setAssignPickerPackId(null)}
        />
      )}
      {assign_packer_pack_id && (
        <UserModal
          title="Przypisz pakera"
          handleConfirm={handleAssignPacker}
          handleClose={() => setAssignPackerPackId(null)}
        />
      )}
      {data.office_pickers_gdn_packs?.length > 0 && (
        <div className="dashboard alerts">
          <div className="alerts-header">
            <span className="title">
              Paki pracowników biurowych w trakcie zbierania:
            </span>
          </div>
          <ul className="alerts-list">
            {data.office_pickers_gdn_packs.map(({ picker, id }) => (
              <li key={id}>
                <span>
                  {picker?.full_name}{" "}
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`/packing/gdn_pack/${id}`}
                  >
                    #{id}
                  </a>
                </span>
                <button
                  onClick={() => setAssignPickerPackId(id)}
                  type="button"
                  className="btn btn-sm btn-blue"
                >
                  <span>Rozwiąż problem</span>
                </button>
              </li>
            ))}
          </ul>
        </div>
      )}
      {data.office_packers_gdn_packs?.length > 0 && (
        <div className="dashboard alerts">
          <div className="alerts-header">
            <span className="title">
              Paki pracowników biurowych w trakcie pakowania:
            </span>
          </div>
          <ul className="alerts-list">
            {data.office_packers_gdn_packs.map(({ packer, id }) => (
              <li key={id}>
                <span>
                  {packer?.full_name}{" "}
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`/packing/gdn_pack/${id}`}
                  >
                    #{id}
                  </a>
                </span>

                <button
                  onClick={() => setAssignPackerPackId(id)}
                  type="button"
                  className="btn btn-sm btn-blue"
                >
                  <span>Rozwiąż problem</span>
                </button>
              </li>
            ))}
          </ul>
        </div>
      )}
      {data.absent_pickers_gdn_packs?.length > 0 && (
        <div className="dashboard alerts">
          <div className="alerts-header">
            <span className="title">
              Paki nieobecnych pracowników w trakcie zbierania:
            </span>
          </div>
          <ul className="alerts-list">
            {data.absent_pickers_gdn_packs.map(({ picker, id }) => (
              <li key={id}>
                <span>
                  {picker?.full_name}{" "}
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`/packing/gdn_pack/${id}`}
                  >
                    #{id}
                  </a>
                </span>
                <button
                  onClick={() => setAssignPickerPackId(id)}
                  type="button"
                  className="btn btn-sm btn-blue"
                >
                  <span>Rozwiąż problem</span>
                </button>
              </li>
            ))}
          </ul>
        </div>
      )}
      {data.absent_packers_gdn_packs?.length > 0 && (
        <div className="dashboard alerts">
          <div className="alerts-header">
            <span className="title">
              Paki nieobecnych pracowników w trakcie pakowania:
            </span>
          </div>
          <ul className="alerts-list">
            {data.absent_packers_gdn_packs.map(({ packer, id }) => (
              <li key={id}>
                <span>
                  {packer?.full_name}{" "}
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`/packing/gdn_pack/${id}`}
                  >
                    #{id}
                  </a>
                </span>

                <button
                  onClick={() => setAssignPackerPackId(id)}
                  type="button"
                  className="btn btn-sm btn-blue"
                >
                  <span>Rozwiąż problem</span>
                </button>
              </li>
            ))}
          </ul>
        </div>
      )}
    </>
  );
};

export default connect(
  ({ alerts: { data } }) => ({
    data,
  }),
  (dispatch) => ({
    assignPicker: (data) => dispatch(assignPicker(data)),
    assignPacker: (data) => dispatch(assignPacker(data)),
  })
)(Alerts);
