import React from "react";
import { Field, reduxForm } from "redux-form";
import { Modal } from "components";
import { Button, Input } from "expano-components";
import { updateRoom } from "actions/rooms";

import { ReactComponent as EditIcon } from "icons/edit.svg";
import { useMainList } from "components/MainList/context/provider";

const InputField = (props) => <Input {...props} />;

const ModalEditRoom = ({
  id,
  invalid,
  pristine,
  handleSubmit,
  handleClose,
}) => {
  const { refetch } = useMainList();

  const onSubmit = async (values) => {
    updateRoom(id, values)
      .then(() => {
        handleClose();
        refetch();
      })
      .catch((error) => console.log(error));
  };

  return (
    <Modal
      size="md"
      handleClose={handleClose}
      header={{
        icon: <EditIcon />,
        title: "Edycja",
      }}
      footer={
        <>
          <Button
            type="cancel"
            text="Anuluj"
            onClick={handleClose}
          />
          <button
            disabled={invalid || pristine}
            onClick={handleSubmit(onSubmit)}
            type="submit"
            className="btn btn-sm btn-green">
            <span>Zapisz</span>
          </button>
        </>
      }>
      <form noValidate>
        <table className="modal-table">
          <tbody>
            <tr>
              <td>Nazwa</td>
              <td>
                <Field
                  placeholder="Nazwa"
                  name="name"
                  component={InputField}
                />
              </td>
            </tr>
            <tr>
              <td>Skrót</td>
              <td>
                <Field
                  placeholder="Skrót"
                  name="identifier"
                  component={InputField}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </form>
    </Modal>
  );
};

const validate = (values) => {
  const errors = {};
  if (!values.name) {
    errors.name = "Pole wymagane!";
  }
  if (!values.identifier) {
    errors.identifier = "Pole wymagane!";
  }
  if (values.identifier?.length > 3) {
    errors.identifier = "Maksymalnie 3 znaki";
  }
  return errors;
};

export default reduxForm({
  form: "edit-room",
  validate,
  enableReinitialize: true,
})(ModalEditRoom);
