import styled from "styled-components";

const StyleModalContent = styled.div`
  padding: 20px;
  display: flex;
  align-items: center;
  .box-qr {
    flex-basis: 250px;
    max-width: 250px;
    padding: 50px;
    border: 1px dashed rgba(226, 229, 236, 1);
    border-radius: 4px;
  }
  .box-text {
    padding-left: 50px;
    p {
      font-size: 19px;
      font-weight: 300;
      line-height: 30px;
      margin-bottom: 24px;
      color: #595d6e;
    }
  }
`;

export default StyleModalContent;
