import React from "react";
import { connect } from "react-redux";

const ErrorsTable = ({ data }) => {
  return (
    <div className="dashboard">
      <div className="dashboard__modal-table m-t-0">
        <table>
          <tr className="main-head">
            <th width="120" />
            <th>Kompletacja</th>
            <th>Pakowanie</th>
          </tr>
          <tr className="sub-head">
            <th width="120">Data</th>
            <th>
              <table className="sub-head__table">
                <tr>
                  <th width="25%" style={{ padding: 5 }}>
                    Błędny produkt
                  </th>
                  <th width="25%" style={{ padding: 5 }}>
                    Błędna ilość produktu
                  </th>
                  <th width="25%" style={{ padding: 5 }}>
                    Niekompletny produkt
                  </th>
                  <th width="25%" style={{ padding: 5 }}>
                    Inne błąd w zamówieniu
                  </th>
                </tr>
              </table>
            </th>
            <th>
              <table className="sub-head__table with-padding">
                <tr>
                  <th width="12%" style={{ padding: 5 }}>
                    Błędny produkt
                  </th>
                  <th width="12%" style={{ padding: 5 }}>
                    Błędna ilość produktu
                  </th>
                  <th width="12%" style={{ padding: 5 }}>
                    Niekompletny produkt
                  </th>
                  <th width="12%" style={{ padding: 5 }}>
                    Żle wykonane zdjęcie
                  </th>
                  <th width="12%" style={{ padding: 5 }}>
                    Zły sposób zapakowania
                  </th>
                  <th width="12%" style={{ padding: 5 }}>
                    Podwójne COD
                  </th>
                  <th width="14%" style={{ padding: 5 }}>
                    Niedostosowanie się do uwag zamówienia
                  </th>
                  <th width="14%" style={{ padding: 5 }}>
                    Inny błąd w zamówieniu
                  </th>
                </tr>
              </table>
            </th>
          </tr>
          {Object.keys(data).map((date) => {
            const errors_data = data[date].errors;

            const getKindCount = (kind, is_created_after_send) =>
              errors_data.filter(
                (item) =>
                  is_created_after_send === item.is_created_after_send &&
                  kind === item.kind
              )?.length;

            const wrong_product_before_send_count = getKindCount(
              "wrong_product",
              false
            );

            const wrong_product_before_send_quantity_count = getKindCount(
              "wrong_product_quantity",
              false
            );

            const incomplete_product_before_send_count = getKindCount(
              "incomplete_product",
              false
            );

            const picking_other_count = getKindCount("picking_other", false);

            const wrong_product_after_send_count = getKindCount(
              "wrong_product",
              true
            );
            const wrong_product_after_send_quantity_count = getKindCount(
              "wrong_product_quantity",
              true
            );

            const incomplete_product_after_send_count = getKindCount(
              "incomplete_product",
              true
            );

            const incorrect_photo_after_send_count = getKindCount(
              "incorrect_photo",
              true
            );

            const badly_packed_after_send_count = getKindCount(
              "badly_packed",
              true
            );

            const double_cod_after_send_count = getKindCount(
              "double_cod",
              true
            );

            const skip_note_after_send_count = getKindCount("skip_note", true);

            const packing_other_after_send_count = getKindCount(
              "packing_other",
              true
            );

            return (
              <tr className="table-row">
                <td style={{ textAlign: "center" }}>
                  <strong>{date}</strong>
                </td>
                <td>
                  <table>
                    <tr>
                      <td
                        width="25%"
                        style={{
                          padding: 5,
                          backgroundColor:
                            wrong_product_before_send_count > 0
                              ? "rgb(252 142 142)"
                              : "#fff",
                        }}
                      >
                        <strong>
                          {wrong_product_before_send_count || "-"}
                        </strong>
                      </td>
                      <td
                        width="25%"
                        style={{
                          padding: 5,
                          backgroundColor:
                            wrong_product_before_send_quantity_count > 0
                              ? "rgb(252 142 142)"
                              : "#fff",
                        }}
                      >
                        <strong>
                          {wrong_product_before_send_quantity_count || "-"}
                        </strong>
                      </td>
                      <td
                        width="25%"
                        style={{
                          padding: 5,
                          backgroundColor:
                            incomplete_product_before_send_count > 0
                              ? "rgb(252 142 142)"
                              : "#fff",
                        }}
                      >
                        <strong>
                          {incomplete_product_before_send_count || "-"}
                        </strong>
                      </td>
                      <td
                        width="25%"
                        style={{
                          padding: 5,
                          backgroundColor:
                            picking_other_count > 0
                              ? "rgb(252 142 142)"
                              : "#fff",
                        }}
                      >
                        <strong>{picking_other_count || "-"}</strong>
                      </td>
                    </tr>
                  </table>
                </td>
                <td>
                  <table>
                    <tr>
                      <td
                        width="12%"
                        style={{
                          padding: 5,
                          backgroundColor:
                            wrong_product_after_send_count > 0
                              ? "rgb(252 142 142)"
                              : "#fff",
                        }}
                      >
                        <strong>{wrong_product_after_send_count || "-"}</strong>
                      </td>
                      <td
                        width="12%"
                        style={{
                          padding: 5,
                          backgroundColor:
                            wrong_product_after_send_quantity_count > 0
                              ? "rgb(252 142 142)"
                              : "#fff",
                        }}
                      >
                        <strong>
                          {wrong_product_after_send_quantity_count || "-"}
                        </strong>
                      </td>
                      <td
                        width="12%"
                        style={{
                          padding: 5,
                          backgroundColor:
                            incomplete_product_after_send_count > 0
                              ? "rgb(252 142 142)"
                              : "#fff",
                        }}
                      >
                        <strong>
                          {incomplete_product_after_send_count || "-"}
                        </strong>
                      </td>
                      <td
                        width="12%"
                        style={{
                          padding: 5,
                          backgroundColor:
                            incorrect_photo_after_send_count > 0
                              ? "rgb(252 142 142)"
                              : "#fff",
                        }}
                      >
                        <strong>
                          {incorrect_photo_after_send_count || "-"}
                        </strong>
                      </td>
                      <td
                        width="12%"
                        style={{
                          padding: 5,
                          backgroundColor:
                            badly_packed_after_send_count > 0
                              ? "rgb(252 142 142)"
                              : "#fff",
                        }}
                      >
                        <strong>{badly_packed_after_send_count || "-"}</strong>
                      </td>
                      <td
                        width="12%"
                        style={{
                          padding: 5,
                          backgroundColor:
                            double_cod_after_send_count > 0
                              ? "rgb(252 142 142)"
                              : "#fff",
                        }}
                      >
                        <strong>{double_cod_after_send_count || "-"}</strong>
                      </td>
                      <td
                        width="14%"
                        style={{
                          padding: 5,
                          backgroundColor:
                            skip_note_after_send_count > 0
                              ? "rgb(252 142 142)"
                              : "#fff",
                        }}
                      >
                        <strong>{skip_note_after_send_count || "-"}</strong>
                      </td>
                      <td
                        width="14%"
                        style={{
                          padding: 5,
                          backgroundColor:
                            packing_other_after_send_count > 0
                              ? "rgb(252 142 142)"
                              : "#fff",
                        }}
                      >
                        <strong>{packing_other_after_send_count || "-"}</strong>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
            );
          })}
        </table>
      </div>
    </div>
  );
};

export default connect(({ packer_dashboard: { data } }) => ({
  data,
}))(ErrorsTable);
