import styled from "styled-components";

const StyledList = styled.ul`
  margin: 30px 0;
  .list-item {
    display: flex;
    align-items: center;
    position: relative;
    margin-bottom: 20px;
    padding: 20px 30px;
    border-radius: 4px;
    background-color: #fff;
    overflow: hidden;
    box-shadow: 0px 0px 13px rgba(0, 0, 0, 0.05);
    &.success,
    &.warning,
    &.error,
    &.cancel {
      &::before {
        content: "";
        display: block;
        width: 9px;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
      }
    }
    &.success::before {
      background-color: #0abb87;
    }
    &.warning::before {
      background-color: #ffb822;
    }
    &.error::before {
      background-color: #fd397a;
    }
    &.cancel::before {
      background-color: #000;
    }
    &__title {
      display: flex;
      align-items: center;
      flex-basis: 180px;
      max-width: 180px;
      padding-right: 20px;
      .box-letter {
        text-align: center;
        font-size: 30px;
        line-height: 38px;
        margin-right: 20px;
        color: #fd397a;
      }
      span {
        font-size: 13px;
        line-height: 20px;
        font-weight: 500;
        color: #595d6e;
      }
    }
    &__products {
      flex: 1;
      .single-product {
        max-width: 650px;
        display: flex;
        align-items: center;
        min-height: 30px;
        &:not(:last-child) {
          border-bottom: 1px solid #ebedf2;
        }
        &__sku {
          flex-basis: 110px;
          max-width: 110px;
          span {
            font-weight: 300;
          }
        }
        &__count {
          flex-basis: 60px;
          max-width: 60px;
          text-align: center;
          span {
            font-weight: 300;
          }
        }
        &__image {
          flex-basis: 30px;
          max-width: 30px;
          font-size: 0;
          line-height: 0;
          img {
            max-width: 100%;
            height: 30px;
            font-size: 0;
          }
        }
        &__name {
          padding-left: 10px;
          flex: 1;
          p {
            font-weight: 300;
          }

          .product-info {
            margin-top: -3px;
            display: flex;
            align-items: center;
            span {
              font-size: 11px;
              line-height: 20px;
              font-weight: 300;
              strong {
                font-weight: 600;
              }
            }
            svg {
              margin-right: 6px;
            }
            &.danger {
              span {
                color: #fd397a;
              }
            }
            &.warning {
              span {
                color: #eb6614;
              }
            }
          }
        }
      }
    }
    &__status {
      text-align: center;
      max-width: 300px;
      .badge {
        display: inline-block;
        text-align: center;
        border-radius: 2px;
        padding: 1px 15px;
        font-size: 0;
        line-height: 0;
        &.black {
          background-color: #000000;
        }
        &.success {
          background-color: #0abb87;
        }
        &.error {
          background-color: #fd397a;
        }
        &.warning {
          background-color: #ffb822;
        }
        span {
          font-size: 11px;
          line-height: 18px;
          font-weight: 300;
          white-space: nowrap;
          color: #ffffff;
        }
      }
      .error-wrapper {
        display: flex;
        flex-direction: column;
        .error-info {
          margin-top: 5px;
          font-size: 11px;
          line-height: 18px;
          font-weight: 300;
          color: #494b74;
        }
      }
    }
  }
`;

export default StyledList;
