export const warehouse_task_options = [
  {
    label: "Inwentaryzacja",
    value: "WarehouseTask::InventarizationTask",
  },
];

export const warehouse_task_status_options = [
  {
    label: "W trakcie",
    value: 0,
  },
  {
    label: "Zakończone",
    value: 1,
  },
  {
    label: "Anulowane",
    value: 2,
  },
];

export const getWarehouseTaskName = (type) =>
  warehouse_task_options.find(({ value }) => value === type)
    ?.label || "-";

export const getWarehouseTaskStatus = (status) => {
  switch (status) {
    case "pending":
      return {
        status_text: "W trakcie",
        status: "info",
      };
    case "completed":
      return {
        status_text: "Zakończone",
        status: "success",
      };
    case "canceled":
      return {
        status_text: "Anulowane",
        status: "danger",
      };
    default:
      return { status_text: "Brak", status: "dark" };
  }
};
