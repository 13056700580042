import React from "react";
import { Field, formValueSelector, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { Input, Button, Checkbox } from "expano-components";
import { AsyncSelect, Modal, CreatableSelect } from "components";
import { updateProductSupplier } from "actions/products";
import getSuppliers from "../const/getSuppliers";

const InputField = (props) => <Input {...props} />;
const CheckField = (props) => <Checkbox {...props} />;

const EditSupplier = ({
  default_select_supplier_value,
  default_select_supplier_package_quantity_list_value,
  id,
  updateProductSupplier,
  handleSubmit,
  pristine,
  handleClose,
  change,
  is_product_decomposed,
  is_product_composed,
}) => {
  const submit = async (values) => {
    await updateProductSupplier(id, values);
    handleClose();
  };

  return (
    <Modal
      handleClose={handleClose}
      header={{ title: "Edytuj dostawcę" }}
      footer={
        <>
          <Button type="cancel" onClick={handleClose} />
          <Button
            type="save"
            text="Zapisz"
            disabled={pristine}
            onClick={handleSubmit(submit)}
          />
        </>
      }
    >
      <form noValidate onSubmit={handleSubmit(submit)}>
        <AsyncSelect
          label="Dostawca"
          initValue={default_select_supplier_value}
          getData={(q) => getSuppliers(q)}
          onChange={({ value }) => {
            change("supplier_id", value);
          }}
        />
        <Field label="Kod" name="code" component={InputField} labelAside />
        <div style={{ paddingLeft: 70 }}>
          <Field name="is_master" label="Główny" component={CheckField} />
        </div>
        <Field
          label="Jednostka dostawcy"
          name="supplier_custom_unit"
          component={InputField}
        />
        <Field
          name="is_product_decomposed"
          label="Produkt podlega dekompletacji"
          component={CheckField}
        />
        {is_product_decomposed && (
          <Field
            label="Ilość"
            name="decomposed_quantity"
            component={InputField}
            labelAside
          />
        )}
        <Field
          name="is_product_composed"
          label="Produkt podlega kompletacji"
          component={CheckField}
        />
        {is_product_composed && (
          <Field
            label="Ilość"
            name="composed_quantity"
            component={InputField}
            labelAside
          />
        )}
        <CreatableSelect
          onChange={(value) => change("supplier_package_quantity_list", value)}
          label="Wielokrotności paczek"
          defaultValue={default_select_supplier_package_quantity_list_value}
        />
      </form>
    </Modal>
  );
};

const validate = (values) => {
  const errors = {};

  if (!values.code) {
    errors.code = "Pole wymagane";
  }
  if (values.is_product_decomposed && values.is_product_composed) {
    errors.is_product_decomposed = "Możesz wybrać tylko jedną opcje";
    errors.is_product_composed = "Możesz wybrać tylko jedną opcje";
  }
  if (values.is_product_decomposed && !values.decomposed_quantity) {
    errors.decomposed_quantity = "Pole wymagane";
  }
  if (values.is_product_composed && !values.composed_quantity) {
    errors.decomposed_quantity = "Pole wymagane";
  }
  return errors;
};

const form_name = "product-edit-supplier";
const selector = formValueSelector(form_name);

export default connect(
  (state) => ({
    is_product_decomposed: selector(state, "is_product_decomposed"),
    is_product_composed: selector(state, "is_product_composed"),
  }),
  (dispatch) => ({
    updateProductSupplier: (id, data) =>
      dispatch(updateProductSupplier(id, data)),
  })
)(
  reduxForm({
    form: form_name,
    validate,
    enableReinitialize: true,
  })(EditSupplier)
);
