const initialState = {
  status: "invalid",
  data: {
    completed_histogram: {
      data: null,
      packer: null,
      type: "hour",
      range: "today",
      all_packers: []
    },
  },
  timestamp: Date.now(),
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "DASHBOARD_UPDATING":
      return {
        ...state,
        status: "updating",
      };
    case "DASHBOARD_LOADING":
      return {
        ...state,
        status: "loading",
      };
    case "DASHBOARD_SUCCESS":
      return {
        ...state,
        status: "success",
        timestamp: Date.now(),
        data: action.data,
      };
    case "DASHBOARD_FAILURE":
      return {
        ...state,
        status: "failure",
      };
    default:
      return state;
  }
};
