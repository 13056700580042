import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Main } from "layouts";
import { getMissings } from "actions/missings";
import LineItem from "./components/LineItem";

import { ReactComponent as MissingIcon } from "icons/missing.svg";

const MissingsList = ({ missings, getMissings }) => {
  useEffect(() => {
    getMissings();
  }, []);

  const { data } = missings;
  return (
    <Main
      is_loading={[
        "invalid",
        "loading",
        "failure",
      ].includes(missings.status)}
      page={{
        name: "Zgłoszone braki",
        icon: <MissingIcon />,
        breadcrumbs: [{ name: "Zgłoszone braki" }],
      }}
    >
      <div className="dashboard">
        {data?.length > 0 ? (
          <div className="table-missings">
            <div className="product-group-container">
              <ul className="products-list">
                <li>
                  <table className="table">
                    <thead className="table__header table-head">
                      <tr>
                        <th width="60"></th>
                        <th width="100">Brakująca ilość</th>
                        <th width="60"></th>
                        <th width="120">SKU</th>
                        <th width="200">Nazwa</th>
                        <th width="200">Magazyn</th>
                        <th width="200">
                          Ostatnie zgłoszenia
                        </th>
                      </tr>
                    </thead>
                  </table>
                </li>
                {data.map((props, index) => (
                  <LineItem
                    key={index}
                    {...props}
                    getMissings={getMissings}
                  />
                ))}
              </ul>
            </div>
          </div>
        ) : (
          <div style={{ padding: 20 }}>
            <p>Brak</p>
          </div>
        )}
      </div>
    </Main>
  );
};

const mapStateToProps = ({ missings }) => ({ missings });

const mapDispatchToProps = (dispatch) => ({
  getMissings: () => dispatch(getMissings()),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MissingsList);
