import React from "react";

const InputPlaceholder = ({
  prefix,
  postfix,
  label = null,
  name,
  value,
}) => (
  <div className="form-group">
    <div className="inp-wrapper">
      {Boolean(label) && (
        <label htmlFor={name} className="label-form">
          {label}
        </label>
      )}
      <div className="inp-group">
        {Boolean(prefix) && (
          <span className="form-span prefix">{prefix}</span>
        )}
        <input
          className="input-disabled"
          id={name}
          name={name}
          defaultValue={value}
          type="text"
        />
        {Boolean(postfix) && (
          <span className="form-span postfix">
            {postfix}
          </span>
        )}
      </div>
    </div>
  </div>
);

export default InputPlaceholder;
