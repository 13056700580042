import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Button, ModalDelete } from "expano-components";
import { Main } from "layouts";
import { getPackage, deletePackage, exportPackage } from "actions/_package";

import { ReactComponent as PackageIcon } from "icons/product.svg";
import { ReactComponent as InfoIcon } from "icons/info.svg";
import moment from "moment";
import { getCourierByPackageType } from "utils/package_type_options";

const PackageEdit = ({
  history,
  match: {
    params: { id },
  },
  _package,
  getPackage,
}) => {
  const [is_exporting, setExporting] = useState(false);
  const [show_delete_modal, handleShowDeleteModal] = useState(false);

  useEffect(() => {
    getPackage(id);
  }, [id]);

  if (_package.status === "failure") {
    return <p>Error</p>;
  }

  const { data } = _package;

  const handleExportWaybill = async () => {
    try {
      setExporting(true);
      await exportPackage(id);
    } catch (error) {
    } finally {
      setExporting(false);
    }
  };

  return (
    <Main
      is_loading={["invalid", "loading"].includes(_package.status)}
      page={{
        name: data?.name,
        icon: <PackageIcon />,
        breadcrumbs: [
          {
            name: "Paczki",
            path: "/packages",
          },
          { name: data?.courier_number },
        ],
        buttons: (
          <Button
            type="delete"
            text="Usuń paczkę"
            onClick={() => handleShowDeleteModal(true)}
          />
        ),
      }}
    >
      {show_delete_modal && (
        <ModalDelete
          handleDelete={() =>
            deletePackage(id).then(() => history.push("/packages"))
          }
          handleClose={() => handleShowDeleteModal(false)}
          name={`paczkę ${data?.courier_number}`}
        />
      )}
      <div className="dashboard-wrapper-flex">
        <div className="column-half">
          <div className="dashboard">
            <header className="dashboard__header">
              <div className="icon__container">
                <PackageIcon />
              </div>
              <h2 className="dashboard__title heading">
                {data?.courier_number}
              </h2>
            </header>
            <div className="dashboard__info">
              <div className="box">
                <p className="title">Numer nadania</p>
                <p>{data?.courier_number}</p>
              </div>
              <div className="box">
                <p className="title">Kurier</p>
                <p>{getCourierByPackageType(data?.type)}</p>
              </div>
              <div className="box">
                <p className="title">Data nadania</p>
                <p>
                  {data?.created_at
                    ? moment(data.created_at).format("DD.MM.YYYY HH:mm")
                    : "-"}
                </p>
              </div>
              <div className="box">
                <p className="title">Data exportu do OMS</p>
                <p>
                  {data?.exported_to_oms_at
                    ? moment(data.exported_to_oms_at).format("DD.MM.YYYY HH:mm")
                    : "-"}
                </p>
              </div>
            </div>
          </div>
          <div className="dashboard">
            <header className="dashboard__header dashboard__header--edit">
              <div className="icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                >
                  <path fill="none" d="M0,0H20V20H0Z" />
                  <path
                    fill="#5d78ff"
                    d="M1.423,6.423A.833.833,0,1,1,.244,5.244l5-5A.833.833,0,0,1,6.4.219l5,4.583A.833.833,0,1,1,10.27,6.031L5.858,1.987Z"
                    transform="translate(10.833 4.167) rotate(90)"
                  />
                  <rect
                    fill="#5d78ff"
                    opacity="0.3"
                    width="8.333"
                    height="1.667"
                    rx="0.833"
                    transform="translate(10 14.167)"
                  />
                </svg>
              </div>
              <h2 className="dashboard__title heading">Status paczki</h2>
            </header>
            {data?.package_statuses?.length > 0 && (
              <ul className="logs-list">
                {data.package_statuses.map(
                  ({ id, date, title, description, status }) => (
                    <li className="logs-list__item" key={id}>
                      <p className="title">
                        {date ? moment(date).format("DD-MM-YYYY HH:mm") : ""}{" "}
                        {title || status}
                      </p>
                      <p>{description || "-"}</p>
                    </li>
                  )
                )}
              </ul>
            )}
          </div>
        </div>
        <div className="column-half">
          {data?.gdn_id && (
            <div className="dashboard m-b-10">
              <header className="dashboard__header dashboard__header--edit">
                <div className="icon__container">
                  <InfoIcon />
                </div>
                <h2 className="dashboard__title heading">Zamówienie</h2>
                {data?.exported_to_oms_at ? (
                  <div className={`status-label success`}>
                    <span>Wyeksportowano do OMS</span>
                  </div>
                ) : (
                  <div className="status-label danger">
                    <span>Brak etykiety na OMS</span>
                  </div>
                )}
              </header>
              <ul className="dashboard__list">
                <li className="dashboard__list-item jc-sb">
                  <Link to={`/gdns/${data.gdn_id}`}>{data.gdn_id}</Link>
                  <button
                    disabled={is_exporting}
                    className="btn btn-blue btn-sm"
                    onClick={handleExportWaybill}
                  >
                    <span>
                      {is_exporting ? "Eksportowanie..." : "Eksportuj"}
                    </span>
                  </button>
                </li>
              </ul>
            </div>
          )}
          <div className="dashboard m-b-10">
            <header className="dashboard__header dashboard__header--edit">
              <div className="icon__container">
                <PackageIcon />
              </div>
              <h2 className="dashboard__title heading">Podpaczki</h2>
            </header>
            <table className="table supply-table">
              <thead className="table__header table-head">
                <tr>
                  <th>Rozmiar</th>
                  <th>Długość</th>
                  <th>Szerokość</th>
                  <th>Głębokość</th>
                  <th>Waga</th>
                  <th>Waga gabarytowa</th>
                </tr>
              </thead>
              <tbody className="table__body table-body">
                {data?.package_boxes?.map(
                  ({
                    id,
                    size,
                    width,
                    height,
                    depth,
                    weight,
                    gauge_weight,
                  }) => (
                    <tr key={id}>
                      <td>{size || "-"}</td>
                      <td>{width} cm</td>
                      <td>{height} cm</td>
                      <td>{depth} cm</td>
                      <td>{weight} kg</td>
                      <td>{gauge_weight || "-"} kg</td>
                    </tr>
                  )
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </Main>
  );
};

export default connect(
  ({ _package }) => ({ _package }),
  (dispatch) => ({
    getPackage: (id) => dispatch(getPackage(id)),
  })
)(PackageEdit);
