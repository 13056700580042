import React, { useEffect } from "react";
import QRCode from "react-qr-code";
import { useCable } from "providers/cable";
import { ActionCableConsumer } from "react-actioncable-provider";
import { withRouter } from "react-router";

import { Modal } from "components";
import StyleModalContent from "./styles";

const ProductsReturnModal = ({
  gdn_id,
  history,
  handleClose,
}) => {
  const { cable } = useCable();

  useEffect(() => {
    const cable_channel = cable.subscriptions.create({
      channel: "PackerChannel",
    });

    cable_channel.perform("init_gdn_returning", {
      gdn_id,
    });

    return () => {
      if (cable_channel) {
        cable_channel.unsubscribe();
      }
    };
  }, []);

  return (
    <>
      <ActionCableConsumer
        channel={{
          channel: 'PackerChannel',
          gdn_id,
        }}
        onReceived={(res) => {
          switch (res.event) {
            case "finish_gdn_returning":
              handleClose();
              history.push("/packing");
              break;
            default:
              break;
          }
        }}
      />

      <Modal
        header={{ title: "Roznieś produkty z zamówienia" }}
        size="lg"
        handleClose={handleClose}>
        <StyleModalContent>
          <div className="box-qr">
            <QRCode
              size={150}
              value={`gdn_returning|${gdn_id}`}
            />
          </div>
          <div className="box-text">
            <p>
              Zeskanuj QR kod za pomocą telefonu <br /> i
              roznieś produkty.
            </p>
          </div>
        </StyleModalContent>
      </Modal>
    </>
  );
};

export default withRouter(ProductsReturnModal);
