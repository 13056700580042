import { client_v2 } from "utils/api";

const getWarehouseRooms = (query, warehouse_id) =>
  new Promise((resolve, reject) =>
    client_v2
      .get(
        `/warehouse_rooms?context=list&sort[order]=asc&sort[column]=position&q[warehouse_id_eq]=${warehouse_id}&page=1&per_page=50${
          query
            ? `&q[name_or_identifier_matches]=%25${query}%25`
            : ""
        }`
      )
      .then(({ data }) =>
        resolve(
          data?.map(({ id, name }) => ({
            label: name,
            value: id,
          }))
        )
      )
      .catch((err) => reject(err))
  );

export default getWarehouseRooms;
