import { client_v2 } from "utils/api";

const getWarehouseAisles = (query, warehouse_room_id) =>
  new Promise(async (resolve, reject) => {
    client_v2
      .get(
        `/warehouse_aisles?context=list&sort[order]=asc&sort[column]=position&q[warehouse_room_id_eq]=${warehouse_room_id}&page=1&per_page=50${
          query ? `&q[name_matches]=%25${query}%25` : ""
        }`
      )
      .then(({ data }) =>
        resolve(
          data?.map(({ id, name }) => ({
            label: name,
            value: id,
          }))
        )
      )
      .catch((err) => reject(err));
  });

export default getWarehouseAisles;
