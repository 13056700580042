import React, { useState, useEffect, useRef } from "react";
import { Field, reduxForm } from "redux-form";
import { Button, Input } from "expano-components";

import { ReactComponent as WarningIcon } from "icons/warning.svg";
import { Modal } from "components";

const InputField = (props) => <Input {...props} />;

const secret_password = "wms_admin!#";

const validate = (values, { product }) => {
  const errors = {};

  if (values?.ean !== product.ean) {
    errors.ean = "Ean musi się zgadzać";
  }
  if (values?.pass !== secret_password) {
    errors.pass = "Nieprawidłowe hasło";
  }
  if (
    values?.pass === secret_password ||
    values?.ean === product.ean
  ) {
    return {};
  }
  return errors;
};

let timeout;

const PackConditionallyModal = ({
  handleSubmit,
  handleClose,
  handleConfirm,
  pristine,
}) => {
  const ref = useRef(null);
  const [is_loaded, setLoaded] = useState(false);

  useEffect(() => {
    const input = ref.current.querySelector("input");
    if (input) {
      input.focus();
    }
    timeout = setTimeout(() => {
      setLoaded(true);
    }, 1000);
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  const onSubmit = () => {
    handleConfirm();
    handleClose();
  };
  return (
    <Modal
      handleClose={handleClose}
      header={{ title: "Uwaga", icon: <WarningIcon /> }}
      footer={
        <>
          <Button
            type="cancel"
            text="Anuluj"
            onClick={handleClose}
          />
          <button
            disabled={pristine}
            type="submit"
            onClick={handleSubmit(onSubmit)}
            className="btn btn-sm btn-green">
            <span>Potwierdź</span>
          </button>
        </>
      }>
      <form
        autoComplete="off"
        noValidate
        onSubmit={handleSubmit(onSubmit)}
        ref={ref}>
        <input
          style={{ display: "none" }}
          type="email"
          name="email"
        />
        <input
          style={{ display: "none" }}
          type="password"
          name="password"
        />
        <p>
          Czy masz problem przy skanowaniu kodu kreskowego i
          chciałbyś odblokować ręczne wyszukiwanie?
        </p>
        <p style={{ margin: "10px 0" }}>
          <strong>Przepisz kod ean</strong>
        </p>
        <Field
          type="text"
          id="ean"
          name="ean"
          component={InputField}
        />
        <p style={{ margin: "10px 0" }}>
          <strong>lub wpisz hasło</strong>
        </p>
        <Field
          type={is_loaded ? "password" : "text"}
          id="pass"
          name="pass"
          component={InputField}
        />
      </form>
    </Modal>
  );
};

export default reduxForm({
  form: "gdn-line-pack-conditionally",
  validate,
  enableReinitialize: true,
})(PackConditionallyModal);
