import React from "react";
import {
  NotFound,
  CompletingPacks,
  CompletingGdns,
  CompletingPackGdn,
  PackerDashboard,
} from "../../pages";

import { Switch, Route } from "react-router-dom";

const PackerRoutes = () => {
  return (
    <Switch>
      <Route exact={true} path="/" component={PackerDashboard} />
      <Route exact={true} path="/packing" component={CompletingPacks} />
      <Route
        exact={true}
        path="/packing/gdn_pack/:pack_id"
        component={CompletingGdns}
      />
      <Route
        exact={true}
        path="/packing/gdn/:id"
        component={CompletingPackGdn}
      />
      <Route component={NotFound} />
    </Switch>
  );
};

export default PackerRoutes;
