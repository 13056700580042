import { toastr } from "react-redux-toastr";
import { client_v2, stringifyQuery } from "utils/api";

export const toggleFilters = () => (dispatch) =>
  dispatch({ type: "WAREHOUSE_TASKS_TOGGLE_FILTERS" });

export const resetFilters = () => (dispatch) =>
  dispatch({ type: "WAREHOUSE_TASKS_RESET_FILTERS" });

export const filterWarehouseTasks = (query) => async (
  dispatch
) =>
  dispatch({
    type: "WAREHOUSE_TASKS_SET_FILTERS_QUERY",
    query,
  });

export const getWarehouseTasks = ({
  page = 1,
  per_page = 50,
  ...rest
}) => async (dispatch, getState) => {
  try {
    const {
      status,
      filters: { query },
    } = getState().warehouse_tasks;

    dispatch({
      type:
        status === "invalid"
          ? "WAREHOUSE_TASKS_LOADING"
          : "WAREHOUSE_TASKS_SEARCHING",
    });

    const { data, meta } = await client_v2.get(
      `/warehouse_tasks?${stringifyQuery({
        context: "ui_index",
        ...rest,
        page,
        per_page,
        ...query,
      })}`
    );

    dispatch({
      type: "WAREHOUSE_TASKS_SUCCESS",
      data,
      meta,
    });
  } catch (error) {
    dispatch({ type: "WAREHOUSE_TASKS_FAILURE" });
    toastr.error(
      "Błąd",
      "Wystąpił błąd podczas pobierania"
    );
  }
};
