import React, { useState } from "react";
import { Button } from "expano-components";
import { AsyncSelect, Modal } from "components";
import { client_v2 } from "utils/api";

const getActiveUsers = (q) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await client_v2.get(
        `/packing_station_users/active_users?per_page=100${
          q
            ? `&q[user_first_name_or_user_last_name_matches]=%25${q}%25`
            : ""
        }`
      );
      resolve(
        data.map(({ user }) => ({
          label: user.full_name,
          value: user.id,
        }))
      );
    } catch (error) {
      console.log(error);
      reject([]);
    }
  });
};

const UserModal = ({
  title,
  handleClose,
  handleConfirm,
}) => {
  const [user_id, setUser] = useState(null);
  return (
    <Modal
      handleClose={handleClose}
      header={{
        title,
      }}
      footer={
        <>
          <Button type="cancel" onClick={handleClose} />
          <button
            type="button"
            disabled={!user_id}
            className="btn btn-sm btn-green"
            onClick={() => handleConfirm(user_id)}>
            <span>Przypisz</span>
          </button>
        </>
      }>
      <AsyncSelect
        getData={(q) => getActiveUsers(q)}
        onChange={({ value }) => setUser(value)}
      />
    </Modal>
  );
};

export default UserModal;
