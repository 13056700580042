import React from "react";
import { Chart } from "react-google-charts";

const createChartData = (data, renderDataItem,) => {
  const chart_data = [["Element", "Ilość"]];
  try {
    if (!data) return chart_data;
    const keys = Object.keys(data);
    keys.forEach((key) => {
      chart_data.push(
        renderDataItem({ key, value: data[key] })
      );
    });
    return chart_data;
  } catch (error) {
    console.log(error);
    return chart_data;
  }
};

const ChartComponent = ({
  data,
  label,
  renderDataItem,
}) => {
  const chart_data = createChartData(data, renderDataItem);
  return (
    <div className="chart-item">
      <p className="form-label">{label}</p>
      <Chart
        options={{
          colors: ["#5D78FF"],
          chartArea: { width: "85%" },
          vAxis: { minValue: 0, maxValue: 15 },
          hAxis: {
            textStyle: {
              fontSize: 13,
              color: "#595D6E",
            },
          },
          legend: { position: "none" },
        }}
        chartType="ColumnChart"
        loader={null}
        width="400px"
        height="250px"
        data={chart_data}
      />
    </div>
  );
};

export default ChartComponent;
