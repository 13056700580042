import React, { useEffect, useState } from "react";
import { reduxForm, formValueSelector } from "redux-form";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Button, Select } from "expano-components";

import { AsyncSelect, Loader, Modal } from "components";
import { updateMainLocation } from "actions/product_main_locations";
import { getWarehousePosition } from "actions/warehouse_position";

import AislePreview from "../components/AislePreview";

import getWarehouses from "../const/getWarehouses";
import getWarehouseRooms from "../const/getWarehouseRooms";
import getWarehouseAisles from "../const/getWarehouseAisles";
import getWarehouseRacks from "../const/getWarehouseRacks";
import getWarehouseShelfs from "../const/getWarehouseShelfs";
import getWarehousePositions from "../const/getWarehousePositions";
import clearFields from "../const/clearFields";

import { ReactComponent as LocationIcon } from "icons/location.svg";

const EditLocation = ({
  id,
  initial_warehouse_position_id,
  warehouse_position,
  getWarehousePosition,
  handleClose,
  handleSubmit,
  change,
  updateMainLocation,
  pristine,
  warehouse_id,
  warehouse_room_id,
  warehouse_aisle_id,
  warehouse_rack_id,
  warehouse_shelf_id,
  warehouse_position_id,
}) => {
  const [
    warehouse_aisle_name,
    setWarehouseAisleName,
  ] = useState("");

  useEffect(() => {
    getWarehousePosition(
      initial_warehouse_position_id
    ).then((data) =>
      setWarehouseAisleName(
        data?.warehouse_shelf?.warehouse_rack
          ?.warehouse_aisle?.name
      )
    );
  }, [initial_warehouse_position_id]);

  const onSubmit = (values) =>
    updateMainLocation(id, values).then(() =>
      handleClose()
    );

  if (
    ["invalid", "loading"].includes(
      warehouse_position.status
    )
  ) {
    return (
      <Modal
        size="md"
        handleClose={handleClose}
        header={{
          icon: <LocationIcon />,
          title: "Trwa pobieranie",
        }}
        footer={
          <>
            <Button type="cancel" onClick={handleClose} />
          </>
        }>
        <div style={{ textAlign: "center", padding: 20 }}>
          <Loader />
        </div>
      </Modal>
    );
  }
  if (["failure"].includes(warehouse_position.status)) {
    return (
      <Modal
        size="md"
        handleClose={handleClose}
        header={{
          icon: <LocationIcon />,
          title: "Wystąpił błąd",
        }}
        footer={
          <>
            <Button type="cancel" onClick={handleClose} />
          </>
        }>
        <div className="dashboard-wrapper-flex">
          <p>
            Nie udało się pobrać informacji o lokalizacji
          </p>
        </div>
      </Modal>
    );
  }

  return (
    <Modal
      size="md"
      handleClose={handleClose}
      header={{
        icon: <LocationIcon />,
        title: "Edytuj lokalizację",
      }}
      footer={
        <>
          <Button type="cancel" onClick={handleClose} />
          <Button
            type="save"
            text="Zapisz"
            disabled={
              pristine || !Boolean(warehouse_position_id)
            }
            onClick={handleSubmit(onSubmit)}
          />
        </>
      }>
      <div className="dashboard-wrapper-flex">
        <div className="column-half">
          <form
            noValidate
            onSubmit={handleSubmit(onSubmit)}>
            <AsyncSelect
              label="Magazyn"
              isDisabled={false}
              getData={getWarehouses}
              initValue={
                warehouse_position?.options?.warehouse
              }
              onChange={({ value }) => {
                clearFields(change, "warehouse_id");
                setTimeout(() => {
                  change("warehouse_id", value);
                }, 200);
              }}
            />
            {!!warehouse_id ? (
              <AsyncSelect
                label="Pomieszczenie"
                getData={(q) =>
                  getWarehouseRooms(q, warehouse_id)
                }
                initValue={
                  !!warehouse_room_id &&
                  warehouse_position?.options
                    ?.warehouse_room
                }
                onChange={({ value }) => {
                  clearFields(change, "warehouse_room_id");
                  setTimeout(() => {
                    change("warehouse_room_id", value);
                  }, 200);
                }}
              />
            ) : (
              <Select
                label="Pomieszczenie"
                isDisabled={true}
              />
            )}

            {!!warehouse_room_id ? (
              <AsyncSelect
                label="Alejka"
                getData={(q) =>
                  getWarehouseAisles(q, warehouse_room_id)
                }
                initValue={
                  !!warehouse_aisle_id &&
                  warehouse_position?.options
                    ?.warehouse_aisle
                }
                onChange={({ value, label }) => {
                  clearFields(change, "warehouse_aisle_id");
                  setWarehouseAisleName(label);
                  setTimeout(() => {
                    change("warehouse_aisle_id", value);
                  }, 200);
                }}
              />
            ) : (
              <Select label="Alejka" isDisabled={true} />
            )}
            {!!warehouse_aisle_id ? (
              <AsyncSelect
                label="Regał"
                getData={(q) =>
                  getWarehouseRacks(q, warehouse_aisle_id)
                }
                initValue={
                  !!warehouse_aisle_id &&
                  warehouse_position?.options
                    ?.warehouse_rack
                }
                onChange={({ value }) => {
                  clearFields(change, "warehouse_rack_id");
                  setTimeout(() => {
                    change("warehouse_rack_id", value);
                  }, 200);
                }}
              />
            ) : (
              <Select label="Regał" isDisabled={true} />
            )}
            {!!warehouse_rack_id ? (
              <AsyncSelect
                label="Półka"
                getData={(q) =>
                  getWarehouseShelfs(q, warehouse_rack_id)
                }
                initValue={
                  !!warehouse_rack_id &&
                  warehouse_position?.options
                    ?.warehouse_shelf
                }
                onChange={({ value }) => {
                  clearFields(change, "warehouse_shelf_id");
                  setTimeout(() => {
                    change("warehouse_shelf_id", value);
                  }, 200);
                }}
              />
            ) : (
              <Select label="Półka" isDisabled={true} />
            )}
            {!!warehouse_shelf_id ? (
              <AsyncSelect
                label="Miejsce"
                getData={(q) =>
                  getWarehousePositions(
                    q,
                    warehouse_shelf_id
                  )
                }
                initValue={
                  !!warehouse_position_id &&
                  warehouse_position?.options
                    ?.warehouse_position
                }
                onChange={({ value }) => {
                  change("warehouse_position_id", value);
                }}
              />
            ) : (
              <Select label="Miejsce" isDisabled={true} />
            )}
          </form>
        </div>
        <div className="column-half">
          <AislePreview
            warehouse_position_id={warehouse_position_id}
            aisle_name={warehouse_aisle_name}
          />
        </div>
      </div>
    </Modal>
  );
};

const selector = formValueSelector("edit-product-location");

const mapStateToProps = (state) => ({
  warehouse_position: state.warehouse_position,
  warehouse_id: selector(state, "warehouse_id"),
  warehouse_room_id: selector(state, "warehouse_room_id"),
  warehouse_aisle_id: selector(state, "warehouse_aisle_id"),
  warehouse_rack_id: selector(state, "warehouse_rack_id"),
  warehouse_shelf_id: selector(state, "warehouse_shelf_id"),
  warehouse_position_id: selector(
    state,
    "warehouse_position_id"
  ),
  initialValues: {
    warehouse_id:
      state.warehouse_position.data.warehouse.id,
    warehouse_room_id:
      state.warehouse_position.data.warehouse_room.id,
    warehouse_aisle_id:
      state.warehouse_position.data.warehouse_aisle.id,
    warehouse_rack_id:
      state.warehouse_position.data.warehouse_rack.id,
    warehouse_shelf_id:
      state.warehouse_position.data.warehouse_shelf.id,
    warehouse_position_id:
      state.warehouse_position.data.warehouse_position.id,
  },
});

const mapDispatchToProps = (dispatch) => ({
  updateMainLocation: (id, data) =>
    dispatch(updateMainLocation(id, data)),
  getWarehousePosition: (id) =>
    dispatch(getWarehousePosition(id)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: "edit-product-location",
    enableReinitialize: true,
  })(withRouter(EditLocation))
);
