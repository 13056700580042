import { toastr } from "react-redux-toastr";
import { client_v2 } from "utils/api";

export const getIslandZipCode = (id) => async (
  dispatch
) => {
  try {
    dispatch({ type: "ISLAND_ZIP_CODE_LOADING" });

    const { data } = await client_v2.get(
      `/island_zip_codes/${id}`
    );

    dispatch({
      type: "ISLAND_ZIP_CODE_SUCCESS",
      data,
    });
  } catch (error) {
    dispatch({ type: "ISLAND_ZIP_CODE_FAILURE" });
    toastr.error(
      "Błąd",
      "Wystąpił błąd podczas pobierania"
    );
    throw error;
  }
};
export const createIslandZipCode = async (values) => {
  try {
    const {
      data: { id },
    } = await client_v2.post("/island_zip_codes", values);
    return id;
  } catch (error) {
    toastr.error(
      "Błąd",
      "Wystąpił błąd podczas dodawania kodu pocztowego"
    );
    throw error;
  }
};

export const deleteIslandZipCode = async (id) => {
  try {
    await client_v2.delete(`/island_zip_codes/${id}`);
    toastr.success("Sukces", "Dostawca został usuniety");
  } catch (error) {
    toastr.error("Błąd", "Wystąpił błąd podczas usuwania");
    throw error;
  }
};
