import React from "react";

const Empty = ({ empty_text = "Brak danych" }) => {
  return (
    <div className='main-table__empty'>
      <p>{empty_text}</p>
    </div>
  );
};

export default Empty;
