import React from 'react'
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Item from './components/Item';

const LastPacked = ({data}) => {
  return (    <div className="dashboard">
  <div className="history-box__header">
    <span className="title">Ostatnio spakowane</span>
  </div>
  {data?.last_completed?.length > 0 && (
    <ul className="history-box__body">
      {data.last_completed.map((data) => (
        <Item key={data.identifier} {...data} />
      ))}
    </ul>
  )}
  <div className="history-box__footer">
    <Link to="/gdns" className="btn-link">
      Logi wysyłki
    </Link>
  </div>
</div>  );
}
 
export default connect(({ dashboard: { data } }) => ({
  data,
}))(LastPacked);
