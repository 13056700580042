import React from "react";
import { useQueryClient } from "@tanstack/react-query";
import { ModalDelete } from "expano-components";
import { useMainList } from "components/MainList/context/provider";
import { deleteRack } from "actions/racks";

const ModalDeleteRack = ({ name, id, handleClose }) => {
  const { refetch } = useMainList();
  const query = useQueryClient();
  const handleDelete = () =>
    deleteRack(id)
      .then(async () => {
        refetch();
        query.refetchQueries(["warehouse_aisles"]);
        handleClose();
      })
      .catch((err) => console.log(err));

  return (
    <ModalDelete
      name={`regał ${name}?`}
      handleClose={handleClose}
      handleDelete={handleDelete}
    />
  );
};

export default ModalDeleteRack;
