import React from "react";
import { connect } from "react-redux";
import StationScanner from "./components/StationScanner";
import { getCookie } from "utils/cookies";

const StationValidator = ({ current_user, children }) => {
  const skip_station_scan = getCookie("skip_station_scan");
  const station_id =
    current_user.today_packing_station_user?.packing_station_id;

  const need_station = ["packer", "warehouseman"].includes(current_user.role);

  if (skip_station_scan) {
    return children;
  }

  if (need_station && (!station_id || station_id === 22)) {
    //22 -> Nawigator (brak stanowiska)
    return <StationScanner />;
  }
  return children;
};

export default connect(({ current_user }) => ({
  current_user,
}))(StationValidator);
