import React from "react";
import { Document, Image, Font, Page, Text, View, StyleSheet } from "@react-pdf/renderer";

import RobotoBlack from "fonts/Roboto-Black.ttf";

Font.register({
  family: "Roboto",
  fonts: [{ src: RobotoBlack }],
});

const Sticker5x3 = ({ data, format, image }) => (
  <Document>
    <Page size={{ width: 142, height: 85 }} style={styles.page}>
      <View style={styles.text}>
        <Text style={styles.sku}>{data.sku}</Text>
      </View>
      <Image src={image} />
    </Page>
  </Document>
);

export default Sticker5x3;

const styles = StyleSheet.create({
  page: {
    backgroundColor: "#fff",
    paddingTop: 5,
  },
  text: {
    textAlign: "center",
  },
  sku: {
    fontFamily: "Roboto",
    fontSize: 13,
    textTransform: "uppercase",
    letterSpacing: 1,
    marginBottom: 5,
  },
});