import getWarehouses from "utils/queries/getWarehouses";

const kind = [
  { label: "Dostawa", value: "0" },
  { label: "Przesunięcie (MM)", value: "1" },
];

const statuses = [
  { label: "Nowe", value: "0" },
  { label: "Rozpoczęte", value: "1" },
  {
    label: "Przyjęte niepoprawne",
    value: "2",
  },
  { label: "Przyjęte poprawne", value: "3" },
  { label: "Oczekuje na MM", value: "5" },
  { label: "Zakończone", value: "4" },
];

export const sort = [
  {
    label: "Data",
    name: "prepared_at",
    style: { width: 130 },
  },
  {
    label: "Numer",
    name: "identifier",
  },
  { label: "Rodzaj", name: "kind", style: { width: 200 } },
  {
    label: "Wydanie",
    name: "from_name",
    style: { width: 180 },
  },
  {
    label: "Odbiór",
    name: "to_name",
    style: { width: 180 },
  },
  {
    label: "Postęp",
    name: "progress",
    style: { width: 100 },
    sortable: true,
  },
  {
    label: "Status",
    name: "status",
    style: { width: 170 },
    sortable: false,
  },
];

export const filters = [
  {
    type: "date",
    name: "prepared_at",
    sort_id: "prepared_at",
    search_type: "date",
  },
  {
    type: "input",
    name: "identifier",
    sort_id: "identifier",
    search_type: "matches",
  },
  {
    type: "select",
    name: "kind",
    sort_id: "kind",
    search_type: "in",
    options: kind,
  },
  {
    type: "input",
    name: "from_name",
    sort_id: "from_name",
    search_type: "matches",
  },
  {
    type: "input",
    name: "to_name",
    sort_id: "to_name",
    search_type: "matches",
  },
  {
    type: "async_select",
    name: "warehouse_id",
    sort_id: "warehouse_name",
    search_type: "in",
    getData: getWarehouses,
  },
  {
    type: "select",
    name: "status",
    sort_id: "status",
    search_type: "in",
    options: statuses,
    initialValues: [
      { label: "Nowe", value: "0" },
      { label: "Rozpoczęte", value: "1" },
      {
        label: "Przyjęte niepoprawne",
        value: "2",
      },
      { label: "Przyjęte poprawne", value: "3" },
      { label: "Oczekuje na MM", value: "5" },
    ],
  },
];
