import React from "react";
import { useQuery } from "@tanstack/react-query";
import { client_v2 } from "utils/api";

import { PageLoader } from "components";

const OmsOrderRedirect = ({
  history,
  match: {
    params: { oms_order_id },
  },
}) => {
  useQuery(
    ["oms_order_id", oms_order_id],
    () =>
      client_v2.get(
        `/gdns/find_by_oms_order_id?oms_order_id=${oms_order_id}`
      ),
    {
      select: (res) => res.data,
      onSuccess: ({ id }) => {
        history.push(`/gdns/${id}`);
      },
      onError: () => {
        history.push("/gdns");
      },
    }
  );
  return <PageLoader />;
};

export default OmsOrderRedirect;
