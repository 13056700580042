import React from "react";
import {
  Document,
  Font,
  Image,
  Page,
  Text,
  View,
  StyleSheet,
} from "@react-pdf/renderer";

import RobotoBold from "fonts/Roboto-Bold.ttf";
import RobotoBlack from "fonts/Roboto-Black.ttf";

Font.register({
  family: "Roboto",
  fonts: [
    { src: RobotoBold, fontWeight: 700 },
    { src: RobotoBlack, fontWeight: 900 },
  ],
});

const StickerCart = ({ data }) => (
  <Document>
    <Page
      size={{ width: 377.95275591, height: 566.92913386 }}
      style={styles.page}>
      <View style={styles.text}>
        <Text style={styles.id}>{data.id}</Text>
        <Text style={styles.box_size}>{data.box_size}</Text>
      </View>
      <View style={styles.image}>
        <View style={styles.image_box}>
          <Image src={data.code} />
        </View>
      </View>
    </Page>
  </Document>
);

const styles = StyleSheet.create({
  page: {
    backgroundColor: "#fff",
    padding: 5,
  },
  text: {
    textAlign: "center",
  },
  id: {
    fontFamily: "Roboto",
    fontSize: 160,
    fontWeight: 900,
  },
  box_size: {
    fontFamily: "Roboto",
    fontWeight: 600,
    fontSize: 40,
    margin: "20px 0",
    color: "#595d6e",
  },
  image: {
    marginTop: 50,
    alignItems: "center",
  },
  image_box: {
    width: "45%",
  },
});

export default StickerCart;
