import React from "react";
import { useQuery } from "@tanstack/react-query";

import { Main } from "layouts";
import { getWarehouse } from "actions/warehouses";

import RoomsList from "./components/RoomsList";
import AislesList from "./components/AislesList";
import RacksList from "./components/RacksList";
import ShelfsList from "./components/ShelfsList";

import { ReactComponent as WarehouseIcon } from "icons/warehouse.svg";

const Warehouses = ({
  match: {
    params: { warehouse_id },
  },
}) => {
  const { isLoading: is_loading, data } = useQuery(
    [warehouse_id],
    () => getWarehouse(warehouse_id),
    {
      keepPreviousData: false,
    }
  );

  return (
    <Main
      is_loading={is_loading}
      page={{
        name: data?.name,
        icon: <WarehouseIcon />,
        breadcrumbs: [
          {
            name: "Zarządzanie magazynem",
            path: "/warehouses",
          },
          { name: data?.name },
        ],
      }}
    >
      <div className="row">
        <RoomsList />
        <AislesList />
        <RacksList />
        <ShelfsList />
      </div>
    </Main>
  );
};

export default Warehouses;
