import React, { useState } from "react";
import { ReactComponent as SaveIcon } from "icons/save.svg";
import { toastr } from "react-redux-toastr";
import { Loader } from "components";
import { client_v2 } from "utils/api";
const getStatue = (diff) => {
  if (diff < 0) return "under";
  return "correct";
};

const Input = ({ id, stock_diff, handleUpdateField }) => {
  const [value, setValue] = useState(stock_diff);
  const [is_loading, setLoading] = useState(false);

  const handleUpdate = async () => {
    try {
      setLoading(true);
      await client_v2.put(`/product_main_locations/${id}`, {
        stock_diff: value,
      });
      handleUpdateField(parseInt(value));
    } catch (error) {
      toastr.error("Błąd", "Wystąpił błąd");
    } finally {
      setLoading(false);
    }
  };

  const onFocus = (e) => e.target.select();
  const status = getStatue(stock_diff);
  return (
    <form
      onSubmit={() => handleUpdate(value)}
      className="form-group save-group">
      <div className="inp-wrapper" data-id={id}>
        <div className={`inp-group ${status}`}>
          <input
            onFocus={onFocus}
            step="1"
            placeholder="Ilość"
            value={value}
            onChange={({ target }) =>
              setValue(target.value)
            }
            type="number"
          />
        </div>
        <button
          tabIndex="-1"
          type="submit"
          onClick={() => handleUpdate(value)}
          className="btn-save"
          disabled={
            is_loading || !value || value === stock_diff
          }>
          {is_loading ? (
            <Loader size={20} color="#000" />
          ) : (
            <SaveIcon />
          )}
        </button>
      </div>
    </form>
  );
};

export default Input;
