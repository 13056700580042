import React from "react";
import QrCodeBox from "./components/QrCodeBox";
import ProductsList from "./components/ProductsList";

import StyledPackInit from "./styles";

const PackInit = () => {
  return (
    <StyledPackInit>
      <QrCodeBox />
      <ProductsList />
    </StyledPackInit>
  );
};

export default PackInit;
