import React, { useState, useRef } from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { Label, ModalPreview } from "expano-components";
import LazyLoad from "react-lazy-load";
import classNames from "classnames";

import { updateGrnLine, setGrnLinesFilter } from "actions/grns";
import { Tooltip, QrCodeStickerModal, LocalizationInfo } from "components";
import Input from "./components/Input";
import { WarningModal, CapacityModal } from "../../../../../components";
import getDispatchCountingState from "../../../../../const/getDispatchCountingState";

import { ReactComponent as QRcodeIcon } from "icons/qrcode.svg";
import { ReactComponent as Sticker } from "icons/sticker.svg";
import { ReactComponent as WarningIcon } from "icons/light-warning.svg";

const Item = ({
  form,
  data: { id },
  updateGrnLine,
  handleSubmit,
  submitted,
  data,
  search_value,
  setGrnLinesFilter,
  status,
  is_dispatched,
  from_name,
  to_name,
}) => {
  const ref = useRef();
  const [is_warning_modal_open, openWarningModal] = useState(false);

  const [is_modal_open, openModal] = useState(false);

  const [is_qr_codes_modal_open, openQrCodesModal] = useState(false);
  const [
    capacity_product_main_location_data,
    setCapacityProductMainLocationData,
  ] = useState(null);

  const onSubmit = (values) => {
    const input_focused = ref?.current?.querySelector("input:focus");
    if (input_focused) {
      input_focused.blur();
    }
    updateGrnLine(id, values);
  };

  const image_url = data.product?.image_url;

  const matches_search_value =
    data?.product.sku === search_value ||
    data?.product?.producer_code === search_value ||
    data?.product?.ean === search_value ||
    data?.product?.logistic_eans
      ?.map(({ code }) => code)
      .includes(search_value);

  const checkStatus = () =>
    ["completed", "with_errors", "finished"].includes(status);

  const isDisabledEasyToPackProductDispatched = () => {
    return (
      !data.has_location_in_shipment_warehouse ||
      checkStatus() ||
      is_dispatched ||
      (data?.product.has_ean &&
        !matches_search_value &&
        !is_dispatched &&
        data.dispatched_quantity === null)
    );
  };

  const isDisabledEasyToPackProductIncome = () => {
    return (
      !data.has_location_in_shipment_warehouse ||
      checkStatus() ||
      !is_dispatched ||
      (data?.product.has_ean &&
        !matches_search_value &&
        data.counting_state === "pending")
    );
  };

  const mapWarehouseIdToName = (warehouse_id) => {
    switch (Number(warehouse_id)) {
      case 1:
      case 8:
        return "Magazyn główny";
      case 2:
      case 4:
        return "Magazyn wysyłkowy";
      default:
        return null;
    }
  };

  const main_location = data?.product?.product_main_locations?.find(
    (item) => mapWarehouseIdToName(item.warehouse?.id) === from_name
  );

  const shipping_location = data?.product?.product_main_locations?.find(
    (item) => mapWarehouseIdToName(item.warehouse?.id) === to_name
  );

  const shipping_location_stock = shipping_location?.stock || 0;
  const shipping_location_capacity = shipping_location?.capacity || 0;

  const available_shipping_warehouse_quantity =
    shipping_location_capacity - shipping_location_stock;

  const max_shipping_warehouse_quantity =
    available_shipping_warehouse_quantity > 0
      ? available_shipping_warehouse_quantity
      : 0;

  return (
    <tr
      data-focus-id={data.id}
      className={classNames(data?.counting_state, {
        is_warned: data?.is_warned,
        is_decomposed:
          data?.supplier_quantity?.is_decomposed ||
          data?.supplier_quantity?.is_composed,
        no_location: !data.has_location_in_shipment_warehouse,
      })}
      style={{
        display: data?.show ? "table-row" : "none",
      }}
    >
      <td className="number">
        <span>{data?.no}</span>
      </td>
      <td className="image-box">
        {image_url && (
          <LazyLoad>
            <button
              tabIndex="-1"
              className="modal__trigger"
              onClick={() => openModal(true)}
              style={{
                backgroundImage: `url('${
                  data?.product?.image_thumb_url || image_url
                }')`,
              }}
            />
          </LazyLoad>
        )}
      </td>
      <td>
        <p>
          <a
            className="text-link"
            href={`/products/${data?.product?.id}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {data?.product?.name}
          </a>
        </p>
        <p className="text-info">SKU: {data?.product?.sku}</p>
        <p className="text-info">Kod dostawcy: {data?.supplier_code || "-"}</p>
        {!data.has_location_in_shipment_warehouse && (
          <Label
            type="fill"
            color="#fd397a"
            text="Brak lokalizacji na magazynie wysyłkowym"
          />
        )}
      </td>
      <td>
        <div className="codes-group">
          <div className="codes-group__box">
            {data.product?.has_ean && <Sticker />}
            {data.product?.sku && data?.product?.has_qr_code && (
              <button
                type="button"
                onClick={() => openQrCodesModal(true)}
                tabIndex="-1"
              >
                <QRcodeIcon />
              </button>
            )}
          </div>
          {data.counting_state === "pending" &&
            search_value !== data.product.sku &&
            search_value !== data.product.ean &&
            data?.product?.has_ean && (
              <div className="codes-group__box">
                <button
                  type="button"
                  style={{ padding: 5 }}
                  onClick={() => openWarningModal(true)}
                >
                  <WarningIcon />
                </button>
              </div>
            )}
        </div>
      </td>
      <td className="box-count bl text-center">
        {data?.supplier_quantity?.is_decomposed ||
        data?.supplier_quantity?.is_composed ? (
          <>
            <p>
              <strong>
                {data.supplier_quantity.package_qty}{" "}
                {data.supplier_quantity?.supplier_custom_unit || "op."}
              </strong>
            </p>
            {data.supplier_quantity.unit_qty > 0 ? (
              <p>
                <strong>{data.supplier_quantity.unit_qty} szt.</strong>
              </p>
            ) : null}
            <p className="text-muted">{data?.quantity} szt.</p>
          </>
        ) : (
          <>
            <p>
              <strong>{data.quantity}</strong>
            </p>
            <p>{data?.unit}</p>
          </>
        )}
      </td>
      <td className="bl">
        <div className="warehouse-form">
          <div className="warehouse-form__box">
            {main_location?.warehouse_position?.id ? (
              <LocalizationInfo
                show_tooltip={false}
                main={main_location?.warehouse_position}
              />
            ) : (
              <p>-</p>
            )}
            {main_location?.warehouse_position?.id && (
              <p className="suggested-count">
                Na lok.: {main_location?.stock} szt.
              </p>
            )}
            <p
              className={classNames("suggested-count pointer", {
                warning: main_location?.is_final_capacity,
              })}
              onClick={() =>
                setCapacityProductMainLocationData({
                  id: main_location.id,
                  capacity: main_location.capacity,
                  is_final_capacity: main_location.is_final_capacity,
                })
              }
            >
              Pojemność: {main_location?.capacity} szt.
            </p>
          </div>
          <div className="warehouse-form__box">
            <form
              onSubmit={handleSubmit(onSubmit)}
              ref={ref}
              className="dispatched"
            >
              <Field
                form_name={form}
                name="dispatched_quantity"
                status={getDispatchCountingState({
                  dispatched_quantity: data?.dispatched_quantity,
                  quantity: data?.quantity,
                })}
                submitted={submitted}
                defaultValue={
                  data?.dispatched_quantity ? data.dispatched_quantity : 0
                }
                component={Input}
                isBlocked={isDisabledEasyToPackProductDispatched()}
                submit={handleSubmit(onSubmit)}
                type="number"
              />
            </form>
          </div>
        </div>
      </td>
      <td className="bl">
        <div className="warehouse-form">
          <div className="warehouse-form__box">
            {shipping_location?.warehouse_position?.id ? (
              <LocalizationInfo
                show_tooltip={false}
                main={shipping_location.warehouse_position}
              />
            ) : (
              <p>-</p>
            )}
            {shipping_location?.warehouse_position?.id && (
              <>
                <p className="suggested-count m-r-5">
                  Na lok.: {shipping_location?.stock} szt.
                </p>
                <br />
                <p
                  className={classNames("suggested-count pointer", {
                    warning: shipping_location?.is_final_capacity,
                  })}
                  onClick={() =>
                    setCapacityProductMainLocationData({
                      id: shipping_location.id,
                      capacity: shipping_location.capacity,
                      is_final_capacity: shipping_location.is_final_capacity,
                    })
                  }
                >
                  Pojemność: {shipping_location?.capacity} szt.
                </p>
              </>
            )}
          </div>
          <div className="warehouse-form__box">
            <form onSubmit={handleSubmit(onSubmit)} className="income">
              <Field
                form_name={form}
                name="counted_quantity"
                status={data?.counting_state}
                submitted={submitted}
                defaultValue={
                  data?.counted_quantity ? data.counted_quantity : 0
                }
                component={Input}
                isBlocked={
                  isDisabledEasyToPackProductIncome() ||
                  ["completed", "with_errors", "finished"].includes(status) ||
                  !is_dispatched
                }
                submit={handleSubmit(onSubmit)}
                type="number"
              />
            </form>
            {max_shipping_warehouse_quantity > 0 && (
              <p className="text-info m-t-5">
                Max. {max_shipping_warehouse_quantity} szt.
              </p>
            )}
          </div>
        </div>
      </td>
      <td className="bl">
        {data?.is_warned && (
          <Tooltip trigger={<WarningIcon />} content={<p>Sprawdź produkt</p>} />
        )}
        {data?.product?.is_warehouseman_alert_enabled &&
          data?.product?.warehouseman_alert && (
            <Tooltip content={<p>{data.product.warehouseman_alert}</p>} />
          )}
      </td>
      {is_modal_open && (
        <ModalPreview
          handleClose={() => {
            openModal(false);
          }}
          src={image_url}
          alt={data?.product?.name}
        />
      )}
      {is_warning_modal_open && (
        <WarningModal
          handleConfirm={() => {
            openWarningModal(false);
            setGrnLinesFilter("sku", data.ean || data.product.sku);
          }}
          product={data.product}
          handleClose={() => openWarningModal(false)}
        />
      )}
      {is_qr_codes_modal_open && (
        <QrCodeStickerModal
          data={data.product}
          handleClose={() => openQrCodesModal(false)}
          initialValues={{ quantity: data?.quantity || 1 }}
        />
      )}
      {capacity_product_main_location_data?.id && (
        <CapacityModal
          grn_line_id={data.id}
          product_main_location_id={capacity_product_main_location_data?.id}
          initialValues={{
            capacity: capacity_product_main_location_data?.capacity || null,
            is_final_capacity:
              capacity_product_main_location_data?.is_final_capacity || false,
          }}
          handleClose={() => setCapacityProductMainLocationData(null)}
        />
      )}
    </tr>
  );
};

const mapDispatchToProps = (dispatch) => ({
  updateGrnLine: (id, data) => dispatch(updateGrnLine(id, data)),
  setGrnLinesFilter: (name, value) => dispatch(setGrnLinesFilter(name, value)),
});
export default connect(
  ({
    grn: {
      filters: { sku },
      data,
    },
  }) => ({
    search_value: sku,
    from_name: data.from_name,
    to_name: data.to_name,
  }),
  mapDispatchToProps
)(reduxForm()(Item));
