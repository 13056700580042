import React from "react";
import { withRouter } from "react-router";
import { MainList } from "components";

import { sort } from "./const/data";

const ShelfsList = ({
  match: {
    params: {
      warehouse_room_id,
      warehouse_aisle_id,
      warehouse_rack_id,
    },
  },
}) => {
  if (
    !warehouse_room_id ||
    !warehouse_aisle_id ||
    !warehouse_rack_id
  )
    return null;

  return (
    <div className="box-3">
      <MainList
        query_key="warehouse_shelves"
        api_path="/warehouse_shelves"
        api_ransack={`q[warehouse_rack_id_eq]=${warehouse_rack_id}`}
        api_context="ui_index"
        title="Lista półek"
        sort_column="position"
        sort_direction="asc"
        hide_meta={true}
        sort={sort}
        renderItem={({
          identifier,
          warehouse_positions,
        }) => (
          <>
            <td>{identifier}</td>
            <td>{warehouse_positions?.length || 0}</td>
          </>
        )}
        subListData={({ warehouse_positions }) => {
          const data = [];
          const products = warehouse_positions
            ?.filter(
              ({ product_main_locations }) =>
                product_main_locations?.length > 0
            )
            ?.map(
              ({ product_main_locations, identifier }) =>
                product_main_locations?.map(
                  ({ product }) => ({
                    id: identifier,
                    identifier,
                    product,
                  })
                )
            );

          for (const product_arr of products) {
            for (const product of product_arr) {
              data.push(product);
            }
          }
          return {
            head: ["Lok.", "SKU", "Nazwa"],
            colspan: 3,
            highlighted_indexes: [1],
            data,
            renderItem: ({ product, identifier }) => {
              return [
                identifier || "-",
                product?.sku || "-",
                product?.name || "-",
              ];
            },
          };
        }}
      />
    </div>
  );
};

export default withRouter(ShelfsList);
