import React from "react";
import { Field } from "redux-form";
import { Button, Input } from "expano-components";

const InputField = (props) => <Input {...props} />;

const Items = ({ package_boxes, fields }) => {
  return (
    <div className="package-boxes__form" id="packages_form">
      {fields.map((item, index) => {
        const name = package_boxes[index].name;
        return (
          <div className="form-group" key={index}>
            <div className="form-group__header">
              <h3>Rozmiar: {name}</h3>
              <Button
                type="delete-bin"
                onClick={() => fields.remove(index)}
              />
            </div>
            <div className="row">
              <div className="box-3">
                <Field
                  name={`${item}.width`}
                  type="number"
                  component={InputField}
                  placeholder="cm"
                  label="Szerokość"
                  postfix="cm"
                />
              </div>
              <div className="box-3">
                <Field
                  name={`${item}.height`}
                  type="number"
                  placeholder="cm"
                  component={InputField}
                  label="Wysokość"
                  postfix="cm"
                />
              </div>
              <div className="box-3">
                <Field
                  name={`${item}.depth`}
                  type="number"
                  placeholder="cm"
                  component={InputField}
                  label="Głębokość"
                  postfix="cm"
                />
              </div>
              <div className="box-3">
                <Field
                  name={`${item}.weight`}
                  type="number"
                  placeholder="kg"
                  component={InputField}
                  label="Waga"
                  postfix="kg"
                />
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Items;
