import React, { useState } from "react";
import { connect } from "react-redux";
import { Button } from "expano-components";
import { AddSupplier } from "./modals";
import ListItem from "./components/ListItem";

const Suppliers = ({ product }) => {
  const [show_add_product, handleShowAddProduct] = useState(
    false
  );

  return (
    <>
      {show_add_product && (
        <AddSupplier
          handleClose={() => handleShowAddProduct(false)}
        />
      )}
      <div className="dashboard">
        <header className="dashboard__header dashboard__header--edit">
          <div className="icon__container">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20">
              <g transform="translate(0 20) rotate(-90)">
                <rect
                  fill="#5d78ff"
                  opacity="0.3"
                  width="1.667"
                  height="10"
                  rx="0.833"
                  transform="translate(10.833 10.833) rotate(180)"
                />
                <path
                  fill="#5d78ff"
                  opacity="0.3"
                  d="M14.5,7.666A.833.833,0,1,1,14.5,6h.833a3.333,3.333,0,0,1,3.333,3.333V16a3.333,3.333,0,0,1-3.333,3.333h-10A3.333,3.333,0,0,1,2,16V9.326A3.333,3.333,0,0,1,5.333,5.993h.833a.833.833,0,0,1,0,1.667H5.333A1.667,1.667,0,0,0,3.667,9.326V16a1.667,1.667,0,0,0,1.667,1.667h10A1.667,1.667,0,0,0,17,16V9.332a1.667,1.667,0,0,0-1.667-1.667Z"
                  transform="translate(-0.333 -0.999)"
                />
                <path
                  fill="#5d78ff"
                  d="M13.244,10.244a.833.833,0,1,1,1.179,1.179l-2.5,2.5a.833.833,0,0,1-1.179,0l-2.5-2.5a.833.833,0,1,1,1.179-1.179l1.911,1.911Z"
                  transform="translate(-1.333 -1.667)"
                />
              </g>
            </svg>
          </div>
          <h2 className="dashboard__title heading">
            Dostawcy
          </h2>
        </header>
        {product?.product_suppliers?.length > 0 && (
          <ul className="dashboard__list">
            {product.product_suppliers.map((item) => (
              <ListItem key={item.id} {...item} />
            ))}
          </ul>
        )}
        <div className="dashboard__button-section">
          <Button
            type="add"
            onClick={() => handleShowAddProduct(true)}
            text="Dodaj dostawcę"
          />
        </div>
      </div>
    </>
  );
};

const mapStateToProps = ({ edit_product }) => ({
  product: edit_product.data,
});

export default connect(mapStateToProps, null)(Suppliers);
