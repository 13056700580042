import React, { useState } from "react";
import { connect } from "react-redux";
import { Button, Input, Select } from "expano-components";
import { Field, reduxForm } from "redux-form";
import { createIssue } from "actions/gdns";

import { Modal } from "components";
import gdn_user_error_options from "utils/gdn_user_error_options";

import { ReactComponent as InfoIcon } from "icons/info.svg";

const default_kind = "packing_other";

const InputField = (props) => <Input {...props} />;
const SelectField = (props) => <Select {...props} />;

const options = gdn_user_error_options.filter(
  ({ value }) => value !== "picking_other"
);

const CreateIssueModal = ({
  invalid,
  change,
  handleSubmit,
  handleClose,
  createIssue,
}) => {
  const [is_loading, setLoading] = useState(false);

  const onSubmit = async (values) => {
    try {
      setLoading(true);
      await createIssue(values);
      setLoading(false);
      handleClose();
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  return (
    <Modal
      prevent_click_outside={true}
      handleClose={handleClose}
      header={{
        title: "Zgłoś błąd zamówienia",
        icon: <InfoIcon />,
      }}
      footer={
        <>
          <Button type="cancel" text="Anuluj" onClick={handleClose} />
          <Button
            disabled={is_loading || invalid}
            type="save"
            text={is_loading ? "Trwa zgłaszanie..." : "Zgłoś"}
            onClick={handleSubmit(onSubmit)}
          />
        </>
      }
    >
      <form autoComplete="off" noValidate onSubmit={handleSubmit(onSubmit)}>
        <Field
          label="Rodzaj błędu"
          name="kind"
          component={SelectField}
          handleChange={(value) => change("kind", value)}
          options={options}
          defaultValue={options.find(({ value }) => value === default_kind)}
        />
        <Field label="Opis błędu" name="description" component={InputField} />
      </form>
    </Modal>
  );
};

export default connect(
  ({
    gdn: {
      data: { id: gdn_id, gdn_user_errors },
    },
  }) => ({
    gdn_user_errors,
    initialValues: {
      gdn_id,
      kind: default_kind,
      description: "",
    },
  }),
  (dispatch) => ({
    createIssue: (values) => dispatch(createIssue(values)),
  })
)(
  reduxForm({
    form: "create_gdn_user_error",
    enableReinitialize: true,
  })(CreateIssueModal)
);
