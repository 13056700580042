import React, { useState, useEffect } from "react";
import classNames from "classnames";
import QRCode from "qrcode";
import { toastr } from "react-redux-toastr";
import { connect } from "react-redux";
import { pdf } from "@react-pdf/renderer";
import { saveAs } from "file-saver";
import { MainTable } from "components";
import { Main } from "layouts";
import { StickerCart, StickerCartBoxQrCode } from "sticker_templates";
import { parseQuery } from "utils/api";
import cart_boxes from "utils/cart_boxes";

import { getCarts, updateCart } from "actions/carts";
import { ReactComponent as QRcodeIcon } from "icons/qrcode.svg";

import { ReactComponent as CartIcon } from "icons/delivery.svg";

const CartsList = ({ carts, getCarts, updateCart, location: { search } }) => {
  const [cart_updating_id, setCartUpdatingId] = useState(null);
  const [is_generating_cart_box_stickers, setGeneratingCartBoxStickers] =
    useState(false);
  const [is_loading, setLoading] = useState(false);
  const [is_loaded, setLoaded] = useState(false);
  const query = parseQuery(search);
  const sort = {
    "sort[column]": query["sort[column]"] || "id",
    "sort[order]": query["sort[order]"] || "asc",
  };

  useEffect(() => {
    getCarts({ ...query, ...sort });
    setLoaded(true);
  }, [search]);

  useEffect(() => {
    if (is_loaded) {
      getCarts({ ...query, page: undefined });
    }
  }, [carts.filters.query]);

  const generateSticker = async ({ id, name, box_size }) => {
    try {
      setLoading(true);
      const code = await QRCode.toDataURL(`cart|${id}`);
      const blob = await pdf(
        <StickerCart
          data={{
            id,
            box_size,
            code,
          }}
        />
      ).toBlob();
      saveAs(blob, `${name}.pdf`);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const generateCartBoxStickers = async ({ id, name, box_size }) => {
    try {
      setGeneratingCartBoxStickers(true);

      const cart_box_data = cart_boxes[box_size];

      if (!cart_box_data) {
        throw new Error("Brak konfiguracji wózka");
      }

      const data = await Promise.all(
        cart_box_data.flat().map(async (cart_box_identifier) => {
          const qr_code_base_64 = await QRCode.toDataURL(
            `cart_box|${id}#${cart_box_identifier}`
          );
          return {
            qr_code_base_64,
            cart_name: name,
            cart_box_identifier,
          };
        })
      );

      const blob = await pdf(<StickerCartBoxQrCode data={data} />).toBlob();
      saveAs(blob, `${name}.pdf`);
    } catch (error) {
      toastr.error("Błąd", error?.message);
    } finally {
      setGeneratingCartBoxStickers(false);
    }
  };

  const toggleCartOccupied = async (id, is_occupied) => {
    try {
      setCartUpdatingId(id);
      await updateCart(id, { is_occupied });
    } catch (error) {
    } finally {
      setCartUpdatingId(null);
    }
  };

  if (carts.status === "failure") {
    return <p>Error</p>;
  }

  const { data, meta } = carts;

  return (
    <Main
      page={{
        name: "Wózki",
        icon: <CartIcon />,
        breadcrumbs: [{ name: "Wózki" }],
      }}
    >
      <div className="dashboard">
        <MainTable
          is_loading={["invalid", "loading"].includes(carts.status)}
          is_searching={carts.status === "searching"}
          filters_open={carts.filters.open}
          data={data}
          meta={meta}
          sort={sort}
          head={[
            {
              label: "ID",
              name: "id",
              style: { width: 100 },
            },
            {
              label: "Nazwa wózka",
              name: "name",
              sortable: false,
            },
            {
              label: "Rozmiar",
              name: "box_size",
            },
            {
              label: "Pack",
              name: "gdn_pack_id",
              sortable: false,
            },
            {
              label: "Zajęty",
              name: "is_occupied",
              sortable: true,
            },
            {
              label: "Akcje",
              name: "actions",
              sortable: false,
              style: { width: 410 },
            },
          ]}
          empty_text="Brak wózków"
          renderRow={({ id, name, box_size, gdn_pack_id, is_occupied }) => (
            <tr key={id}>
              <td>{id}</td>
              <td>{name}</td>
              <td>{box_size}</td>
              <td>
                {gdn_pack_id ? (
                  <a
                    className="link"
                    href={`/packing/gdn_pack/${gdn_pack_id}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {gdn_pack_id}
                  </a>
                ) : (
                  "-"
                )}
              </td>
              <td>{is_occupied ? "Tak" : "Nie"}</td>
              <td>
                <div className="row ai-c">
                  <button
                    className="m-r-20"
                    disabled={is_loading}
                    type="button"
                    onClick={() =>
                      generateSticker({
                        id,
                        name,
                        box_size,
                      })
                    }
                    tabIndex="-1"
                  >
                    <QRcodeIcon />
                  </button>
                  <button
                    type="button"
                    disabled={cart_updating_id === id}
                    className={classNames("btn btn-blue btn-sm")}
                    onClick={() =>
                      generateCartBoxStickers({
                        id,
                        name,
                        box_size,
                      })
                    }
                    tabIndex="-1"
                  >
                    <span>
                      {is_generating_cart_box_stickers
                        ? "Proszę czekać..."
                        : "Etykiety kuwet"}
                    </span>
                  </button>
                  <button
                    type="button"
                    disabled={cart_updating_id === id}
                    className={classNames("m-l-10 btn btn-sm", {
                      "btn-pink": !is_occupied,
                      "btn-green": is_occupied,
                    })}
                    onClick={() => toggleCartOccupied(id, !is_occupied)}
                    tabIndex="-1"
                  >
                    {cart_updating_id === id ? (
                      <span>Proszę czekać...</span>
                    ) : (
                      <span>
                        Oznacz jako {is_occupied ? "wolny" : "zajęty"}
                      </span>
                    )}
                  </button>
                </div>
              </td>
            </tr>
          )}
        />
      </div>
    </Main>
  );
};

export default connect(
  ({ carts }) => ({ carts }),
  (dispatch) => ({
    getCarts: (data) => dispatch(getCarts(data)),
    updateCart: (id, data) => dispatch(updateCart(id, data)),
  })
)(CartsList);
