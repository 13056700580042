//{ new: 0, picked: 1, packed: 2, completed: 3, error: 4, with_missings: 5, problem: 6, to_cancel: 7 }

export const status_options = [
  { label: "Nowe", value: "new" },
  { label: "Skompletowane", value: "picked" },
  { label: "Spakowane", value: "packed" },
  { label: "Wydane", value: "completed" },
  { label: "Błąd", value: "error" },
  { label: "Z brakami", value: "with_missings" },
  { label: "Problem", value: "problem" },
  { label: "Anulowane", value: "to_cancel" },
];

export const getStatusLabelData = (status) => {
  //  new picking packing completed with_missings
  switch (status) {
    case "new":
      return { status: "info", status_text: "Nowe" };
    case "picked":
      return {
        status: "warning",
        status_text: "Skompletowane",
      };
    case "packed":
      return {
        status: "warning",
        status_text: "Spakowane",
      };
    case "completed":
      return { status: "success", status_text: "Wydane" };
    case "with_missings":
      return { status: "danger", status_text: "Z brakami" };
    case "error":
      return { status: "danger", status_text: "Błąd" };
    case "problem":
      return { status: "warning", status_text: "Problem" };
    case "to_cancel":
      return { status: "dark", status_text: "Anulowane" };
    default:
      return { status: null, status_text: null };
  }
};
