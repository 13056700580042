import { toastr } from "react-redux-toastr";
import { client_v2 } from "utils/api";
import { removeCookie } from "utils/cookies";
import {
  CURRENT_USER_FAILURE,
  CURRENT_USER_LOADING,
  CURRENT_USER_SUCCESS,
  CURRENT_USER_UPDATE,
} from "../reducers/current_user";

export const getCurrentUser = () => async (dispatch) => {
  try {
    dispatch({ type: CURRENT_USER_LOADING });
    const { data } = await client_v2.get(`/users/my_account`);
    dispatch({
      type: CURRENT_USER_SUCCESS,
      data,
    });
  } catch (error) {
    await removeCookie("token");
    dispatch({ type: CURRENT_USER_FAILURE });
  }
};

export const updateCurrentUser = (data) => (dispatch) =>
  dispatch({ type: CURRENT_USER_UPDATE, data });

export const assignPackingStation = (code) => async (dispatch) => {
  try {
    const type = code.split("|")[0];
    const packing_station_id = code.split("|")[1];

    if (type !== "packing_station") {
      throw new Error("Nieprawidłowy kod stanowiska");
    }

    const { data } = await client_v2.post(`/users/assign_packing_station`, {
      packing_station_id,
    });

    dispatch(updateCurrentUser(data));
  } catch (error) {
    toastr.error(
      "Błąd",
      error?.message || "Nie udało się przypisać stanowiska"
    );
  }
};

export const skipScanningPackingStation = () => async (dispatch) => {
  try {
    const { data } = await client_v2.post(`/users/assign_packing_station`, {
      packing_station_id: 21,
    });

    dispatch(updateCurrentUser(data));
  } catch (error) {
    toastr.error("Błąd", "Nie udało się pominąć skanowania");
  }
};
