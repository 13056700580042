import React, { useState, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import classNames from "classnames";
import { withRouter } from "react-router";

import {
  getSuggestedCartToPack,
  assignPackerToCart,
} from "actions/gdn_packs";
import { TimeAgo } from "components";
import onScan from "utils/scan_handler";

import { ReactComponent as ClockIcon } from "icons/clock.svg";

const SuggestedCart = ({ history }) => {
  const [last_updated, setLastUpdated] = useState(null);
  const [is_assigning, setAssigning] = useState(false);

  const {
    isLoading: is_loading,
    isFetching: is_fetching,
    refetch,
    data,
  } = useQuery(
    ["suggested_cart"],
    () => getSuggestedCartToPack(),
    {
      keepPreviousData: true,
      refetchInterval: 10 * 1000,
      refetchIntervalInBackground: true,
      select: (res) => res.data,
      onSuccess: () => {
        setLastUpdated(Date.now() + 11 * 1000);
      },
    }
  );

  const handleScan = async ({ detail: { scanCode } }) => {
    try {
      const type = scanCode.split("|")[0];
      if (type === "gdn_id") {
        const gdn_id = scanCode.split("|")[1];
        history.push(`/packing/gdn/${gdn_id}`);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleAssignPacker = async () => {
    try {
      setAssigning(true);
      const id = await assignPackerToCart(data?.id);
      history.push(`/packing/gdn_pack/${id}`);
    } catch (error) {
      console.log(error);
    } finally {
      setAssigning(false);
    }
  };

  useEffect(() => {
    onScan.attachTo(document);
    document.addEventListener("scan", handleScan);
    return () => {
      onScan.detachFrom(document);
      document.removeEventListener("scan", handleScan);
    };
  }, [data?.id]);

  if (is_loading) {
    return null;
  }

  const suggested_cart_exist = Boolean(data?.id);

  return (
    <>
      <div
        className={classNames("packs-suggested-cart", {
          is_fetching,
        })}>
        <div className="packs-suggested-cart__header">
          <span className="title">
            {suggested_cart_exist ? (
              "Dostępny wózek"
            ) : (
              <>
                Brak wózków.
                <br />
                Skompletuj paka
              </>
            )}
          </span>
        </div>
        {suggested_cart_exist && (
          <button
            disabled={is_assigning}
            className="packs-suggested-cart__button"
            onClick={handleAssignPacker}>
            <span>
              {is_assigning
                ? "Proszę czekać..."
                : "Przypisz"}
            </span>
          </button>
        )}
      </div>
      <div className="packs-suggested-cart__refresh">
        <span className="timestamp" onClick={refetch}>
          <ClockIcon />
          Odświeżenie <TimeAgo date={last_updated} />
        </span>
      </div>
    </>
  );
};

export default withRouter(SuggestedCart);
