const initialState = {
  status: "invalid",
  filters: {
    open: true,
    query: {
      "q[status_in]": [0],
    },
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "WAREHOUSE_TASKS_TOGGLE_FILTERS":
      return {
        ...state,
        filters: {
          ...state.filters,
          open: !state.filters.open,
        },
      };
    case "WAREHOUSE_TASKS_RESET_FILTERS":
      return {
        ...state,
        filters: {
          open: true,
          query: {},
        },
      };
    case "WAREHOUSE_TASKS_SET_FILTERS_QUERY":
      return {
        ...state,
        filters: { ...state.filters, query: action.query },
      };
    case "WAREHOUSE_TASKS_LOADING":
      return {
        ...state,
        status: "loading",
      };

    case "WAREHOUSE_TASKS_SEARCHING":
      return {
        ...state,
        status: "searching",
      };
    case "WAREHOUSE_TASKS_SUCCESS":
      return {
        ...state,
        status: "success",
        ...action,
      };
    case "WAREHOUSE_TASKS_FAILURE":
      return {
        ...state,
        status: "failure",
      };
    default:
      return state;
  }
};
