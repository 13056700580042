import React from "react";
import {
  NotFound,
  //
  AdminDashboard,
  //
  WarehousesList,
  WarehouseEdit,
  WarehouseEmptyPositions,
  WarehouseDoublePositions,
  WarehouseClosePositions,
  //
  ProductsList,
  ProductsEdit,
  //
  GrnsList,
  GrnEdit,
  //
  GdnsList,
  GdnPreview,
  //
  PacksList,
  //
  MissingsList,
  //
  CompletingPacks,
  CompletingGdns,
  CompletingPackGdn,
  //
  SortingList,
  //
  SuppliersList,
  SupplierNew,
  SupplierEdit,
  //
  CartsList,
  //
  UsersList,
  UserEdit,
  //
  StationsList,
  //
  WarehouseTasksList,
  //
  WeightShow,
  //
  SpeditorsList,
  //
  IslandZipCodesList,
  IslandZipCodeNew,
  IslandZipCodeEdit,
  //
  SettingsEdit,
  //
  OmsOrderRedirect,
  //
  PackagesList,
  PackageNew,
  PackageEdit,
  PackagesStats,
  //
  GdnErrorsStats,
  //
  Inventarization2023,
  WarehouseStockStats
} from "../../pages";

import { Switch, Route } from "react-router-dom";

const AdminRoutes = () => {
  return (
    <Switch>
      <Route exact={true} path="/" component={AdminDashboard} />
      <Route exact={true} path="/waga" component={WeightShow} />
      <Route
        exact={true}
        path="/products/:id/:type*"
        component={ProductsEdit}
      />
      <Route exact={true} path="/products" component={ProductsList} />
      <Route exact={true} path="/missings" component={MissingsList} />
      <Route exact={true} path="/grns" component={GrnsList} />
      <Route exact={true} path="/grns/:id" component={GrnEdit} />
      <Route exact={true} path="/gdns" component={GdnsList} />
      <Route exact={true} path="/gdns/:id" component={GdnPreview} />
      <Route exact={true} path="/packs" component={PacksList} />
      <Route exact={true} path="/packing" component={CompletingPacks} />
      <Route
        exact={true}
        path="/packing/gdn_pack/:pack_id"
        component={CompletingGdns}
      />
      <Route
        exact={true}
        path="/packing/gdn/:id"
        component={CompletingPackGdn}
      />
      <Route exact={true} path="/sorting" component={SortingList} />
      <Route exact={true} path="/warehouses" component={WarehousesList} />
      <Route
        exact={true}
        path="/warehouses/:id/empty_positions"
        component={WarehouseEmptyPositions}
      />
      <Route
        exact={true}
        path="/warehouses/:id/double_positions"
        component={WarehouseDoublePositions}
      />
      <Route
        exact={true}
        path="/warehouses/:id/close_positions"
        component={WarehouseClosePositions}
      />
      <Route
        exact={true}
        path={[
          "/warehouses/:warehouse_id",
          "/warehouses/:warehouse_id/rooms/:warehouse_room_id",
          "/warehouses/:warehouse_id/rooms/:warehouse_room_id/aisles/:warehouse_aisle_id",
          "/warehouses/:warehouse_id/rooms/:warehouse_room_id/aisles/:warehouse_aisle_id/racks/:warehouse_rack_id",
        ]}
        component={WarehouseEdit}
      />
      <Route exact={true} path="/suppliers" component={SuppliersList} />
      <Route exact={true} path="/suppliers/new" component={SupplierNew} />
      <Route exact={true} path="/suppliers/:id" component={SupplierEdit} />
      <Route exact={true} path="/carts" component={CartsList} />
      <Route exact={true} path="/users" component={UsersList} />
      <Route exact={true} path="/users/:id" component={UserEdit} />
      <Route exact={true} path="/stations" component={StationsList} />
      <Route
        exact={true}
        path="/warehouse_tasks"
        component={WarehouseTasksList}
      />
      <Route exact={true} path="/speditors" component={SpeditorsList} />
      <Route
        exact={true}
        path="/island-zip-codes"
        component={IslandZipCodesList}
      />
      <Route
        exact={true}
        path="/island-zip-codes/new"
        component={IslandZipCodeNew}
      />
      <Route
        exact={true}
        path="/island-zip-codes/:id"
        component={IslandZipCodeEdit}
      />
      <Route exact={true} path="/settings" component={SettingsEdit} />
      <Route exact={true} path="/packages" component={PackagesList} />
      <Route exact={true} path="/packages/new" component={PackageNew} />
      <Route exact={true} path="/packages/stats" component={PackagesStats} />

      <Route exact={true} path="/packages/:id" component={PackageEdit} />
      <Route
        exact={true}
        path="/oms_order/:oms_order_id"
        component={OmsOrderRedirect}
      />
      <Route
        exact={true}
        path="/inwentaryzacja"
        component={Inventarization2023}
      />
      <Route exact={true} path="/gdn_errors_stats" component={GdnErrorsStats} />
      <Route exact={true} path="/warehouse_stock_stats" component={WarehouseStockStats} />
      <Route component={NotFound} />
    </Switch>
  );
};

export default AdminRoutes;
