import React, { useState } from "react";
import { connect } from "react-redux";
import QRCode from "qrcode";
import { Main } from "layouts";
import { saveAs } from "file-saver";
import { pdf } from "@react-pdf/renderer";
import { StickerUser } from "sticker_templates";
import { MainList } from "components";
import { getUserRoleName } from "utils/user_role_options";
import { sort, filters } from "./const/data";

import { ReactComponent as UserIcon } from "icons/user.svg";
import { client_v2 } from "utils/api";
import { setCookie } from "utils/cookies";

const getAuthToken = async (user_id) => {
  try {
    const { auth_token } = await client_v2.get(`/users/${user_id}/auth_token`);
    return auth_token;
  } catch (error) {
    throw error;
  }
};

const Users = ({ current_user, history }) => {
  const is_admin = current_user.role === "admin";

  const [is_loading, setLoading] = useState(false);
  const [is_loading_change_user, setLoadingChangeUser] = useState(false);

  const generateSticker = async ({ user_id, first_name, last_name }) => {
    try {
      setLoading(true);
      const auth_token = await getAuthToken(user_id);

      const code = await QRCode.toDataURL(`auth_token|${auth_token}`);

      const blob = await pdf(
        <StickerUser
          data={{
            first_name,
            last_name,
            code,
          }}
        />
      ).toBlob();

      saveAs(blob, `${first_name}_${last_name}.pdf`);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const changeUser = async (user_id) => {
    try {
      setLoadingChangeUser(true);
      const auth_token = await getAuthToken(user_id);
      setCookie("token", auth_token);
      setCookie("skip_station_scan", 1);
      window.location.reload();
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingChangeUser(false);
    }
  };

  return (
    <Main
      page={{
        name: "Użytkownicy",
        icon: <UserIcon />,
        breadcrumbs: [{ name: "Użytkownicy" }],
      }}
    >
      <MainList
        query_key="users"
        api_path="/users"
        api_context="ui_index"
        sort_column="id"
        sort_direction="desc"
        sort={sort}
        filters={filters}
        renderActions={({ id, first_name, last_name, role }) =>
          [
            is_admin && {
              type: "preview",
              disabled: is_loading_change_user,
              handleClick: () => changeUser(id),
            },
            role !== "admin" && {
              type: "qr_code",
              disabled: is_loading,
              handleClick: () =>
                generateSticker({
                  user_id: id,
                  first_name,
                  last_name,
                }),
            },
            {
              type: "edit",
              handleClick: () => history.push(`/users/${id}`),
            },
          ].filter(Boolean)
        }
        renderItem={({ id, email, first_name, last_name, role }) => (
          <>
            <td>{id}</td>
            <td>{email}</td>
            <td>{first_name}</td>
            <td>{last_name}</td>
            <td>{getUserRoleName(role)}</td>
          </>
        )}
      />
    </Main>
  );
};

export default connect(({ current_user }) => ({ current_user }))(Users);
