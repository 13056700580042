export const sort = [
  { label: "ID", name: "id", style: { width: "30%" } },
  {
    label: "Nazwa",
    name: "name",
    style: { width: "30%" },
  },
  {
    label: "Skrót",
    name: "identifier",
    style: { width: "20%" },
  },
];

export const filters = [
  {
    type: "input",
    name: "id",
    sort_id: "id",
    search_type: "matches",
  },
  {
    type: "input",
    name: "name",
    sort_id: "name",
    search_type: "matches",
  },
  {
    type: "input",
    name: "identifier",
    sort_id: "identifier",
    search_type: "matches",
  },
];
