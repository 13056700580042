import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { MainTable } from "components";
import { Main } from "layouts";
import { parseQuery } from "utils/api";
import {
  getWarehouseTasks,
  filterWarehouseTasks,
  resetFilters,
  toggleFilters,
} from "actions/warehouse_tasks";

import { ReactComponent as StationIcon } from "icons/dock.svg";
import {
  warehouse_task_options,
  getWarehouseTaskName,
  warehouse_task_status_options,
  getWarehouseTaskStatus,
} from "utils/warehouse_tasks";
import getUsers from "utils/queries/getUsers";
import moment from "moment";

const WarehouseTasks = ({
  location: { search },
  warehouse_tasks,
  getWarehouseTasks,
  filterWarehouseTasks,
  resetFilters,
  toggleFilters,
}) => {
  const [is_loaded, setLoaded] = useState(false);
  const query = parseQuery(search);
  const sort = {
    "sort[column]": query["sort[column]"] || "id",
    "sort[order]": query["sort[order]"] || "asc",
  };

  useEffect(() => {
    getWarehouseTasks({ ...query, ...sort });
    setLoaded(true);
  }, [search]);

  useEffect(() => {
    if (is_loaded) {
      getWarehouseTasks({ ...query, page: undefined });
    }
  }, [warehouse_tasks.filters.query]);

  useEffect(() => {
    return () => resetFilters();
  }, []);

  if (warehouse_tasks.status === "failure") {
    return <p>Error</p>;
  }

  const { data, meta } = warehouse_tasks;

  return (
    <Main
      page={{
        name: "Zadania magazynowe",
        icon: <StationIcon />,
        breadcrumbs: [{ name: "Zadania magazynowe" }],
      }}>
      <div className="dashboard">
        <MainTable
          is_loading={["invalid", "loading"].includes(
            warehouse_tasks.status
          )}
          is_searching={
            warehouse_tasks.status === "searching"
          }
          filters_open={warehouse_tasks.filters.open}
          toggleFilters={toggleFilters}
          data={data}
          meta={meta}
          sort={sort}
          head={[
            {
              label: "Rodzaj zadania",
              name: "type",
              style: { width: 200 },
            },
            {
              label: "Data utworzenia",
              name: "created_at",
              style: { width: 200 },
            },
            {
              label: "Data zakończenia",
              name: "completed_at",
              style: { width: 200 },
            },
            {
              label: "Pracownik",
              name: "user_id",
              style: { width: 200 },
            },
            {
              label: "Opis",
              name: "description",
            },
            {
              label: "Status",
              name: "status",
              style: { width: 200 },
            },
          ]}
          empty_text="Brak zadań magazynowych"
          renderRow={({
            id,
            created_at,
            completed_at,
            type,
            description,
            result_message,
            user,
            status,
          }) => {
            const label = getWarehouseTaskStatus(status);
            return (
              <tr key={id}>
                <td>{getWarehouseTaskName(type)}</td>
                <td>
                  {created_at
                    ? moment(created_at).format(
                        "YYYY-MM-DD HH:mm"
                      )
                    : "-"}
                </td>
                <td>
                  {completed_at
                    ? moment(completed_at).format(
                        "YYYY-MM-DD HH:mm"
                      )
                    : "-"}
                </td>
                <td>{user?.full_name || "-"}</td>
                <td>
                  <p>{description}</p>
                  {result_message ? (
                    <p>
                      <strong>{result_message}</strong>
                    </p>
                  ) : null}
                </td>
                <td>
                  <div
                    className={`status-label status-label--sm ${label.status}`}>
                    <span>{label.status_text}</span>
                  </div>
                </td>
              </tr>
            );
          }}
          filtersAction={filterWarehouseTasks}
          filters={[
            {
              type: "multi-select",
              label: "Rodzaj taska",
              name: "type",
              search_type: "in",
              options: warehouse_task_options,
            },
            {
              type: "multi-select",
              label: "Status",
              name: "status",
              search_type: "in",
              options: warehouse_task_status_options,
              defaultValue:
                warehouse_task_status_options[0],
            },
            {
              type: "async-multi-select",
              label: "Pracownik",
              asyncAction: getUsers,
              name: "user_id",
              search_type: "in",
            },
          ]}
        />
      </div>
    </Main>
  );
};

export default connect(
  ({ warehouse_tasks }) => ({ warehouse_tasks }),
  (dispatch) => ({
    getWarehouseTasks: (data) =>
      dispatch(getWarehouseTasks(data)),
    filterWarehouseTasks: (data) =>
      dispatch(filterWarehouseTasks(data)),
    toggleFilters: () => dispatch(toggleFilters()),
    resetFilters: () => dispatch(resetFilters()),
  })
)(WarehouseTasks);
