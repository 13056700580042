const initialState = {
  status: "invalid",
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "EDIT_PRODUCT_LOADING":
      return {
        ...state,
        status: "loading",
      };
    case "EDIT_PRODUCT_SUCCESS":
      return {
        ...state,
        status: "success",
        ...action,
      };
    case "EDIT_PRODUCT_FAILURE":
      return {
        ...state,
        status: "failure",
      };
    case "EDIT_PRODUCT_ADD_MAIN_LOCATION": {
      try {
        return {
          ...state,
          data: {
            ...state.data,
            product_main_locations: [
              ...state.data.product_main_locations,
              action.data,
            ],
          },
        };
      } catch (error) {
        console.log(error);
        return state;
      }
    }
    case "EDIT_PRODUCT_DELETE_MAIN_LOCATION": {
      try {
        return {
          ...state,
          data: {
            ...state.data,
            product_main_locations: state.data.product_main_locations.filter(
              ({ id }) => id !== action.id
            ),
          },
        };
      } catch (error) {
        console.log(error);
        return state;
      }
    }
    case "EDIT_PRODUCT_UPDATE_MAIN_LOCATION": {
      const new_data = [
        ...state.data.product_main_locations,
      ];
      const index = new_data.indexOf(
        new_data.find(({ id }) => id === action.data.id)
      );
      new_data[index] = action.data;
      try {
        return {
          ...state,
          data: {
            ...state.data,
            product_main_locations: new_data,
          },
        };
      } catch (error) {
        console.log(error);
        return state;
      }
    }
    case "EDIT_PRODUCT_ADD_EAN": {
      return {
        ...state,
        data: {
          ...state.data,
          logistic_eans: [
            ...state.data.logistic_eans,
            action.data,
          ],
        },
      };
    }
    case "EDIT_PRODUCT_EDIT_EAN": {
      const logistic_eans = [...state.data.logistic_eans];
      const index = logistic_eans.indexOf(
        logistic_eans.find(
          ({ id }) => Number(id) === Number(action.data.id)
        )
      );
      if (index > -1) {
        logistic_eans[index] = action.data;
      }
      return {
        ...state,
        data: {
          ...state.data,
          logistic_eans,
        },
      };
    }

    case "EDIT_PRODUCT_DELETE_EAN":
      return {
        ...state,
        data: {
          ...state.data,
          logistic_eans: state.data.logistic_eans.filter(
            ({ id }) => id !== action.id
          ),
        },
      };
    case "CREATE_PRODUCT_SUPPLIER": {
      let product_suppliers = [
        ...state.data.product_suppliers,
      ];
      if (action.data.is_master) {
        product_suppliers = product_suppliers.map(
          (item) => ({ ...item, is_master: false })
        );
      }

      return {
        ...state,
        data: {
          ...state.data,
          product_suppliers: [
            ...product_suppliers,
            action.data,
          ],
        },
      };
    }
    case "UPDATE_PRODUCT_SUPPLIER": {
      let product_suppliers = [
        ...state.data.product_suppliers,
      ];
      const index = product_suppliers.indexOf(
        product_suppliers.find(
          ({ id }) => Number(id) === Number(action.data.id)
        )
      );

      if (index > -1) {
        if (action.data.is_master) {
          product_suppliers = product_suppliers.map(
            (item) => ({ ...item, is_master: false })
          );
        }
        product_suppliers[index] = action.data;
      }
      return {
        ...state,
        data: {
          ...state.data,
          product_suppliers,
        },
      };
    }
    case "DELETE_PRODUCT_SUPPLIER": {
      return {
        ...state,
        data: {
          ...state.data,
          product_suppliers: state.data.product_suppliers.filter(
            ({ id }) => id !== action.id
          ),
        },
      };
    }
    default:
      return state;
  }
};
