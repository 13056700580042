import React, { useState } from "react";
import { withRouter } from "react-router";
import { MainList } from "components";
import ModalAddRackType from "./components/ModalAddRackType";
import ModalEditRackType from "./components/ModalEditRackType";
import ModalDeleteRackType from "./components/ModalDeleteRackType";

import { sort, filters } from "./const/data";

const default_state = {
  type: null,
  data: null,
};

const WarehouseRackSchemas = () => {
  const [rack_schema_modal, setRackSchemaModal] = useState(
    default_state
  );

  const onCloseRackModal = () =>
    setRackSchemaModal(default_state);

  return (
    <div className="box-5">
      <MainList
        query_key="warehouse_rack_schemas"
        api_path="/warehouse_rack_schemas"
        api_context="ui_index"
        title="Typy regałów"
        sort_column="id"
        sort_direction="desc"
        sort={sort}
        filters={filters}
        renderActions={(item) =>
          false
            ? [
                {
                  type: "preview",
                  handleClick: () =>
                    setRackSchemaModal({
                      type: "previev",
                      data: item,
                    }),
                },
              ]
            : [
                {
                  type: "edit",
                  handleClick: () =>
                    setRackSchemaModal({
                      type: "edit",
                      data: item,
                    }),
                },
                {
                  type: "remove",
                  handleClick: () =>
                    setRackSchemaModal({
                      type: "remove",
                      data: item,
                    }),
                },
              ]
        }
        renderItem={({ id, name, identifier }) => (
          <>
            <td>{id}</td>
            <td>{name}</td>
            <td>{identifier}</td>
          </>
        )}
        bottom_action={{
          name: "Dodaj typ",
          onClick: () =>
            setRackSchemaModal({ type: "create" }),
        }}>
        {rack_schema_modal?.type === "create" && (
          <ModalAddRackType
            handleClose={onCloseRackModal}
          />
        )}
        {rack_schema_modal?.type === "edit" && (
          <ModalEditRackType
            {...rack_schema_modal.data}
            initialValues={{
              name: rack_schema_modal.data.name,
              identifier: rack_schema_modal.data.identifier,
            }}
            handleClose={onCloseRackModal}
          />
        )}
        {rack_schema_modal?.type === "remove" && (
          <ModalDeleteRackType
            {...rack_schema_modal.data}
            handleClose={onCloseRackModal}
          />
        )}
      </MainList>
    </div>
  );
};

export default withRouter(WarehouseRackSchemas);
