import React from "react";
import { connect } from "react-redux";
import { reduxForm, formValueSelector } from "redux-form";

import { setGrnComplete } from "actions/grns";

import Confirm from "./components/Confirm";
import DiffList from "./components/DiffList";
import ErrorText from "./components/ErrorText";
import InfoText from "./components/InfoText";
import OtherErrorForm from "./components/OtherErrorForm";
import DispatchedError from "./components/DispatchedError";

const Summary = ({
  data,
  has_other_error,
  handleSubmit,
  setGrnComplete,
}) => {
  const onSubmit = (values) => {
    setGrnComplete(data?.id, values);
  };
  return (
    <form
      noValidate
      onSubmit={handleSubmit(onSubmit)}
      className="grn-summary">
      <div className="grn-summary__left">
        <DispatchedError />
        <InfoText />
        <DiffList />
        <OtherErrorForm has_other_error={has_other_error} />
        <ErrorText />
      </div>
      <div className="grn-summary__right">
        <Confirm />
      </div>
    </form>
  );
};

const selector = formValueSelector("grn-confirm-form");
export default connect(
  (state) => ({
    has_other_error: selector(state, "has_other_error"),
    data: state?.grn?.data,
    initialValues: {
      has_other_error: state?.grn?.data?.has_other_error,
      other_error: state?.grn?.data?.other_error,
    },
  }),
  (dispatch) => ({
    setGrnComplete: (id, values) =>
      dispatch(setGrnComplete(id, values)),
  })
)(
  reduxForm({
    form: "grn-confirm-form",
    enableReinitialize: true,
  })(Summary)
);
