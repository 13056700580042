import { toastr } from "react-redux-toastr";
import { client_v2 } from "utils/api";

export const checkGdnsForErrors = async (pack_id) => {
  try {
    const { data } = await client_v2.get(
      `/gdn_packs/${pack_id}/gdns_with_required_action`
    );
    return data;
  } catch (error) {
    if (error.response.status === 404) {
      toastr.error("Błąd", "Nie znaleziono paka");
      window.location.pathname = "/packing";
    } else {
      toastr.error(
        "Błąd",
        "Wystąpił błąd podczas sprawdzania zamówień"
      );
    }

    throw error;
  }
};

export const getPackGdns = ({ pack_id }) => (dispatch) =>
  new Promise(async (resolve, reject) => {
    try {
      dispatch({ type: "PACK_GDNS_LOADING" });
      const { data } = await client_v2.get(
        `/gdn_packs/${pack_id}?context=ui_show`
      );
      dispatch({
        type: "PACK_GDNS_SUCCESS",
        data,
      });
      resolve(data);
    } catch (error) {
      dispatch({ type: "PACK_GDNS_FAILURE" });
      toastr.error(
        "Błąd",
        "Wystąpił błąd podczas pobierania"
      );
      reject(error);
    }
  });

export const gdnPackStartPacking = (pack_id) => (
  dispatch
) =>
  new Promise(async (resolve, reject) => {
    try {
      await client_v2.post(
        `/gdn_packs/${pack_id}/start_packing`
      );
      const { data } = await client_v2.get(
        `/gdn_packs/${pack_id}?context=ui_show`
      );
      dispatch({
        type: "PACK_GDNS_SUCCESS",
        data,
      });
      resolve();
    } catch (error) {
      toastr.error(
        "Błąd",
        error?.response?.data?.message || "Wystąpił błąd"
      );
      reject();
    }
  });

export const gdnsPackComplete = (pack_id) =>
  new Promise(async (resolve, reject) => {
    try {
      await client_v2.post(
        `/gdn_packs/${pack_id}/complete`
      );
      resolve();
    } catch (error) {
      toastr.error(
        "Błąd",
        error?.response?.data?.message || "Wystąpił błąd"
      );
      reject(error);
    }
  });

export const gdnPackClose = async (pack_id) => {
  try {
    await client_v2.post(
      `/gdn_packs/${pack_id}/close_empty`
    );
  } catch (error) {
    toastr.error(
      "Błąd",
      error?.response?.data?.message || "Wystąpił błąd"
    );
    throw error;
  }
};
