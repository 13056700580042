const package_boxes = {
  lists: [
    {
      name: "D14",
      id: "d_14",
      sizes: {
        width: 20.0,
        height: 27.5,
        depth: 5.0,
      },
    },
    {
      name: "G17",
      id: "g_17",
      sizes: {
        width: 26.0,
        height: 35.0,
        depth: 4.0,
      },
    },
    {
      name: "K20",
      id: "k_20",
      sizes: {
        width: 38.0,
        height: 48.0,
        depth: 2.0,
      },
    },
  ],
  cartons_a: [
    {
      name: "A3",
      id: "a_3",
      sizes: {
        width: 38.0,
        height: 20.0,
        depth: 8.0,
      },
    },
    {
      name: "A4",
      id: "a_4",
      sizes: {
        width: 8.0,
        height: 40.0,
        depth: 38.0,
      },
    },
  ],
  cartons_b: [
    {
      name: "B5",
      id: "b_5",
      sizes: {
        width: 19.0,
        height: 20.0,
        depth: 38.0,
      },
    },
    {
      name: "B6",
      id: "b_6",
      sizes: {
        width: 40.0,
        height: 38.0,
        depth: 19.0,
      },
    },
    {
      name: "B9",
      id: "b_9",
      sizes: {
        width: 21.0,
        height: 15,
        depth: 11,
      },
    },
  ],
  cartons_c: [
    {
      name: "C1",
      id: "c_1",
      sizes: {
        width: 40.0,
        height: 30.0,
        depth: 38.0,
      },
    },
    {
      name: "C2",
      id: "c_2",
      sizes: {
        width: 40.0,
        height: 38.0,
        depth: 40.0,
      },
    },
    {
      name: "C4",
      id: "c_4",
      sizes: {
        width: 59.5,
        height: 40.5,
        depth: 34.0,
      },
    },
  ],
  cartons_t: [
    {
      name: "T2",
      id: "t_2",
      sizes: {
        width: 110.0,
        height: 50.0,
        depth: 30.0,
      },
    },
  ],
  cartons_e: [
    {
      name: "E1",
      id: "e_1",
      sizes: {
        width: 52.0,
        height: 16.0,
        depth: 16.0,
      },
    },
    {
      name: "E2",
      id: "e_2",
      sizes: {
        width: 52.0,
        height: 26.0,
        depth: 26.0,
      },
    },
  ],
  cartons_r: [
    {
      name: "R1",
      id: "r_1",
      sizes: {
        width: 74.0,
        height: 27.0,
        depth: 74.0,
      },
    },
  ],
  custom: [
    {
      name: "Niestandardowe",
      id: "none",
      sizes: {
        width: 0,
        height: 0,
        depth: 0,
      },
    },
  ],
};

export const package_boxes_modal_list = [
  {
    label: "",
    is_gauge: false,
    items: package_boxes.custom,
  },
  {
    label: "Koperty",
    is_gauge: false,
    items: package_boxes.lists,
  },
  {
    label: "Kartony A",
    is_gauge: false,
    items: package_boxes.cartons_a,
  },
  {
    label: "Kartony B",
    is_gauge: false,
    items: package_boxes.cartons_b,
  },
  {
    label: "Kartony C",
    is_gauge: false,
    items: package_boxes.cartons_c,
  },
  {
    label: "Kartony T",
    is_gauge: true,
    items: package_boxes.cartons_t,
  },
  {
    label: "Kartony E",
    is_gauge: false,
    items: package_boxes.cartons_e,
  },
  {
    label: "Kartony R",
    is_gauge: true,
    items: package_boxes.cartons_r,
  },
];

export default package_boxes;
