import styled from "styled-components";

const StyledWarningModalContent = styled.div`
  text-align: center;
  h2 {
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
  }
  .buttons-wrapper {
    margin: 30px 0 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &.center {
      justify-content: center;
      .btn {
        flex-basis: 100%;
        max-width: 100%;
      }
    }
    .btn {
      flex-basis: calc(50% - 10px);
      max-width: calc(50% - 10px);
      justify-content: center;
      white-space: nowrap;
      min-height: 80px;
      span {
        font-size: 16px;
        line-height: 20px;
      }
    }
    .btn-warning {
      background-color: #ffb822;
      span {
        color: #fff;
      }
      &:hover {
        opacity: 0.8;
      }
    }
    .btn-danger {
      background-color: #fd397a;
      span {
        color: #fff;
      }
      &:hover {
        opacity: 0.8;
      }
    }
  }
`;

export default StyledWarningModalContent;
