import styled from "styled-components";

const StyledProductsList = styled.ul`
  padding: 0 30px;
  li {
    display: flex;
    align-items: center;
    padding: 1px 30px 1px 0;
    &:not(:last-child) {
      border-bottom: 1px solid #ebedf2;
    }
    .btn-preview {
      width: 100px;
      height: 100px;
      border-radius: 4px;
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      overflow: hidden;
      position: relative;
      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(93, 120, 255, 0.8);
        opacity: 0;
        transition: 0.2s;
        z-index: 1;
      }
      &::after {
        content: url(${require("icons/zoom-big.svg")});
        position: absolute;
        z-index: 2;
        top: 50%;
        left: 50%;
        opacity: 0;
        transition: 0.2s;
        transform: translate(-50%, -50%);
      }
      &:hover {
        &::before,
        &::after {
          opacity: 1;
        }
      }
    }
    .product-info {
      flex: 1;
      padding: 0 30px;
      p {
        font-size: 13px;
        line-height: 20px;
        font-weight: 300;
        color: #595d6e;
        &.sku {
          font-weight: 500;
        }
      }
    }
    .product-qty {
      flex-basis: 60px;
      max-width: 60px;
      .qty-box {
        min-width: 35px;
        min-height: 35px;
        padding: 5px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        border: 1px solid #ebedf2;
        span {
          font-size: 22px;
          line-height: 22px;
          font-weight: 500;
          color: #595d6e;
        }
      }
    }
  }
`;

export default StyledProductsList;
