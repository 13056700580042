import React from "react";
import { connect } from "react-redux";

import { ReactComponent as WarningIcon } from "icons/light-warning.svg";

const DispatchedError = ({ data }) => {
  if (Boolean(data?.dispatch_error)) {
    return (
      <p className="box-text">
        <WarningIcon /> {data.dispatch_error}
      </p>
    );
  }
  return null;
};

export default connect(({ grn }) => ({ data: grn?.data }))(
  DispatchedError
);
