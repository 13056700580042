const getGdnStatus = (status) => {
  switch (status) {
    // { new: 0, picked: 1, packed: 2, completed: 3, error: 4, with_missings: 5, problem: 6, to_cancel: 7 }
    case "new":
      return { name: "Nowe", color: "default" };
    case "picked":
      return { name: "Skompletowane", color: "info-light" };
    case "packed":
      return { name: "Spakowane", color: "success" };
    case "problem":
      return { name: "Problem", color: "warning" };
    case "to_cancel":
      return { name: "Do anulowania", color: "dark" };
    case "with_missings":
      return { name: "Brak", color: "danger" };
    default:
      return { name: "Brak", color: "default" };
  }
};

export default getGdnStatus;
