import React from "react";
import {
  Document,
  Image,
  Page,
  Text,
  View,
  StyleSheet,
  Font,
} from "@react-pdf/renderer";

Font.register({
  family: "PoppinsBold",
  src: "/fonts/Poppins-Medium.ttf",
});

Font.register({
  family: "PoppinsRegular",
  src: "/fonts/Poppins-Regular.ttf",
});

const PdfDoc = ({ data, show_images, show_packages }) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.header}>
        <Text style={styles.header_text}>{data?.identifier}</Text>
      </View>
      <View style={styles.table_header} fixed>
        <View style={styles.row}>
          <View style={styles.box_sm}>
            <Text style={styles.text}>Lp</Text>
          </View>
          {show_images && <View style={styles.box_md} />}
          <View style={styles.box_lg}>
            <Text style={styles.text}>Nazwa</Text>
          </View>
          <View style={styles.box_sm}>
            <Text style={styles.text}>Zam.</Text>
          </View>
        </View>
      </View>
      {data?.grn_lines_to_download?.length > 0 &&
        data.grn_lines_to_download.map(
          ({ no, product, quantity, supplier_code, supplier_quantity }) => (
            <View
              key={product.id}
              wrap={false}
              style={[
                styles.row,
                {
                  borderBottomWidth: 1,
                  borderBottomColor: "#f8f9fa",
                  paddingVertical: 5,
                },
              ]}
            >
              <Text style={[styles.box_sm, styles.text]}>{no}</Text>
              {show_images && (
                <>
                  {product?.image_thumb_url ? (
                    <Image
                      src={product?.image_thumb_url}
                      style={styles.box_md}
                    />
                  ) : (
                    <View style={[styles.box_md, { height: 50 }]} />
                  )}
                </>
              )}
              <View style={styles.box_lg}>
                <Text style={styles.text}>{product?.name}</Text>
                <Text style={styles.text_muted}>
                  Sku: {product?.sku} | Kod dostawcy: {supplier_code}
                </Text>
              </View>
              {show_packages ? (
                <View style={styles.box_md}>
                  {supplier_quantity.is_decomposed &&
                    supplier_quantity.decomposed_qty > 0 && (
                      <Text style={[styles.text, styles.text_right]}>
                        {supplier_quantity.is_decomposed
                          ? `${supplier_quantity.package_qty} op. x ${supplier_quantity.decomposed_qty} szt.`
                          : "-"}
                      </Text>
                    )}
                  <Text style={[styles.text_muted, styles.text_right]}>
                    {quantity} szt.
                  </Text>
                </View>
              ) : (
                <Text style={[styles.box_sm, styles.text]}>{quantity}</Text>
              )}
            </View>
          )
        )}
    </Page>
  </Document>
);

const styles = StyleSheet.create({
  page: {
    backgroundColor: "#fff",
    margin: 0,
    padding: 0,
  },
  header: {
    padding: 30,
    textAlign: "center",
  },
  header_text: {
    fontSize: 20,
    fontFamily: "PoppinsBold",
  },
  table_header: {
    backgroundColor: "#f8f9fa",
    paddingVertical: 12,
  },
  text: {
    fontSize: 12,
    fontFamily: "PoppinsRegular",
  },
  text_right: {
    textAlign: "right",
  },
  text_muted: {
    marginTop: 10,
    fontSize: 10,
    color: "#a2a5b9",
    fontFamily: "PoppinsRegular",
  },
  row: {
    flexDirection: "row",
    flexFlow: "wrap",
    alignItems: "center",
    paddingHorizontal: 10,
  },
  box_sm: {
    flexBasis: 40,
    paddingHorizontal: 5,
  },
  box_md: {
    flexBasis: 100,
    paddingHorizontal: 5,
  },
  box_lg: {
    flex: 1,
    paddingLeft: 5,
    paddingRight: 50,
  },
});

export default PdfDoc;
