import { toastr } from "react-redux-toastr";
import { client_v2, client } from "utils/api";

export const getGdnPacks = () => async (
  dispatch,
  getState
) => {
  const { current_user } = getState();
  try {
    dispatch({ type: "GDN_PACKS_LOADING" });
    const { data, meta } = await client_v2.get(
      "/gdn_packs/packer_packs?&page=1&per_page=50"
    );
    const {
      data: { data: statistics },
    } = await client.get(
      `/stats/today_completed_by_packer?user_id=${current_user?.id}`
    );
    dispatch({
      type: "GDN_PACKS_SUCCESS",
      data,
      meta,
      statistics,
    });
  } catch (error) {
    dispatch({ type: "GDN_PACKS_FAILURE" });
    toastr.error(
      "Błąd",
      "Wystąpił błąd podczas pobierania"
    );
  }
};

export const getMoreGdnPacks = (page = 2) => async (
  dispatch
) => {
  try {
    const { data, meta } = await client_v2.get(
      `/gdn_packs/packer_packs?page=${page}&per_page=50`
    );
    dispatch({
      type: "GDNS_PACKS_MORE_SUCCESS",
      data,
      meta,
    });
  } catch (error) {
    toastr.error(
      "Błąd",
      "Wystąpił błąd podczas pobierania"
    );
  }
};

export const assignPackerByCartCode = (
  code,
  suggested_cart_id
) =>
  new Promise(async (resolve, reject) => {
    try {
      const type = code.split("|")[0];
      const cart_id = code.split("|")[1];
      if (type === "cart") {
        const {
          data: { gdn_pack_id },
        } = await client_v2.post(
          `/carts/${cart_id}/assign_packer?suggested_cart_id=${suggested_cart_id}`
        );
        resolve(gdn_pack_id);
      } else {
        throw new Error("Błędny kod");
      }
    } catch (error) {
      toastr.error(
        "Błąd",
        error?.message ||
          error?.response?.data?.message ||
          "Wystąpił błąd"
      );
      reject(error);
    }
  });

export const assignPackerToCart = (cart_id) =>
  new Promise(async (resolve, reject) => {
    try {
      const {
        data: { gdn_pack_id },
      } = await client_v2.post(
        `/carts/${cart_id}/assign_packer`
      );
      resolve(gdn_pack_id);
    } catch (error) {
      toastr.error(
        "Błąd",
        error?.message ||
          error?.response?.data?.message ||
          "Wystąpił błąd"
      );
      reject(error);
    }
  });

export const getSuggestedCartToPack = () =>
  client_v2.get("/carts/suggested_cart_to_pack");
