import moment from "moment";

export const date_options = [
  { label: "Wczoraj", value: "yesterday" },
  { label: "Dzisiaj", value: "today" },
  { label: "Ostatnie 7 dni", value: "last_7_days" },
  { label: "Ostatnie 14 dni", value: "last_14_days" },
  { label: "Ostatnie 30 dni", value: "last_30_days" },
  { label: "Ten miesiąc", value: "current_month" },
  { label: "Zeszły miesiąc", value: "last_month" },
  { label: "Ten rok", value: "this_year" },
  { label: "Zeszły rok", value: "last_year" },
];

export const getHistogramDates = (value) => {
  switch (value) {
    case "yesterday":
      return {
        histogram_range_from: moment().subtract(1, "day").startOf("day"),
        histogram_range_to: moment().subtract(1, "day").endOf("day"),
      };
    case "today":
      return {
        histogram_range_from: moment().startOf("day"),
        histogram_range_to: moment().endOf("day"),
      };
    case "last_7_days":
      return {
        histogram_range_from: moment().subtract(7, "day").startOf("day"),
        histogram_range_to: moment().endOf("day"),
      };
    case "last_14_days":
      return {
        histogram_range_from: moment().subtract(14, "day").startOf("day"),
        histogram_range_to: moment().endOf("day"),
      };
    case "last_30_days":
      return {
        histogram_range_from: moment().subtract(30, "day").startOf("day"),
        histogram_range_to: moment().endOf("day"),
      };

    case "current_month":
      return {
        histogram_range_from: moment().startOf("month"),
        histogram_range_to: moment().endOf("month"),
      };
    case "last_month":
      return {
        histogram_range_from: moment().subtract(1, "month").startOf("month"),
        histogram_range_to: moment().subtract(1, "month").endOf("month"),
      };
    case "this_year":
      return {
        histogram_range_from: moment().startOf("year"),
        histogram_range_to: moment().endOf("year"),
      };
    case "last_year":
      return {
        histogram_range_from: moment().subtract(1, "year").startOf("year"),
        histogram_range_to: moment().subtract(1, "year").endOf("year"),
      };
    default:
      break;
  }
};
