import React, { useState, useEffect } from "react";
import { CSVLink } from "react-csv";
import { connect } from "react-redux";
import { Button } from "expano-components";
import { MultiSelect, Modal } from "components";
import { ReactComponent as DownloadIcon } from "icons/download.svg";

const options = [
  { label: "Nazwa produktu", value: "product_name" },
  { label: "Kod sprzedażowy", value: "producer_code" },
  { label: "Kod dostawcy", value: "supplier_code" },
  { label: "Ilość sztuki", value: "quantity" },
  { label: "Ilość opakowanie", value: "package_quantity" },
  { label: "Jednostka", value: "unit" },
  { label: "Jednostka dostawcy", value: "supplier_custom_unit" },
  { label: "Ean logistyczny", value: "logistic_ean" },
];

const GenerateCsvModal = ({ name, data, handleClose }) => {
  const [csvk_data, setCsvkData] = useState([]);
  const [columns, setColumns] = useState([]);
  useEffect(() => {
    if (columns?.length > 0) {
      const csvk = [columns.map(({ label }) => label)];
      for (const item of data) {
        const item_array = [];
        for (const column of columns) {
          item_array.push(item[column.value] || "");
        }
        csvk.push(item_array);
      }
      setCsvkData(csvk);
    }
  }, [columns]);
  return (
    <Modal
      header={{
        title: "Eksportuj do CSV",
        icon: <DownloadIcon />,
      }}
      footer={
        <>
          <Button type="cancel" onClick={handleClose} />
          {columns?.length > 0 ? (
            <CSVLink data={csvk_data} filename={`${name}.csv`}>
              <button type="button" className="btn btn-sm btn-green">
                <span>Eksportuj</span>
              </button>
            </CSVLink>
          ) : (
            <button
              type="button"
              className="btn btn-sm btn-green"
              disabled={true}
            >
              <span>Eksportuj</span>
            </button>
          )}
        </>
      }
      handleClose={handleClose}
    >
      <MultiSelect
        label="Wybierz kolumny"
        options={options}
        handleChange={(data) => setColumns(data)}
      />
    </Modal>
  );
};

const createData = (data) =>
  data.map(
    ({
      supplier_code,
      product: { name, sku, logistic_eans },
      supplier_quantity,
      quantity,
    }) => ({
      supplier_code,
      product_name: name,
      product_sku: sku,
      quantity: quantity,
      unit: supplier_quantity.unit,
      supplier_package_quantity: supplier_quantity.package_qty,
      is_decomposed: supplier_quantity.is_decomposed,
      is_composed: supplier_quantity.is_composed,
      supplier_quantity: supplier_quantity.unit_qty,
      package_quantity:
        supplier_quantity.is_decomposed || supplier_quantity.is_composed
          ? supplier_quantity.package_qty
          : quantity,
      decomposed_missing_qty:
        supplier_quantity.decomposed_qty - supplier_quantity.unit_qty,
      supplier_custom_unit:
        supplier_quantity.supplier_custom_unit || supplier_quantity.unit,
      logistic_ean: logistic_eans?.[0]?.code || "",
    })
  );
export default connect(
  ({
    grn: {
      data: { grn_lines_to_download, identifier },
    },
  }) => ({
    name: identifier,
    data: createData(grn_lines_to_download),
  }),
  null
)(GenerateCsvModal);
