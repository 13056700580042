import { toastr } from "react-redux-toastr";
import { client } from "utils/api";

export const getMissings = () => async (dispatch) => {
  try {
    dispatch({ type: "MISSINGS_LOADING" });
    const {
      data: { data },
    } = await client.get(`/missings`);
    dispatch({
      type: "MISSINGS_SUCCESS",
      data,
    });
  } catch (error) {
    dispatch({ type: "MISSINGS_FAILURE" });
    console.log(error);
    toastr.error(
      "Błąd",
      "Wystąpił błąd podczas pobierania"
    );
  }
};
