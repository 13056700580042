import React, { useState } from "react";
import { ModalPreview } from "expano-components";
import { connect } from "react-redux";
import ReactDOM from "react-dom";
import classNames from "classnames";
import { markAsPackedLeaflet } from "actions/pack_gdn";
import { ReactComponent as CheckIcon } from "icons/check.svg";
import { ReactComponent as ArrowIcon } from "icons/arrow-next.svg";

import StyledGdnLineBrand from "./styles";
import PackConditionallyModal from "../GdnLine/components/PackConditionallyModal";

const GdnLineBrand = ({
  is_leaflet_scanned,
  show_leaflet,
  leaflet,
  markAsPackedLeaflet,
  disabled = false,
}) => {
  const [is_modal_open, openModal] = useState(false);
  const [
    show_pack_conditionally_modal,
    handleShowPackConditionallyModal,
  ] = useState(false);

  if (!show_leaflet) {
    return null;
  }

  return (
    <StyledGdnLineBrand
      className={classNames({ disabled })}>
      {show_pack_conditionally_modal && (
        <PackConditionallyModal
          product={{ logistic_eans: [], ean: leaflet.ean }}
          handleConfirm={() => markAsPackedLeaflet()}
          handleClose={() =>
            handleShowPackConditionallyModal(false)
          }
        />
      )}
      <div className="gdn-line__main">
        <div className="preview">
          <button
            className="btn-preview"
            onClick={() => openModal(true)}
            style={{
              backgroundImage: `url(${leaflet.image_url})`,
            }}
          />
        </div>
        <div className="info">
          <p className="title">WRZUĆ ULOTKĘ</p>
        </div>
        <div className="quantity">
          <span className="quantity__value">1</span>
        </div>
        <div className="action">
          {is_leaflet_scanned ? (
            <div className="action-group">
              <div className="box-icon">
                <CheckIcon />
              </div>
              <div className="box-text">
                <p>Potwierdzone</p>
              </div>
            </div>
          ) : (
            <div className="action-group">
              <button
                className="btn btn-warning"
                onClick={() =>
                  handleShowPackConditionallyModal(true)
                }>
                <span>Spakuj warunkowo</span>
                <ArrowIcon style={{ marginLeft: 10 }} />
              </button>
            </div>
          )}
        </div>
      </div>
      {is_modal_open &&
        ReactDOM.createPortal(
          <ModalPreview
            handleClose={() => {
              openModal(false);
            }}
            src={`${leaflet?.image_url}`}
            alt="Ulotka"
          />,
          document.getElementById("root")
        )}
    </StyledGdnLineBrand>
  );
};

export default connect(
  ({
    pack_gdn: { is_leaflet_scanned, show_leaflet, leaflet },
  }) => ({
    is_leaflet_scanned,
    show_leaflet,
    leaflet,
  }),
  (dispatch) => ({
    markAsPackedLeaflet: () =>
      dispatch(markAsPackedLeaflet()),
  })
)(GdnLineBrand);
