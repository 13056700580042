import React from "react";
import {
  Document,
  Image,
  Font,
  Page,
  Text,
  View,
  StyleSheet,
} from "@react-pdf/renderer";

import RobotoBlack from "fonts/Roboto-Black.ttf";

Font.register({
  family: "Roboto",
  fonts: [{ src: RobotoBlack, fontWeight: 900 }],
});

const StickerCartBoxQrCode = ({ data }) => (
  <Document>
    <Page size={{ width: 305, height: 60 }} style={styles.page}>
      <View style={styles.wrapper} wrap={true}>
        {data?.length > 0 &&
          data.map(({ cart_name, cart_box_identifier, qr_code_base_64 }) => (
            <View style={styles.box} key={cart_box_identifier}>
              <View style={styles.box_image}>
                <Image
                  src={qr_code_base_64}
                  style={{
                    width: 50,
                    height: 50,
                  }}
                />
              </View>
              <View style={styles.box_sku}>
                <Text style={styles.box_sku__text}>{cart_name}</Text>
                <Text style={styles.box_sku__text}>{cart_box_identifier}</Text>
              </View>
            </View>
          ))}
      </View>
    </Page>
  </Document>
);

export default StickerCartBoxQrCode;

const styles = StyleSheet.create({
  page: {
    backgroundColor: "#fff",
    paddingTop: 5,
  },
  wrapper: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "flex-start",
  },
  box: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: 100,
  },

  text_wrapper: {
    justifyContent: "flex-end",
    paddingBottom: 5,
    paddingLeft: 12,
  },
  text_normal: {
    fontFamily: "Roboto",
    fontSize: 7,
    fontWeight: 900,
    marginBottom: 3,
  },
  box_image: {
    flexBasis: 50,
  },
  box_sku: {
    width: 45,
    transform: "rotate(-90deg)",
    transformOrigin: "50% 50%",
    left: -5,
  },
  box_sku__text: {
    fontFamily: "Roboto",
    fontSize: 6,
    fontWeight: 900,
  },
});
