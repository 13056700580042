import React, { useEffect } from "react";
import { connect } from "react-redux";

import { Main } from "layouts";
import { ReactComponent as ProductIcon } from "icons/product.svg";
import Charts from "./components/Charts";
import { getGdnErrorsStats } from "actions/gdn_errors_stats";

const GdnErrorsStats = ({ gdn_errors_stats, getGdnErrorsStats }) => {
  useEffect(() => {
    getGdnErrorsStats();
  }, [gdn_errors_stats.filters]);
  return (
    <Main
      is_loading={["invalid", "loading"].includes(gdn_errors_stats.status)}
      page={{
        name: "Statystyki błedów w zamówieniach",
        icon: <ProductIcon />,
        breadcrumbs: [{ name: "Statystyki błędów" }],
      }}
    >
      <Charts />
    </Main>
  );
};

export default connect(
  ({ gdn_errors_stats }) => ({
    gdn_errors_stats,
  }),
  (dispatch) => ({
    getGdnErrorsStats: () => dispatch(getGdnErrorsStats()),
  })
)(GdnErrorsStats);
