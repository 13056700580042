import React from "react";
import DateTimeRangePicker from "@wojtekmaj/react-datetimerange-picker";

const CalendarIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
  >
    <rect
      fill="#595d6e"
      width="15.833"
      height="3.333"
      rx="1"
      transform="translate(1.667 4.167)"
    />
    <rect
      fill="#595d6e"
      opacity="0.3"
      width="15.833"
      height="8.333"
      rx="1"
      transform="translate(1.667 9.167)"
    />
  </svg>
);

const DatePicker = ({ label, name, onChange, value }) => {
  return (
    <div className="inp-wrapper">
      {label && (
        <label htmlFor={name} className="label-form">
          {label}
        </label>
      )}
      <div className="calendar-group">
        <DateTimeRangePicker
          disableClock={true}
          calendarIcon={<CalendarIcon />}
          onChange={onChange}
          value={value}
          locale="pl-PL"
          format="yyyy-MM-dd"
          maxDetail="minute"
        />
      </div>
    </div>
  );
};

export default DatePicker;
