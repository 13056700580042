import React from "react";
import { Chart } from "react-google-charts";
import { connect } from "react-redux";

import { ReactComponent as InfoIcon } from "icons/info.svg";

const createChartData = (data) => {
  const chart_data = [["Data", "Ilość"]];
  try {
    if (!data) return chart_data;
    const keys = Object.keys(data);
    keys.forEach((key) => {
      chart_data.push([
        key,
        data[key] ? parseFloat(data[key]) : 0,
      ]);
    });
  } catch (error) {
    console.log(error);
  }
  return chart_data;
};

const AveragePackages = ({ data, status }) => {
  if (status !== "success") {
    return null;
  }
  const chart_data = createChartData(data);

  return (
    <div className="dashboard chart">
      <div className="dashboard__header chart-box__header">
        <h2 className="dashboard__title heading">
          Średnia nadwyżka objętości paczek
        </h2>
        <div
          className="info-icon"
          data-tooltip="Średni tygodniowy stosunek objętości paczek do objętości produktów"
        >
          <InfoIcon />
        </div>
      </div>
      <Chart
        options={{
          chartArea: { width: "75%" },
          hAxis: {
            textStyle: {
              fontSize: 13,
              color: "#595D6E",
            },
          },
          legend: { position: "none" },
          colors: ["#5D78FF"],
        }}
        chartType="ColumnChart"
        loader={null}
        width="100%"
        height="300px"
        data={chart_data}
      />
    </div>
  );
};

export default connect(
  ({ average_packages: { data, status } }) => ({
    data,
    status,
  })
)(AveragePackages);
