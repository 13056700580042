import React from "react";
import { ReactComponent as PackIcon } from "icons/pack.svg";
import { ReactComponent as ProductIcon } from "icons/product.svg";

const PackCount = ({ gdns_count, products_quanitity }) => {
  return (
    <div className="packs-count">
      <div className="count-item">
        <PackIcon />
        <span className="count">{gdns_count} zamówień</span>
      </div>
      <div className="count-item">
        <ProductIcon />
        <span className="count">{products_quanitity} produktów</span>
      </div>
    </div>
  );
};

export default PackCount;
