import React from "react";
import { Chart } from "react-google-charts";
import { connect } from "react-redux";
import { Select } from "expano-components";
import getChartData from "./getChartData";

const LastCompletedChart = ({
  getData,
  data,
  range,
  type,
  packer,
  all_packers,
  status,
  user_role,
}) => {
  const packers_options =
    all_packers?.length > 0
      ? [
          { label: "Wszyscy", value: null },
          ...all_packers?.map((item) => ({
            label: item,
            value: item,
          })),
        ]
      : [{ label: "Wszyscy", value: null }];

  const type_options = [
    {
      label: "Godzinny",
      value: "hour",
      isDisabled: !(
        range === "yesterday" || range === "today"
      ),
    },
    {
      label: "Dzienny",
      value: "day",
      isDisabled:
        range === "this_year" || range === "last_year",
    },
    {
      label: "Miesięczny",
      value: "month",
      isDisabled: !(
        range === "current_month" ||
        range === "last_month" ||
        range === "this_year" ||
        range === "last_year"
      ),
    },
  ];

  const range_options = [
    { label: "Wczoraj", value: "yesterday" },
    { label: "Dzisiaj", value: "today" },
    { label: "Ostatnie 7 dni", value: "last_7_days" },
    { label: "Ostatnie 14 dni", value: "last_14_days" },
    { label: "Ostatnie 28 dni", value: "last_28_days" },
    { label: "Ten miesiąc", value: "current_month" },
    { label: "Zeszły miesiąc", value: "last_month" },
    { label: "Ten rok", value: "this_year" },
    { label: "Zeszły rok", value: "last_year" },
  ];

  if (!data) return null;

  return (
    <div className="dashboard">
      <div className="dashboard__header">
        <h2 className="dashboard__title heading">
          Zamówienia zrealizowane
        </h2>
      </div>
      <div className="chart-box__body">
        <div className="chart-box__selects">
          {user_role === "admin" && (
            <Select
              label="Paker"
              isDisabled={status === "updating"}
              handleChange={(value) =>
                getData({
                  histogram_packer: value,
                  histogram_type: type,
                  histogram_range: range,
                })
              }
              options={packers_options}
              value={packers_options.find(
                ({ value }) => value === packer
              )}
            />
          )}
          <Select
            label="Typ"
            isDisabled={status === "updating"}
            handleChange={(value) =>
              getData({
                histogram_packer: packer,
                histogram_type: value,
                histogram_range: range,
              })
            }
            options={type_options}
            value={type_options.find(
              ({ value }) => value === type
            )}
          />
          <Select
            label="Zakres"
            isDisabled={status === "updating"}
            handleChange={(value) =>
              getData({
                histogram_packer: packer,
                histogram_type: type,
                histogram_range: value,
              })
            }
            options={range_options}
            value={range_options.find(
              ({ value }) => value === range
            )}
          />
        </div>
        <Chart
          options={{
            colors: ["#5D78FF"],
            chartArea: { width: "85%" },
            vAxis: { minValue: 0, maxValue: 15 },
            hAxis: {
              textStyle: {
                fontSize: 10,
                color: "#595D6E",
              },
            },
            legend: { position: "none" },
          }}
          chartType="ColumnChart"
          loader={null}
          width="100%"
          height="400px"
          data={[
            ["Element", "Ilość"],
            ...getChartData(data, range),
          ]}
        />
      </div>
    </div>
  );
};

export default connect(
  ({
    dashboard: { data, status },
    current_user: { role },
  }) => ({
    data: data?.completed_histogram?.data,
    range: data?.completed_histogram?.range,
    type: data?.completed_histogram?.type,
    packer: data?.completed_histogram?.packer,
    all_packers:
      data?.completed_histogram?.all_packers || [],
    status,
    user_role: role,
  })
)(LastCompletedChart);
