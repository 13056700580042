import React from "react";
import { useQuery } from "@tanstack/react-query";
import Loader from "react-loader-spinner";
import classnames from "classnames";

import { client_v2 } from "utils/api";

const AislePreview = ({
  aisle_name,
  warehouse_position_id,
}) => {
  const { isLoading: is_loading, data } = useQuery(
    [warehouse_position_id],
    () =>
      client_v2.get(
        `/product_main_locations?context=ui_product_show&q[warehouse_position_id_eq]=${warehouse_position_id}&include=product`
      ),
    {
      keepPreviousData: false,
      enabled: Boolean(warehouse_position_id),
      select: (res) => res.data,
    }
  );

  if (!warehouse_position_id) return null;
  return (
    <div className="aisle-preview">
      <p className="preview-label">Podgląd</p>
      <div
        className={classnames("preview-table", {
          "is-loading": is_loading,
        })}>
        <div className="preview-table__head">
          <span className="preview-table__title">
            {aisle_name}
          </span>
        </div>
        <ul className="preview-table__body">
          {data?.map(
            ({ warehouse_position, product, id }) => (
              <li className="preview-table__item" key={id}>
                <span className="item-position">
                  {warehouse_position?.identifier}
                </span>
                <div className="item-product">
                  {product?.name}
                </div>
              </li>
            )
          )}
        </ul>
        <div className="loader-wrapper">
          <Loader
            type="Oval"
            color="#2959F8"
            height={40}
            width={40}
          />
        </div>
      </div>
    </div>
  );
};

export default AislePreview;
