import React from "react";
import { Main } from "layouts";

import WarehousesList from "./components/WarehousesList";
import WarehouseRackSchemas from "./components/WarehouseRackSchemas";

import { ReactComponent as WarehouseIcon } from "icons/warehouse.svg";

const Warehouses = () => {
  return (
    <Main
      page={{
        name: "Zarządzanie magazynem",
        icon: <WarehouseIcon />,
        breadcrumbs: [{ name: "Zarządzanie magazynem" }],
      }}>
      <div className="row">
        <WarehousesList />
        <WarehouseRackSchemas />
      </div>
    </Main>
  );
};

export default Warehouses;
