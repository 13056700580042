import React from "react";
import { MainList } from "components";
import { Main } from "layouts";
import { ReactComponent as DeliveryIcon } from "icons/delivery.svg";

import { sort, filters } from "./const/data";
import { Button } from "expano-components";

const IslandZipCodesList = ({ history }) => {
  return (
    <Main
      page={{
        name: "Wyspy - kody pocztowe",
        icon: <DeliveryIcon />,
        breadcrumbs: [
          {
            name: "Wyspy - kody pocztowe",
          },
        ],
        buttons: (
          <Button
            type="add"
            text="Dodaj kod pocztowy"
            onClick={() =>
              history.push("/island-zip-codes/new")
            }
          />
        ),
      }}>
      <MainList
        query_key="island_zip_codes"
        api_path="/island_zip_codes"
        sort_column="id"
        sort_direction="desc"
        sort={sort}
        filters={filters}
        handleNavigate={(item, e) =>
          e?.button === 1
            ? window.open(
                `/island-zip-codes/${item.id}`,
                "_blank"
              )
            : history.push(`/island-zip-codes/${item.id}`)
        }
        renderItem={({ country_code, zip_code }) => (
          <>
            <td>{country_code}</td>
            <td>
              <strong>{zip_code}</strong>
            </td>
          </>
        )}
      />
    </Main>
  );
};

export default IslandZipCodesList;
