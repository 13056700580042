import axios from "axios";
import moment from "moment";

import { url } from "utils/api";

export const getNavigatorVersion = () => async (
  dispatch
) => {
  const timestamp = moment().unix();
  try {
    const {
      data: { version },
    } = await axios.get(
      `${url}/navigator/navigator-version.json?t=${timestamp}`
    );
    dispatch({
      type: "NAVIGATOR_SUCCESS",
      version,
    });
  } catch (error) {
    console.error(error);
  }
};
