import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";
import Loader from "react-loader-spinner";
import { PageLoader } from "components";
import {
  getGdnPacks,
  getMoreGdnPacks,
} from "actions/gdn_packs";
import { useCable } from "providers/cable";
import { Header, Item, SuggestedCart } from "./components";

const CompletingPacksList = ({
  gdn_packs,
  getGdnPacks,
  getMoreGdnPacks,
}) => {
  const { cable } = useCable();
  const [channel, setChannel] = useState(null);

  useEffect(() => {
    getGdnPacks();
    const cable_channel = cable.subscriptions.create({
      channel: "PackerChannel",
    });

    setChannel(cable_channel);
    return () => {
      if (cable_channel) {
        setChannel(null);
        cable_channel.unsubscribe();
      }
    };
  }, []);

  const sendMessage = (gdn_pack_id) =>
    channel?.perform("init_packing", { gdn_pack_id });

  if (["invalid", "loading"].includes(gdn_packs.status)) {
    return <PageLoader />;
  }

  if (gdn_packs.status === "failure") {
    return <p>Error</p>;
  }

  const { data, meta } = gdn_packs;

  const gdn_pack_to_close = data.filter(
    ({ can_complete_pack }) => can_complete_pack
  );

  const gdn_packs_important = data.filter(
    ({ can_complete_pack, gdns_important_to_pack_count }) =>
      !can_complete_pack && gdns_important_to_pack_count > 0
  );

  const gdn_packs_ready = data.filter(
    ({
      can_complete_pack,
      gdns_important_to_pack_count,
      gdns_ready_to_pack_count,
    }) =>
      !can_complete_pack &&
      gdns_important_to_pack_count === 0 &&
      gdns_ready_to_pack_count > 0
  );

  const gdn_packs_paused = data.filter(
    ({ can_complete_pack, gdns_ready_to_pack_count }) =>
      !can_complete_pack && gdns_ready_to_pack_count === 0
  );

  const all_ids = [
    ...gdn_pack_to_close.map(({ id }) => id),
    ...gdn_packs_important.map(({ id }) => id),
    ...gdn_packs_ready.map(({ id }) => id),
    ...gdn_packs_paused.map(({ id }) => id),
  ];

  const gdn_pack_others = data.filter(
    ({ id }) => !all_ids.includes(id)
  );

  return (
    <div className="packs-view">
      <Header />
      <div className="packs-container">
        <SuggestedCart />
        {data?.length > 0 ? (
          <ul className="packs-list">
            <InfiniteScroll
              dataLength={data.length}
              next={() => getMoreGdnPacks(meta.next_page)}
              hasMore={!!meta.next_page}
              loader={
                <div
                  style={{
                    textAlign: "center",
                    padding: 30,
                  }}>
                  <Loader
                    type="Watch"
                    color="#2959F8"
                    height={40}
                    width={40}
                  />
                </div>
              }>
              {gdn_packs_important.length > 0 && (
                <>
                  <li className="list-item separator important">
                    <p>Pilne do spakowania!</p>
                  </li>
                  {gdn_packs_important.map((item) => (
                    <Item
                      key={item.id}
                      {...item}
                      sendMessage={sendMessage}
                    />
                  ))}
                </>
              )}
              {gdn_packs_ready.length > 0 && (
                <>
                  <li className="list-item separator ready">
                    <p>Możliwe do spakowania!</p>
                  </li>
                  {gdn_packs_ready.map((item) => (
                    <Item
                      key={item.id}
                      {...item}
                      is_disabled={
                        gdn_packs_important.length > 0
                      }
                      sendMessage={sendMessage}
                    />
                  ))}
                </>
              )}
              {gdn_pack_to_close.length > 0 && (
                <>
                  <li className="list-item separator ready">
                    <p>Możliwe do zamknięcia!</p>
                  </li>
                  {gdn_pack_to_close.map((item) => (
                    <Item
                      key={item.id}
                      {...item}
                      sendMessage={sendMessage}
                    />
                  ))}
                </>
              )}
              {gdn_packs_paused.length > 0 && (
                <>
                  <li className="list-item separator paused">
                    <p>Wstrzymane</p>
                  </li>
                  {gdn_packs_paused.map((item) => (
                    <Item
                      key={item.id}
                      {...item}
                      is_disabled={
                        gdn_packs_important.length > 0
                      }
                      sendMessage={sendMessage}
                    />
                  ))}
                </>
              )}
              {gdn_pack_others.length > 0 && (
                <>
                  <li className="list-item separator others">
                    <p>Pozostałe</p>
                  </li>
                  {gdn_pack_others.map((item) => (
                    <Item
                      key={item.id}
                      {...item}
                      is_disabled={
                        gdn_packs_important.length > 0
                      }
                      sendMessage={sendMessage}
                    />
                  ))}
                </>
              )}
            </InfiniteScroll>
          </ul>
        ) : null}
      </div>
    </div>
  );
};

export default connect(
  ({ gdn_packs }) => ({ gdn_packs }),
  (dispatch) => ({
    getGdnPacks: () => dispatch(getGdnPacks()),
    getMoreGdnPacks: (page) =>
      dispatch(getMoreGdnPacks(page)),
  })
)(CompletingPacksList);
