import React, { useState } from "react";
import ReactDOM from "react-dom";
import LazyLoad from "react-lazy-load";
import { ModalPreview } from "expano-components";

const Item = ({
  name,
  sku,
  quantity,
  image_url,
  image_thumb_url
}) => {
  const [is_modal_open, openModal] = useState(false);

  return (
    <li>
      <LazyLoad>
        <button
          className="btn-preview"
          onClick={() => openModal(true)}
          style={{
            backgroundImage: image_thumb_url || image_url
              ? `url(${image_thumb_url || image_url})`
              : "",
          }}
        />
      </LazyLoad>
      <div className="product-info">
        <p>{name}</p>
        <p className="sku">{sku}</p>
      </div>
      <div className="product-qty">
        <div className="qty-box">
          <span>{quantity}</span>
        </div>
      </div>
      {is_modal_open &&
        ReactDOM.createPortal(
          <ModalPreview
            handleClose={() => {
              openModal(false);
            }}
            src={image_url}
            alt={name}
          />,
          document.getElementById("root")
        )}
    </li>
  );
};

export default Item;
