import React from "react";
import { ReactComponent as DashboardIcon } from "icons/dashboard.svg";
import { ReactComponent as GdnsIcon } from "icons/gdns.svg";
import { ReactComponent as GrnsIcon } from "icons/grns.svg";
import { ReactComponent as UserIcon } from "icons/user.svg";
import { ReactComponent as SettingsIcon } from "icons/settings.svg";

const getWarehousemanNav = () => {
  const disabled_routes = [
    "/users",
    "/waga",
    "/speditors",
    "/stations",
    "/island-zip-codes",
    "/settings",
    "/warehouse_stock_stats",
  ];

  return nav
    .map(({ items, ...rest }) => ({
      ...rest,
      items: items.filter(({ path }) => !disabled_routes.includes(path)),
    }))
    .filter(({ items }) => items.length > 0);
};

const getManagerNav = () => {
  const disabled_routes = [
    "/users",
    "/waga",
    "/speditors",
    "/stations",
    "/settings",
    "/warehouse_stock_stats",
  ];

  return nav
    .map(({ items, ...rest }) => ({
      ...rest,
      items: items.filter(({ path }) => !disabled_routes.includes(path)),
    }))
    .filter(({ items }) => items.length > 0);
};

const getPackerNav = () => {
  const enabled_routes = ["/", "/packing"];

  return nav
    .map(({ items, ...rest }) => ({
      ...rest,
      items: items.filter(({ path }) => enabled_routes.includes(path)),
    }))
    .filter(({ items }) => items.length > 0);
};

const getNavByRole = (role) => {
  const warehouseman_nav = getWarehousemanNav();
  const packer_nav = getPackerNav();
  const manager_nav = getManagerNav();

  switch (role) {
    case "admin":
      return nav;
    case "warehouseman":
      return warehouseman_nav;
    case "manager":
      return manager_nav;
    case "packer":
      return packer_nav;
    default:
      return [];
  }
};

const nav = [
  {
    name: "",
    type: "single",
    icon: <DashboardIcon />,
    items: [
      {
        name: "Dashboard",
        path: "/",
      },
    ],
  },
  {
    name: "Wydania",
    type: "group",
    icon: <GdnsIcon />,
    items: [
      {
        name: "Obsługa wydań",
        path: "/packing",
      },
      {
        name: "Sortownia",
        path: "/sorting",
      },
      { name: "Wydania", path: "/gdns" },
      { name: "Paki wydań", path: "/packs" },
      {
        name: "Zgłoszone braki",
        path: "/missings",
      },
      {
        name: "Zadania magazynowe",
        path: "/warehouse_tasks",
      },
      {
        name: "Paczki",
        path: "/packages",
      },
      {
        name: "Paczki - statystyki",
        path: "/packages/stats",
      },
      {
        name: "Statystyki błędów",
        path: "/gdn_errors_stats",
      },
    ],
  },
  {
    name: "Dostawy",
    type: "group",
    icon: <GrnsIcon />,
    items: [
      { name: "Dostawy", path: "/grns" },
      { name: "Waga", path: "/waga" },
    ],
  },
  {
    name: "Zarządzanie",
    type: "group",
    icon: <UserIcon />,
    items: [
      {
        name: "Produkty",
        path: "/products",
      },
      {
        name: "Dostawcy",
        path: "/suppliers",
      },
      {
        name: "Magazyny",
        path: "/warehouses",
      },
      {
        name: "Wózki",
        path: "/carts",
      },
      {
        name: "Spedytorzy",
        path: "/speditors",
      },
      {
        name: "Stanowiska",
        path: "/stations",
      },
      {
        name: "Użytkownicy",
        path: "/users",
      },
      {
        name: "Wyspy - kody pocztowe",
        path: "/island-zip-codes",
      },
      { name: "Wartości magazynów", path: "/warehouse_stock_stats" },
    ],
  },
  {
    name: "Ustawienia",
    type: "single",
    icon: <SettingsIcon />,
    items: [
      {
        name: "Ustawienia",
        path: "/settings",
      },
    ],
  },
];

export default getNavByRole;
