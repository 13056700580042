import styled from "styled-components";

const StyledStatus = styled.button`
  display: flex;
  justify-content: center;
  width: 100%;
  border-radius: 5px;
  overflow: hidden;
  margin: 30px 0 20px;
  padding: 30px 25px;
  text-align: center;
  background-color: #0abb87;
  transition: 0.2s;
  background-color: #0abb87;
  box-shadow: 0px 0px 13px rgba(82, 63, 105, 0.05);
  &[button]:hover {
    background-color: rgba(10, 187, 135, 0.8);
  }
  span {
    font-size: 20px;
    line-height: 30px;
    font-weight: 500;
    color: #fff;
  }
`;

export default StyledStatus;
