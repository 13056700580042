import React from "react";
import { connect } from "react-redux";
import getPackageWarnings from "utils/getPackageWarnings";

import cant_use_stretch_icon from "icons/cant_use_stretch.png";
import cant_use_envelope_icon from "icons/no_k20.png";
import envelope_icon from "icons/speditor-envelope.png";
import package_icon from "icons/speditor-package.png";

const PackageInfo = ({ data }) => {
  const package_warnings = getPackageWarnings(
    data?.suggested_couriers
  );

  return (
    <div className="package-info-panel">
      <div className="package-info-panel__warnings">
        <h3>Uwaga!</h3>
        <p>
          Sprawdź, czy na etykiecie jest poprawny kod
          pocztowy i miasto:{" "}
          <strong>
            {data?.delivery_point_postcode ||
              data?.delivery_postcode}
            ,{" "}
            {data?.delivery_point_city ||
              data?.delivery_city}
            , {data?.delivery_country_code}
          </strong>
        </p>
        {data?.cod && (
          <p>
            <strong>Pobranie!</strong> Upewnij się, że na
            etykiecie widnieje kwota pobrania{" "}
            <strong>
              {data?.total_price}
              {data?.currency}
            </strong>
          </p>
        )}
        {package_warnings !== null && (
          <>
            {package_warnings.max_weight !== null ? (
              <p>
                Maksymalna waga paczki:{" "}
                <strong>
                  {package_warnings.max_weight} kg!
                </strong>
              </p>
            ) : null}
            {package_warnings.longest_side !== null ? (
              <p>
                Najdłuższy bok paczki:{" "}
                <strong>
                  {package_warnings.longest_side} cm!
                </strong>
              </p>
            ) : null}
            {package_warnings.max_size !== null ? (
              <p>
                Maksymalny wymiar paczki:{" "}
                <strong>
                  {package_warnings.max_size}!
                </strong>
              </p>
            ) : null}
            {package_warnings.warnings.includes(
              "box_or_envelope"
            ) && (
              <>
                <p>
                  Towar powinien być zapakowany w kształt
                  zbliżony do prostopadłościanu lub kopertę!
                </p>
                <div className="images-group">
                  <img src={envelope_icon} alt="" />
                  <img src={package_icon} alt="" />
                </div>
              </>
            )}
          </>
        )}
      </div>
      {(data?.delivery_method_name ===
        "Kurier międzynarodowy" ||
        package_warnings?.warnings?.includes(
          "cant_use_stretch"
        )) && (
        <div className="package-info-panel__dangers">
          <p>Zakaz użycia folii stretch!</p>
          <img src={cant_use_stretch_icon} alt="" />
        </div>
      )}
      {data?.delivery_method_name ===
        "Kurier międzynarodowy" && (
        <div className="package-info-panel__dangers">
          <p>Zakaz użycia koperty K20!</p>
          <img src={cant_use_envelope_icon} alt="" />
        </div>
      )}
    </div>
  );
};

export default connect(({ pack_gdn: { data } }) => ({
  data,
}))(PackageInfo);
