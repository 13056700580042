import React from "react";
import { toastr } from "react-redux-toastr";
import { saveAs } from "file-saver";
import { api_url_v2, client_v2 } from "utils/api";
import QRCode from "qrcode";
import { StickerGdn, StickerGdnControl } from "sticker_templates";
import { pdf } from "@react-pdf/renderer";

export const closeCartWarning = () => (dispatch) =>
  dispatch({
    type: "PACK_GDN_TOGGLE_CART_WARNING",
    is_open: false,
  });

export const getPackingGdn =
  ({ id, shuffle_colors, is_loading = true }) =>
  async (dispatch) => {
    if (is_loading) {
      dispatch({ type: "PACK_GDN_LOADING" });
    }

    try {
      const { data } = await client_v2.get(`/gdns/${id}?context=ui_packing`);

      dispatch({
        type: shuffle_colors ? "PACK_GDN_SHUFFLE_COLORS" : "PACK_GDN_SUCCESS",
        data,
      });

      return data;
    } catch (err) {
      dispatch({ type: "PACK_GDN_FAILURE" });
      toastr.error("Błąd", "Wystąpił błąd podczas pobierania");
      throw err;
    }
  };

export const updatePackingGdn = (id, values) => async (dispatch) => {
  try {
    await client_v2.put(`/gdns/${id}`, values);
    const { data } = await client_v2.get(`/gdns/${id}?context=ui_packing`);

    dispatch({
      type: "PACK_GDN_SUCCESS",
      data,
    });
    return data;
  } catch (err) {
    toastr.error("Błąd", "Wystąpił błąd podczas aktualizowania");
    throw err;
  }
};

export const markAsPackedGdnLine = (id) => async (dispatch, getState) => {
  const {
    pack_gdn: { data, scanned, is_blocked },
  } = getState();
  if (is_blocked) {
    return;
  } else {
    const handleMarkAsPacked = async () => {
      try {
        dispatch({
          type: "PACK_GDN_SET_BLOCKED",
          is_blocked: true,
        });
        await client_v2.post(`/gdn_lines/${id}/mark_as_packed`);

        const {
          data: { gdn, ...gdn_line },
        } = await client_v2.get(`/gdn_lines/${id}?context=ui_packing`);

        dispatch({
          type: "UPDATE_GDN_LINE",
          gdn_line,
          gdn,
        });

        const is_ready = gdn?.status === "packed";

        if (is_ready) {
          if (data?.packages?.length > 0) {
            data.packages.forEach(({ id }) => {
              downloadLabel(id);
            });
          }
          dispatch(openTakePhotoModal(true));
        }
      } catch (error) {
        toastr.error("Błąd", "Wystąpił błąd podczas oznaczania jako spakowane");
      } finally {
        dispatch({
          type: "PACK_GDN_SET_BLOCKED",
          is_blocked: false,
        });
      }
    };

    const gdn_line = data.gdn_lines.find((gdn_line) => gdn_line.id === id);
    const needs_confirm = parseInt(gdn_line?.quantity) > 1;
    if (needs_confirm) {
      if (scanned?.id === id) {
        handleMarkAsPacked();
      }
      dispatch({ type: "SET_SCANNED", data: gdn_line });
    } else {
      handleMarkAsPacked();
    }
  }
};

export const unMarkAsPackedGdnLine = (id) => async (dispatch) => {
  try {
    await client_v2.post(`/gdn_lines/${id}/unmark_as_packed`);
    const {
      data: { gdn, ...gdn_line },
    } = await client_v2.get(`/gdn_lines/${id}?context=ui_packing`);

    dispatch({
      type: "UPDATE_GDN_LINE",
      gdn_line,
      gdn,
    });
  } catch (error) {
    toastr.error("Błąd", "Wystąpił błąd podczas odznaczania jako spakowane");
  }
};

export const markAsMissingGdnLine =
  ({ id, missing_quantity }) =>
  async (dispatch) => {
    try {
      await client_v2.post(`/gdn_lines/${id}/mark_as_missing`, {
        missing_quantity,
      });

      const {
        data: { gdn, ...gdn_line },
      } = await client_v2.get(`/gdn_lines/${id}?context=ui_packing`);

      dispatch({
        type: "UPDATE_GDN_LINE",
        gdn,
        gdn_line,
      });
    } catch (error) {
      toastr.error(
        "Błąd",
        error?.response?.data?.message ||
          "Wystąpił błąd podczas oznaczania jako brak"
      );
    }
  };

export const unMarkAsMissingGdnLine = (id) => async (dispatch) => {
  try {
    await client_v2.post(`/gdn_lines/${id}/unmark_as_missing`);

    const {
      data: { gdn, ...gdn_line },
    } = await client_v2.get(`/gdn_lines/${id}?context=ui_packing`);

    dispatch({
      type: "UPDATE_GDN_LINE",
      gdn,
      gdn_line,
    });
  } catch (error) {
    toastr.error("Błąd", "Wystąpił błąd podczas odznaczania jako brak");
  }
};

export const completePackingGdn = (id) => async (dispatch, getState) => {
  try {
    const { data: gdn } = getState()?.pack_gdn;
    const has_photo = gdn?.photo_url?.length > 1;
    if (!has_photo) {
      const {
        data: { photo_url },
      } = await client_v2.get(`/gdns/${id}`);
      if (!photo_url) {
        throw new Error("Brak zdjęcia");
      }
    }
    const {
      data: { packages_need_verification, validation_result },
    } = await client_v2.post(`/gdns/${id}/complete`);
    const { data } = await client_v2.get(`/gdns/${id}?context=ui_packing`);

    dispatch({
      type: "PACK_GDN_SUCCESS",
      data,
    });
    toastr.success("Sukces", "Zamówienie zostało zakończone");

    const packages_dimensions = data.packages
      .map(({ package_boxes }) =>
        package_boxes.map(({ id, width, height, depth, weight, size }) => ({
          id,
          width,
          height,
          depth,
          weight,
          size,
        }))
      )
      .flat();

    return {
      packages_need_verification,
      validation_result,
      packages_dimensions,
    };
  } catch (error) {
    toastr.error(
      "Błąd",
      error?.message ||
        error?.response?.data?.message ||
        "Wystąpił błąd podczas aktualizacji"
    );
    throw error;
  }
};

export const openSpeditorModal = (is_open) => (dispatch) =>
  dispatch({ type: "OPEN_SPEDITOR_MODAL", is_open });

export const openTakePhotoModal = (is_open) => (dispatch) =>
  dispatch({ type: "OPEN_TAKE_PHOTO_MODAL", is_open });

export const openIssueModal = (is_open) => (dispatch) =>
  dispatch({ type: "OPEN_ISSUE_MODAL", is_open });

export const openPickerMistakeModal = (is_open) => (dispatch) =>
  dispatch({ type: "OPEN_PICKER_MISTAKE_MODAL", is_open });

export const findGdnLineByCode = (code) => async (_, getState) => {
  const { pack_gdn } = getState();
  try {
    if (pack_gdn.is_blocked) {
      throw new Error(
        "Trwa procesowanie. Skanowanie jest tymczasowo zablokowane."
      );
    }
    const gdn_line = pack_gdn.data.gdn_lines.find(
      ({ product: { ean, sku, logistic_eans }, status }) => {
        if (status !== "picked") {
          return false;
        }
        const codes = [
          ean,
          sku,
          ...logistic_eans.map(({ code }) => code),
        ].filter(Boolean);
        return codes.includes(code?.toUpperCase());
      }
    );

    if (gdn_line) {
      return gdn_line.id;
    } else {
      throw new Error("Nie znaleziono produktu lub jest już spakowany");
    }
  } catch (error) {
    toastr.error("Błąd", error.message);
    throw error;
  }
};

export const removeScanned = () => (dispatch) =>
  dispatch({ type: "SET_SCANNED", data: null });

const downloadLabel = (id) =>
  saveAs(`${api_url_v2}packages/${id}/download_label`, `${id}.pdf`);

export const checkGdnForCloseAfterScanningBox =
  (letter) => async (dispatch, getState) => {
    try {
      const {
        data: { status, id, gdn_pack },
      } = getState()?.pack_gdn;

      if (status === "packed") {
        const next_gdn_id = gdn_pack?.gdns.find(
          ({ picking_box, status }) =>
            picking_box === letter && status === "picked"
        )?.id;
        if (!!next_gdn_id) {
          await dispatch(completePackingGdn(id));
          return next_gdn_id;
        } else {
          throw new Error(
            `Brak zamówienia w statusie "picked" dla boxa "${letter}"`
          );
        }
      } else {
        throw new Error("Zamówienie nie jest jeszcze spakowane");
      }
    } catch (error) {
      toastr.error("Błąd", error?.message);
      throw error;
    }
  };

export const notifyAboutWrongGauge = async (id) => {
  try {
    await client_v2.post(`/gdns/${id}/notify_about_wrong_gauge`);
    toastr.success("Sukces", "Wysłano powiadomienie");
  } catch (error) {
    toastr.error(
      "Błąd",
      error?.response?.data?.message ||
        "Wystąpił błąd podczas wysyłania powiadomienia"
    );
    throw error;
  }
};

export const notifyAboutWrongPick = async ({
  gdn_id,
  description,
  kind,
  gdn_line_id,
}) => {
  try {
    await client_v2.post(`/gdns/${gdn_id}/notify_about_wrong_pick`, {
      description,
      kind,
      gdn_line_id,
    });
    toastr.success("Sukces", "Zgłoszono błąd");
  } catch (error) {
    toastr.error(
      "Błąd",
      error?.response?.data?.message ||
        "Wystąpił błąd podczas wysyłania powiadomienia"
    );
    throw error;
  }
};

export const notifyAboutWrongWeight = async (id) => {
  try {
    await client_v2.post(`/gdns/${id}/notify_about_wrong_weight`);
    toastr.success("Sukces", "Wysłano powiadomienie");
  } catch (error) {
    toastr.error(
      "Błąd",
      error?.response?.data?.message ||
        "Wystąpił błąd podczas wysyłania powiadomienia"
    );
    throw error;
  }
};

export const markGdnAsProblem =
  (id, { packer_note }) =>
  async (dispatch) => {
    try {
      await client_v2.post(`gdns/${id}/mark_as_problem`, {
        packer_note,
      });
      const { data } = await client_v2.get(`/gdns/${id}?context=ui_packing`);

      dispatch({
        type: "PACK_GDN_SUCCESS",
        data,
      });
      downloadGdnLabel({
        id: data.id,
        expano_oms_id: data.expano_oms_id,
      });
      dispatch({
        type: "PACK_GDN_TOGGLE_CART_WARNING",
        is_open: true,
      });
    } catch (error) {
      toastr.error("Błąd", "Wystąpił błąd podczas zgłaszania problemu");
      throw error;
    }
  };

export const unMarkGdnAsProblem = (id) => async (dispatch) => {
  try {
    await client_v2.post(`gdns/${id}/unmark_as_problem`);
    const { data } = await client_v2.get(`/gdns/${id}?context=ui_packing`);

    dispatch({
      type: "PACK_GDN_SUCCESS",
      data,
    });
    return id;
  } catch (error) {
    toastr.error("Błąd", "Wystąpił błąd podczas rozwiązywania problemu");
    throw error;
  }
};

export const markGdnAsMissing = (id) => async (dispatch) => {
  try {
    await client_v2.post(`gdns/${id}/mark_as_missing`);
    const { data } = await client_v2.get(`/gdns/${id}?context=ui_packing`);

    dispatch({
      type: "PACK_GDN_SUCCESS",
      data,
    });
    downloadGdnLabel({
      id: data.id,
      expano_oms_id: data.expano_oms_id,
    });
    dispatch({
      type: "PACK_GDN_TOGGLE_CART_WARNING",
      is_open: true,
    });
  } catch (error) {
    toastr.error("Błąd", "Wystąpił błąd podczas zgłaszania braku");
    throw error;
  }
};

export const unMarkGdnAsMissing = (id) => async (dispatch) => {
  try {
    await client_v2.post(`gdns/${id}/unmark_as_missing`);
    const { data } = await client_v2.get(`/gdns/${id}?context=ui_packing`);

    dispatch({
      type: "PACK_GDN_SUCCESS",
      data,
    });
    return id;
  } catch (error) {
    toastr.error("Błąd", "Wystąpił błąd podczas rozwiązywania braku");
    throw error;
  }
};

export const downloadGdnLabel = async ({ expano_oms_id, id }) => {
  try {
    const code = await QRCode.toDataURL(`gdn_id|${id}`);

    const blob = await pdf(
      <StickerGdn
        data={{
          id,
          expano_oms_id,
          code,
        }}
      />
    ).toBlob();

    saveAs(blob, `${id}.pdf`);
  } catch (error) {
    throw error;
  }
};

export const downloadGdnControlLabel = async ({
  expano_oms_id,
  id,
  validation_result,
  packages_dimensions,
}) => {
  try {
    const code = await QRCode.toDataURL(`gdn_id|${id}`);

    const blob = await pdf(
      <StickerGdnControl
        data={{
          id,
          expano_oms_id,
          code,
          validation_result,
          packages_dimensions,
        }}
      />
    ).toBlob();

    saveAs(blob, `${id}.pdf`);
  } catch (error) {
    throw error;
  }
};

export const createPackageLabel = async ({ gdn_id, package_boxes }) => {
  try {
    package_boxes = package_boxes.map(
      ({ id, width, height, depth, weight }) => ({
        size: id,
        width: parseFloat(width),
        height: parseFloat(height),
        depth: parseFloat(depth),
        weight: parseFloat(weight),
      })
    );
    const {
      data: { id },
    } = await client_v2.post("/packages", {
      gdn_id,
      package_boxes,
    });
    downloadLabel(id);
  } catch (error) {
    let message = "Wystąpił błąd";
    const response_error = error?.response?.data?.error;

    switch (response_error?.message) {
      case "account_id_missing":
        message =
          "Nie znaleziono ID konta lub konto nie jest zintegorwane. Zgłoś problem wraz z wymiarami.";
        break;
      case "speditor_not_found":
        message = `Nie znaleziono spedytora dla ${response_error.delivery_method_name}`;
        break;
      case "speditor_invalid":
        message = `${response_error.delivery_method_name} - nieprawidłowe wymiary paczek`;
        break;
      case "not_foreign_gdn":
        message = "Zamówienie krajowe (PL) dla metody 'Kurier międzynarodowy'";
        break;
      case "unsupported_country_code":
        message = `${response_error.country_code} - brak cen wysyłki dla wybranego kraju`;
        break;
      case "foreign_speditor_not_found":
        message = `-1Nie znaleziono spedytora. Sprawdź czy spedytorzy mają nadane ceny dla kraju ${response_error.country_code}`;
        break;
      case "foreign_speditor_not_valid":
        message = "Brak spedytorów spełniających kryteria nadawanych paczek";
        break;
      case "foreign_speditor_island":
        message =
          "Uwaga prawdopodobnie WYSPA! Brak możliwości nadania paczki. Zgłoś problem z zamówieniem wraz z wymiarami!";
        break;
      default:
        if (response_error) {
          message = response_error;
        }
        if (response_error?.message) {
          message = response_error.message;
        }

        break;
    }
    toastr.error("Błąd", message);

    throw error;
  }
};

export const checkLeafletEan = (code) => (_, getState) => {
  const { pack_gdn } = getState();
  if (!pack_gdn.is_leaflet_scanned) {
    return code === pack_gdn.leaflet?.ean;
  }
  return false;
};

export const markAsPackedLeaflet = () => (dispatch) =>
  dispatch({
    type: "SET_LEAFLET_SCANNED",
  });
