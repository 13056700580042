import React from "react";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { Button, Textarea } from "expano-components";

import { Modal } from "components";
import { updateNote } from "actions/grns";

import { ReactComponent as MessageIcon } from "icons/message.svg";

const TextAreaField = (props) => <Textarea {...props} />;

const NoteModal = ({
  grn_id,
  handleClose,
  pristine,
  handleSubmit,
  updateNote,
}) => {
  const onSubmit = ({ note }) =>
    updateNote(grn_id, note).then(handleClose);

  return (
    <Modal
      handleClose={handleClose}
      size="md"
      header={{ title: "Notatki", icon: <MessageIcon /> }}
      footer={
        <>
          <Button
            type="cancel"
            onClick={handleClose}
            style={{ marginRight: 15 }}
          />
          <Button
            disabled={pristine}
            type="save"
            text="Zapisz"
            onClick={handleSubmit(onSubmit)}
          />
        </>
      }>
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <Field name="note" component={TextAreaField} />
      </form>
    </Modal>
  );
};

export default connect(
  ({ grn }) => ({
    grn_id: grn?.data?.id,
    initialValues: {
      note: grn?.data?.note,
    },
  }),
  (dispatch) => ({
    updateNote: (id, note) =>
      dispatch(updateNote(id, note)),
  })
)(
  reduxForm({
    form: "grn-note-form",
    enableReinitialize: true,
  })(NoteModal)
);
