import React from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";
import LazyLoad from "react-lazy-load";
import { ReactComponent as ArrowNextIcon } from "icons/double-arrow.svg";
import { ReactComponent as ErrorIcon } from "icons/error.svg";
import { ReactComponent as WarningIcon } from "icons/warning.svg";

const Action = ({ status, packer_note }) => {
  switch (status) {
    case "to_cancel": {
      return (
        <div className="badge black">
          <span>Anulowano</span>
        </div>
      );
    }
    case "with_missings": {
      return (
        <div className="error-wrapper">
          <div className="badge error">
            <span>Brak</span>
          </div>
        </div>
      );
    }
    case "problem": {
      return (
        <div className="error-wrapper">
          <div className="badge warning">
            <span>Problem</span>
          </div>
          <p className="error-info">{packer_note}</p>
        </div>
      );
    }
    default: {
      return <ArrowNextIcon />;
    }
  }
};

const Item = ({
  id,
  identifier,
  gdn_lines,
  status,
  picking_box,
  packer_note,
  is_fast_shipment,
}) => {
  return (
    <Link
      to={`/packing/gdn/${id}`}
      className={classNames("list-item", {
        error: status === "with_missings",
        success: status === "completed",
        warning: status === "problem",
        cancel: status === "to_cancel",
      })}
    >
      <div className="list-item__title">
        {picking_box && <span className="box-letter">{picking_box}</span>}
        <span className="text-overflow">{identifier}</span>
      </div>
      <ul className="list-item__products">
        {is_fast_shipment && (
          <p className="text-danger">
            <strong>Szybka wysyłka</strong>
          </p>
        )}
        {gdn_lines?.map(
          ({
            id,
            quantity,
            product,
            status,
            missing_quantity,
            is_potentially_missing,
          }) => (
            <li className="single-product" key={id}>
              <div className="single-product__sku">
                <span>{product?.sku}</span>
              </div>
              <div className="single-product__count">
                <span>{parseInt(quantity) || 0}</span>
              </div>
              <div className="single-product__image">
                {product?.image_url && (
                  <LazyLoad>
                    <img
                      src={product?.image_thumb_url || product.image_url}
                      alt={product.name}
                    />
                  </LazyLoad>
                )}
              </div>
              <div className="single-product__name">
                <p>{product?.name}</p>
                {status === "missing" ? (
                  <p className="product-info danger">
                    <ErrorIcon />
                    <span>
                      Potwierdzony brak:{" "}
                      <strong>{parseInt(missing_quantity) || 0} szt.</strong>
                    </span>
                  </p>
                ) : is_potentially_missing ? (
                  <p className="product-info warning">
                    <WarningIcon />
                    <span>Brak produktu zgłoszony podczas kompletacji</span>
                  </p>
                ) : null}
              </div>
            </li>
          )
        )}
      </ul>
      <div className="list-item__status">
        <Action status={status} packer_note={packer_note} />
      </div>
    </Link>
  );
};

export default Item;
