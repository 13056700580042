import React from "react";
import { connect } from "react-redux";
import { Modal } from "components";
import { ReactComponent as WarningIcon } from "icons/warning.svg";
import { openIssueModal } from "actions/pack_gdn";
import StyledWarningModalContent from "./styles";
import { updatePackingGdn } from "actions/pack_gdn";

const WarningModal = ({
  handleConfirm,
  openIssueModal,
  warning_type,
}) => {
  return (
    <Modal
      size="md"
      handleClose={() => undefined}
      header={{
        title: "To zamówienie wymaga Twojego działania!",
        icon: <WarningIcon />,
      }}>
      <StyledWarningModalContent>
        {warning_type === "missing_solution_not_set" && (
          <>
            <h2>Co robisz?</h2>
            <div className="buttons-wrapper center">
              <button
                type="button"
                className="btn btn-default btn-danger"
                onClick={() => {
                  const warning_select = document.querySelector(
                    ".missings-panel__info .select-box .form-group [class*=control]"
                  );
                  if (warning_select) {
                    warning_select.style.border =
                      "5px solid #ffb822";
                  }

                  handleConfirm();
                }}>
                <span>
                  Wybieram co zrobiłem z produktami
                </span>
              </button>
            </div>
          </>
        )}
        {warning_type === "packed_not_completed" && (
          <>
            <h2>Co robisz?</h2>
            <div className="buttons-wrapper">
              <button
                type="button"
                className="btn btn-warning"
                onClick={() => {
                  handleConfirm();

                  openIssueModal(true);
                }}>
                <span>Zgłaszam problem</span>
              </button>
              <button
                type="button"
                className="btn btn-default"
                onClick={() => {
                  handleConfirm();
                }}>
                <span>Dokańczam pakowanie</span>
              </button>
            </div>
          </>
        )}
      </StyledWarningModalContent>
    </Modal>
  );
};

export default connect(
  ({
    pack_gdn: {
      data: { id },
    },
  }) => ({ id }),
  (dispatch) => ({
    openIssueModal: (is_open) =>
      dispatch(openIssueModal(is_open)),
    updatePackingGdn: (id, data) =>
      dispatch(updatePackingGdn(id, data)),
  })
)(WarningModal);
