import "../../../../styles/schema-shelf.scss";

import React from "react";
import { Field, reduxForm } from "redux-form";
import { InputPlaceholder, Modal } from "components";
import { Button, Input } from "expano-components";
import { updateRackSchema } from "actions/warehouse_rack_schema";

import { ReactComponent as EditIcon } from "icons/edit.svg";
import { useMainList } from "components/MainList/context/provider";

const InputField = (props) => <Input {...props} />;

const ModalEditRackType = ({
  id,
  invalid,
  pristine,
  handleSubmit,
  handleClose,
  width,
  depth,
  warehouse_shelf_schemas,
}) => {
  const { refetch } = useMainList();
  const onSubmit = async (values) => {
    updateRackSchema(id, values)
      .then(() => {
        handleClose();
        refetch();
      })
      .catch((error) => console.log(error));
  };

  return (
    <Modal
      size="md"
      handleClose={handleClose}
      header={{
        icon: <EditIcon />,
        title: "Edycja",
      }}
      footer={
        <>
          <Button
            type="cancel"
            text="Anuluj"
            onClick={handleClose}
          />
          <button
            disabled={invalid || pristine}
            onClick={handleSubmit(onSubmit)}
            type="submit"
            className="btn btn-sm btn-green">
            <span>Zapisz</span>
          </button>
        </>
      }>
      <form noValidate>
        <table className="modal-table">
          <tbody>
            <tr>
              <td>Nazwa</td>
              <td>
                <Field
                  placeholder="Nazwa"
                  name="name"
                  component={InputField}
                />
              </td>
            </tr>
            <tr>
              <td>Skrót</td>
              <td>
                <Field
                  placeholder="Skrót"
                  name="identifier"
                  component={InputField}
                />
              </td>
            </tr>
            <tr>
              <td>Szerokość</td>
              <td>
                <InputPlaceholder
                  name="width"
                  value={width}
                />
              </td>
            </tr>
            <tr>
              <td>Głębokość</td>
              <td>
                <InputPlaceholder
                  name="depth"
                  value={depth}
                />
              </td>
            </tr>
          </tbody>
        </table>
        <div className="schema-shelf">
          <div className="schema-shelf__info">
            <p>Półki</p>
          </div>
          <div className="schema-shelf__rows">
            <div className="shelf-row shelf-row--info">
              <p>Wysokość</p>
              <p>Ilość pozycji</p>
            </div>
            {warehouse_shelf_schemas
              ?.sort((a, b) => b.position - a.position)
              ?.map(
                ({
                  id,
                  position,
                  height,
                  warehouse_positions_count,
                }) => {
                  return (
                    <div className="shelf-row" key={id}>
                      <span className="shelf-row__position">
                        P
                        {position > 9
                          ? position
                          : `0${position}`}
                      </span>
                      <div className="shelf-row__form">
                        <InputPlaceholder
                          name={id}
                          value={height}
                          postfix="cm"
                        />
                        <InputPlaceholder
                          name={id}
                          value={warehouse_positions_count}
                          postfix="cm"
                        />
                      </div>
                    </div>
                  );
                }
              )}
          </div>
        </div>
      </form>
    </Modal>
  );
};

const validate = (values) => {
  const errors = {};
  if (!values.name) {
    errors.name = "Pole wymagane!";
  }
  return errors;
};

export default reduxForm({
  form: "edit-rack-schema",
  validate,
  enableReinitialize: true,
})(ModalEditRackType);
