import checkDeliveryKind from "pages/grns/Edit/const/checkGrnKind";

const getHead = ({ is_simplified, kind, warehouse_id, from_name, to_name }) => {
  const grn_kind = checkDeliveryKind({
    is_simplified,
    kind,
    warehouse_id,
    from_name,
    to_name,
  });
  switch (grn_kind) {
    case "simplified":
      return [
        {
          label: "Lp",
          name: "no",
          style: { width: 60 },
        },
        {
          label: "",
          name: "image",
          sortable: false,
          style: { width: 60 },
        },
        {
          label: "SKU",
          name: "sku",
          style: { width: 120 },
        },
        { label: "Nazwa i kod producenta", name: "name" },
        {
          label: "Stan",
          name: "stock",
          sortable: false,
          style: { width: 80 },
        },
        {
          label: "Kody",
          name: "codes",
          sortable: false,
          style: { width: 90 },
        },
        {
          label: "Zam.",
          name: "zam",
          style: { width: 80 },
        },
        {
          label: "Ilość",
          name: "count",
          sortable: false,
          style: { width: 120 },
        },
        {
          label: "Info",
          name: "tooltip",
          sortable: false,
          style: { width: 50 },
        },
      ];

    case "shift":
      return [
        {
          label: "Lp",
          name: "no",
          style: { width: 60 },
        },
        {
          label: "",
          name: "image",
          sortable: false,
          style: { width: 60 },
        },
        {
          label: "Produkt",
          name: "product",
          sortable: false,
        },
        {
          label: "Kody",
          name: "codes",
          sortable: false,
          style: { width: 80 },
        },
        {
          label: "Ilość",
          name: "zam",
          sortable: false,
          style: {
            width: 100,
            borderLeft: "1px solid #e9e9e9",
            borderTop: "1px solid #e9e9e9",
            textAlign: "center",
          },
        },
        {
          label: from_name,
          name: "main_count",
          sortable: false,
          style: {
            width: 300,
            borderLeft: "1px solid #e9e9e9",
            borderTop: "1px solid #e9e9e9",
            textAlign: "center",
          },
        },
        {
          label: to_name,
          name: "shipment_count",
          sortable: false,
          style: {
            width: 300,
            borderLeft: "1px solid #e9e9e9",
            borderTop: "1px solid #e9e9e9",
            textAlign: "center",
          },
        },
        {
          label: "Info",
          name: "tooltip",
          sortable: false,
          style: {
            width: 50,
            borderLeft: "1px solid #e9e9e9",
            textAlign: "center",
          },
        },
      ];
    case "delivery":
      return [
        {
          label: "Lp",
          name: "no",
          style: { width: 60 },
        },
        {
          label: "",
          name: "image",
          sortable: false,
          style: { width: 60 },
        },
        {
          label: "SKU",
          name: "sku",
          style: { width: 120 },
        },
        { label: "Nazwa i kod producenta", name: "name" },
        {
          label: "Lokalizacja",
          name: "location",
          sortable: false,
          style: { width: 180 },
        },
        {
          label: "Stan",
          name: "stock",
          sortable: false,
          style: { width: 80 },
        },
        {
          label: "Kody",
          name: "codes",
          sortable: false,
          style: { width: 90 },
        },
        {
          label: "Ilość",
          name: "counted_quantity",
          style: { width: 80 },
        },
        {
          label: "Paczki",
          name: "packages",
          sortable: false,
          style: { width: "20%" },
        },
        {
          label: "Łącznie",
          name: "zam",
          sortable: false,
          style: { width: 80 },
        },
        {
          label: "Info",
          name: "tooltip",
          sortable: false,
          style: { width: 50 },
        },
      ];
    case "delivery_to_main_warehouse":
      return [
        {
          label: "Lp",
          name: "no",
          style: { width: 60 },
        },
        {
          label: "",
          name: "image",
          sortable: false,
          style: { width: 60 },
        },
        {
          label: "Produkt",
          name: "product",
          sortable: false,
        },
        {
          label: "Kody",
          name: "codes",
          sortable: false,
          style: { width: 80 },
        },
        {
          label: "Ilość",
          name: "zam",
          sortable: false,
          style: {
            width: 100,
            borderLeft: "1px solid #e9e9e9",
            borderTop: "1px solid #e9e9e9",
            textAlign: "center",
          },
        },
        {
          label: "Wysyłkowy",
          name: "shipment_count",
          sortable: false,
          style: {
            width: 300,
            borderLeft: "1px solid #e9e9e9",
            borderTop: "1px solid #e9e9e9",
            textAlign: "center",
          },
        },
        {
          label: "Główny",
          name: "main_count",
          sortable: false,
          style: {
            width: 300,
            borderLeft: "1px solid #e9e9e9",
            borderTop: "1px solid #e9e9e9",
            textAlign: "center",
          },
        },
        {
          label: "Łącznie",
          name: "total",
          sortable: false,
          style: {
            textAlign: "center",
            width: 80,
            border: "1px solid #e9e9e9",
            borderBottom: 0,
          },
        },
        {
          label: "Info",
          name: "tooltip",
          sortable: false,
          style: { width: 50 },
        },
      ];
    default:
      return [];
  }
};

export default getHead;
