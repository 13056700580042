import React from "react";
import { connect } from "react-redux";

import { ReactComponent as InfoIcon } from "icons/info.svg";

const BasicInfo = ({ data }) => {
  return (
    <div className="dashboard m-b-10">
      <header className="dashboard__header dashboard__header--edit">
        <div className="icon__container">
          <InfoIcon />
        </div>
        <h2 className="dashboard__title heading">Podstawowe informacje</h2>
      </header>
      <div className="dashboard__form p-t-10">
        <div className="dashboard-wrapper-flex">
          <div className="column-half">
            <div className="info_group">
              <p className="label">Waga produktu</p>
              <p className="value">{data?.weight || "-"} kg</p>
            </div>
            <div className="info_group">
              <p className="label">Kategoria logistyczna</p>
              <p className="value">{data?.root_taxon_name || "="}</p>
            </div>
          </div>
          <div className="column-half">
            <div className="info_group">
              <p className="label">Kod producenta</p>
              <p className="value">{data?.producer_code || "-"}</p>
            </div>
            <div className="info_group">
              <p className="label">Ean</p>
              <p className="value">{data?.ean || "-"}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(({ edit_product }) => ({
  data: edit_product?.data,
}))(BasicInfo);
