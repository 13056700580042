const initialState = {
  status: "invalid",
  data: {
    week: {},
    two_weeks: {},
    month: {},
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "AVERAGE_PACKAGES_LOADING":
      return {
        ...state,
        status: "loading",
      };
    case "AVERAGE_PACKAGES_SUCCESS":
      return {
        ...state,
        status: "success",
        data: action.data,
      };
    case "AVERAGE_PACKAGES_FAILURE":
      return {
        ...state,
        status: "failure",
      };
    default:
      return state;
  }
};
