import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Modal } from "components";
import { ReactComponent as WarningIcon } from "icons/warning.svg";
import { closeCartWarning } from "actions/pack_gdn";
import StyledCartWarningModalContent from "./styles";

const CartWarningModal = ({
  history,
  gdn_pack,
  closeCartWarning,
}) => {
  return (
    <Modal
      size="md"
      handleClose={() => {
        closeCartWarning();
        history.push(`/packing/gdn_pack/${gdn_pack.id}`);
      }}
      header={{
        title: "UWAGA!",
        icon: <WarningIcon />,
      }}>
      <StyledCartWarningModalContent>
        <p>
          Upewnij się, że w koszyku nie ma produktów z tego
          zamówienia.
        </p>
        <p>
          Naklej wydrukowany kod kreskowy na odłożone
          zamówienie.
        </p>
      </StyledCartWarningModalContent>
    </Modal>
  );
};

export default connect(
  ({
    pack_gdn: {
      data: { id, gdn_pack },
    },
  }) => ({ id, gdn_pack }),
  (dispatch) => ({
    closeCartWarning: () => dispatch(closeCartWarning()),
  })
)(withRouter(CartWarningModal));
