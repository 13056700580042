import { client_v2 } from "utils/api";

const getSuppliers = (q) =>
  new Promise(async (resolve, reject) => {
    try {
      const { data } = await client_v2.get(
        `/suppliers?context=list&per_page=100${
          q ? `&q[name_matches]=%25${q}%25` : ""
        }`
      );
      resolve(
        data.map(({ id, name }) => ({
          label: name,
          value: id,
        }))
      );
    } catch (error) {
      console.log(error);
      reject([]);
    }
  });

export default getSuppliers;
