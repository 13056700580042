const getPackerPointsInfo = (value, worked_hours = 8) => {
  const daily_first_limit = Math.ceil(250 * (parseFloat(worked_hours) / 8));
  const daily_second_limit = Math.ceil(300 * (parseFloat(worked_hours) / 8));
  const daily_third_limit = Math.ceil(350 * (parseFloat(worked_hours) / 8));
  const daily_full_limit = Math.ceil(400 * (parseFloat(worked_hours) / 8));

  let level_info = "brak";

  if (worked_hours > 0) {
    if (value >= daily_first_limit) {
      level_info = "Próg I";
    }
    if (value >= daily_second_limit) {
      level_info = "Próg II";
    }
    if (value >= daily_third_limit) {
      level_info = "Próg III";
    }
    if (value >= daily_full_limit) {
      level_info = "Próg IV";
    }
  }

  return {
    daily_first_limit,
    daily_second_limit,
    daily_third_limit,
    daily_full_limit,
    level_info,
  };
};

export default getPackerPointsInfo;
