import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Helmet from "react-helmet";
import { PageLoader } from "components";
import {
  findGdnLineByCode,
  markAsPackedGdnLine,
  getPackingGdn,
  checkGdnForCloseAfterScanningBox,
  checkLeafletEan,
  markAsPackedLeaflet,
} from "actions/pack_gdn";
import { Alert, Loader } from "components";
import {
  ConfirmPanel,
  MissingsPanel,
  ProblemPanel,
  Header,
  GdnLine,
  ConfirmModal,
  GdnLineBrand,
  WarningModal,
  CartWarningModal,
  CancelPanel,
  ErrorPanel,
  PackageInfo,
} from "./components";
import onScan from "utils/scan_handler";
import classNames from "classnames";

const PackGdn = ({
  pack_gdn,
  getPackingGdn,
  findGdnLineByCode,
  checkLeafletEan,
  markAsPackedGdnLine,
  markAsPackedLeaflet,
  checkGdnForCloseAfterScanningBox,
  location: { state },
  match: { params },
  history,
}) => {
  const [warning_type, setWarningType] = useState(null);

  useEffect(() => {
    const handleBeforeClose = (event) => {
      event.preventDefault();
      event.returnValue = "";
    };

    if (state?.error_status) {
      setWarningType(state.error_status);
    }

    getPackingGdn({
      id: params.id,
      shuffle_colors: true,
    }).then((data) => {
      if (state?.code) {
        const gdn_line = data.gdn_lines.find(
          (gdn_line) =>
            gdn_line.product.ean === state.code ||
            gdn_line.product.sku === state.code
        );
        const is_packed = gdn_line.status === "packed";
        if (!is_packed) {
          markAsPackedGdnLine(gdn_line.id);
        }
      }
    });

    onScan.attachTo(document);
    document.addEventListener("scan", handleScan);

    window.addEventListener(
      "beforeunload",
      handleBeforeClose
    );

    return () => {
      onScan.detachFrom(document);
      document.removeEventListener("scan", handleScan);
      window.removeEventListener(
        "beforeunload",
        handleBeforeClose
      );
      window.history.replaceState({}, document.title);
    };
  }, []);

  const handleScan = ({ detail }) => {
    if (checkLeafletEan(detail.scanCode)) {
      markAsPackedLeaflet();
    } else if (detail?.scanCode.includes("picking_box")) {
      const letter = detail.scanCode.split("|")[1];
      checkGdnForCloseAfterScanningBox(letter)
        .then((gdn_id) =>
          history.push(`/packing/gdn/${gdn_id}`)
        )
        .catch((err) => console.log(err));
    } else {
      findGdnLineByCode(detail?.scanCode)
        .then((id) => markAsPackedGdnLine(id))
        .catch((err) => console.log(err));
    }
  };

  if (["invalid", "loading"].includes(pack_gdn.status)) {
    return <PageLoader />;
  }

  if (pack_gdn.status === "failure") {
    return <p>Error</p>;
  }

  const {
    data,
    colors,
    is_blocked,
    is_leaflet_scanned,
  } = pack_gdn;

  const is_error = data.status === "error";

  const getProductsByStatus = (status) =>
    data?.gdn_lines
      ?.filter((item) => item.status === status)
      .sort((item, next) => item.id - next.id);

  const is_disabled = [
    "problem",
    "with_missings",
    "to_cancel",
  ].includes(data.status);

  const picked_products = getProductsByStatus("picked");
  const missing_products = getProductsByStatus("missing");
  const packed_products = getProductsByStatus("packed");

  const gdn_lines = is_error
    ? []
    : [
        ...picked_products,
        ...missing_products,
        ...packed_products,
      ];

  return (
    <div
      className={classNames("packs-view lg", {
        is_blocked,
      })}>
      <Header
        getData={() =>
          getPackingGdn({
            id: params.id,
            shuffle_colors: true,
            is_loading: false,
          })
        }
      />
      <div className="packs-container">
        {colors && (
          <Helmet>
            <style>{`
          ${colors
            .map(
              ({
                sku,
                color,
              }) => `.${sku} .gdn-line__main::before{
                background-color: ${color};}
              .${sku}::after {
                background: linear-gradient(87deg, ${color} 0%, #ffffff 100%);
              }
          `
            )
            .join("\n")}
        `}</style>
          </Helmet>
        )}
        {!!data?.service_note && (
          <Alert text={data.service_note} />
        )}
        {["picked", "with_missings", "problem"].includes(
          data.status
        ) && Boolean(data?.problem_station_note) ? (
          <Alert text={data.problem_station_note} />
        ) : null}
        <PackageInfo />
        <ConfirmPanel />
        <MissingsPanel />
        <ProblemPanel />
        <ConfirmModal />
        <CancelPanel />
        <ErrorPanel />
        {!is_error && (
          <GdnLineBrand disabled={is_disabled} />
        )}
        {gdn_lines.map((item, index) => (
          <GdnLine
            gdn_warehouse_id={data.warehouse_id}
            disabled={is_disabled || !is_leaflet_scanned}
            zIndex={gdn_lines.length - index}
            key={item.id}
            {...item}
          />
        ))}
      </div>
      {is_blocked && (
        <div className="blocked-loader">
          <Loader />
        </div>
      )}
      {warning_type && (
        <WarningModal
          warning_type={warning_type}
          handleConfirm={() => {
            setWarningType(null);
            window.history.replaceState({}, document.title);
          }}
        />
      )}
      {pack_gdn.is_open_cart_warning_modal && (
        <CartWarningModal />
      )}
    </div>
  );
};

export default connect(
  ({ pack_gdn }) => ({ pack_gdn }),
  (dispatch) => ({
    checkLeafletEan: (code) =>
      dispatch(checkLeafletEan(code)),
    markAsPackedLeaflet: () =>
      dispatch(markAsPackedLeaflet()),
    getPackingGdn: (data) => dispatch(getPackingGdn(data)),
    findGdnLineByCode: (code) =>
      dispatch(findGdnLineByCode(code)),
    markAsPackedGdnLine: (id) =>
      dispatch(markAsPackedGdnLine(id)),
    checkGdnForCloseAfterScanningBox: (id) =>
      dispatch(checkGdnForCloseAfterScanningBox(id)),
  })
)(PackGdn);
