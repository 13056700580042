import React from "react";
import { connect } from "react-redux";

import { ReactComponent as GrnStatusIcon } from "icons/grn-summary-status.svg";
import classNames from "classnames";

const getGrnStatusText = (status) => {
  switch (status) {
    case "waiting_for_shift":
      return "Oczekuje na utworzenie MM";
    case "with_errors":
      return "Potwierdzone z błędami";
    case "completed":
      return "Potwierdzone";
    case "finished":
      return "Zakończone";
    default:
      return "";
  }
};

const Status = ({ data }) => {
  const status_text = getGrnStatusText(data?.status);
  return (
    <div
      className={classNames("grn-summary-status", {
        error: data?.status === "with_errors",
        success: data?.status === "completed",
      })}>
      <GrnStatusIcon />
      <span>{status_text}</span>
    </div>
  );
};

export default connect(({ grn }) => ({ data: grn?.data }))(
  Status
);
