import React from "react";
import { connect } from "react-redux";

const InfoText = ({ data }) => {
  if (data?.status === "finished") {
    return (
      <p className="box-text">
        To zamówienie jest zakończone, nie możesz go
        edytować
      </p>
    );
  }
  if (
    data?.grn_lines.filter(
      ({ counting_state }) => counting_state === "pending"
    ).length > 0
  ) {
    return (
      <p className="box-text">
        Możesz potwierdzić tylko uzupełnione zamówienie
      </p>
    );
  }

  if (
    data?.grn_lines.filter(
      ({ counting_state }) => counting_state === "correct"
    ).length === data?.grn_lines?.length &&
    !data?.has_other_error
  ) {
    return (
      <p className="box-text">
        Wszystkie pozycje zostały poprawnie dostarczone i
        przyjęte
      </p>
    );
  }

  return null;
};

export default connect(({ grn }) => ({ data: grn?.data }))(
  InfoText
);
