import React, { useState } from "react";
import { connect } from "react-redux";
import { Button } from "expano-components";
import { AsyncSelect, Modal } from "components";
import { client_v2 } from "utils/api";
import { createPack } from "actions/sorting_gdns";

import { ReactComponent as PacksIcon } from "icons/packs.svg";

const default_option = { label: "Brak", value: null };

const getUsers = (q) =>
  new Promise(async (resolve, reject) => {
    try {
      const { data } = await client_v2.get(
        `/users?context=list&per_page=100${
          q
            ? `&q[first_name_or_last_name_matches]=%25${q}%25`
            : ""
        }`
      );
      resolve([
        default_option,
        ...data.map(({ id, full_name }) => ({
          label: full_name,
          value: id,
        })),
      ]);
    } catch (error) {
      console.log(error);
      reject([]);
    }
  });

const CreatePackModal = ({
  createPack,
  handleClose,
  handleConfirm,
  data,
}) => {
  const [packer, setPacker] = useState(null);

  let gdns_count = 0;
  let gdn_lines_sum_count = 0;
  let gdn_lines_sum_quantity = 0;

  data.forEach((item) => {
    gdn_lines_sum_count += item.gdn_lines_total_count;
    gdn_lines_sum_quantity += item.gdn_lines_total_quantity;
    gdns_count++;
  });

  const gdn_ids = data.map(({ id }) => id);

  return (
    <Modal
      handleClose={handleClose}
      header={{ title: "Utwórz PAK", icon: <PacksIcon /> }}
      footer={
        <>
          <Button type="cancel" onClick={handleClose} />
          <button
            type="button"
            className="btn btn-sm btn-green"
            onClick={() =>
              createPack({
                gdn_ids,
                packer_id: packer,
                compose_mode: "multi",
              }).then(handleConfirm)
            }>
            <span>Utwórz</span>
          </button>
        </>
      }>
      <p className="m-b-20">
        PAK składa się z {gdns_count} zamówień,{" "}
        {gdn_lines_sum_count} pozycji (
        {gdn_lines_sum_quantity} produktów)
      </p>
      <AsyncSelect
        label="Wybierz pakera"
        getData={(q) => getUsers(q)}
        initValue={default_option}
        onChange={({ value }) => setPacker(value)}
      />
    </Modal>
  );
};

export default connect(null, (dispatch) => ({
  createPack: (data) => dispatch(createPack(data)),
}))(CreatePackModal);
