import React, { useEffect, useState } from "react";
import moment from "moment";
import { toastr } from "react-redux-toastr";
import { Button } from "expano-components";

import { Loader, Modal } from "components";
import { client_v2 } from "utils/api";

import { ReactComponent as LocationIcon } from "icons/location.svg";

const LastActivity = ({ id, handleClose }) => {
  const [is_loading, setLoading] = useState(true);
  const [last_picker_activity, setLastPickerActivity] = useState(null);
  const [last_warehouseman_activity, setLastWarehousemanActivity] =
    useState(null);

  useEffect(() => {
    getLastActivity(id);
  }, [id]);

  const getLastActivity = async (id) => {
    try {
      const { data } = await client_v2.get(
        `/warehouse_positions/${id}/last_activity`
      );
      setLastPickerActivity(data.last_picker_activity);
      setLastWarehousemanActivity(data.last_warehouseman_activity);
    } catch (error) {
      toastr.error("Błąd", "Nie udało się pobrać");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      size="md"
      handleClose={handleClose}
      header={{
        icon: <LocationIcon />,
        title: "Ostatna aktywność na lokalizacji",
      }}
      footer={<Button type="cancel" onClick={handleClose} />}
    >
      {is_loading ? (
        <div className="row jc-c">
          <Loader />
        </div>
      ) : (
        <>
          <p className="m-b-5">Ostatnia aktywność pikera</p>
          {last_picker_activity ? (
            <ul className="logs-list">
              <li className="logs-list__item p-0">
                <p className="title">
                  {last_picker_activity.date
                    ? moment(last_picker_activity.date).format(
                        "YYYY-MM-DD HH:mm"
                      )
                    : ""}{" "}
                  {last_picker_activity.user_name}
                </p>
                <p>
                  <a
                    className="link"
                    target="_blank"
                    href={`/gdns/${last_picker_activity.gdn_id}`}
                  >
                    Przejdź do zamówienia
                  </a>
                </p>
              </li>
            </ul>
          ) : (
            <p>-</p>
          )}
          <p className="m-t-10 m-b-5">Ostatnia aktywność magazyniera</p>
          {last_warehouseman_activity ? (
            <ul className="logs-list">
              <li className="logs-list__item p-0">
                <p className="title">
                  {last_warehouseman_activity.date
                    ? moment(last_warehouseman_activity.date).format(
                        "YYYY-MM-DD HH:mm"
                      )
                    : ""}{" "}
                  {last_warehouseman_activity.user_name}
                </p>
                <p>
                  <a
                    className="link"
                    target="_blank"
                    href={`/grns/${last_warehouseman_activity.grn_id}`}
                  >
                    Przejdź do dostawy
                  </a>
                </p>
              </li>
            </ul>
          ) : (
            <p>-</p>
          )}
        </>
      )}
    </Modal>
  );
};

export default LastActivity;
