import { toastr } from "react-redux-toastr";
import { client_v2 } from "utils/api";

export const createRoom = ({
  name,
  identifier,
  warehouse_id,
}) =>
  new Promise(async (resolve, reject) => {
    try {
      await client_v2.post("/warehouse_rooms", {
        name,
        identifier,
        warehouse_id,
      });
      resolve();
      toastr.success(
        "Sukces",
        "Pomieszczenie zostało dodane"
      );
    } catch (error) {
      reject(error);
      toastr.error("Błąd", "Wystąpił błąd");
    }
  });

export const updateRoom = (id, { name, identifier }) =>
  new Promise(async (resolve, reject) => {
    try {
      await client_v2.put(`/warehouse_rooms/${id}`, {
        name,
        identifier,
      });
      resolve();
      toastr.success(
        "Sukces",
        "Pomieszczenie zostało zaktualizowane"
      );
    } catch (error) {
      reject(error);
      toastr.error("Błąd", "Wystąpił błąd");
    }
  });

export const deleteRoom = (id) =>
  new Promise(async (resolve, reject) => {
    try {
      await client_v2.delete(`/warehouse_rooms/${id}`);
      resolve();
      toastr.success(
        "Sukces",
        "Pomieszczenie zostało usunięte"
      );
    } catch (error) {
      reject(error);
      toastr.error("Błąd", "Wystąpił błąd");
    }
  });
