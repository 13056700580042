import { toastr } from "react-redux-toastr";
import { client_v2, stringifyQuery } from "utils/api";

export const toggleFilters = () => (dispatch) => {
  dispatch({ type: "SORTING_TOGGLE_FILTERS" });
};

export const resetFilters = () => (dispatch) => {
  dispatch({ type: "SORTING_RESET_FILTERS" });
};

export const filterSortingGdns = (query) => async (
  dispatch
) => {
  dispatch({ type: "SORTING_SET_FILTERS_QUERY", query });
};

export const getSortingGdns = ({
  page = 1,
  per_page = 16,
  ...rest
}) => async (dispatch, getState) => {
  try {
    const {
      status,
      filters: { query },
    } = getState().sorting_gdns;

    dispatch({
      type:
        status === "invalid"
          ? "SORTING_LOADING"
          : "SORTING_SEARCHING",
    });

    delete rest["sort[column]"];
    delete rest["sort[order]"];
    const { data, meta, aggs, stats } = await client_v2.get(
      `/gdns/sorting_room?${stringifyQuery({
        ...rest,
        page,
        per_page,
        context: "ui_sorting_room",
        ...query,
      })}`
    );
    dispatch({
      type: "SORTING_SUCCESS",
      data,
      meta,
      aggs,
      stats,
    });
  } catch (error) {
    dispatch({ type: "SORTING_FAILURE" });
    toastr.error(
      "Błąd",
      "Wystąpił błąd podczas pobierania"
    );
  }
};

export const createPack = ({
  compose_mode,
  packer_id,
  gdn_ids,
}) => (dispatch) =>
  new Promise(async (resolve, reject) => {
    try {
      await client_v2.post("/gdn_packs/compose", {
        compose_mode,
        packer_id,
        gdn_ids,
      });

      dispatch({
        type: "CREATE_PACK_SUCCESS",
        gdn_ids,
      });
      toastr.success(
        "Sukces",
        "Pomyślnie utworzono pak(i)"
      );
      resolve(gdn_ids);
    } catch (error) {
      console.log(error);
      toastr.error(
        "Błąd",
        error?.data?.response?.message ||
          "Wystąpił błąd podczas tworzenia paka"
      );
      reject(error);
    }
  });
