import React, { useState } from "react";
import { connect } from "react-redux";
import { Select, Button } from "expano-components";
import { Modal } from "components";
import { autoGeneratePack } from "actions/packs";
import { ReactComponent as PackIcon } from "icons/pack.svg";
import picking_mode_options from "utils/picking_mode_options";

const picking_scope_options = [
  { label: "Wszystkie", value: "all" },
  { label: "Zagraniczne", value: "foreign" },
];

const ModalAddPackScope = ({
  handleClose,
  handleReload,
  current_user,
}) => {
  const [picking_mode, setPickingMode] = useState(
    current_user?.picking_mode
  );

  const [picking_scope, setPickingScope] = useState(
    picking_scope_options
      .map(({ value }) => value)
      .includes(current_user?.picking_scope)
      ? current_user?.picking_scope
      : "all"
  );

  const handleAddPack = async () => {
    try {
      await autoGeneratePack({
        picking_mode,
        picking_scope,
      });

      handleClose();
      handleReload();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Modal
      handleClose={handleClose}
      header={{ icon: <PackIcon />, title: "Utwórz paka" }}
      footer={
        <>
          <Button type="cancel" onClick={handleClose} />
          <Button
            text="Utwórz"
            disabled={!picking_mode}
            type="save"
            onClick={handleAddPack}
          />
        </>
      }>
      <Select
        label="Wybierz rodzaj zamówień"
        defaultValue={picking_scope_options.find(
          ({ value }) => value === picking_scope
        )}
        options={picking_scope_options}
        handleChange={(value) => setPickingScope(value)}
      />
      <Select
        label="Wybierz rozmiar"
        defaultValue={picking_mode_options.find(
          ({ value }) => value === picking_mode
        )}
        options={picking_mode_options}
        handleChange={(value) => setPickingMode(value)}
      />
    </Modal>
  );
};

export default connect(({ current_user }) => ({
  current_user,
}))(ModalAddPackScope);
