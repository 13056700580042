const cart_boxes = {
  box_24: [
    ["A1", "A2", "A3", "A4"],
    ["B1", "B2", "B3", "B4"],
    ["C1", "C2", "C3", "C4"],
    ["D1", "D2", "D3", "D4"],
    ["E1", "E2", "E3", "E4"],
    ["F1", "F2", "F3", "F4"],
  ],
  box_12: [
    ["A1", "A2"],
    ["B1", "B2"],
    ["C1", "C2"],
    ["D1", "D2"],
    ["E1", "E2"],
    ["F1", "F2"],
  ],
  box_6: [
    ["A1", "A2"],
    ["B1", "B2"],
    ["C1", "C2"],
  ],
  box_4: [["A1"], ["B1"], ["C1"], ["D1"]],
  box_1: [["A1"]],
};

export default cart_boxes;
