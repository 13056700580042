const initialState = {
  status: "invalid",
  filters: {
    open: true,
    query: {},
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "PACKS_TOGGLE_FILTERS":
      return {
        ...state,
        filters: {
          ...state.filters,
          open: !state.filters.open,
        },
      };
    case "PACKS_RESET_FILTERS":
      return {
        ...state,
        filters: {
          open: true,
          query: initialState.filters.query,
        },
      };
    case "PACKS_SET_FILTERS_QUERY":
      return {
        ...state,
        filters: { ...state.filters, query: action.query },
      };
    case "PACKS_LOADING":
      return {
        ...state,
        status: "loading",
      };
    case "PACKS_SEARCHING":
      return {
        ...state,
        status: "searching",
      };
    case "PACKS_SUCCESS":
      return {
        ...state,
        status: "success",
        data: action.data,
        meta: action.meta,
      };
    case "PACKS_FAILURE":
      return {
        ...state,
        status: "failure",
      };
    case "PACKS_SET_PACKER": {
      const data = [...state.data];
      action.data.forEach((item) => {
        const index = data.indexOf(
          data.find(({ id }) => id === item.id)
        );
        data[index] = item;
      });
      return {
        ...state,
        data,
      };
    }
    default:
      return state;
  }
};
