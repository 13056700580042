import React from 'react'

const getActivityStatus = (status, gdn_pack_id) => {
  switch (status) {
    case "picked":
    case "packed":
      return (
        <>
          <span className="label label--warning">
            <span className="text-10">Pakowane</span>
          </span>
          <span> ({gdn_pack_id})</span>
        </>
      );
    case "new":
      return (
        <>
          <span className="label label--purple">
            <span className="text-10">W kompletacji</span>
          </span>
          <span> ({gdn_pack_id})</span>
        </>
      );
    default:
      return "Bezczynny";
  }
};

export default getActivityStatus