import React, { useState } from "react";
import { withRouter } from "react-router";
import {
  LocalizationInfo,
  QrCodeStickerModal,
} from "components";
import { ReactComponent as QRcodeIcon } from "icons/qrcode.svg";
import { ReactComponent as BarCodeIcon } from "icons/barcode.svg";
import getProductStatus from "utils/getProductStatus";
// import getStatus from "../../const/statuses";

const getMainLocation = (data) => {
  if (!data?.length) return null;
  try {
    const current_location = data.find((item) =>
      [2, 4].includes(item?.warehouse?.id)
    );
    return current_location?.warehouse_position;
  } catch (error) {
    console.log(error);
    return null;
  }
};

const LineItem = ({
  id,
  name,
  sku,
  ean,
  producer_code,
  producer_name,
  has_qr_code,
  has_ean,
  status,
  product_main_locations,
  history,
}) => {
  const [
    is_qr_codes_modal_open,
    openQrCodesModal,
  ] = useState(false);

  const clickAction = {
    style: { cursor: "pointer" },
    onMouseDown: (event) => {
      if (event.button === 1) {
        window.open(`/products/${id}`, "_blank");
      }
    },
    onClick: () => history.push(`/products/${id}`),
  };

  const main_location = getMainLocation(
    product_main_locations
  );

  return (
    <tr>
      <td {...clickAction}>{id}</td>
      <td {...clickAction}>{sku}</td>
      <td {...clickAction}>{ean}</td>
      <td {...clickAction}>{producer_code || "-"}</td>
      <td {...clickAction}>{name?.truncate(60)}</td>
      <td {...clickAction}>
        <LocalizationInfo main={main_location} />
      </td>
      <td>
        {has_qr_code && (
          <button
            onClick={() => openQrCodesModal(true)}
            className="m-r-10">
            <QRcodeIcon />
          </button>
        )}
        {has_ean && <BarCodeIcon />}
      </td>
      <td {...clickAction}>{producer_name}</td>
      <td {...clickAction}>
        {" "}
        <div
          className={`product_status product_status__big ${
            getProductStatus(status)
              ?.color
          }`}
          style={{ marginLeft: "auto" }}>
          <span>
            {
              getProductStatus(status)
                ?.name
            }
          </span>
        </div>
      </td>
      {is_qr_codes_modal_open && (
        <QrCodeStickerModal
          data={{ id, sku, product_main_locations, name }}
          handleClose={() => openQrCodesModal(false)}
          initialValues={{ quantity: 1 }}
        />
      )}
    </tr>
  );
};

export default withRouter(LineItem);
