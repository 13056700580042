const package_type_options = [
  { label: "Dpd", value: "Package::DpdPackage" },
  { label: "Allegro", value: "Package::AllegroPackage" },
  { label: "Gls", value: "Package::GlsPackage" },
  { label: "InPost", value: "Package::InpostPackage" },
  { label: "Erli", value: "Package::ErliPackage" },
  { label: "Poczta Polska", value: "Package::PocztaPolskaPackage" },
  { label: "UPS", value: "Package::UpsPackage" },
  { label: "DHL", value: "Package::DhlPackage" },
];

export const getCourierByPackageType = (type) =>
  package_type_options.find(({ value }) => value === type)
    ?.label;

export default package_type_options;
