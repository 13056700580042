import React from "react";
import {
  AlertsForm,
  ImageForm,
  NoteForm,
  Stickers,
  BasicInfo,
  LogisticEans,
  Suppliers,
  QrCode,
} from "./components";

const Basic = () => {
  return (
    <div className="dashboard-wrapper-flex">
      <div className="column-2">
        <BasicInfo />
        <AlertsForm />
        <div className="dashboard-wrapper-flex">
          <div className="column-half">
            <LogisticEans />
          </div>
          <div className="column-half">
            <Suppliers />
          </div>
        </div>
      </div>
      <div className="column-1">
        <QrCode/>
        <ImageForm />
        <NoteForm />
        <Stickers />
      </div>
    </div>
  );
};

export default Basic;
