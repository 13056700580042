export const sort = [
  {
    label: "ID",
    name: "id",
    sortable: false,
    style: { width: 150 },
  },
  {
    label: "Pozycja",
    name: "identifier",
    sortable: false,
    style: { width: 150 },
  },
    {
    label: "Sku produktu",
    name: "height",
    sortable: false,
    style: { width: 200 },
  },
  {
    label: "Nazwa produktu",
    name: "depth",
    sortable: false,
  },
  {
    label: "Stan magazynowy",
    name: "stock",
    style: { width: 170 },
  },
  {
    label: "Status produktu",
    name: "status",
    sortable: false,
    style: { width: 150 },
  },
];

export const filters = [
  {
    type: "input",
    name: "id",
    sort_id: "id",
    search_type: "matches",
  },
  {
    type: "input",
    name: "identifier",
    sort_id: "identifier",
    search_type: "matches",
  },
];
