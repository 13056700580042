import { client_v2 } from "utils/api";

const getWarehousePositions = (query, shelf_id) =>
  new Promise((resolve, reject) =>
    client_v2
      .get(
        `/warehouse_positions?context=list&sort[order]=asc&sort[column]=position&q[warehouse_shelf_id_eq]=${shelf_id}&page=1&per_page=50&${
          query
            ? `&q[identifier_matches]=%25${query}%25`
            : ""
        }`
      )
      .then(({ data }) =>
        resolve(
          data?.map(({ id, identifier }) => ({
            label: identifier,
            value: id,
          }))
        )
      )
      .catch((err) => reject(err))
  );

export default getWarehousePositions;
