import React, { useState } from "react";
import { connect } from "react-redux";
import { Button, ModalDelete } from "expano-components";
import { deleteEan } from "actions/products";
import { EditEan } from "../modals";

const EanListItem = ({
  current_user,
  id,
  code,
  quantity,
  deleteEan,
}) => {
  const [show_delete_ean, handleShowDeleteEan] = useState(
    false
  );
  const [show_edit_ean, handleShowEditEan] = useState(
    false
  );

  return (
    <>
      {show_delete_ean && (
        <ModalDelete
          handleDelete={() => deleteEan(id)}
          handleClose={() => handleShowDeleteEan(true)}
          name={`kod logistyczny ${code}`}
        />
      )}
      {show_edit_ean && (
        <EditEan
          ean_id={id}
          initialValues={{
            code,
            quantity,
          }}
          handleClose={() => handleShowEditEan(false)}
        />
      )}
      <li className="dashboard__list-item">
        <p>
          {code} ({quantity} szt.)
        </p>
        <span className="country country-label country-label--hidden" />
        {["admin", "manager"].includes(
          current_user.role
        ) && (
          <>
            <Button
              type="edit"
              onClick={() => handleShowEditEan(true)}
            />
            <Button
              type="delete-bin"
              onClick={() => handleShowDeleteEan(true)}
            />
          </>
        )}
      </li>
    </>
  );
};

export default connect(
  ({ current_user }) => ({ current_user }),
  (dispatch) => ({
    deleteEan: (id) => dispatch(deleteEan(id)),
  })
)(EanListItem);
