import { toastr } from "react-redux-toastr";
import { client_v2 } from "utils/api";

const getOrderDeliveryData = async (gdn_id) => {
  try {
    const { data } = await client_v2.get(
      `/gdns/${gdn_id}?context=delivery_address`
    );
    return data;
  } catch (error) {
    toastr.error(
      "Błąd",
      "Nie udało się pobrać danych adresowych"
    );
    throw error;
  }
};

export default getOrderDeliveryData;
