import React from "react";

import { package_boxes_modal_list } from "utils/package_boxes";
import { ReactComponent as ArrowPrevIcon } from "icons/arrow-back.svg";

const AddItem = ({ handleAdd, handleBack }) => {
  const handleFocusOnAddedFormRow = () => {
    const el = document.querySelector(
      "#packages_form .form-group:last-child .box-3:first-child input"
    );
    if (el) {
      el.select();
    }
  };
  return (
    <div className="package-boxes">
      <button
        className="btn btn-sm btn-cancel"
        type="cancel"
        onClick={handleBack}>
        <ArrowPrevIcon
          style={{ transform: "scaleX(-1)" }}
        />
        <span>Wróć</span>
      </button>
      {package_boxes_modal_list.map(({ label, items }) => (
        <div className="package-boxes__box" key={label}>
          <h3>{label}</h3>
          <ul className="box-list">
            <div className="box-list__image">
              <img
                src={require(`icons/speditor-${
                  label === "Koperty"
                    ? "envelope"
                    : "package"
                }.png`)}
                alt="Ikona"
              />
            </div>
            {items.map(({ id, name, sizes }) => (
              <li
                className="box-list__item"
                key={id}
                onClick={() => {
                  handleAdd({ id, name, sizes });
                  setTimeout(() => {
                    handleFocusOnAddedFormRow();
                  }, 50);
                }}>
                <span>{name}</span>
              </li>
            ))}
          </ul>
        </div>
      ))}
    </div>
  );
};

export default AddItem;
