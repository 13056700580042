import React from "react";
import { Field, reduxForm } from "redux-form";
import { Input, Button } from "expano-components";
import { updateSupplier } from "actions/suppliers";
import { connect } from "react-redux";

import { ReactComponent as InfoIcon } from "icons/info.svg";

const InputField = (props) => <Input {...props} />;

const BasicInfo = ({
  id,
  pristine,
  invalid,
  submitting,
  handleSubmit,
  updateSupplier,
}) => {
  const onSubmit = (values) => updateSupplier(id, values);

  return (
    <form
      noValidate
      onSubmit={handleSubmit(onSubmit)}
      className="dashboard">
      <header className="dashboard__header">
        <div className="icon__container">
          <InfoIcon />
        </div>
        <h2 className="dashboard__title heading">
          Podstawowe dane
        </h2>
      </header>
      <div className="dashboard__form p-t-20">
        <div className="dashboard-wrapper-flex m-b-20">
          <div className="column-half">
            <Field
              name="name"
              label="Nazwa"
              component={InputField}
            />
          </div>
          <div className="column-half">
            <Field
              name="full_name"
              label="Pełna nazwa"
              component={InputField}
            />
          </div>
        </div>
        <div className="dashboard-wrapper-flex m-b-20">
          <div className="column-half">
            <Field
              name="email"
              label="E-mail"
              component={InputField}
            />
          </div>
          <div className="column-half">
            <Field
              name="phone"
              type="phone"
              label="Telefon"
              component={InputField}
            />
          </div>
        </div>
        <div className="dashboard-wrapper-flex m-b-20">
          <div className="column-half">
            <Field
              name="min_logistic_price"
              type="number"
              label="Minimum logistyczne"
              component={InputField}
            />
          </div>
          <div className="column-half">
            <Field
              name="address"
              label="Adres"
              component={InputField}
            />
          </div>
        </div>
        <div className="dashboard-wrapper-flex m-b-20">
          <div className="column-half">
            <Field
              name="erp_code"
              label="Akronim dostawcy"
              component={InputField}
            />
          </div>
        </div>
      </div>
      <div className="dashboard__form__footer">
        <Button
          disabled={invalid || pristine || submitting}
          type="save"
          text="Zapisz"
          onClick={handleSubmit(onSubmit)}
        />
      </div>
    </form>
  );
};

const validate = (values) => {
  const errors = {};

  if (!values.name) {
    errors.name = "Pole wymagane";
  }

  return errors;
};

export default connect(
  ({ supplier }) => ({
    id: supplier.data.id,
    initialValues: {
      name: supplier?.data?.name,
      full_name: supplier?.data?.full_name || "",
      email: supplier?.data?.email || "",
      phone: supplier?.data?.phone || "",
      min_logistic_price: supplier?.data?.min_logistic_price
        ? parseFloat(supplier.data.min_logistic_price)
        : 0,
      address: supplier?.data?.address || "",
      erp_code: supplier?.data?.erp_code || "",
      
    },
  }),
  (dispatch) => ({
    updateSupplier: (id, values) =>
      dispatch(updateSupplier(id, values)),
  })
)(
  reduxForm({
    form: "edit-supplier",
    validate,
    enableReinitialize: true,
  })(BasicInfo)
);
