const getStatus = (status) => {
  switch (status) {
    case "new":
      return { name: "Nowe", color: "default" };
    case "picking":
      return { name: "W kompletacji", color: "purple" };

    case "picked":
      return { name: "Skompletowane", color: "info-light" };

    case "packing":
      return { name: "Pakowane", color: "warning" };

    case "packed":
      return { name: "Spakowane", color: "success" };

    case "error":
      return { name: "Błąd", color: "danger" };

    case "returning":
      return { name: "W zwrocie", color: "warning" };

    case "completed_with_missings":
      return {
        name: "Wstrzymane z brakami",
        color: "danger",
      };

    default:
      return { name: "Brak", color: "default" };
  }
};

export default getStatus;
