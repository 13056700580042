import React, { useRef } from "react";
import ReactDOM from "react-dom";
import { Button } from "expano-components";

const Modal = ({
  children,
  header,
  footer,
  handleClose,
  className = "",
  size = null,
  prevent_click_outside = false,
  no_padding = false,
}) => {
  const ref = useRef();

  return ReactDOM.createPortal(
    <div
      className="popup-wrapper"
      onClick={(e) => {
        if (ref?.current && !prevent_click_outside) {
          if (!ref.current.contains(e.target)) {
            handleClose();
          }
        }
      }}
    >
      <div
        ref={ref}
        className={`popup ${className} ${Boolean(size) ? size : ""} active`}
      >
        <header className="popup__header">
          <div className="title">
            {Boolean(header?.icon) && (
              <div className="icon">{header?.icon}</div>
            )}
            <h2>{header?.title}</h2>
            {header?.additional_icon}
            {header?.additional_info ? (
              <p className="m-l-10">{header.additional_info}</p>
            ) : null}
          </div>
          <Button type="close" className="popup-close" onClick={handleClose} />
        </header>
        <div className={`popup__body ${no_padding ? "p-0" : ""}`}>
          {children}
        </div>
        {footer && <div className="popup__footer">{footer}</div>}
      </div>
    </div>,
    document.querySelector("#root")
  );
};

export default Modal;
