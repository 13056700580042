const getCompanyNameById = (company_id) => {
  switch (company_id) {
    case "1":
    case 1:
      return "Tradecron";
    case "2":
    case 2:
      return "Wisetrade";
    default:
      return "-";
  }
};

export default getCompanyNameById;
