import React, { useMemo, useContext } from "react";

const CableContext = React.createContext();

function CableProvider({ children, cable }) {
  const value = useMemo(() => {
    return {
      cable,
    };
  }, [cable]);

  return (
    <CableContext.Provider value={value}>
      {children}
    </CableContext.Provider>
  );
}

export const useCable = () => useContext(CableContext);

export default CableProvider;
