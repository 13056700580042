import "../../../../styles/schema-shelf.scss";

import React, { useState } from "react";
import { toastr } from "react-redux-toastr";
import { Field, reduxForm } from "redux-form";
import {
  DragDropContext,
  Droppable,
  Draggable,
} from "react-beautiful-dnd";
import { Modal } from "components";
import { Button, Input } from "expano-components";
import { createRackSchema } from "actions/warehouse_rack_schema";

import { useMainList } from "components/MainList/context/provider";

import { ReactComponent as EditIcon } from "icons/edit.svg";
import { ReactComponent as DragnDropIcon } from "icons/dragndrop.svg";

const InputField = (props) => <Input {...props} />;

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: "none",
  background: isDragging ? "#fff" : "#fff",
  ...draggableStyle,
});

const ModalAddRackType = ({
  invalid,
  handleSubmit,
  handleClose,
}) => {
  const [shelfs, setShelfs] = useState([]);

  const { refetch } = useMainList();

  const onSubmit = async (values) => {
    try {
      if (shelfs?.length < 1) {
        throw new Error("Brak półek");
      }
      const errored_shelf = shelfs.find(
        ({ height, warehouse_positions_count }) =>
          !Boolean(height) ||
          !Boolean(warehouse_positions_count)
      );
      if (Boolean(errored_shelf)) {
        throw new Error(
          "Wszystkie półki muszą mieć wysokość i ilość pozycji!"
        );
      }

      const warehouse_shelf_schemas_attributes = shelfs
        .map(({ warehouse_positions_count, height }) => ({
          warehouse_positions_count,
          height,
        }))
        .reverse();
      const data = {
        ...values,
        warehouse_shelf_schemas_attributes,
      };
      createRackSchema(data)
        .then(() => {
          handleClose();
          refetch();
        })
        .catch((error) => console.log(error));
    } catch (error) {
      toastr.error("Błąd", error?.message);
    }
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const reordered_shelfs = reorder(
      shelfs,
      result.source.index,
      result.destination.index
    );
    setShelfs(reordered_shelfs);
  };

  const updateShelf = (key, id, value) => {
    const shelfs_copy = [...shelfs];
    const index = shelfs_copy.indexOf(
      shelfs_copy.find((shelf) => id === shelf.id)
    );
    if (index > -1) {
      shelfs_copy[index][key] = value;
      setShelfs(shelfs_copy);
    }
  };

  const removeShelf = (id) => {
    setShelfs(
      [...shelfs].filter((shelf) => shelf.id !== id)
    );
  };

  return (
    <Modal
      size="md"
      handleClose={handleClose}
      header={{
        icon: <EditIcon />,
        title: "Utwórz",
      }}
      footer={
        <>
          <Button
            type="cancel"
            text="Anuluj"
            onClick={handleClose}
          />
          <button
            disabled={invalid}
            onClick={handleSubmit(onSubmit)}
            type="submit"
            className="btn btn-sm btn-green">
            <span>Zapisz</span>
          </button>
        </>
      }>
      <form noValidate>
        <table className="modal-table">
          <tbody>
            <tr>
              <td>Nazwa</td>
              <td>
                <Field
                  placeholder="Nazwa"
                  name="name"
                  component={InputField}
                />
              </td>
            </tr>
            <tr>
              <td>Skrót</td>
              <td>
                <Field
                  placeholder="Skrót"
                  name="identifier"
                  component={InputField}
                />
              </td>
            </tr>
            <tr>
              <td>Szerokość</td>
              <td>
                <Field
                  placeholder="Szerokość"
                  name="width"
                  type="number"
                  component={InputField}
                  postfix="cm"
                />
              </td>
            </tr>
            <tr>
              <td>Głębokość</td>
              <td>
                <Field
                  placeholder="Głębokość"
                  name="depth"
                  type="number"
                  component={InputField}
                  postfix="cm"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </form>
      <div className="schema-shelf">
        <div className="schema-shelf__info">
          <p>Półki</p>
          <p className="info">
            Wprowadz wysokość i ilość pozycji każdej półki
          </p>
        </div>
        <div className="schema-shelf__rows">
          <div className="shelf-row shelf-row--add">
            <Button
              type="add"
              text={`Dodaj ${
                shelfs?.length > 0 ? "kolejną" : "pierwszą"
              } półkę`}
              onClick={() => {
                const id = `shelf_${shelfs?.length + 1}`;
                setShelfs([
                  {
                    id,
                    height: "",
                    warehouse_positions_count: "",
                  },
                  ...shelfs,
                ]);
                setTimeout(() => {
                  const el = document.getElementById(
                    `${id}_height`
                  );
                  if (el) {
                    el.focus();
                  }
                }, 100);
              }}
            />
          </div>
          <div className="shelf-row shelf-row--info">
            <p>Wysokość</p>
            <p>Ilość pozycji</p>
          </div>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
              {(provided) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}>
                  {shelfs.map(
                    (
                      {
                        id,
                        height,
                        warehouse_positions_count,
                      },
                      index
                    ) => {
                      const position =
                        shelfs.length - index;
                      return (
                        <Draggable
                          key={id}
                          draggableId={id}
                          index={index}>
                          {(provided, snapshot) => (
                            <div
                              className="shelf-row"
                              key={id}
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={getItemStyle(
                                snapshot.isDragging,
                                provided.draggableProps
                                  .style
                              )}>
                              <span className="shelf-row__position">
                                P
                                {position > 9
                                  ? position
                                  : `0${position}`}
                              </span>
                              <div className="shelf-row__icon">
                                <DragnDropIcon />
                              </div>
                              <div className="shelf-row__form">
                                <Input
                                  type="number"
                                  placeholder="Wysokość"
                                  postfix="cm"
                                  input={{
                                    name: `${id}_height`,
                                    value: height,
                                    onChange: ({
                                      target: { value },
                                    }) =>
                                      updateShelf(
                                        "height",
                                        id,
                                        value
                                      ),
                                  }}
                                  meta={{
                                    touched: true,
                                  }}
                                />
                                <Input
                                  type="number"
                                  placeholder="Ilość pozycji"
                                  input={{
                                    name: `${id}_warehouse_positions_count`,
                                    value: warehouse_positions_count,
                                    onChange: ({
                                      target: { value },
                                    }) =>
                                      updateShelf(
                                        "warehouse_positions_count",
                                        id,
                                        value
                                      ),
                                  }}
                                  meta={{ touched: true }}
                                />
                              </div>
                              <Button
                                type="delete-bin"
                                onClick={() =>
                                  removeShelf(id)
                                }
                              />
                            </div>
                          )}
                        </Draggable>
                      );
                    }
                  )}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      </div>
    </Modal>
  );
};

const validate = (values) => {
  const errors = {};
  if (!values.name) {
    errors.name = "Pole wymagane!";
  }
  return errors;
};

export default reduxForm({
  form: "add-rack-schema",
  validate,
  destroyOnUnmount: false,
  enableReinitialize: true,
})(ModalAddRackType);
