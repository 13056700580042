import moment from "moment";

const initial_state = {
  status: "invalid",
  filters: {
    histogram_range_from: moment().subtract(1, "month"),
    histogram_range_to: moment(),
    histogram_warehouse_ids: [1, 2],
    histogram_date_option: "last_30_days",
  },
  data: {},
};
export default (state = initial_state, action) => {
  switch (action.type) {
    case "WAREHOUSE_STOCK_STATS_LOADING":
      return {
        ...state,
        status: "loading",
      };
    case "WAREHOUSE_STOCK_STATS_UPDATING":
      return {
        ...state,
        status: "updating",
      };
    case "WAREHOUSE_STOCK_STATS_SUCCESS":
      return {
        ...state,
        status: "success",
        data: action.data,
      };
    case "WAREHOUSE_STOCK_STATS_FAILURE":
      return {
        ...state,
        status: "failure",
      };
    case "WAREHOUSE_STOCK_STATS_SET_FILTER": {
      return {
        ...state,
        filters: { ...state.filters, ...action.filter },
      };
    }
    default:
      return state;
  }
};
