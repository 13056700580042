import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { Button } from "expano-components";

const Modal = ({ handleClose, title, gdn_lines }) => {
  const [active, setActive] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setActive(true);
    }, 200);
  }, []);
  return ReactDOM.createPortal(
    <div className='popup-wrapper'>
      <div className={`popup ${active ? "active" : ""}`} style={{ maxWidth: 650 }}>
        <header className='popup__header'>
          <div className='icon__container'>
            <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20'>
              <g transform='translate(20) rotate(90)'>
                <rect fill='none' width='20' height='20' />
                <path
                  fill='#5d78ff'
                  opacity='0.3'
                  d='M14.5,7.666A.833.833,0,1,1,14.5,6h.833a3.333,3.333,0,0,1,3.333,3.333V16a3.333,3.333,0,0,1-3.333,3.333h-10A3.333,3.333,0,0,1,2,16V9.326A3.333,3.333,0,0,1,5.333,5.993h.833a.833.833,0,0,1,0,1.667H5.333A1.667,1.667,0,0,0,3.667,9.326V16a1.667,1.667,0,0,0,1.667,1.667h10A1.667,1.667,0,0,0,17,16V9.332a1.667,1.667,0,0,0-1.667-1.667Z'
                  transform='translate(-0.333 -0.999)'
                />
                <rect fill='#5d78ff' opacity='0.3' width='1.667' height='10' rx='0.833' transform='translate(10.833 11.667) rotate(180)' />
                <path
                  fill='#5d78ff'
                  d='M11.333-2.988l1.911,1.911a.833.833,0,0,0,1.179,0,.833.833,0,0,0,0-1.179l-2.5-2.5a.833.833,0,0,0-1.179,0l-2.5,2.5a.833.833,0,0,0,0,1.179.833.833,0,0,0,1.179,0Z'
                  transform='translate(-1.333 5)'
                />
              </g>
            </svg>
          </div>
          <h2 className='dashboard__title heading'>{title}</h2>
          <Button type='close' onClick={handleClose} />
        </header>
        <div className='popup__body'>
          {gdn_lines?.length > 0 && (
            <ul className='dashboard__modal-gdn-list'>
              {gdn_lines.map(({ product_image_url, product_name, product_sku, quantity }) => (
                <li key={product_sku} className='gdn-item'>
                  <div className='product-image' style={{ backgroundImage: `url(${product_image_url})` }} />
                  <div className='product-info'>
                    <p className='product-name'>{product_name}</p>
                    <p className='product-sku'>{product_sku}</p>
                  </div>
                  <div className='product-quantity'>
                    <span>{quantity}</span>
                  </div>
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    </div>,
    document.getElementById("root")
  );
};

export default Modal;
