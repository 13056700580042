import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { MainTable, Flag } from "components";
import { Label } from "expano-components";

import { Main } from "layouts";
import {
  getSortingGdns,
  filterSortingGdns,
  resetFilters,
  toggleFilters,
} from "actions/sorting_gdns";
import { convertDate } from "utils/helpers";
import { parseQuery } from "utils/api";

import gauge_options from "utils/gauge_options";
import priority_options from "utils/priority_options";
import kind_options from "utils/kind_options";
import difficulty_options from "utils/difficulty_options";

import CreatePackModal from "./components/CreatePackModal";
import CreateSinglePackModal from "./components/CreateSinglePackModal";
import Chart from "./components/Chart";

import { ReactComponent as SortingIcon } from "icons/sorting.svg";

const all_options = [
  ...gauge_options,
  ...priority_options,
  ...kind_options,
  ...difficulty_options,
];

const createOptionsFormAggs = (aggs) => {
  const getLabel = (value) => {
    const label = all_options.find(
      (option) => option.value === value
    )?.label;
    return label || value;
  };
  return (
    aggs?.map(({ value, count }) => {
      const name = getLabel(value);
      return {
        label: `${
          name === "none" ? "box_1" : name
        } (${count})`,
        value,
      };
    }) || []
  );
};

const SortingGdnsList = ({
  sorting_gdns,
  getSortingGdns,
  filterSortingGdns,
  toggleFilters,
  resetFilters,
  location: { search },
}) => {
  const [is_loaded, setLoaded] = useState(false);
  const [show_pack_modal, showCreatePackModal] = useState(
    false
  );
  const [
    show_single_pack_modal,
    showCreateSinglePacksModal,
  ] = useState(false);
  const [sorting_gdns_checked, setGdnChecked] = useState(
    []
  );
  const query = parseQuery(search);

  const sort = {
    "sort[column]": query["sort[column]"] || "prepared_at",
    "sort[order]": query["sort[order]"] || "asc",
  };

  const createSort = () => {
    return {
      sort_column: sort["sort[column]"],
      sort_dir: sort["sort[order]"],
    };
  };
  const getData = () =>
    getSortingGdns({ ...query, ...createSort() });

  useEffect(() => {
    const nav_link = document.querySelector(
      '.nav-item__link[href="/sorting"]'
    );
    nav_link.addEventListener("click", getData);
    return () => {
      setGdnChecked([]);
      resetFilters();
      nav_link.removeEventListener("click", getData);
    };
  }, []);

  useEffect(() => {
    getSortingGdns({ ...query, ...createSort() });
    setLoaded(true);
    setGdnChecked([]);
  }, [search]);

  useEffect(() => {
    if (is_loaded) {
      getSortingGdns({
        ...query,
        ...createSort(),
        page: undefined,
      });
      setGdnChecked([]);
    }
  }, [sorting_gdns.filters.query]);

  if (sorting_gdns.status === "failure") {
    return <p>Error</p>;
  }

  const { data, meta, aggs, stats } = sorting_gdns;

  return (
    <Main
      page={{
        name: "Sortownia",
        icon: <SortingIcon />,
        breadcrumbs: [{ name: "Sortownia" }],
      }}>
      {show_pack_modal && (
        <CreatePackModal
          data={data.filter(({ id }) =>
            sorting_gdns_checked.includes(id)
          )}
          handleClose={() => showCreatePackModal(false)}
          handleConfirm={() => {
            setGdnChecked([]);
            showCreatePackModal(false);
          }}
        />
      )}
      {show_single_pack_modal && (
        <CreateSinglePackModal
          data={data.filter(({ id }) =>
            sorting_gdns_checked.includes(id)
          )}
          handleClose={() =>
            showCreateSinglePacksModal(false)
          }
          handleConfirm={() => {
            setGdnChecked([]);
            showCreateSinglePacksModal(false);
          }}
        />
      )}
      <div className="dashboard">
        <MainTable
          is_loading={["invalid", "loading"].includes(
            sorting_gdns.status
          )}
          is_searching={sorting_gdns.status === "searching"}
          filters_open={sorting_gdns.filters.open}
          toggleFilters={toggleFilters}
          histogram={
            <>
              <Chart
                data={stats?.prepared_at_days_ago}
                label="Opóźnienie"
                renderDataItem={({ key, value }) => [
                  `${key}\n`,
                  value?.count || 0,
                ]}
              />
              <Chart
                data={stats?.cart_box_size}
                label="Gabaryty"
                renderDataItem={({ key, value }) => [
                  `${
                    key === "none"
                      ? "box_1"
                      : key === "heavy"
                      ? "ciężkie"
                      : key
                  }\n ${value?.latency} H`,
                  value?.count || 0,
                ]}
              />
            </>
          }
          show_histogram={true}
          histogram_width={820}
          data={data}
          meta={{
            ...meta,
            per_page_limits: [8, 10, 16, 24],
          }}
          sort={sort}
          head={[
            {
              label: "check_all",
              name: "check_all",
              checked:
                data?.length > 0 &&
                sorting_gdns_checked?.length ===
                  data?.length,
              onChange: ({ target: { checked } }) =>
                data?.length > 0
                  ? checked
                    ? setGdnChecked(
                        data.map(({ id }) => id)
                      )
                    : setGdnChecked([])
                  : null,
            },
            { label: "ID", name: "id", sortable: false },
            {
              label: "Flagi",
              name: "flag",
              sortable: false,
              style: { width: 200 },
            },
            {
              label: "Kompletacja pudełkowa",
              name: "fits_picking_box",
              sortable: false,
              style: { width: 120 },
            },
            { label: "Data utw.", name: "prepared_at" },
            {
              label: "Metoda dostawy",
              name: "delivery_method_name",
            },
            {
              label: "Platforma",
              name: "platform_name",
              sortable: false,
            },
            {
              label: "Sklep",
              name: "shop_name",
              sortable: false,
            },
            {
              label: "Ilości",
              name: "total_products_quantity",
              sortable: false,
              style: { width: 250 },
            },
            {
              label: "Tagi",
              name: "tags_list",
              sortable: false,
              style: { width: 150 },
            },
            {
              label: "Waga",
              name: "weight",
              sortable: true,
              style: { width: 150 },
            },
          ]}
          empty_text="Brak zamówień"
          renderRow={({
            id,
            expano_oms_id,
            prepared_at,
            delivery_method_name,
            gdn_lines_total_count,
            gdn_lines_total_quantity,
            difficulty,
            kind,
            priority,
            gauge,
            channel,
            cart_box_size,
            tag_list,
            weight,
          }) => {
            const kind_value = kind_options
              .filter(({ value }) => value !== "normal")
              .find(({ value }) => value === kind)?.label;

            const priority_value = priority_options
              .filter(({ value }) => value !== "standard")
              .find(({ value }) => value === priority)
              ?.label;

            const gauge_value = gauge_options.find(
              ({ value }) => value === gauge
            )?.label;

            const difficulty_value = difficulty_options.find(
              ({ value }) => value === difficulty
            )?.label;

            const handleChange = () => {
              const is_checked = !!sorting_gdns_checked.find(
                (item) => item === id
              );
              !is_checked
                ? setGdnChecked([
                    ...sorting_gdns_checked,
                    id,
                  ])
                : setGdnChecked(
                    sorting_gdns_checked.filter(
                      (item) => item !== id
                    )
                  );
            };

            return (
              <tr key={id} style={{ cursor: "pointer" }}>
                <td>
                  <div className="form-group">
                    <div
                      className="check-group"
                      style={{ margin: 0 }}>
                      <input
                        id={`gdn_${id}`}
                        type="checkbox"
                        onChange={({
                          target: { checked },
                        }) =>
                          checked
                            ? setGdnChecked([
                                ...sorting_gdns_checked,
                                id,
                              ])
                            : setGdnChecked(
                                sorting_gdns_checked.filter(
                                  (item) => item !== id
                                )
                              )
                        }
                        checked={
                          !!sorting_gdns_checked.find(
                            (item) => item === id
                          )
                        }
                      />
                      <label
                        htmlFor={`gdn_${id}`}
                        className="label-form"
                        style={{ minHeight: 16, margin: 0 }}
                      />
                    </div>
                  </div>
                </td>
                <td>
                  <a
                    className="btn-link"
                    target="__blank"
                    href={`/gdns/${id}`}>
                    {expano_oms_id}
                  </a>
                </td>
                <td onClick={handleChange}>
                  {difficulty_value && (
                    <Flag text={difficulty_value} />
                  )}
                  {gauge_value && (
                    <Flag text={gauge_value} />
                  )}
                  {kind_value && <Flag text={kind_value} />}
                  {priority_value && (
                    <Flag text={priority_value} />
                  )}
                </td>
                <td onClick={handleChange}>
                  {cart_box_size && (
                    <Label
                      type="bordered"
                      color="#0abc88"
                      text={
                        cart_box_size === "none"
                          ? "box_1"
                          : cart_box_size
                      }
                    />
                  )}
                </td>
                <td onClick={handleChange}>
                  {convertDate({ value: prepared_at })}
                </td>
                <td onClick={handleChange}>
                  {delivery_method_name}
                </td>
                <td onClick={handleChange}>
                  {channel?.platform?.label_text ? (
                    <Label
                      type="bordered"
                      color={`#${channel?.platform?.label_color}`}
                      text={channel?.platform?.label_text}
                    />
                  ) : (
                    "-"
                  )}
                </td>
                <td onClick={handleChange}>
                  {channel?.shop?.label_text ? (
                    <Label
                      type="bordered"
                      color={`#${channel?.shop?.label_color}`}
                      text={channel?.shop?.label_text}
                    />
                  ) : (
                    "-"
                  )}
                </td>
                <td onClick={handleChange}>
                  {gdn_lines_total_count} pozycji (
                  {gdn_lines_total_quantity} sztuk)
                </td>
                <td onClick={handleChange}>
                  {tag_list?.length > 0
                    ? tag_list.map((tag, index) => (
                        <p key={index}>{tag}</p>
                      ))
                    : "-"}
                </td>
                <td onClick={handleChange}>{weight} kg</td>
              </tr>
            );
          }}
          filtersAction={filterSortingGdns}
          filters={[
            {
              type: "input",
              label: "Numer zamówienia",
              name: "expano_oms_id",
              search_type: "elastic",
            },
            {
              type: "select",
              label: "Trudność",
              name: "difficulty",
              search_type: "elastic",
              options: createOptionsFormAggs(
                aggs?.difficulty
              ),
            },
            {
              type: "select",
              label: "Priorytet",
              name: "priority",
              search_type: "elastic",
              options: createOptionsFormAggs(
                aggs?.priority
              ),
            },
            {
              type: "select",
              label: "Gabaryt",
              name: "gauge",
              search_type: "elastic",
              options: createOptionsFormAggs(aggs?.gauge),
            },
            {
              type: "select",
              label: "Platforma",
              name: "platform_name",
              search_type: "elastic",
              options: createOptionsFormAggs(
                aggs?.platform_name
              ),
            },
            {
              type: "select",
              label: "Sklep",
              name: "shop_name",
              search_type: "elastic",
              options: createOptionsFormAggs(
                aggs?.shop_name
              ),
            },
            {
              type: "multi-select",
              label: "Kompletacja pudełkowa",
              name: "cart_box_size",
              search_type: "elastic-multi",
              options: createOptionsFormAggs(
                aggs?.cart_box_size
              ),
            },
            {
              type: "select",
              label: "Metoda dostawy",
              name: "delivery_method_name",
              search_type: "elastic",
              options: createOptionsFormAggs(
                aggs?.delivery_method_name
              ),
            },
            {
              type: "multi-select",
              label: "Tagi",
              name: "tag_list",
              search_type: "elastic",
              options: createOptionsFormAggs(
                aggs?.tag_list
              ),
            },
          ]}
          buttons={
            <>
              {/* <button
                disabled={sorting_gdns_checked.length === 0}
                onClick={() =>
                  showCreateSinglePacksModal(true)
                }
                type="button"
                className="btn btn-lg btn-info">
                <svg
                  style={{ marginRight: 6 }}
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20">
                  <path
                    opacity={0.3}
                    fill="#fff"
                    d="M5.25,2H16.083a1.25,1.25,0,0,1,1.25,1.25v2.5A1.25,1.25,0,0,1,16.083,7H5.25A1.25,1.25,0,0,1,4,5.75V3.25A1.25,1.25,0,0,1,5.25,2ZM9.833,3.667a.833.833,0,0,0,0,1.667H11.5a.833.833,0,0,0,0-1.667Z"
                    transform="translate(-0.667 -0.333)"
                  />
                  <path
                    fill="#fff"
                    d="M5.25,9H16.083a1.25,1.25,0,0,1,1.25,1.25v2.5A1.25,1.25,0,0,1,16.083,14H5.25A1.25,1.25,0,0,1,4,12.75v-2.5A1.25,1.25,0,0,1,5.25,9Zm4.583,1.667a.833.833,0,0,0,0,1.667H11.5a.833.833,0,0,0,0-1.667ZM5.25,14.833H16.083a1.25,1.25,0,0,1,1.25,1.25v2.5a1.25,1.25,0,0,1-1.25,1.25H5.25A1.25,1.25,0,0,1,4,18.583v-2.5A1.25,1.25,0,0,1,5.25,14.833ZM9.833,16.5a.833.833,0,1,0,0,1.667H11.5a.833.833,0,1,0,0-1.667Z"
                    transform="translate(-0.667 -1.5)"
                  />
                  <path
                    fill="#fff"
                    d="M15.208,6.125h-1.25a.625.625,0,0,1,0-1.25h1.25V3.625a.625.625,0,0,1,1.25,0v1.25h1.25a.625.625,0,1,1,0,1.25h-1.25v1.25a.625.625,0,0,1-1.25,0Z"
                    transform="translate(-5.833 -1.333)"
                  />
                </svg>
                <span>
                  Utwórz pojedyncze PAKi z zaznaczonych
                </span>
              </button> */}
              <button
                disabled={sorting_gdns_checked.length === 0}
                onClick={() => showCreatePackModal(true)}
                type="button"
                className="btn btn-lg btn-blue">
                <svg
                  style={{ marginRight: 6 }}
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20">
                  <path
                    opacity={0.3}
                    fill="#fff"
                    d="M5.25,2H16.083a1.25,1.25,0,0,1,1.25,1.25v2.5A1.25,1.25,0,0,1,16.083,7H5.25A1.25,1.25,0,0,1,4,5.75V3.25A1.25,1.25,0,0,1,5.25,2ZM9.833,3.667a.833.833,0,0,0,0,1.667H11.5a.833.833,0,0,0,0-1.667Z"
                    transform="translate(-0.667 -0.333)"
                  />
                  <path
                    fill="#fff"
                    d="M5.25,9H16.083a1.25,1.25,0,0,1,1.25,1.25v2.5A1.25,1.25,0,0,1,16.083,14H5.25A1.25,1.25,0,0,1,4,12.75v-2.5A1.25,1.25,0,0,1,5.25,9Zm4.583,1.667a.833.833,0,0,0,0,1.667H11.5a.833.833,0,0,0,0-1.667ZM5.25,14.833H16.083a1.25,1.25,0,0,1,1.25,1.25v2.5a1.25,1.25,0,0,1-1.25,1.25H5.25A1.25,1.25,0,0,1,4,18.583v-2.5A1.25,1.25,0,0,1,5.25,14.833ZM9.833,16.5a.833.833,0,1,0,0,1.667H11.5a.833.833,0,1,0,0-1.667Z"
                    transform="translate(-0.667 -1.5)"
                  />
                  <path
                    fill="#fff"
                    d="M15.208,6.125h-1.25a.625.625,0,0,1,0-1.25h1.25V3.625a.625.625,0,0,1,1.25,0v1.25h1.25a.625.625,0,1,1,0,1.25h-1.25v1.25a.625.625,0,0,1-1.25,0Z"
                    transform="translate(-5.833 -1.333)"
                  />
                </svg>
                <span>Utwórz PAKa z zaznaczonych</span>
              </button>
            </>
          }
        />
      </div>
    </Main>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getSortingGdns: (data) => dispatch(getSortingGdns(data)),
  filterSortingGdns: (data) =>
    dispatch(filterSortingGdns(data)),
  toggleFilters: () => dispatch(toggleFilters()),
  resetFilters: () => dispatch(resetFilters()),
});
export default connect(
  ({ sorting_gdns }) => ({ sorting_gdns }),
  mapDispatchToProps
)(SortingGdnsList);
