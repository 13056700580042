import React, { useState } from "react";
import { ModalPreview } from "expano-components";
import { Link } from "react-router-dom";
import ReactDOM from "react-dom";
import Item from "./components/Item";
import LazyLoad from "react-lazy-load";

const LineItem = ({ product, gdn_lines, getMissings }) => {
  const [is_open, setOpen] = useState(false);
  const [is_modal_open, openModal] = useState(false);
  const is_collapse = gdn_lines?.length > 0;

  return (
    <>
      <li className={`product ${is_open ? "active" : ""}`}>
        <table className="table">
          <tbody className="table__body">
            <tr onClick={() => setOpen(!is_open)}>
              <td width="60" className="collapse">
                {is_collapse && (
                  <button
                    style={{
                      transform: `rotate(${
                        !is_open ? "0" : "90deg"
                      })`,
                    }}
                    type="button"
                    className="btn-collapse"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      setOpen(!is_open);
                    }}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="6"
                      height="11"
                      viewBox="0 0 6 11">
                      <path
                        d="M4.763.8A1,1,0,0,1,6.237.8l3.227,3.52A1,1,0,0,1,8.727,6H2.273a1,1,0,0,1-.737-1.676Z"
                        transform="translate(6) rotate(90)"
                        fill="#5d78ff"
                      />
                    </svg>
                  </button>
                )}
              </td>
              <td width="100">
                {product?.total_missing_quantity}
              </td>
              <td width="60">
                <div className="preview">
                  <LazyLoad>
                    <button
                      className="btn-preview"
                      onClick={() => openModal(true)}
                      style={{
                        backgroundImage: `url(${product?.image_thumb_url || product?.image_url})`,
                      }}
                    />
                  </LazyLoad>
                </div>
              </td>
              <td width="120">
                <Link
                  className="link"
                  to={`/products/${product?.id}`}>
                  {product?.sku}
                </Link>
              </td>
              <td width="200">{product?.name}</td>
              <td width="200">
                {product?.gdn_warehouse_names}
              </td>
              <td width="200">
                {product?.latest_reported_missing_at || "-"}
              </td>
              {is_modal_open &&
                ReactDOM.createPortal(
                  <ModalPreview
                    handleClose={() => {
                      openModal(false);
                    }}
                    src={`${product?.image_url}`}
                    name={product?.name}
                  />,
                  document.getElementById("root")
                )}
            </tr>
          </tbody>
        </table>
        {is_collapse && is_open && (
          <div className="inner-table">
            <table className="table table--inner">
              <thead className="table__head table__head--inner">
                <tr>
                  <th>Brakująca ilość</th>
                  <th>Nr zamówienia</th>
                  <th>Data zamówienia</th>
                  <th>Magazyn</th>
                  <th>Data zgłoszenia</th>
                  <th>Zgłaszający</th>
                </tr>
              </thead>
              <tbody className="table__body table__body--inner">
                {gdn_lines.map((props) => (
                  <Item
                    key={props.id}
                    {...props}
                    product={product}
                    getMissings={getMissings}
                  />
                ))}
              </tbody>
            </table>
          </div>
        )}
      </li>
    </>
  );
};

export default LineItem;
