import React from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { Modal } from "components";
import { Button, Checkbox, Input } from "expano-components";
import { updateProductMainLocationCapacity } from "actions/grns";

import { ReactComponent as EditIcon } from "icons/edit.svg";

const InputField = (props) => <Input {...props} />;
const CheckField = (props) => <Checkbox {...props} />;

const CapacityModal = ({
  product_main_location_id,
  grn_line_id,
  invalid,
  pristine,
  handleSubmit,
  handleClose,
  updateProductMainLocationCapacity,
}) => {
  const onSubmit = async ({ capacity, is_final_capacity }) => {
    try {
      await updateProductMainLocationCapacity({
        grn_line_id,
        product_main_location_id,
        capacity,
        is_final_capacity,
      });
      handleClose(handleClose);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Modal
      handleClose={handleClose}
      header={{
        icon: <EditIcon />,
        title: "Zmień pojemności",
      }}
      footer={
        <>
          <Button type="cancel" text="Anuluj" onClick={handleClose} />
          <button
            disabled={invalid || pristine}
            onClick={handleSubmit(onSubmit)}
            type="submit"
            className="btn btn-sm btn-green"
          >
            <span>Zapisz</span>
          </button>
        </>
      }
    >
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <Field
          placeholder="Pojemność"
          name="capacity"
          type="number"
          postfix="szt"
          component={InputField}
        />
        <Field
          label="Ostateczna pojemność"
          name="is_final_capacity"
          component={CheckField}
        />
      </form>
    </Modal>
  );
};

const validate = (values) => {
  const errors = {};
  if (!values.capacity) {
    errors.capacity = "Pole wymagane!";
  }
  if (values.capacity < 1) {
    errors.capacity = "Musi być większe od 0";
  }

  return errors;
};

export default reduxForm({
  form: "edit-capacity",
  validate,
  enableReinitialize: true,
})(
  connect(null, (dispatch) => ({
    updateProductMainLocationCapacity: (data) =>
      dispatch(updateProductMainLocationCapacity(data)),
  }))(CapacityModal)
);
