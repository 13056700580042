import React, { useState } from "react";
import { connect } from "react-redux";
import QRCode from "react-qr-code";
import Loader from "react-loader-spinner";
import { withRouter } from "react-router-dom";
import { gdnPackStartPacking } from "actions/pack_gdns";

import { ReactComponent as ArrowNext } from "icons/arrow-next.svg";

import StyledQrCodeBox from "./styles";

const QrCodeBox = ({ gdnPackStartPacking, history, match, data }) => {
  const [is_loading, setLoading] = useState(false);

  return (
    <StyledQrCodeBox>
      <div className="box-qr">
        <QRCode size={150} value={`gdn_pack|${data.id}`} />
      </div>
      <div className="box-content">
        <h3>Rozpoczęcie kompletacji</h3>
        <p>
          Zeskanuj QR kod za pomocą telefonu i pakuj zamówienie razem z
          aplikacją mobilną{" "}
        </p>
        <div className="btn-holder">
          <button
            className="btn-success"
            onClick={async () => {
              try {
                setLoading(true);
                await gdnPackStartPacking(data.id);
              } catch (error) {
                console.log(error);
              } finally {
                setLoading(false);
              }
            }}>
            <span>Spakuj zamówienia</span>
            {is_loading ? (
              <Loader type="Oval" color="#fff" height={12} width={12} />
            ) : (
              <ArrowNext />
            )}
          </button>
        </div>
      </div>
    </StyledQrCodeBox>
  );
};

export default connect(
  ({ pack_gdns: { data } }) => ({ data }),
  (dispatch) => ({
    gdnPackStartPacking: (pack_id) => dispatch(gdnPackStartPacking(pack_id)),
  })
)(withRouter(QrCodeBox));
