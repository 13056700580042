const initialState = {
  status: "invalid",
  data: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "PACKER_DASHBOARD_UPDATING":
      return {
        ...state,
        status: "updating",
      };
    case "PACKER_DASHBOARD_LOADING":
      return {
        ...state,
        status: "loading",
      };
    case "PACKER_DASHBOARD_SUCCESS":
      return {
        ...state,
        status: "success",
        data: action.data,
      };
    case "PACKER_DASHBOARD_FAILURE":
      return {
        ...state,
        status: "failure",
      };
    default:
      return state;
  }
};
