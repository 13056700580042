import React from "react";
import { Modal } from "components";
import StyledPackageVerifyWarningModalContent from "./styles";

const PackageVerifyWarningModal = ({ handleClose }) => (
  <Modal
    size="md"
    className="danger"
    prevent_click_outside
    handleClose={handleClose}
    header={{
      title: "UWAGA!",
      icon: null,
    }}
  >
    <StyledPackageVerifyWarningModalContent>
      <p>!! PACZKI DO KONTROLI !!</p>
      <p>
        Zanieś proszę paczki z tego zamówienia do biura w
        celu werfyikacji poprawności pakowania.
      </p>
    </StyledPackageVerifyWarningModalContent>
  </Modal>
);

export default PackageVerifyWarningModal;
