import React, { useState, useEffect } from "react";
import { change } from "redux-form";
import { connect } from "react-redux";
import { pdf } from "@react-pdf/renderer";
import { Main } from "layouts";
import { QrCodeModal, ButtonGroup } from "components";

import { getGrn, setGrnLinesFilter, refreshOrder } from "actions/grns";
import onScan from "utils/scan_handler";

import GrnKind from "./GrnKind";

import {
  PdfDoc,
  GenerateCsvModal,
  GenerateExcelModal,
  NoteModal,
  LogsModal,
} from "./components";

import { getStatusLabelData } from "../const/statuses";

import { ReactComponent as DeliveryIcon } from "icons/delivery.svg";
import { ReactComponent as DownloadIcon } from "icons/download.svg";
import { ReactComponent as RefreshIcon } from "icons/refresh.svg";
import { ReactComponent as MessageIcon } from "icons/message.svg";
import { ReactComponent as LogIcon } from "icons/log.svg";
import { ReactComponent as QRIcon } from "icons/qr.svg";

const GrnEdit = ({
  dispatch,
  grn,
  getGrn,
  setGrnLinesFilter,
  refreshOrder,
  match: { params },
}) => {
  const [is_loading, setLoading] = useState(false);

  const [is_open_generate_excel_modal, handleOpenExcelModal] = useState(false);
  const [is_open_generate_csvk_modal, handleOpenCsvkModal] = useState(false);

  const [is_open_notes_modal, handleOpenNotesModal] = useState(false);
  const [is_open_logs_modal, handleOpenLogsModal] = useState(false);
  const [is_open_grn_qr_modal, setOpenGrnQrModal] = useState(false);

  const generatePdf = async ({ show_images = true, show_packages = false }) => {
    try {
      setLoading(true);

      const blob = await pdf(
        <PdfDoc
          data={data}
          show_images={show_images}
          show_packages={show_packages}
        />
      ).toBlob();

      const url = window.URL.createObjectURL(blob);
      window.open(url, "_blank");
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getGrn(params.id);
    onScan.attachTo(document);
    document.addEventListener("scan", handleScan);
    return () => {
      onScan.detachFrom(document);
      document.removeEventListener("scan", handleScan);
      setGrnLinesFilter("sku", "");
    };
  }, [params.id]);

  const handleScan = ({ detail: { scanCode } }) => {
    const focused = document.querySelector("input:focus");
    if (["counted_quantity", "dispatched_quantity"].includes(focused?.name)) {
      const form_name = focused.dataset?.form;
      const value = focused.dataset?.value;
      const new_value = value.replace(scanCode, "") || "";
      if (form_name) {
        dispatch(
          change(
            form_name,
            "counted_quantity",
            new_value === "0" ? "" : new_value
          )
        );
      }
      focused.value = new_value;
      focused.dataset.value = new_value;
      focused.blur();
    }
    setGrnLinesFilter("sku", scanCode || "");
  };

  const { data } = grn;

  const { status_text, status } = getStatusLabelData({
    status: data.status,
    is_dispatched: data.is_dispatched,
  });

  return (
    <Main
      is_loading={["invalid", "loading", "failure"].includes(grn.status)}
      page={{
        name: data?.identifier,
        icon: <DeliveryIcon />,
        breadcrumbs: [
          {
            name: "Dostawy",
            path: "/grns",
          },
          { name: data?.identifier || "Trwa pobieranie..." },
        ],
        buttons: (
          <>
            <button
              type="button"
              className="btn btn-default"
              onClick={() => setOpenGrnQrModal(true)}
            >
              <QRIcon />
              <span>QR Dostawy</span>
            </button>
            {data?.logs?.length > 0 && (
              <button
                type="button"
                className="btn btn-default"
                onClick={() => handleOpenLogsModal(true)}
              >
                <LogIcon />
                <span>Logi</span>
              </button>
            )}
            <button
              type="button"
              className="btn btn-default"
              onClick={() => handleOpenNotesModal(true)}
            >
              <MessageIcon />
              <span>Notatki</span>
            </button>
            <button
              type="button"
              className="btn btn-default"
              onClick={() => refreshOrder(data?.id)}
            >
              <RefreshIcon />
              <span>Odśwież zamówienie</span>
            </button>
            <ButtonGroup
              trigger={{
                icon: <DownloadIcon />,
                text: is_loading ? "Generwanie..." : "Wydruk dostawy",
              }}
              buttons={[
                {
                  text: "Bez zdjęć",
                  action: () =>
                    generatePdf({ show_images: false, show_packages: false }),
                },
                {
                  text: "Bez zdjęć - opakowania",
                  action: () =>
                    generatePdf({ show_images: false, show_packages: true }),
                },
                {
                  text: "Ze zdjęciami",
                  action: () =>
                    generatePdf({ show_images: true, show_packages: false }),
                },
              ]}
            />
            <ButtonGroup
              trigger={{
                icon: <DownloadIcon />,
                text: "Eksportuj",
              }}
              buttons={[
                {
                  text: "CSV",
                  action: () => handleOpenCsvkModal(true),
                },
                {
                  text: "EXCEL",
                  action: () => handleOpenExcelModal(true),
                },
              ]}
            />
          </>
        ),
        status_text,
        status,
      }}
    >
      <GrnKind />
      {is_open_generate_csvk_modal && (
        <GenerateCsvModal handleClose={() => handleOpenCsvkModal(false)} />
      )}
      {is_open_notes_modal && (
        <NoteModal handleClose={() => handleOpenNotesModal(false)} />
      )}
      {is_open_logs_modal && (
        <LogsModal handleClose={() => handleOpenLogsModal(false)} />
      )}
      {is_open_generate_excel_modal && (
        <GenerateExcelModal handleClose={() => handleOpenExcelModal(false)} />
      )}
      {is_open_grn_qr_modal && (
        <QrCodeModal
          identifier={data.identifier}
          handleClose={() => setOpenGrnQrModal(false)}
          title="Przejdź do dostawy"
          value={`grn_id|${data.id}`}
        />
      )}
    </Main>
  );
};

export default connect(
  ({ grn }) => ({ grn }),
  (dispatch) => ({
    dispatch,
    getGrn: (id) => dispatch(getGrn(id)),
    setGrnLinesFilter: (name, value) =>
      dispatch(setGrnLinesFilter(name, value)),
    refreshOrder: (id) => dispatch(refreshOrder(id)),
  })
)(GrnEdit);
