import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { MainTable } from "components";
import { Main } from "layouts";
import { parseQuery } from "utils/api";
import {
  getSuppliers,
  filterSuppliers,
  resetFilters,
  toggleFilters,
} from "actions/suppliers";
import { Button } from "expano-components";

import { ReactComponent as SupplierIcon } from "icons/supplier.svg";

const SuppliersList = ({
  suppliers,
  filterSuppliers,
  toggleFilters,
  resetFilters,
  getSuppliers,
  history,
  location: { search },
}) => {
  const [is_loaded, setLoaded] = useState(false);
  const query = parseQuery(search);
  const sort = {
    "sort[column]": query["sort[column]"] || "id",
    "sort[order]": query["sort[order]"] || "asc",
  };

  useEffect(() => {
    return () => {
      resetFilters();
    };
  }, []);

  useEffect(() => {
    getSuppliers({ ...query, ...sort });
    setLoaded(true);
  }, [search]);

  useEffect(() => {
    if (is_loaded) {
      getSuppliers({ ...query, page: undefined });
    }
  }, [suppliers.filters.query]);

  if (suppliers.status === "failure") {
    return <p>Error</p>;
  }

  const { data, meta } = suppliers;
  return (
    <Main
      page={{
        name: "Dostawcy",
        icon: <SupplierIcon />,
        breadcrumbs: [{ name: "Dostawcy" }],
        buttons: (
          <Button
            type="add"
            text="Dodaj dostawcę"
            onClick={() => history.push("/suppliers/new")}
          />
        ),
      }}>
      <div className="dashboard">
        <MainTable
          is_loading={["invalid", "loading"].includes(
            suppliers.status
          )}
          is_searching={suppliers.status === "searching"}
          filters_open={suppliers.filters.open}
          toggleFilters={toggleFilters}
          data={data}
          meta={meta}
          sort={sort}
          head={[
            {
              label: "ID",
              name: "id",
              style: { width: 100 },
            },
            {
              label: "Nazwa",
              name: "name",
              style: { width: 150 },
            },
            {
              label: "Akronim",
              name: "erp_code",
              style: { width: 150 },
            },
            {
              label: "Pełna nazwa",
              name: "full_name",
            },
            { label: "Adres", name: "address" },
            { label: "Email", name: "email" },
            {
              label: "Minimum logistyczne",
              name: "min_logistic_price",
            },
          ]}
          empty_text="Brak dostawców"
          renderRow={({
            id,
            name,
            full_name,
            address,
            email,
            min_logistic_price,
            erp_code,
          }) => (
            <tr
              key={id}
              style={{ cursor: "pointer" }}
              onMouseDown={(event) => {
                if (event.button === 1) {
                  window.open(`/suppliers/${id}`, "_blank");
                }
              }}
              onClick={() =>
                history.push(`/suppliers/${id}`)
              }>
              <td>{id}</td>
              <td>{name}</td>
              <td>{erp_code}</td>
              <td>{full_name}</td>
              <td>{address}</td>
              <td>{email}</td>
              <td>{min_logistic_price}</td>
            </tr>
          )}
          filtersAction={filterSuppliers}
          filters={[
            {
              type: "input",
              label: "Nazwa",
              name: "name_or_full_name",
              search_type: "matches",
            },
            {
              type: "input",
              label: "E-mail",
              name: "email",
              search_type: "matches",
            },
          ]}
        />
      </div>
    </Main>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getSuppliers: (data) => dispatch(getSuppliers(data)),
  filterSuppliers: (data) =>
    dispatch(filterSuppliers(data)),
  toggleFilters: () => dispatch(toggleFilters()),
  resetFilters: () => dispatch(resetFilters()),
});
export default connect(
  ({ suppliers }) => ({ suppliers }),
  mapDispatchToProps
)(SuppliersList);
