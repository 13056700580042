import React from "react";
import { Modal } from "components";

import { status_options } from "pages/gdns/const/statuses";

const statuses_order = status_options.map(
  ({ value }) => value
);

const getStatusLabel = (name) =>
  status_options.find(({ value }) => value === name)
    ?.label || "-";

const InProgressBoxModal = ({
  handleClose,
  new_per_status = {},
  new_per_packer = {},
}) => {
  const statuses_data =
    Object.keys(new_per_status).length > 0
      ? Object.keys(new_per_status).map((key) => ({
          name: key,
          value: new_per_status[key],
        }))
      : [];

  const packers_data =
    Object.keys(new_per_packer).length > 0
      ? Object.keys(new_per_packer).map((key) => ({
          name: key,
          value: new_per_packer[key],
        }))
      : [];

  return (
    <Modal
      size="lg"
      handleClose={handleClose}
      header={{
        icon: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24">
            <path
              fill="#5d78ff"
              d="M10.963,7.5h.084a.5.5,0,0,1,.5.45L12,12.5l3.248,1.856a.5.5,0,0,1,.252.434V15a.382.382,0,0,1-.482.368L10.4,14.109a.5.5,0,0,1-.367-.521l.433-5.626A.5.5,0,0,1,10.963,7.5Z"
              transform="translate(0)"
            />
            <path
              fill="#5d78ff"
              opacity="0.3"
              d="M7.39,2.835a10.487,10.487,0,1,1-6,6.823l1.926.54A8.489,8.489,0,1,0,8.749,4.454L9.939,5.874a.5.5,0,0,1-.355.821L4.718,6.97A.5.5,0,0,1,4.2,6.355L5.332,1.624A.5.5,0,0,1,6.2,1.418Z"
            />
          </svg>
        ),
        title: "W trakcie",
      }}>
      {statuses_data?.length > 0 && (
        <ul className="dashboard__modal-list">
          <li className="title">
            <span>Statusy</span>
          </li>
          {statuses_data
            .sort(
              (a, b) =>
                statuses_order.indexOf(a.name) -
                statuses_order.indexOf(b.name)
            )
            .map(({ name, value }, index) => (
              <li key={index}>
                <span>{getStatusLabel(name)}</span>
                <span>{value}</span>
              </li>
            ))}
        </ul>
      )}
      {packers_data?.length > 0 && (
        <ul className="dashboard__modal-list">
          <li className="title">
            <span>Pakerzy</span>
          </li>
          {packers_data?.map(({ name, value }, index) => (
            <li key={index}>
              <span>{name}</span>
              <span>{value}</span>
            </li>
          ))}
        </ul>
      )}
    </Modal>
  );
};

export default InProgressBoxModal;
