import React, { useState, useEffect } from "react";
import QRCode from "qrcode";
import { pdf } from "@react-pdf/renderer";
import { saveAs } from "file-saver";
import Loader from "react-loader-spinner";
import { Field, reduxForm } from "redux-form";
import { Button, Input } from "expano-components";
import { Modal } from "components";
import { StickerQrCode } from "sticker_templates";
import { ReactComponent as Sticker } from "icons/sticker.svg";

const InputField = (props) => <Input {...props} />;

const validate = (values) => {
  const errors = {};
  if (!values.quantity) {
    errors.validate = "Pole wymagane!";
  } else if (isNaN(Number(values.quantity))) {
    errors.quantity = "Musi być liczbą!";
  } else if (Number(values.quantity) < 1) {
    errors.quantity = "Wartość musi byc wieksza od 0!";
  }
  return errors;
};

const QrCodeStickerModal = ({
  data,
  invalid,
  handleSubmit,
  handleClose,
}) => {
  const product_location_identifier =
    data.product_main_locations?.[0]?.warehouse_position
      ?.identifier;

  const [qr_code_base64, setQrCodeBase64] = useState(null);
  const [is_loading, setLoading] = useState(true);

  useEffect(() => {
    if (data.sku) {
      QRCode.toDataURL(data.sku).then((code) => {
        setQrCodeBase64(code);
        setLoading(false);
      });
    }
  }, []);

  const submit = async ({ quantity }) => {
    setLoading(true);
    pdf(
      <StickerQrCode
        data={[
          {
            sku: data.sku,
            name: data.name,
            quantity: Number(quantity)?.toFixed(0),
            product_location_identifier,
            qr_code_base64,
          },
        ]}
      />
    )
      .toBlob()
      .then((blob) => {
        saveAs(blob, `${data?.sku}-qr.pdf`);
        setLoading(false);
        handleClose();
      });
  };
  return (
    <Modal
      header={{ title: "Naklejki QR", icon: <Sticker /> }}
      footer={
        <>
          <Button
            type="cancel"
            text="Anuluj"
            onClick={handleClose}
          />
          <Button
            type="save"
            text={
              is_loading ? (
                <Loader
                  type="Oval"
                  color="#fff"
                  height={15}
                  width={15}
                />
              ) : (
                "Pobierz"
              )
            }
            disabled={is_loading || invalid}
            onClick={handleSubmit(submit)}
          />
        </>
      }
      handleClose={handleClose}>
      <form noValidate>
        <div className="row">
          <div className="box-12">
            <Field
              label="Ilość"
              name="quantity"
              type="number"
              component={InputField}
            />
          </div>
        </div>
      </form>
    </Modal>
  );
};

export default reduxForm({
  form: "qr-code-sticker",
  validate,
  enableReinitialize: true,
})(QrCodeStickerModal);
