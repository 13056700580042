import React, { useState, useEffect } from "react";
import moment from "moment";
import {
  Input,
  Select,
  MultiSelect,
  AsyncMultiSelect,
  DatePicker,
} from "./components";

const renderComponent = (data, values, setValues) => {
  switch (data.type) {
    case "input": {
      const handleChange = (value) => {
        const new_values = [...values];
        const index = new_values.indexOf(
          new_values.find((item) => item.name === data.name)
        );
        if (index > -1) {
          new_values[index].value = value;
        } else {
          new_values.push({
            name: data.name,
            value,
            search_type: data.search_type,
          });
        }
        setValues(new_values);
      };
      return (
        <Input data={data} handleChange={handleChange} />
      );
    }
    case "date": {
      const handleChange = (value) => {
        const new_values = [...values];
        if (value) {
          value = moment(value).format("YYYY-MM-DD");
          const index = new_values.indexOf(
            new_values.find(
              (item) => item.name === data.name
            )
          );
          if (index > -1) {
            new_values[index].value = value;
          } else {
            new_values.push({
              name: data.name,
              value,
              search_type: data.search_type,
            });
          }
          setValues(new_values);
        } else {
          setValues(
            new_values.filter(
              (item) => item.name !== data.name
            )
          );
        }
      };
      return (
        <DatePicker
          data={data}
          handleChange={handleChange}
        />
      );
    }
    case "async-multi-select": {
      const handleChange = (
        items,
        { action, option, removedValue }
      ) => {
        switch (action) {
          case "select-option": {
            const new_values = [...values];

            const index = new_values.indexOf(
              new_values.find(
                (item) =>
                  item.name === option.label &&
                  item.value === option.value
              )
            );
            if (index > -1) {
              new_values[index].value = option.value;
            } else {
              new_values.push({
                name: data.name,
                value: option.value,
                search_type: data.search_type,
              });
            }
            return setValues(new_values);
          }
          case "remove-value": {
            let new_values = [...values];
            new_values = new_values.filter(
              (item) =>
                item.name !== removedValue.label &&
                item.value !== removedValue.value
            );
            return setValues(new_values);
          }
          case "clear": {
            let new_values = [...values];
            new_values = new_values.filter(
              (item) => item.name !== data.name
            );
            return setValues(new_values);
          }
          default:
            return null;
        }
      };
      return (
        <AsyncMultiSelect
          data={data}
          handleChange={handleChange}
        />
      );
    }
    case "select": {
      const handleChange = (option) => {
        const new_values = [...values];
        if (option) {
          const index = new_values.indexOf(
            new_values.find(
              (item) =>
                item.name === option.label &&
                item.value === option.value
            )
          );
          if (index > -1) {
            new_values[index].value = option.value;
          } else {
            new_values.push({
              name: data.name,
              value: option.value,
              search_type: data.search_type,
            });
          }
          setValues(new_values);
        } else {
          setValues(
            new_values.filter(
              (item) => item.name !== data.name
            )
          );
        }
      };
      return (
        <Select data={data} handleChange={handleChange} />
      );
    }
    case "multi-select": {
      const handleChange = (
        items,
        { action, option, removedValue }
      ) => {
        switch (action) {
          case "select-option": {
            const new_values = [...values];

            const index = new_values.indexOf(
              new_values.find(
                (item) =>
                  item.name === option.label &&
                  item.value === option.value
              )
            );
            if (index > -1) {
              new_values[index].value = option.value;
            } else {
              new_values.push({
                name: data.name,
                value: option.value,
                search_type: data.search_type,
              });
            }
            return setValues(new_values);
          }
          case "remove-value": {
            let new_values = [...values];
            new_values = new_values.filter(
              (item) =>
                item.name !== removedValue.label &&
                item.value !== removedValue.value
            );
            return setValues(new_values);
          }
          case "clear": {
            let new_values = [...values];
            new_values = new_values.filter(
              (item) => item.name !== data.name
            );
            return setValues(new_values);
          }
          default:
            return null;
        }
      };
      return (
        <MultiSelect
          data={data}
          handleChange={handleChange}
        />
      );
    }
    default:
      return null;
  }
};

const Filters = ({
  data,
  filtersAction,
  filters_open,
  toggleFilters,
  histogram = null,
  histogram_width = 400,
  show_histogram = false,
}) => {
  const [is_loaded, setLoaded] = useState(false);
  const [values, setValues] = useState([]);

  useEffect(() => {
    setLoaded(true);
  }, []);

  useEffect(() => {
    if (is_loaded) {
      const query = {};
      values.forEach((item) => {
        switch (item.search_type) {
          case "true":
            query[`q[${item.name}_true]`] = item.value;
            break;
          case "elastic-multi": {
            if (query[`${item.name}`]) {
              query[`${item.name}`] = [
                ...query[`${item.name}`],
                `${item.value}`,
              ];
            } else {
              query[`${item.name}`] = [`${item.value}`];
            }
            break;
          }
          case "elastic": {
            query[item.name] = item.value;
            break;
          }
          case "matches":
            query[
              `q[${item.name}_matches]`
            ] = `%${item.value}%`;
            break;
          case "date_matches":
            query[`q[${item.name}_gt]`] = moment(
              item.value
            ).format("YYYY-MM-DD 00:00");
            query[`q[${item.name}_lt]`] = moment(
              item.value
            ).format("YYYY-MM-DD 23:59");
            break;
          case "gt_eq":
            query[`q[${item.name}_gteq]`] = item.value;
            break;
          case "in":
            if (query[`q[${item.name}_in]`]) {
              query[`q[${item.name}_in]`] = [
                ...query[`q[${item.name}_in]`],
                `${item.value}`,
              ];
            } else {
              query[`q[${item.name}_in]`] = [
                `${item.value}`,
              ];
            }
            break;
          default:
            break;
        }
      });
      filtersAction(query);
    }
  }, [values]);

  return (
    <div className="main-table__filters">
      <div className="filters-wrapper">
        <div
          className={`filters-grid ${
            filters_open ? "open" : ""
          } ${show_histogram ? "with-histogram" : ""}`}>
          {data.map((item) => (
            <div key={item.name} className="form-group">
              {renderComponent(item, values, setValues)}
            </div>
          ))}
        </div>
        {show_histogram ? (
          <div
            className="filters-chart"
            style={{ flexBasis: histogram_width }}>
            {histogram}
          </div>
        ) : null}
      </div>
      <div className="filters-collapse">
        <button
          type="button"
          className="btn-collapse"
          onClick={toggleFilters}>
          <span>
            {!filters_open ? "Pokaż" : "Zwiń"} filtry
          </span>
          <svg
            height="20"
            width="20"
            viewBox="0 0 20 20"
            aria-hidden="true"
            style={{
              transform: `scaleY(${
                !filters_open ? "1" : "-1"
              })`,
            }}>
            <path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path>
          </svg>
        </button>
      </div>
    </div>
  );
};

export default Filters;
