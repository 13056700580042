import React from "react";

const Alert = ({ text, status = "info" }) => (
  <div className={`alert ${status}`}>
    <div className="alert__icon">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="25.639"
        height="25.639"
        viewBox="0 0 25.639 25.639">
        <path
          fill="#fff"
          opacity="0.3"
          d="M11.745,4.535,2.834,20.575a1.068,1.068,0,0,0,.934,1.587H22.481a1.068,1.068,0,0,0,.912-1.625L13.591,4.5a1.068,1.068,0,0,0-1.845.038Z"
          transform="translate(0.184 0.272)"
        />
        <rect
          fill="#fff"
          width="2.137"
          height="7.478"
          rx="1"
          transform="translate(11.751 9.615)"
        />
        <rect
          fill="#fff"
          width="2.137"
          height="2.137"
          rx="1"
          transform="translate(11.751 18.161)"
        />
      </svg>
    </div>
    <div className="alert__content">
      <p>{text}</p>
    </div>
  </div>
);

export default Alert;
