import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Main } from "layouts";
import { getGdn, resetGdn, conditionallyConfirm } from "actions/gdns";
import {
  Details,
  Image,
  Flags,
  Table,
  GdnLogs,
  GdnPackLogs,
  GdnErrors,
} from "./components";
import { ReactComponent as GdnIcon } from "icons/gdn.svg";
import { getStatusLabelData } from "../const/statuses";

const GdnPreview = ({
  gdn,
  current_user,
  getGdn,
  resetGdn,
  conditionallyConfirm,
  match,
}) => {
  const [is_confirming, setConfirming] = useState(false);

  useEffect(() => {
    getGdn(match.params.id);
    return () => resetGdn();
  }, []);

  if (gdn.status === "failure") {
    return <p>Error</p>;
  }
  const { status, status_text } = getStatusLabelData(gdn?.data?.status);

  const handleConfirm = async () => {
    try {
      setConfirming(true);
      await conditionallyConfirm(match.params.id);
    } catch (error) {
      console.log(error);
    } finally {
      setConfirming(false);
    }
  };

  const reload = () => getGdn(match.params.id);

  return (
    <Main
      is_loading={["invalid", "loading"].includes(gdn.status)}
      page={{
        name: "Wydania",
        icon: <GdnIcon />,
        breadcrumbs: [
          {
            name: "Wydania",
            path: "/gdns",
            icon: "export",
          },
          { name: gdn?.data?.expano_oms_id || "Trwa pobieranie..." },
        ],
        buttons:
          current_user.role !== "packer" && gdn?.data?.status === "problem" ? (
            <button className="btn-sm btn-success" onClick={handleConfirm}>
              <span>
                {is_confirming ? "Proszę czekać..." : "Potwierdź zamówienie"}
              </span>
            </button>
          ) : null,
        status,
        status_text,
      }}
    >
      <div className="dashboard-wrapper-flex m-t-10">
        <div className="column-half">
          <Details data={gdn?.data} reload={reload} />
          <GdnLogs />
          <GdnPackLogs />
        </div>
        <div className="column-half">
          <Flags />
          <Image image={gdn?.data?.photo_url} name={gdn?.data?.expano_oms_id} />
          <Table />
          <GdnErrors />
        </div>
      </div>
    </Main>
  );
};

const mapStateToProps = ({ gdn, current_user }) => ({
  current_user,
  gdn,
});

const mapDispatchToProps = (dispatch) => ({
  getGdn: (id) => dispatch(getGdn(id)),
  resetGdn: () => dispatch(resetGdn()),
  conditionallyConfirm: (id) => dispatch(conditionallyConfirm(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(GdnPreview);
