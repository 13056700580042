import React from "react";
import { connect } from "react-redux";
import Delivery from "./components/Delivery";
import DeliveryToMainWarehouse from "./components/DeliveryToMainWarehouse";
import Shift from "./components/Shift";
import Simplified from "./components/Simplified";
import checkDeliveryKind from "../const/checkGrnKind";

const GrnKind = ({ data }) => {
  const grn_kind = checkDeliveryKind({
    is_simplified: data.is_simplified,
    kind: data?.kind,
    warehouse_id: data?.warehouse?.id,
  });

  switch (grn_kind) {
    case "simplified":
      return <Simplified />;
    case "shift":
      return <Shift />;
    case "delivery_to_main_warehouse":
      return <DeliveryToMainWarehouse />;
    case "delivery":
      return <Delivery />;
    default:
      return null;
  }
};

export default connect(({ grn: { data } }) => ({ data }))(GrnKind);
