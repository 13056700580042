import React from "react";
import { Field, formValueSelector, reduxForm } from "redux-form";
import { toastr } from "react-redux-toastr";
import { connect } from "react-redux";
import { Input, Button, Checkbox } from "expano-components";
import { AsyncSelect, CreatableSelect, Modal } from "components";
import { createProductSupplier } from "actions/products";
import getSuppliers from "../const/getSuppliers";

const InputField = (props) => <Input {...props} />;
const CheckField = (props) => <Checkbox {...props} />;

const AddSupplier = ({
  createProductSupplier,
  handleSubmit,
  pristine,
  handleClose,
  change,
  supplier_id,
  is_product_decomposed,
  is_product_composed,
}) => {
  const submit = async (values) => {
    if (!supplier_id) {
      toastr.error("Błąd", "Wybierz dostawce!");
      return;
    }
    await createProductSupplier(values);
    handleClose();
  };
  return (
    <Modal
      handleClose={handleClose}
      header={{ title: "Dodaj dostawcę" }}
      footer={
        <>
          <Button type="cancel" onClick={handleClose} />
          <Button
            type="add-green"
            text="Dodaj"
            disabled={pristine}
            onClick={handleSubmit(submit)}
          />
        </>
      }
    >
      <form noValidate onSubmit={handleSubmit(submit)}>
        <AsyncSelect
          label="Dostawca"
          getData={(q) => getSuppliers(q)}
          onChange={({ value }) => {
            change("supplier_id", value);
          }}
        />
        <Field label="Kod" name="code" component={InputField} labelAside />
        <div style={{ paddingLeft: 70 }}>
          <Field name="is_master" label="Główny" component={CheckField} />
        </div>
        <Field
          label="Jednostka dostawcy"
          name="supplier_custom_unit"
          component={InputField}
        />
        <Field
          name="is_product_decomposed"
          label="Produkt podlega dekompletacji"
          component={CheckField}
        />
        {is_product_decomposed && (
          <Field
            label="Ilość"
            name="decomposed_quantity"
            component={InputField}
            labelAside
          />
        )}
        <Field
          name="is_product_composed"
          label="Produkt podlega kompletacji"
          component={CheckField}
        />
        {is_product_composed && (
          <Field
            label="Ilość"
            name="composed_quantity"
            component={InputField}
            labelAside
          />
        )}
        <CreatableSelect
          onChange={(value) => change("supplier_package_quantity_list", value)}
          label="Wielokrotności paczek"
          defaultValue={[]}
        />
      </form>
    </Modal>
  );
};

const validate = (values) => {
  const errors = {};

  if (!values.code) {
    errors.code = "Pole wymagane";
  }
  if (values.is_product_decomposed && values.is_product_composed) {
    errors.is_product_decomposed = "Możesz wybrać tylko jedną opcje";
    errors.is_product_composed = "Możesz wybrać tylko jedną opcje";
  }
  if (values.is_product_decomposed && !values.decomposed_quantity) {
    errors.decomposed_quantity = "Pole wymagane";
  }
  if (values.is_product_composed && !values.composed_quantity) {
    errors.decomposed_quantity = "Pole wymagane";
  }
  return errors;
};

const form_name = "product-add-supplier";
const selector = formValueSelector(form_name);

export default connect(
  ({ edit_product, ...state }) => ({
    initialValues: {
      product_id: edit_product?.data?.id,
      supplier_id: null,
      is_master: false,
      is_product_decomposed: false,
      is_product_composed: false,
      code: "",
    },
    supplier_id: selector(state, "supplier_id"),
    is_product_decomposed: selector(state, "is_product_decomposed"),
    is_product_composed: selector(state, "is_product_composed"),
  }),
  (dispatch) => ({
    createProductSupplier: (data) => dispatch(createProductSupplier(data)),
  })
)(
  reduxForm({
    form: form_name,
    validate,
    enableReinitialize: true,
  })(AddSupplier)
);
