import { client_v2 } from "utils/api";

const getWarehouseRackSchemaOptions = (q) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await client_v2.get(
        `/warehouse_rack_schemas?context=list&per_page=50${
          q
            ? `&q[identifier_or_name_matches]=%25${q}%25`
            : ""
        }`
      );
      resolve(
        data.map(({ id, name }) => ({
          label: name,
          value: id,
        }))
      );
    } catch (error) {
      console.log(error);
      reject([]);
    }
  });
};

export default getWarehouseRackSchemaOptions;
