const clearFields = (change, name) => {
  const keys = getKeys(name);
  for (const key of keys) {
    change(key, null);
  }
};

const getKeys = (key) => {
  const prefix = "warehouse_";
  const all_keys = [
    "id",
    "room_id",
    "aisle_id",
    "rack_id",
    "shelf_id",
    "position_id",
  ];
  let keys = [];
  switch (key) {
    case "warehouse_id":
      keys = all_keys;
      break;
    case "warehouse_room_id":
      keys = all_keys.slice(1);
      break;
    case "warehouse_aisle_id":
      keys = all_keys.slice(2);
      break;
    case "warehouse_rack_id":
      keys = all_keys.slice(3);
      break;
    case "warehouse_shelf_id":
      keys = all_keys.slice(4);
      break;
    default:
      break;
  }
  return keys.map((key) => [prefix, key].join(""));
};

export default clearFields;
