import React, { useState } from "react";
import { connect } from "react-redux";
import { ModalPreview } from "expano-components";
import Item from "./components/Item";
import GdnErrorsPreview from "./components/GdnErrorsPreview";
import CreateGdnLineIssueModal from "./components/CreateGdnLineIssueModal";

const Table = ({ gdn }) => {
  const [is_modal_open, openModal] = useState(false);
  const [image_data, setImageData] = useState({
    src: null,
    alt: null,
  });

  const [gdn_line_picker_errors_id, setGdnLinePickerErrorsId] = useState(null);
  const [gdn_line_packer_errors_id, setGdnLinePackerErrorsId] = useState(null);
  const [report_gdn_line_error_id, setReportGdnLineErrorId] = useState(null);

  const handleOpenImageModal = (data) => {
    setImageData(data);
    openModal(true);
  };

  const is_gdn_completed = gdn.data.status === 'completed'

  return (
    <div className="dashboard m-b-10">
      <table className={`table supply-table`}>
        <thead className="table__header table-head">
          <tr>
            <th width="100px">Ilość</th>
            <th width="100px">Objętość</th>
            <th width="50px"></th>
            <th width="140px">SKU</th>
            <th>Nazwa</th>
            <th width="110px">Błędy</th>
          </tr>
        </thead>
        <tbody className="table__body table-body">
          {gdn?.data?.gdn_lines?.map((line) => (
            <Item
              key={line?.id}
              openModal={handleOpenImageModal}
              {...line}
              setGdnLinePickerErrorsId={setGdnLinePickerErrorsId}
              setGdnLinePackerErrorsId={setGdnLinePackerErrorsId}
              setReportGdnLineErrorId={setReportGdnLineErrorId}
              is_gdn_completed={is_gdn_completed}
            />
          ))}
        </tbody>
      </table>
      {is_modal_open ? (
        <ModalPreview
          handleClose={() => {
            openModal(false);
            setImageData({ src: null, alt: null });
          }}
          {...image_data}
        />
      ) : null}
      {gdn_line_picker_errors_id && (
        <GdnErrorsPreview
          mode="picker"
          gdn_line_id={gdn_line_picker_errors_id}
          handleClose={() => setGdnLinePickerErrorsId(null)}
        />
      )}
      {gdn_line_packer_errors_id && (
        <GdnErrorsPreview
          mode="packer"
          gdn_line_id={gdn_line_packer_errors_id}
          handleClose={() => setGdnLinePackerErrorsId(null)}
        />
      )}
      {report_gdn_line_error_id && (
        <CreateGdnLineIssueModal
          gdn_line_id={report_gdn_line_error_id}
          handleClose={() => setReportGdnLineErrorId(null)}
        />
      )}
    </div>
  );
};

const mapStateToProps = ({ gdn }) => ({
  gdn,
});

export default connect(mapStateToProps, null)(Table);
