import React from "react";
import { connect } from "react-redux";
import { Textarea } from "expano-components";
import { Field } from "redux-form";

const TextAreaField = (props) => (
  <Textarea
    {...props}
    input={{
      ...props.input,
      placeholder: "Treść alertu...",
    }}
  />
);

const OtherErrorForm = ({ data }) => {
  if (
    data.grn_lines.filter(
      ({ dispatched_quantity }) =>
        !Boolean(dispatched_quantity)
    ).length === 0 &&
    (data?.status === "open" || data.status === "active")
  ) {
    return (
      <div className="grn-summary__form grn-summary__form--only-textarea">
        <Field
          name="dispatch_error"
          component={TextAreaField}
        />
      </div>
    );
  }
  return null;
};

export default connect((state) => ({
  data: state?.grn?.data,
}))(OtherErrorForm);
