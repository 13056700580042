import styled from "styled-components";

const StyledPackageVerifyWarningModalContent = styled.div`
  text-align: center;
  p {
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    margin: 10px 0;
  }
`;

export default StyledPackageVerifyWarningModalContent;
