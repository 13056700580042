import React, { useState } from "react";
import { connect } from "react-redux";

import ProductsReturnModal from "./components/ProductsReturnModal";

import { ReactComponent as ArrowIcon } from "icons/arrow-next.svg";
import { ReactComponent as PackageInCircleIcon } from "icons/package_in_circle.svg";

const CancelPanel = ({ id, gdn_pack_id, status }) => {
  const [is_open_modal, setOpenModal] = useState(false);
  if (status !== "to_cancel") return null;

  return (
    <div className="status-panel cancel">
      <div className="status-panel__info">
        <div className="info-icon">
          <PackageInCircleIcon />
        </div>
        <div className="info-text">
          <p className="title">Zamówienie anulowane</p>
          <p className="desc">
            Z pomocą nawigatora roznieś produkty z powrotem
            na magazyn.
          </p>
        </div>
      </div>
      <button
        className="btn btn-sm btn-next"
        onClick={() => setOpenModal(true)}>
        <span>Roznieś produkty</span>
        <ArrowIcon />
      </button>
      {is_open_modal && (
        <ProductsReturnModal
          gdn_id={id}
          gdn_pack_id={gdn_pack_id}
          handleClose={() => setOpenModal(false)}
        />
      )}
    </div>
  );
};

export default connect(
  ({
    pack_gdn: {
      data: { id, gdn_pack, status },
    },
  }) => ({ id, gdn_pack_id: gdn_pack?.id, status })
)(CancelPanel);
