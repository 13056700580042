import React, { useState } from "react";
import { connect } from "react-redux";
import { ReactComponent as Sticker } from "icons/sticker.svg";
import { QrCodeStickerModal } from "components";

import StyledStickers from "./styles";

const Stickers = ({ data }) => {
  const [is_open_qr_code_modal, handleOpenQrCodeModal] = useState(false);
  if (!data?.has_qr_code) return null;
  return (
    <StyledStickers>
      <div className="dashboard">
        <header className="dashboard__header dashboard__header--edit">
          <div className="icon__container">
            <Sticker />
          </div>
          <h2 className="dashboard__title heading">Naklejki</h2>
        </header>
        <div className="stickers-container">
          <button
            type="button"
            className="btn-sticker"
            onClick={() => handleOpenQrCodeModal(true)}>
            Naklejka QR Code
          </button>
          {is_open_qr_code_modal && (
            <QrCodeStickerModal
              data={data}
              handleClose={() => handleOpenQrCodeModal(false)}
              initialValues={{ quantity: 1 }}
            />
          )}
        </div>
      </div>
    </StyledStickers>
  );
};

export default connect(({ edit_product: { data } }) => ({ data }))(Stickers);
