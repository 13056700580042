import React, { useEffect } from "react";
import { connect } from "react-redux";

import { Main } from "layouts";
import { ReactComponent as WarehouseIcon } from "icons/warehouse.svg";
import Charts from "./components/Charts";
import { getWarehouseStockStats } from "actions/warehouse_stock_stats";

const WarehouseStockStats = ({
  warehouse_stock_stats,
  getWarehouseStockStats,
}) => {
  useEffect(() => {
    getWarehouseStockStats();
  }, [warehouse_stock_stats.filters]);
  return (
    <Main
      is_loading={["invalid", "loading"].includes(warehouse_stock_stats.status)}
      page={{
        name: "Wartości magazynów",
        icon: <WarehouseIcon />,
        breadcrumbs: [{ name: "Wartości magazynów" }],
      }}
    >
      <Charts />
    </Main>
  );
};

export default connect(
  ({ warehouse_stock_stats }) => ({
    warehouse_stock_stats,
  }),
  (dispatch) => ({
    getWarehouseStockStats: () => dispatch(getWarehouseStockStats()),
  })
)(WarehouseStockStats);
