import React from "react";
import { Main } from "layouts";
import { MainList } from "components";

import { sort, filters } from "./const/data";

import { ReactComponent as WarehouseIcon } from "icons/warehouse.svg";

const getVolumeLiters = (val) => {
  if (Number(val) > 0) {
    return (Number(val) / 1000).toFixed(2) + "l";
  }
  return 0;
};

const WarehousePositions = ({
  match: {
    params: { id },
  },
}) => {
  return (
    <Main
      page={{
        name: "Wolne pozycje",
        icon: <WarehouseIcon />,
        breadcrumbs: [
          {
            name: "Zarządzanie magazynem",
            path: "/warehouses",
          },
          { name: "Wolne pozycje" },
        ],
      }}>
      <MainList
        query_key="warehouse_empty_positions"
        api_path="/warehouse_positions"
        api_ransack={`q[warehouse_id_eq]=${id}&q[is_occupied_eq]=false`}
        sort_column="id"
        sort_direction="desc"
        sort={sort}
        filters={filters}
        renderItem={({
          id,
          identifier,
          depth,
          height,
          width,
          volume,
        }) => (
          <>
            <td>{id}</td>
            <td>{identifier}</td>
            <td>{depth}</td>
            <td>{height}</td>
            <td>{width}</td>
            <td>{getVolumeLiters(volume)}</td>
          </>
        )}
      />
    </Main>
  );
};

export default WarehousePositions;
