import React from "react";
import { ActionCableConsumer } from "react-actioncable-provider";
import { toastr } from "react-redux-toastr";

const InformationChannel = () => {
  return (
    <ActionCableConsumer
      channel={{
        channel: 'InformationChannel',
      }}
      onReceived={({ message = "" }) => {
        toastr.info("Informacja", message, {
          enableHtml: true,
          position: "top-center",
          timeOut: 0,
          width: "500px",
          attention: true,
          removeOnHover: false,
          closeOnToastrClick: true,
        });
      }}
    />
  );
};

export default InformationChannel;
