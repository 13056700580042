import React from "react";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { Button, Select } from "expano-components";
import { updateGdn } from "actions/gdns";
import gauge_options from "utils/gauge_options";
import priority_options from "utils/priority_options";
import kind_options from "utils/kind_options";

const SelectField = (props) => <Select {...props} />;

const Flags = ({
  data,
  updateGdn,
  handleSubmit,
  pristine,
  initialValues,
  change,
}) => {
  const submit = (values) => updateGdn(data.id, values);
  return (
    <div className="dashboard m-b-10">
      <header className="dashboard__header dashboard__header--edit">
        <div className="icon">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20">
            <path
              fill="#5d78ff"
              d="M3.25,3H4.5V16.75A1.25,1.25,0,0,1,3.25,18h0A1.25,1.25,0,0,1,2,16.75V4.25A1.25,1.25,0,0,1,3.25,3Z"
              transform="translate(-0.333 -0.5)"
            />
            <path
              fill="#5d78ff"
              opacity="0.3"
              d="M7,3H17.629a.833.833,0,0,1,.619,1.391l-2.5,2.776,2.5,2.776a.833.833,0,0,1-.619,1.391H7Z"
              transform="translate(-1.167 -0.5)"
            />
          </svg>
        </div>
        <h2 className="dashboard__title heading">
          Flagi zamówienia
        </h2>
      </header>
      <form
        noValidate
        onSubmit={handleSubmit(submit)}
        className="dashboard__form">
        <div className="dashboard-wrapper-flex m-b-10">
          <div className="column-half">
            <Field
              label="Typ"
              name="kind"
              component={SelectField}
              handleChange={(value) =>
                change("kind", value)
              }
              options={kind_options}
              defaultValue={kind_options.find(
                ({ value }) => value === initialValues.kind
              )}
            />
          </div>
          <div className="column-half">
            <Field
              label="Priorytet"
              name="priority"
              component={SelectField}
              handleChange={(value) =>
                change("priority", value)
              }
              options={priority_options}
              defaultValue={priority_options.find(
                ({ value }) =>
                  value === initialValues.priority
              )}
            />
          </div>
        </div>
        <div className="dashboard-wrapper-flex">
          <div className="column-half">
            <Field
              label="Gabaryt"
              name="gauge"
              component={SelectField}
              handleChange={(value) =>
                change("gauge", value)
              }
              options={gauge_options}
              defaultValue={gauge_options.find(
                ({ value }) => value === initialValues.gauge
              )}
            />
          </div>
        </div>
      </form>
      <div className="dashboard__form__footer">
        <Button
          disabled={pristine}
          type="save"
          onClick={handleSubmit(submit)}
        />
      </div>
    </div>
  );
};

export default connect(
  ({ gdn: { data } }) => ({
    data,
    initialValues: {
      kind: data?.kind,
      priority: data?.priority,
      gauge: data?.gauge,
    },
  }),
  (dispatch) => ({
    updateGdn: (id, data) => dispatch(updateGdn(id, data)),
  })
)(
  reduxForm({
    form: "gdn-flags-form",
    enableReinitialize: true,
  })(Flags)
);
