import moment from "moment";
import { setCookie } from "utils/cookies";

export const toggleSidebar = () => (dispatch, getState) => {
  const {
    sidebar: { is_open },
  } = getState();
  
  setCookie(
    "sidebar_open",
    !is_open,
    moment()
      .add(1, "year")
      .toDate()
  );
  dispatch({ type: "SIDEBAR_TOGGLE" });
};
