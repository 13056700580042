import React, { useState } from "react";
import { connect } from "react-redux";
import { numberWithSpaces } from "utils/helpers";
import Modal from "./components/Modal";

const InProgressBox = ({ data, user_role }) => {
  const [show_modal, handleShow] = useState(false);
  return (
    <>
      {user_role === "admin" && show_modal && (
        <Modal
          handleClose={() => handleShow(false)}
          {...data}
        />
      )}
      <div
        onClick={() =>
          user_role === "admin"
            ? handleShow(true)
            : undefined
        }
        className="summary-box"
        style={{
          cursor:
            user_role === "admin" ? "pointer" : "default",
        }}
      >
        <div className="summary-box__name">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
          >
            <path
              fill="#5d78ff"
              d="M10.963,7.5h.084a.5.5,0,0,1,.5.45L12,12.5l3.248,1.856a.5.5,0,0,1,.252.434V15a.382.382,0,0,1-.482.368L10.4,14.109a.5.5,0,0,1-.367-.521l.433-5.626A.5.5,0,0,1,10.963,7.5Z"
              transform="translate(0)"
            />
            <path
              fill="#5d78ff"
              opacity="0.3"
              d="M7.39,2.835a10.487,10.487,0,1,1-6,6.823l1.926.54A8.489,8.489,0,1,0,8.749,4.454L9.939,5.874a.5.5,0,0,1-.355.821L4.718,6.97A.5.5,0,0,1,4.2,6.355L5.332,1.624A.5.5,0,0,1,6.2,1.418Z"
            />
          </svg>
          <span>W trakcie</span>
        </div>
        <div className="summary-box__value">
          <span>{numberWithSpaces(data?.totals?.new)}</span>
          <span> / </span>
          <span className="text-muted">
            {numberWithSpaces(data?.totals?.with_problem)}
          </span>
          <span> / </span>
          <span className="text-danger">
            {numberWithSpaces(data?.totals?.with_missings)}
          </span>
        </div>
      </div>
    </>
  );
};

export default connect(
  ({ dashboard: { data }, current_user: { role } }) => ({
    data,
    user_role: role,
  })
)(InProgressBox);
