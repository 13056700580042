import React from "react";
import { connect } from "react-redux";
import QRCodeBox from "react-qr-code";

const QrCode = ({ sku }) => {
  return (
    <div className="dashboard m-b-10">
      <div style={{ padding: 20, textAlign: "center" }}>
        <QRCodeBox value={sku} size={128} />
      </div>
    </div>
  );
};

export default connect(({ edit_product }) => ({
  sku: edit_product?.data?.sku,
}))(QrCode);
