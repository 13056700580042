import { toastr } from "react-redux-toastr";
import { client_v2, stringifyQuery } from "utils/api";

export const toggleFilters = () => (dispatch) => {
  dispatch({ type: "GDNS_TOGGLE_FILTERS" });
};

export const resetFilters = () => (dispatch) => {
  dispatch({ type: "GDNS_RESET_FILTERS" });
};

export const filterGdns = (query) => (dispatch) => {
  dispatch({ type: "GDNS_SET_FILTERS_QUERY", query });
};

export const getGdns =
  ({ page = 1, per_page = 50, ...rest }) =>
  async (dispatch, getState) => {
    try {
      const {
        status,
        filters: { query },
      } = getState().gdns;

      dispatch({
        type: status === "invalid" ? "GDNS_LOADING" : "GDNS_SEARCHING",
      });

      const { data, meta } = await client_v2.get(
        `/gdns?${stringifyQuery({
          ...rest,
          page,
          per_page,
          context: "ui_index",
          ...query,
        })}`
      );
      dispatch({
        type: "GDNS_SUCCESS",
        data,
        meta,
      });
    } catch (err) {
      dispatch({ type: "GDNS_FAILURE" });
      toastr.error("Błąd", "Wystąpił błąd podczas pobierania");
    }
  };

export const getGdn =
  (id, loading = true) =>
  async (dispatch) => {
    try {
      if (loading) {
        dispatch({ type: "GDN_LOADING" });
      }
      const { data } = await client_v2.get(`/gdns/${id}?context=ui_show`);
      dispatch({
        type: "GDN_SUCCESS",
        data,
      });
    } catch (error) {
      console.log(error);
      dispatch({ type: "GDN_FAILURE" });
      toastr.error("Błąd", "Wystąpił błąd podczas pobierania");
    }
  };

export const updateGdn = (id, values) => async (dispatch) => {
  try {
    await client_v2.put(`/gdns/${id}`, values);

    dispatch({
      type: "GDN_UPDATE_SUCCESS",
      data: values,
    });
  } catch (error) {
    dispatch({ type: "GDN_FAILURE" });
    toastr.error("Błąd", "Wystąpił błąd podczas pobierania");
  }
};

export const resetGdn = () => async (dispatch) =>
  dispatch({ type: "GDN_INVALID" });

export const conditionallyConfirm = (id) => async (dispatch) => {
  try {
    await client_v2.post(`/gdns/${id}/conditionally_confirm`);
    await dispatch(getGdn(id, false));
  } catch (error) {
    toastr.error(
      "Błąd",
      error?.response?.data?.message ||
        "Wystąpił błąd podczas potwierdzania zamówienia"
    );
    throw error;
  }
};

export const exportWaybill =
  ({ id, gdn_id }) =>
  async (dispatch) => {
    try {
      await client_v2.post(`/packages/${id}/export`);
      await dispatch(getGdn(gdn_id, false));

      toastr.success("Sukces", "Etykieta została wyeksportowana");
    } catch (error) {
      toastr.error(
        "Błąd",
        error?.response?.data?.error ||
          "Wystąpił błąd podczas eksportu etykiety"
      );
      throw error;
    }
  };

export const createWaybill =
  ({ gdn_id, type, waybill, package_numbers }) =>
  async (dispatch) => {
    try {
      const form_data = new FormData();

      form_data.append("gdn_id", gdn_id);
      form_data.append("type", type);
      form_data.append("package_numbers", package_numbers);
      form_data.append("waybill", waybill);

      await client_v2.post("/packages/upload", form_data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      await dispatch(getGdn(gdn_id, false));

      toastr.success("Sukces", "Etykieta została dodana");
    } catch (error) {
      toastr.error(
        "Błąd",
        error?.response?.data?.error ||
          "Wystąpił błąd podczas dodawania etykiety"
      );
      throw error;
    }
  };

export const createIssue = (values) => async (dispatch) => {
  try {
    await client_v2.post("/gdn_user_errors", values);
    await dispatch(getGdn(values.gdn_id, false));
  } catch (error) {
    toastr.error(
      "Błąd",
      error?.response?.data?.error || "Wystąpił błąd podczas zgłaszania błędu"
    );
    throw error;
  }
};

export const cancelIssue = (id) => async (dispatch, getState) => {
  try {
    const gdn_id = getState().gdn.data.id;
    await client_v2.post(`/gdn_user_errors/${id}/cancel`);
    await dispatch(getGdn(gdn_id, false));
  } catch (error) {
    toastr.error(
      "Błąd",
      error?.response?.data?.error || "Wystąpił błąd podczas anulowania błędu"
    );
    throw error;
  }
};

export const createGdnLineIssue =
  (id, values) => async (dispatch, getState) => {
    try {
      const gdn_id = getState().gdn.data.id;

      await client_v2.post(`/gdn_lines/${id}/gdn_user_error`, values);
      await dispatch(getGdn(gdn_id, false));
    } catch (error) {
      toastr.error(
        "Błąd",
        error?.response?.data?.message ||
          "Wystąpił błąd podczas zgłaszania błędu"
      );
      throw error;
    }
  };
