import { toastr } from "react-redux-toastr";
import { client_v2, stringifyQuery } from "utils/api";

export const toggleFilters = () => (dispatch) => {
  dispatch({ type: "SUPPLIERS_TOGGLE_FILTERS" });
};

export const resetFilters = () => (dispatch) => {
  dispatch({ type: "SUPPLIERS_RESET_FILTERS" });
};

export const filterSuppliers = (query) => async (
  dispatch
) => {
  dispatch({ type: "SUPPLIERS_SET_FILTERS_QUERY", query });
};

export const getSuppliers = ({
  page = 1,
  per_page = 50,
  ...rest
}) => async (dispatch, getState) => {
  try {
    const {
      status,
      filters: { query },
    } = getState().suppliers;

    dispatch({
      type:
        status === "invalid"
          ? "SUPPLIERS_LOADING"
          : "SUPPLIERS_SEARCHING",
    });

    const { data, meta } = await client_v2.get(
      `/suppliers?${stringifyQuery({
        ...rest,
        page,
        per_page,
        ...query,
      })}`
    );
    dispatch({
      type: "SUPPLIERS_SUCCESS",
      data,
      meta,
    });
  } catch (error) {
    dispatch({ type: "SUPPLIERS_FAILURE" });
    toastr.error(
      "Błąd",
      "Wystąpił błąd podczas pobierania"
    );
  }
};

export const getSupplier = (id) => (dispatch) =>
  new Promise(async (resolve, reject) => {
    try {
      dispatch({ type: "SUPPLIER_LOADING" });

      const { data } = await client_v2.get(
        `/suppliers/${id}`
      );

      dispatch({
        type: "SUPPLIER_SUCCESS",
        data,
      });
      resolve(data);
    } catch (error) {
      dispatch({ type: "SUPPLIER_FAILURE" });
      toastr.error(
        "Błąd",
        "Wystąpił błąd podczas pobierania"
      );
      reject();
    }
  });

export const createSupplier = (values) =>
  new Promise(async (resolve, reject) => {
    try {
      const {
        data: { id },
      } = await client_v2.post("/suppliers", values);
      resolve(id);
    } catch (error) {
      toastr.error(
        "Błąd",
        "Wystąpił błąd podczas dodawania dostawcy"
      );
      reject(error);
    }
  });

export const updateSupplier = (id, values) => (dispatch) =>
  new Promise(async (resolve, reject) => {
    try {
      await client_v2.put(`/suppliers/${id}`, values);
      const { data } = await client_v2.get(
        `/suppliers/${id}`
      );
      toastr.success(
        "Sukces",
        "Dostawca został zaktualizowany"
      );

      dispatch({
        type: "SUPPLIER_SUCCESS",
        data,
      });
      resolve();
    } catch (error) {
      toastr.error(
        "Błąd",
        "Wystąpił błąd podczas aktualizowania"
      );
      reject();
    }
  });

export const deleteSupplier = (id) =>
  new Promise(async (resolve, reject) => {
    try {
      await client_v2.delete(`/suppliers/${id}`);
      toastr.success("Sukces", "Dostawca został usuniety");
      resolve(null);
    } catch (error) {
      toastr.error(
        "Błąd",
        "Wystąpił błąd podczas usuwania"
      );
      reject(error);
    }
  });

export const createSupplierProduct = ({
  is_master,
  code,
  product_id,
  supplier_id,
}) => (dispatch) =>
  new Promise(async (resolve, reject) => {
    try {
      const {
        data: { id },
      } = await client_v2.post(`/product_suppliers`, {
        is_master,
        code,
        product_id,
        supplier_id,
      });
      const { data } = await client_v2.get(
        `/product_suppliers/${id}`
      );

      toastr.success(
        "Sukces",
        "Produkt dostawcy został dodany"
      );

      dispatch({
        type: "CREATE_SUPPLIER_PRODUCT",
        data,
      });
      resolve(data);
    } catch (error) {
      toastr.error(
        "Błąd",
        error?.response?.data?.errors?.[0]?.source?.pointer.includes(
          "product_id"
        )
          ? "Ten produkt jest już dodany do dostawcy"
          : "Wystąpił błąd podczas dodawania produktu dostawcy"
      );
      reject(error);
    }
  });

export const updateSupplierProduct = (
  id,
  { is_master, code, product_id }
) => (dispatch) =>
  new Promise(async (resolve, reject) => {
    try {
      await client_v2.put(`/product_suppliers/${id}`, {
        is_master,
        code,
        product_id,
      });
      const { data } = await client_v2.get(
        `/product_suppliers/${id}`
      );

      toastr.success(
        "Sukces",
        "Produkt dostawcy został zaktualizowany"
      );

      dispatch({
        type: "UPDATE_SUPPLIER_PRODUCT",
        data,
      });
      resolve(data);
    } catch (error) {
      toastr.error(
        "Błąd",
        error?.response?.data?.errors?.[0]?.source?.pointer.includes(
          "product_id"
        )
          ? "Ten produkt jest już dodany do dostawcy"
          : "Wystąpił błąd podczas dodawania produktu dostawcy"
      );
      reject(error);
    }
  });

export const deleteSupplierProduct = (id) => (dispatch) =>
  new Promise(async (resolve, reject) => {
    try {
      await client_v2.delete(`/product_suppliers/${id}`);
      toastr.success(
        "Sukces",
        "Produkt dostawcy został usuniety"
      );

      dispatch({
        type: "DELETE_SUPPLIER_PRODUCT",
        id,
      });
      resolve(id);
    } catch (error) {
      toastr.error(
        "Błąd",
        "Wystąpił błąd podczas usuwania produktu dostawcy"
      );
      reject(error);
    }
  });
