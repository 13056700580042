import React, { useState } from "react";
import { connect } from "react-redux";
import {
  markGdnAsMissing,
  unMarkGdnAsMissing,
} from "actions/pack_gdn";

import { ReactComponent as ArrowIcon } from "icons/arrow-next.svg";
import { ReactComponent as PackageInCircleIcon } from "icons/package_in_circle.svg";
import classNames from "classnames";

const checkForShowPanel = ({ status, gdn_lines }) => {
  if (status === "with_missings") return true;
  if (status === "picked") {
    const gdn_lines_statuses = gdn_lines.map(
      ({ status }) => status
    );
    const packed_gdn_lines = gdn_lines_statuses.filter(
      (gdn_line_status) =>
        ["packed", "missing"].includes(gdn_line_status)
    );
    const is_all_packed =
      packed_gdn_lines.length === gdn_lines.length;

    const has_error = gdn_lines_statuses.includes(
      "missing"
    );

    if (is_all_packed && has_error) {
      return true;
    }
  }

  return false;
};

const MissingsPanel = ({
  id,
  unMarkGdnAsMissing,
  markGdnAsMissing,
  status,
  gdn_lines,
}) => {
  const [is_loading, setLoading] = useState(false);

  if (!checkForShowPanel({ status, gdn_lines })) {
    return null;
  }

  const is_confirmed = status === "with_missings";

  const handleConfirmMissing = async () => {
    try {
      setLoading(true);

      is_confirmed
        ? await unMarkGdnAsMissing(id)
        : await markGdnAsMissing(id);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div
      className={classNames("status-panel", {
        error: is_confirmed,
        soft_error: !is_confirmed,
      })}>
      <div className="status-panel__info">
        <div className="info-icon">
          <PackageInCircleIcon />
        </div>
        <div className="info-text">
          <p className="title">
            W zamówieniu występują wybrakowane pozycje.
          </p>
          <p className="desc">
            {is_confirmed
              ? "Dział obsługi klienta otrzymał Twoje zgłoszenie."
              : "Potwierdź zgłoszenie braków w zamówieniu."}
          </p>
        </div>
      </div>
      <button
        disabled={is_loading}
        className="btn btn-sm btn-next"
        onClick={handleConfirmMissing}>
        {is_loading ? (
          <span>Proszę czekać...</span>
        ) : (
          <>
            <span>
              {is_confirmed
                ? "Oznacz problem jako rozwiązany!"
                : "Potwierdź wybrakowane zamówienie"}
            </span>
            <ArrowIcon />
          </>
        )}
      </button>
    </div>
  );
};

export default connect(
  ({
    pack_gdn: {
      data: { id, status, problem_station_note, gdn_lines },
    },
  }) => ({ id, status, problem_station_note, gdn_lines }),
  (dispatch) => ({
    unMarkGdnAsMissing: (id) =>
      dispatch(unMarkGdnAsMissing(id)),
    markGdnAsMissing: (id) =>
      dispatch(markGdnAsMissing(id)),
  })
)(MissingsPanel);
