import { toastr } from "react-redux-toastr";
import { saveAs } from "file-saver";
import {
  api_url_v2,
  client_v2,
  stringifyQuery,
} from "utils/api";

export const toggleFilters = () => (dispatch) => {
  dispatch({ type: "PACKAGES_TOGGLE_FILTERS" });
};

export const resetFilters = () => (dispatch) => {
  dispatch({ type: "PACKAGES_RESET_FILTERS" });
};

export const filterPackages = (query) => (dispatch) => {
  dispatch({ type: "PACKAGES_SET_FILTERS_QUERY", query });
};

export const getPackages = ({
  page = 1,
  per_page = 20,
  ...rest
}) => async (dispatch, getState) => {
  try {
    const {
      status,
      filters: { query },
    } = getState().packages;

    dispatch({
      type:
        status === "invalid"
          ? "PACKAGES_LOADING"
          : "PACKAGES_SEARCHING",
    });
    const { data, meta } = await client_v2.get(
      `/packages?${stringifyQuery({
        ...rest,
        page,
        per_page,
        context: "ui_index",
        ...query,
      })}`
    );
    dispatch({
      type: "PACKAGES_SUCCESS",
      data,
      meta,
    });
  } catch (error) {
    dispatch({ type: "PACKAGES_FAILURE" });
    toastr.error(
      "Błąd",
      "Wystąpił błąd podczas pobierania danych o paczkach"
    );
    throw error;
  }
};

export const createPackage = async (values) => {
  try {
    const {
      data: { id },
    } = await client_v2.post(
      "/packages/create_custom",
      values
    );
    saveAs(
      `${api_url_v2}packages/${id}/download_label`,
      `${id}.pdf`
    );
    return id;
  } catch (error) {
    let message = "Wystąpił błąd";
    const response_error = error?.response?.data?.error;

    switch (response_error?.message) {
      case "speditor_not_found":
        message = "Nie znaleziono spedytora";
        break;
      case "gdn_not_found":
        message = "Nie znaleziono zamówienia";
        break;
      case "speditor_invalid":
        message = `${response_error.delivery_method_name} - nieprawidłowe wymiary paczek`;
        break;
      case "unsupported_country_code":
        message = `${response_error.country_code} - brak cen wysyłki dla wybranego kraju`;
        break;
      case "foreign_speditor_not_found":
        message = `- Nie znaleziono spedytora. Sprawdź czy spedytorzy mają nadane ceny dla kraju ${response_error.country_code}`;
        break;
      case "foreign_speditor_not_valid":
        message =
          "Brak spedytorów spełniających kryteria nadawanych paczek";
        break;
      case "foreign_speditor_island":
        message =
          "Uwaga prawdopodobnie WYSPA! Brak możliwości nadania paczki. Zgłoś problem z zamówieniem wraz z wymiarami!";
        break;
      case "packages_empty":
        message = "Brak paczek do nadania!";
        break;
      default:
        if (response_error) {
          message = response_error;
        }
        if (response_error?.message) {
          message = response_error.message;
        }

        break;
    }
    toastr.error("Błąd", message);

    throw error;
  }
};

