import React from "react";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { Button, Textarea } from "expano-components";
import { Modal } from "components";
import { notifyAboutWrongPick } from "actions/pack_gdn";

import { ReactComponent as WarningIcon } from "icons/warning.svg";

const InputField = (props) => (
  <Textarea
    {...props}
    input={{
      ...props.input,
      placeholder: props.placeholder,
    }}
  />
);

const ModalPickerMistake = ({
  handleClose,
  handleSubmit,
  pristine,
  submitting,
  gdn_id,
}) => {
  const onSubmit = async ({ description }) => {
    try {
      await notifyAboutWrongPick({ gdn_id, description, kind: "picking_other" });
      handleClose();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Modal
      handleClose={handleClose}
      header={{
        title: "Zgłoś błąd pikera",
        icon: <WarningIcon />,
      }}
      footer={
        <>
          <Button type="cancel" text="Anuluj" onClick={handleClose} />
          <Button
            type="save"
            text="Zgłoś"
            disabled={pristine || submitting}
            onClick={handleSubmit(onSubmit)}
          />
        </>
      }
    >
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <Field
          name="description"
          component={InputField}
          label="Opis błedu"
          placeholder="Brak porduktu"
        />
      </form>
    </Modal>
  );
};

export default connect(
  ({
    pack_gdn: {
      data: { id },
    },
  }) => ({
    gdn_id: id,
    initialValues: { description: "" },
  })
)(
  reduxForm({
    form: "picker-mistake-form",
    enableReinitialize: true,
  })(ModalPickerMistake)
);
