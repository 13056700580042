import { toastr } from "react-redux-toastr";
import { client_v2, stringifyQuery } from "utils/api";

export const getGdnErrorsStats = () => async (dispatch, getState) => {
  try {
    const { status, filters } = getState().gdn_errors_stats;
    const query = stringifyQuery(filters);

    dispatch({
      type:
        status === "invalid"
          ? "GDN_ERRORS_STATS_LOADING"
          : "GDN_ERRORS_STATS_UPDATING",
    });
    const { data } = await client_v2.get(`/gdn_user_errors/stats?${query}`);
    dispatch({
      type: "GDN_ERRORS_STATS_SUCCESS",
      data,
    });
  } catch (error) {
    dispatch({ type: "GDN_ERRORS_STATS_FAILURE" });
    toastr.error("Błąd", "Wystąpił błąd podczas pobierania danych");
    throw error;
  }
};

export const setFilter = (filter) => (dispatch) =>
  dispatch({
    type: "GDN_ERRORS_STATS_SET_FILTER",
    filter,
  });
