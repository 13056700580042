import React from "react";
import { Field, reduxForm } from "redux-form";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { markGdnAsProblem } from "actions/pack_gdn";
import { Button, Textarea } from "expano-components";
import { Modal } from "components";

import { ReactComponent as WarningIcon } from "icons/warning.svg";

const InputField = (props) => (
  <Textarea
    {...props}
    input={{
      ...props.input,
      placeholder: props.placeholder,
    }}
  />
);

const ModalIssue = ({
  handleClose,
  markGdnAsProblem,
  handleSubmit,
  pristine,
  submitting,
  match: {
    params: { id },
  },
}) => {
  const onSubmit = async (values) => {
    try {
      await markGdnAsProblem(id, values);
      handleClose();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Modal
      handleClose={handleClose}
      header={{
        title: "Zgłoś problem",
        icon: <WarningIcon />,
      }}
      footer={
        <>
          <Button
            type="cancel"
            text="Anuluj"
            onClick={handleClose}
          />
          <Button
            type="save"
            text="Zapisz"
            disabled={pristine || submitting}
            onClick={handleSubmit(onSubmit)}
          />
        </>
      }>
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <Field
          name="packer_note"
          component={InputField}
          label="Opis problemu"
          placeholder="Opakowanie produktu zostało uszkodzone itp. itd."
        />
      </form>
    </Modal>
  );
};

export default connect(
  ({
    pack_gdn: {
      data: { packer_note },
    },
  }) => ({
    initialValues: { packer_note },
  }),
  (dispatch) => ({
    markGdnAsProblem: (id, values) =>
      dispatch(markGdnAsProblem(id, values)),
  })
)(
  reduxForm({
    form: "issue-form",
    enableReinitialize: true,
  })(withRouter(ModalIssue))
);
