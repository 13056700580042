import { toastr } from "react-redux-toastr";
import { client_v2 } from "utils/api";

export const createRack = async ({
  name,
  identifier,
  warehouse_aisle_id,
  warehouse_rack_schema_id,
  position,
  is_variation_uniqueness_enabled,
}) => {
  try {
    await client_v2.post("/warehouse_racks", {
      name,
      identifier,
      warehouse_aisle_id,
      warehouse_rack_schema_id,
      position,
      is_variation_uniqueness_enabled,
    });
    toastr.success("Sukces", "Regał został dodany");
  } catch (error) {
    toastr.error("Błąd", "Wystąpił błąd");
    throw error;
  }
};

export const updateRack = async (
  id,
  { name, identifier, is_variation_uniqueness_enabled }
) => {
  try {
    await client_v2.put(`/warehouse_racks/${id}`, {
      name,
      identifier,
      is_variation_uniqueness_enabled,
    });
    toastr.success("Sukces", "Regał został zaktualizowany");
  } catch (error) {
    toastr.error("Błąd", "Wystąpił błąd");
    throw error;
  }
};

export const deleteRack = async (id) => {
  try {
    await client_v2.delete(`/warehouse_racks/${id}`);
    toastr.success("Sukces", "Regał został usunięty");
  } catch (error) {
    toastr.error("Błąd", "Wystąpił błąd");
    throw error;
  }
};
