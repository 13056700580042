import { client_v2 } from "utils/api";

export const getWarehousePosition = (id) => (dispatch) =>
  new Promise(async (resolve, reject) => {
    try {
      dispatch({ type: "WAREHOUSE_POSITION_LOADING" });
      const { data } = await client_v2.get(
        `/warehouse_positions/${id}?context=ui_product_edit`
      );
      dispatch({
        type: "WAREHOUSE_POSITION_SUCCESS",
        data,
      });
      resolve(data);
    } catch (error) {
      dispatch({ type: "WAREHOUSE_POSITION_FAILURE" });
      reject(error);
    }
  });
