import styled from "styled-components";

const StyledStickers = styled.div`
  .stickers-container {
    padding: 20px 30px;
    display: flex;
    flex-direction: column;
    .btn-sticker {
      display: inline-block;
      font-size: 13px;
      line-height: 19px;
      font-weight: 300;
      margin-bottom: 5px;
      text-align: left;
      color: #5d78ff;
      &:hover {
        text-decoration: underline;
      }
    }
  }
`;
export default StyledStickers;