import { toastr } from "react-redux-toastr";
import moment from "moment";
import { client, stringifyQuery } from "utils/api";

export const getWarehouseStockStats = () => async (dispatch, getState) => {
  try {
    const { status, filters } = getState().warehouse_stock_stats;
    const parsed_query = {
      ...filters,
      histogram_date_option: undefined,
      histogram_range_from: filters.histogram_range_from
        ? moment(filters.histogram_range_from).format()
        : undefined,
      histogram_range_to: filters.histogram_range_to
        ? moment(filters.histogram_range_to).format()
        : undefined,
    };
    const query = stringifyQuery(parsed_query);

    dispatch({
      type:
        status === "invalid"
          ? "WAREHOUSE_STOCK_STATS_LOADING"
          : "WAREHOUSE_STOCK_STATS_UPDATING",
    });
    const {
      data: { data },
    } = await client.get(`/stats/warehouse_stocks?${query}`);
    dispatch({
      type: "WAREHOUSE_STOCK_STATS_SUCCESS",
      data,
    });
  } catch (error) {
    dispatch({ type: "WAREHOUSE_STOCK_STATS_FAILURE" });
    toastr.error("Błąd", "Wystąpił błąd podczas pobierania danych");
    throw error;
  }
};

export const setFilter = (filter) => (dispatch) =>
  dispatch({
    type: "WAREHOUSE_STOCK_STATS_SET_FILTER",
    filter,
  });
