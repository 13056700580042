import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  Field,
  FieldArray,
  formValueSelector,
  reduxForm,
} from "redux-form";
import { Input, Button, Select } from "expano-components";
import { Main } from "layouts";
import { connect } from "react-redux";

import { ReactComponent as ProductIcon } from "icons/product.svg";
import { ReactComponent as InfoIcon } from "icons/info.svg";
import PackageBoxesArray from "./components/PackageBoxesArray";
import { AsyncSelect } from "components";
import getGdns from "utils/queries/getGdns";
import { createPackage } from "actions/packages";
import delivery_methods from "./const/delivery_methods";
import currency_code_options from "./const/currency_code_options";
import getOrderDeliveryData from "./const/getOrderDeliveryData";

const InputField = (props) => <Input {...props} />;
const SelectField = (props) => <Select {...props} />;

const CreatePackage = ({
  change,
  pristine,
  submitting,
  handleSubmit,
  delivery_method_name,
  package_boxes,
  expano_root_company_id,
  gdn_id,
}) => {
  const [is_saving, setSaving] = useState(false);
  const [
    is_getting_order_delivery_data,
    setGettingOrderDeliveryData,
  ] = useState(false);

  const onSubmit = async (values) => {
    try {
      setSaving(true);
      await createPackage(values);
    } catch (error) {
      throw error;
    } finally {
      setSaving(false);
    }
  };

  const speditor = useMemo(
    () =>
      delivery_methods.find(
        ({ name }) => name === delivery_method_name
      ),
    [delivery_method_name]
  );

  const handleGetOrderDeliveryData = useCallback(async () => {
    try {
      setGettingOrderDeliveryData(true);
      const {
        client_email,
        client_phone,
        delivery_city,
        delivery_company,
        delivery_country_code,
        delivery_postcode,
        delivery_street,
        delivery_fullname,
      } = await getOrderDeliveryData(gdn_id);
      const [
        first_name,
        last_name,
      ] = delivery_fullname.split(" ");
      const delivery_street_array = delivery_street.split(
        " "
      );

      const building_number = delivery_street_array.slice(
        -1
      )[0];
      const street = delivery_street_array
        .slice(0, -1)
        .join(" ");

      change("first_name", first_name);
      change("last_name", last_name);
      change("email", client_email);
      change("phone", client_phone);
      change("city", delivery_city);
      change("company", delivery_company);
      change("country_code", delivery_country_code);
      change("post_code", delivery_postcode);
      change("street", street);
      change("building_number", building_number);
    } catch (error) {
      console.error(error);
    } finally {
      setGettingOrderDeliveryData(false);
    }
  }, [gdn_id]);

  useEffect(() => {
    change("delivery_point_id", "");
  }, [delivery_method_name]);

  return (
    <form noValidate onSubmit={handleSubmit(onSubmit)}>
      <Main
        page={{
          name: "Nadaj paczkę",
          icon: <ProductIcon />,
          breadcrumbs: [
            { name: "Paczki", path: "/packages" },
            { name: "Nadaj paczkę" },
          ],
          buttons: (
            <Button
              disabled={is_saving || pristine || submitting}
              type="save"
              text={
                is_saving ? "Proszę czekać..." : "Utwórz"
              }
              onClick={handleSubmit(onSubmit)}
            />
          ),
        }}>
        <div className="dashboard-wrapper-flex">
          <div className="column-half">
            <div className="dashboard m-t-10">
              <header className="dashboard__header">
                <div className="icon__container">
                  <InfoIcon />
                </div>
                <h2 className="dashboard__title heading">
                  Metoda dostawy
                </h2>
              </header>
              <ul className="delivery-methods-list">
                {delivery_methods.map(
                  ({ name, image, company_ids }) => (
                    <li
                      onClick={() =>
                        change("delivery_method_name", name)
                      }
                      className={`delivery-item ${
                        name === delivery_method_name
                          ? "active"
                          : ""
                      } ${
                        !company_ids.includes(
                          expano_root_company_id
                        )
                          ? "disabled"
                          : ""
                      }`}
                      key={name}>
                      <p>{name}</p>
                      <img
                        className="logotype"
                        src={image}
                        alt={name}
                      />
                    </li>
                  )
                )}
              </ul>
            </div>
            <FieldArray
              package_boxes={package_boxes}
              name="package_boxes"
              component={PackageBoxesArray}
            />
          </div>
          <div className="column-half">
            <div className="dashboard m-t-10">
              <header className="dashboard__header">
                <div className="icon__container">
                  <InfoIcon />
                </div>
                <h2 className="dashboard__title heading">
                  Dodatkowe informacje
                </h2>
              </header>
              <div className="dashboard__form p-t-20">
                <div className="dashboard-wrapper-flex m-b-20">
                  <div className="column-half">
                    <Field
                      name="ref"
                      label="Numer referencyjny"
                      component={InputField}
                    />
                  </div>
                  {speditor?.is_automat && (
                    <div className="column-half">
                      <Field
                        name="delivery_point_id"
                        label="ID punktu odbioru"
                        component={InputField}
                      />
                    </div>
                  )}
                </div>
                <div className="dashboard-wrapper-flex m-b-20">
                  <div className="column-half">
                    <Field
                      name="cod"
                      label="COD"
                      type="number"
                      component={InputField}
                    />
                  </div>
                  <div className="column-half">
                    <Field
                      label="Waluta"
                      name="currency_code"
                      component={SelectField}
                      handleChange={(value) =>
                        change("currency_code", value)
                      }
                      options={currency_code_options}
                      defaultValue={
                        currency_code_options[0]
                      }
                    />
                  </div>
                </div>
                <div className="dashboard-wrapper-flex ai-fe m-b-20">
                  <div className="column-half">
                    <AsyncSelect
                      label="Zamówienie"
                      isDisabled={false}
                      getData={getGdns}
                      onChange={({ value }) => {
                        change("gdn_id", value);
                      }}
                    />
                  </div>
                  <div className="column-half">
                    <button
                      type="button"
                      className="btn btn-sm btn-blue"
                      onClick={handleGetOrderDeliveryData}
                      disabled={
                        !gdn_id ||
                        is_getting_order_delivery_data
                      }>
                      {is_getting_order_delivery_data
                        ? "Trwa pobieranie..."
                        : "Pobierz dane adresowe"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="dashboard m-t-10">
              <header className="dashboard__header">
                <div className="icon__container">
                  <InfoIcon />
                </div>
                <h2 className="dashboard__title heading">
                  Dane adresowe
                </h2>
              </header>
              <div className="dashboard__form p-t-20">
                <div className="dashboard-wrapper-flex m-b-20">
                  <div className="column-half">
                    <Field
                      name="company_name"
                      label="Nazwa firmy"
                      component={InputField}
                    />
                  </div>
                  <div className="column-half">
                    <Field
                      label="Kod kraju"
                      name="country_code"
                      component={InputField}
                    />
                  </div>
                </div>
                <div className="dashboard-wrapper-flex m-b-20">
                  <div className="column-half">
                    <Field
                      name="first_name"
                      label="Imię"
                      component={InputField}
                    />
                  </div>
                  <div className="column-half">
                    <Field
                      name="last_name"
                      label="Nazwisko"
                      component={InputField}
                    />
                  </div>
                </div>
                <div className="dashboard-wrapper-flex m-b-20">
                  <div className="column-half">
                    <Field
                      name="email"
                      label="E-mail"
                      component={InputField}
                    />
                  </div>
                  <div className="column-half">
                    <Field
                      name="phone"
                      type="phone"
                      label="Telefon"
                      component={InputField}
                    />
                  </div>
                </div>
                <div className="dashboard-wrapper-flex m-b-20">
                  <div className="column-half">
                    <Field
                      name="street"
                      label="Ulica"
                      component={InputField}
                    />
                  </div>
                  <div className="column-half">
                    <Field
                      name="building_number"
                      label="Numer budynku"
                      component={InputField}
                    />
                  </div>
                </div>
                <div className="dashboard-wrapper-flex m-b-20">
                  <div className="column-half">
                    <Field
                      name="city"
                      label="Miasto"
                      component={InputField}
                    />
                  </div>
                  <div className="column-half">
                    <Field
                      name="post_code"
                      label="Kod pocztowy"
                      component={InputField}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Main>
    </form>
  );
};

const validate = (values) => {
  const errors = {};
  [
    "delivery_method_name",
    "first_name",
    "last_name",
    "email",
    "phone",
    "street",
    "building_number",
    "city",
    "post_code",
    "country_code",
    "currency_code",
    "ref",
  ].forEach((name) => {
    if (!values[name]) {
      errors[name] = "Pole wymagane";
    }
  });
  if (
    values.country_code &&
    ![
      "PL",
      "AT",
      "BE",
      "BG",
      "HR",
      "CZ",
      "DK",
      "EE",
      "FI",
      "FR",
      "GR",
      "ES",
      "NL",
      "IE",
      "LT",
      "LU",
      "LV",
      "DE",
      "PT",
      "RO",
      "SK",
      "SI",
      "SE",
      "HU",
      "IT",
    ].includes(values.country_code)
  ) {
    errors.country_code =
      "Brak możliwości wysyłki do tego kraju";
  }
  if (values.package_boxes) {
    if (values.package_boxes.length > 0) {
      errors.package_boxes = [];
      values.package_boxes.forEach((item, index) => {
        errors.package_boxes.push({
          width: null,
          height: null,
          weight: null,
          depth: null,
        });

        ["depth", "height", "width", "weight"].forEach(
          (el) => {
            if (!item[el]) {
              errors.package_boxes[index][el] =
                "Pole wymagane";
            } else if (!(item[el] > 0)) {
              errors.package_boxes[index][el] =
                "Wartość musi być większa od 0";
            }
          }
        );
      });
    } else {
      errors.package_boxes = {
        _error: "Dodaj paczkę",
      };
    }
  }

  return errors;
};

const form_name = "create-package";
const selector = formValueSelector(form_name);

export default connect((state) => ({
  initialValues: {
    delivery_method_name: "Kurier GLS",
    expano_root_company_id:
      state?.current_user?.company?.expano_root_id,
    company_name: "",
    first_name: "",
    last_name: "",
    email: "",
    cod: 0,
    phone: "",
    street: "",
    building_number: "",
    city: "",
    post_code: "",
    country_code: "PL",
    delivery_point_id: "",
    ref: "",
    currency_code: "PLN",
    gdn_id: null,
    package_boxes: [],
  },
  expano_root_company_id:
    state?.current_user?.company?.expano_root_id,
  delivery_method_name: selector(
    state,
    "delivery_method_name"
  ),
  package_boxes: selector(state, "package_boxes"),
  gdn_id: selector(state, "gdn_id"),
}))(
  reduxForm({
    form: form_name,
    enableReinitialize: true,
    validate,
  })(CreatePackage)
);
