import React from "react";
import { connect } from "react-redux";

const DiffList = ({ grn_lines }) => {
  if (
    grn_lines.filter(
      ({ dispatched_quantity }) =>
        !Boolean(dispatched_quantity)
    ).length > 0
  ) {
    return null;
  }

  const diff_items = grn_lines.filter(
    ({ dispatched_quantity, quantity }) =>
      dispatched_quantity !== quantity
  );

  return (
    <ul className="diff-list">
      {diff_items.map(
        ({
          quantity,
          dispatched_quantity,
          id,
          product,
        }) => {
          const diff_quantity =
            dispatched_quantity - quantity;
          const label_status =
            dispatched_quantity > quantity
              ? "info-light"
              : "danger";
          const label_text =
            dispatched_quantity > quantity
              ? "nad stan"
              : "brak";
          return (
            <li className="diff-list__item" key={id}>
              <div className="item-label">
                <p
                  className={`label label--${label_status} label--round`}>
                  <span>
                    {label_text}{" "}
                    {dispatched_quantity > quantity
                      ? diff_quantity
                      : Number(diff_quantity) * -1}{" "}
                    szt.
                  </span>
                </p>
              </div>
              <div className="item-text">
                <p>
                  {product?.sku} {product?.name}
                </p>
              </div>
            </li>
          );
        }
      )}
    </ul>
  );
};

export default connect(({ grn }) => ({
  grn_lines: grn?.data?.grn_lines,
}))(DiffList);
