const initialState = {
  status: "invalid",
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "PACK_GDNS_LOADING":
      return {
        ...state,
        status: "loading",
      };
    case "PACK_GDNS_SUCCESS": {
      const products = [];
      const { gdns } = action.data;
      if (gdns?.length > 0) {
        gdns.forEach((gdn) => {
          if (gdn.gdn_lines.length > 0) {
            gdn.gdn_lines.forEach((gdn_line) => {
              if (gdn_line.product) {
                const {
                  id,
                  image_url,
                  name,
                  sku,
                } = gdn_line.product;
                const product_index = products.indexOf(
                  products.find((product) => product.id === gdn_line.product.id)
                );

                if (product_index < 0) {
                  products.push({
                    id,
                    image_url,
                    name,
                    sku,
                    quantity: parseInt(gdn_line.quantity),
                  });
                } else {
                  products[product_index].quantity += parseInt(
                    gdn_line.quantity
                  );
                }
              }
            });
          }
        });
      }
      return {
        ...state,
        status: "success",
        ...action,
        products,
      };
    }
    case "PACK_GDNS_FAILURE":
      return {
        ...state,
        status: "failure",
      };
    default:
      return state;
  }
};
