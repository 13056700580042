import React, { useState } from "react";
import { connect } from "react-redux";
import { Button } from "expano-components";
import {
  reduxForm,
  FieldArray,
  formValueSelector,
} from "redux-form";
import {
  getPackingGdn,
  createPackageLabel,
} from "actions/pack_gdn";
import { Modal } from "components";

import { ReactComponent as PackIcon } from "icons/pack.svg";
import PackageBoxesArray from "./components/PackageBoxesArray";

const SpeditorModal = ({
  gdn_id,
  invalid,
  package_boxes,
  handleSubmit,
  handleClose,
  getPackingGdn,
}) => {
  const [is_loading, setLoading] = useState(false);
  const [is_open_add_package, setOpenAddPackage] = useState(
    true
  );

  const onSubmit = async (values) => {
    try {
      setLoading(true);
      await createPackageLabel(values);
      getPackingGdn({
        id: gdn_id,
        shuffle_colors: false,
        is_loading: false,
      });
      setLoading(false);
      handleClose();
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  return (
    <Modal
      size="lg"
      prevent_click_outside={true}
      handleClose={handleClose}
      header={{
        title: "Utwórz etykietę",
        icon: <PackIcon />,
      }}
      footer={
        <>
          <Button
            type="cancel"
            text="Anuluj"
            onClick={handleClose}
          />
          {!is_open_add_package && (
            <Button
              disabled={is_loading || invalid}
              type="save"
              text={
                is_loading
                  ? "Trwa generowanie..."
                  : "Utwórz"
              }
              onClick={handleSubmit(onSubmit)}
            />
          )}
        </>
      }>
      <form
        autoComplete="off"
        noValidate
        onSubmit={handleSubmit(onSubmit)}>
        <FieldArray
          package_boxes={package_boxes}
          is_open_add_package={is_open_add_package}
          setOpenAddPackage={setOpenAddPackage}
          name="package_boxes"
          component={PackageBoxesArray}
        />
        <input
          type="submit"
          value="Submit"
          style={{ display: "none" }}
        />
      </form>
    </Modal>
  );
};

const validate = ({ package_boxes = [] }) => {
  const errors = {};
  if (package_boxes.length > 0) {
    errors.package_boxes = [];
    package_boxes.forEach((item, index) => {
      errors.package_boxes.push({
        width: null,
        height: null,
        weight: null,
        depth: null,
      });

      ["depth", "height", "width", "weight"].forEach(
        (el) => {
          if (!item[el]) {
            errors.package_boxes[index][el] =
              "Pole wymagane";
          } else if (!(item[el] > 0)) {
            errors.package_boxes[index][el] =
              "Wartość musi być większa od 0";
          }
        }
      );
    });
  } else {
    errors.package_boxes = {
      _error: "Dodaj paczkę",
    };
  }
  return errors;
};

const form_name = "speditor-form";
const selector = formValueSelector(form_name);

export default connect(
  (state) => ({
    gdn_id: state.pack_gdn.data.id,
    initialValues: {
      package_boxes: [],
      gdn_id: state.pack_gdn.data.id,
    },
    package_boxes: selector(state, "package_boxes"),
  }),
  (dispatch) => ({
    getPackingGdn: (data) => dispatch(getPackingGdn(data)),
  })
)(
  reduxForm({
    form: form_name,
    validate,
    enableReinitialize: true,
  })(SpeditorModal)
);
