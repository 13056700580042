import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { MultiSelect } from "components";
import { Button, Checkbox, Input } from "expano-components";
import { Main } from "layouts";
import {
  getSettings,
  updateSettings,
} from "actions/settings";

import { ReactComponent as SettingsIcon } from "icons/settings.svg";
import delivery_methods_options from "utils/delivery_methods";

const InputField = (props) => <Input {...props} />;
const CheckField = (props) => <Checkbox {...props} />;
const SelectField = (props) => (
  <MultiSelect isMulti={true} {...props} />
);

const SettingsEdit = ({
  settings,
  getSettings,
  updateSettings,
  handleSubmit,
  pristine,
  invalid,
  submitting,
  change,
}) => {
  useEffect(() => {
    getSettings();
  }, []);

  const onSubmit = (values) => {
    const data = {
      ...values,
      disabled_delivery_methods_json: JSON.stringify(
        values.disabled_delivery_methods_json
      ),
    };

    updateSettings(settings?.data?.id, data);
  };

  return (
    <Main
      is_loading={[
        "invalid",
        "loading",
        "failure",
      ].includes(settings.status)}
      page={{
        name: "Ustawienia",
        icon: <SettingsIcon />,
        breadcrumbs: [
          {
            name: "Ustawienia",
          },
        ],
      }}
    >
      <form
        noValidate
        onSubmit={handleSubmit(onSubmit)}
        className="dashboard"
      >
        <div className="dashboard__form p-t-10">
          <div className="row">
            <div className="box-3">
              <Field
                name="need_scan_leaflet"
                label="Wymuś skanowanie ulotek"
                component={CheckField}
              />
              <Field
                name="package_proportion_limit"
                type="number"
                label="Limit proporcji objętości paczek do objętości zamówienia"
                component={InputField}
              />
              <Field
                name="disabled_delivery_methods_json"
                label="Zablokowane metody dostawy"
                component={SelectField}
                options={delivery_methods_options}
                handleChange={(value) =>
                  change(
                    "disabled_delivery_methods_json",
                    value?.map(({ value }) => value)
                  )
                }
                defaultValue={delivery_methods_options.filter(
                  ({ value }) =>
                    settings?.data?.disabled_delivery_methods?.includes(
                      value
                    )
                )}
              />
            </div>
          </div>
        </div>
        <div className="dashboard__form__footer">
          <Button
            disabled={invalid || pristine || submitting}
            type="save"
            text="Zapisz"
            onClick={handleSubmit(onSubmit)}
          />
        </div>
      </form>
    </Main>
  );
};

export default connect(
  ({ settings }) => ({
    settings,
    initialValues: {
      package_proportion_limit:
        settings?.data?.package_proportion_limit || 0,
      need_scan_leaflet:
        settings?.data?.need_scan_leaflet || false,
      delivery_methods_options_json: delivery_methods_options
        .filter(({ value }) =>
          settings?.data?.disabled_delivery_methods?.includes(
            value
          )
        )
        .map(({ value }) => value),
    },
  }),
  (dispatch) => ({
    getSettings: () => dispatch(getSettings()),
    updateSettings: (id, values) =>
      dispatch(updateSettings(id, values)),
  })
)(
  reduxForm({
    form: "update-settings",
    enableReinitialize: true,
  })(SettingsEdit)
);
