import React, { useState } from "react";
import LazyLoad from "react-lazy-load";
import classNames from "classnames";
import {
  Tooltip,
  QrCodeStickerModal,
  LocalizationInfo,
  QrCodeModal,
} from "components";
import { Label, ModalPreview } from "expano-components";

import { CapacityModal, GrnLineBatches } from "../../../../../components";

import { ReactComponent as QRcodeIcon } from "icons/qrcode.svg";
import { ReactComponent as Sticker } from "icons/sticker.svg";
import { ReactComponent as PreviewIcon } from "icons/preview.svg";
import { ReactComponent as WarningIcon } from "icons/light-warning.svg";

const ItemRow = ({ data }) => {
  const [is_modal_open, openModal] = useState(false);
  const [is_qr_codes_modal_open, openQrCodesModal] = useState(false);
  const [
    capacity_product_main_location_data,
    setCapacityProductMainLocationData,
  ] = useState(null);
  const [is_open_grn_qr_modal, setOpenGrnQrModal] = useState(false);

  const main_location = data?.product?.product_main_locations?.find((item) =>
    [1].includes(item.warehouse?.id)
  );

  const shipping_location = data?.product?.product_main_locations?.find(
    (item) => [2].includes(item.warehouse?.id)
  );

  const image_url = data.product?.image_url;

  const is_virtual_shipment_position =
    shipping_location?.warehouse_position?.id === 3034; // P02A01-R01P01M01

  const main_grn_line_batches = data.grn_line_batches.filter(
    ({ warehouse_position }) => warehouse_position?.warehouse?.id === 1
  );
  const shipment_grn_line_batches = data.grn_line_batches.filter(
    ({ warehouse_position }) => warehouse_position?.warehouse?.id === 2
  );

  return (
    <tr
      data-focus-id={data.id}
      className={classNames(data?.counting_state, {
        is_warned: data?.is_warned,
        is_decomposed:
          data?.supplier_quantity?.is_decomposed ||
          data?.supplier_quantity?.is_composed,
        no_location: !data.has_location_in_shipment_warehouse,
      })}
      style={{
        display: data?.show ? "table-row" : "none",
      }}
    >
      <td className="number">
        <span>{data?.no}</span>
      </td>
      <td className="image-box">
        {image_url && (
          <LazyLoad>
            <button
              tabIndex="-1"
              className="modal__trigger"
              onClick={() => openModal(true)}
              style={{
                backgroundImage: `url('${
                  data?.product?.image_thumb_url || image_url
                }')`,
              }}
            />
          </LazyLoad>
        )}
      </td>
      <td>
        <p>
          <a
            className="text-link"
            href={`/products/${data?.product?.id}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {data?.product?.name}
          </a>
        </p>
        <p className="text-info">SKU: {data?.product?.sku}</p>
        <p className="text-info">Kod dostawcy: {data?.supplier_code || "-"}</p>
        {!data.has_location_in_shipment_warehouse && (
          <Label
            type="fill"
            color="#fd397a"
            text="Brak lokalizacji na magazynie wysyłkowym"
          />
        )}
      </td>
      <td>
        <div className="codes-group">
          <div className="codes-group__box">
            <button
              className="m-r-5"
              type="button"
              onClick={() => setOpenGrnQrModal(true)}
              tabIndex="-1"
            >
              <PreviewIcon />
            </button>
            {data.product?.has_ean && <Sticker />}
            {data.product?.sku && data?.product?.has_qr_code && (
              <button
                type="button"
                onClick={() => openQrCodesModal(true)}
                tabIndex="-1"
              >
                <QRcodeIcon />
              </button>
            )}
          </div>
        </div>
      </td>
      <td className="box-count bl text-center">
        {data?.supplier_quantity?.is_decomposed ||
        data?.supplier_quantity?.is_composed ? (
          <>
            <p>
              <strong>
                {data.supplier_quantity.package_qty}{" "}
                {data.supplier_quantity?.supplier_custom_unit || "op."}
              </strong>
            </p>
            {data.supplier_quantity.unit_qty > 0 ? (
              <p>
                <strong>{data.supplier_quantity.unit_qty} szt.</strong>
              </p>
            ) : null}
            <p className="text-muted">{data?.quantity} szt.</p>
          </>
        ) : (
          <>
            <p>
              <strong>{data.quantity}</strong>
            </p>
            <p>{data?.unit}</p>
          </>
        )}
      </td>
      {is_virtual_shipment_position ? (
        <>
          <td className="bl">-</td>
          <td className="bl">
            <div className="warehouse-form">
              <div className="warehouse-form__box">
                <Label type="fill" color="#567cfb" text="Gabarytowa" />
                {main_location?.warehouse_position ? (
                  <LocalizationInfo
                    show_tooltip={false}
                    main={main_location?.warehouse_position}
                  />
                ) : (
                  <p>brak</p>
                )}
                <p className="suggested-count m-r-5">
                  Na lok.: {main_location?.stock} szt.
                </p>
                <p
                  className={classNames("suggested-count pointer", {
                    warning: main_location?.is_final_capacity,
                  })}
                  onClick={() =>
                    setCapacityProductMainLocationData({
                      id: main_location.id,
                      capacity: main_location.capacity,
                      is_final_capacity: main_location.is_final_capacity,
                    })
                  }
                >
                  Pojemność: {main_location?.capacity} szt.
                </p>
              </div>
              <div className="warehouse-form__box">
                <GrnLineBatches
                  grn_line_id={data.id}
                  data={main_grn_line_batches}
                />
              </div>
            </div>
          </td>
        </>
      ) : (
        <>
          <td className="bl">
            {!shipping_location ? (
              "-"
            ) : (
              <div className="warehouse-form">
                <div className="warehouse-form__box">
                  <LocalizationInfo
                    show_tooltip={false}
                    main={shipping_location?.warehouse_position}
                  />
                  <p className="suggested-count m-r-5">
                    Na lok.: {shipping_location?.stock} szt
                  </p>
                  <p
                    className={classNames("suggested-count pointer", {
                      warning: shipping_location?.is_final_capacity,
                    })}
                    onClick={() =>
                      setCapacityProductMainLocationData({
                        id: shipping_location.id,
                        capacity: shipping_location.capacity,
                        is_final_capacity: shipping_location.is_final_capacity,
                      })
                    }
                  >
                    Pojemność: {shipping_location?.capacity} szt.
                  </p>
                </div>
                <div className="warehouse-form__box">
                  <GrnLineBatches
                    grn_line_id={data.id}
                    data={shipment_grn_line_batches}
                  />
                </div>
              </div>
            )}
          </td>
          <td className="bl">
            {!main_location ? (
              "-"
            ) : (
              <div className="warehouse-form">
                <div className="warehouse-form__box">
                  <LocalizationInfo
                    show_tooltip={false}
                    main={main_location?.warehouse_position}
                  />
                  <p className="suggested-count">
                    Na lok.: {main_location?.stock} szt
                  </p>
                  <p
                    className={classNames("suggested-count pointer", {
                      warning: main_location?.is_final_capacity,
                    })}
                    onClick={() =>
                      setCapacityProductMainLocationData({
                        id: main_location.id,
                        capacity: main_location.capacity,
                        is_final_capacity: main_location.is_final_capacity,
                      })
                    }
                  >
                    Pojemność: {main_location?.capacity} szt.
                  </p>
                </div>
                <div className="warehouse-form__box">
                  <GrnLineBatches
                    grn_line_id={data.id}
                    data={main_grn_line_batches}
                  />
                </div>
              </div>
            )}
          </td>
        </>
      )}
      <td className="bl text-center">
        <strong>
          {data?.counted_quantity
            ? `${data.counted_quantity} ${data?.unit || "szt."}`
            : "-"}
        </strong>
      </td>
      <td className="bl text-center">
        {data?.is_warned && (
          <Tooltip trigger={<WarningIcon />} content={<p>Sprawdź produkt</p>} />
        )}
        {data?.product?.is_warehouseman_alert_enabled &&
          data?.product?.warehouseman_alert && (
            <Tooltip content={<p>{data.product.warehouseman_alert}</p>} />
          )}
      </td>
      {is_modal_open && (
        <ModalPreview
          handleClose={() => {
            openModal(false);
          }}
          src={image_url}
          alt={data?.product?.name}
        />
      )}
      {is_qr_codes_modal_open && (
        <QrCodeStickerModal
          data={data.product}
          handleClose={() => openQrCodesModal(false)}
          initialValues={{
            quantity: data?.quantity || 1,
          }}
        />
      )}
      {capacity_product_main_location_data?.id && (
        <CapacityModal
          grn_line_id={data.id}
          product_main_location_id={capacity_product_main_location_data?.id}
          initialValues={{
            capacity: capacity_product_main_location_data?.capacity || null,
            is_final_capacity:
              capacity_product_main_location_data?.is_final_capacity || false,
          }}
          handleClose={() => setCapacityProductMainLocationData(null)}
        />
      )}
      {is_open_grn_qr_modal && (
        <QrCodeModal
          identifier={data.product.sku}
          handleClose={() => setOpenGrnQrModal(false)}
          title="Roznieś produkt"
          value={`grn_line_id|${data.id}`}
        />
      )}
    </tr>
  );
};

export default ItemRow;
