import React, { useEffect } from "react";
import { ActionCableProvider } from "react-actioncable-provider";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { connect } from "react-redux";
import { CookiesProvider } from "react-cookie";
import ActionCable from "actioncable";
import { getNavigatorVersion } from "actions/navigator";
import { getCurrentUser } from "actions/current_user";
import CableProvider from "providers/cable";
import { PageLoader } from "components";
import Router from "../Router";

import { ws_url } from "utils/api";
import { getCookie } from "utils/cookies";
import InformationChannel from "./components/InformationChannel";
import StationValidator from "./components/StationValidator";
import AppLogin from "./components/AppLogin";
import TimeRecordValidator from "./components/TimeRecordValidator";

const App = ({ current_user, getCurrentUser, getNavigatorVersion }) => {
  const token = getCookie("token");

  const cable = ActionCable.createConsumer(`${ws_url}?token=${token}`);

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        retry: 2,
        cacheTime: 30 * 1000, //30s
        keepPreviousData: true,
      },
    },
  });

  useEffect(() => {
    getCurrentUser();
    getNavigatorVersion();
  }, []);

  if (["invalid", "loading"].includes(current_user.status)) {
    return <PageLoader />;
  }
  if (current_user.status === "failure") {
    return <AppLogin />;
  }
  return (
    <CookiesProvider>
      <ActionCableProvider cable={cable}>
        <InformationChannel />
        <QueryClientProvider client={queryClient} contextSharing={true}>
          <CableProvider cable={cable}>
            <TimeRecordValidator>
              <StationValidator>
                <Router />
              </StationValidator>
            </TimeRecordValidator>
          </CableProvider>
        </QueryClientProvider>
      </ActionCableProvider>
    </CookiesProvider>
  );
};

export default connect(
  ({ current_user }) => ({ current_user }),
  (dispatch) => ({
    getCurrentUser: () => dispatch(getCurrentUser()),
    getNavigatorVersion: () => dispatch(getNavigatorVersion()),
  })
)(App);

// eslint-disable-next-line
Object.defineProperty(String.prototype, "truncate", {
  value: function (len) {
    if (this.length > len) {
      return this.substring(0, len) + "...";
    }
    return this;
  },
});
