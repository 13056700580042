import React from "react";
import { connect } from "react-redux";
import { ReactComponent as PackIcon } from "icons/product.svg";
import Item from "./components/Item";
import StyledProductsList from "./styles";

const ProductsList = ({ products }) => {
  return (
    <div className="dashboard">
      <header className="dashboard__header dashboard__header--edit">
        <div className="icon__container">
          <PackIcon />
        </div>
        <h2 className="dashboard__title heading">Lista produktów w PAKu</h2>
      </header>
      <StyledProductsList>
        {products?.length > 0 &&
          products.map((product) => <Item key={product.id} {...product} />)}
      </StyledProductsList>
    </div>
  );
};

export default connect(({ pack_gdns: { products } }) => ({ products }))(
  ProductsList
);
