import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Main } from "layouts";
import { getProduct } from "actions/products";
import { Basic, Localizations } from "./components";

import { ReactComponent as ProductIcon } from "icons/product.svg";
import getProductStatus from "utils/getProductStatus";

const tabs = [
  {
    id: "basic",
    name: "Ogólne",
  },
  {
    id: "localization",
    name: "Lokalizacja",
  },
];

class ProductEdit extends PureComponent {
  componentDidMount = () => {
    const {
      history,
      getProduct,
      match: { params },
    } = this.props;

    getProduct(params.id).then(() => {
      if (!!!params.type) {
        try {
          history.push(`/products/${params.id}/basic`);
        } catch (error) {
          console.log(error);
        }
      }
    });
  };

  render() {
    const {
      edit_product,
      match: { params },
    } = this.props;

    return (
      <Main
        is_loading={[
          "invalid",
          "loading",
          "failure",
        ].includes(edit_product.status)}
        breadcrumbs={[
          {
            name: "Produkty",
            path: "/products",
            icon: "product",
          },
          { name: "Edytuj produkt" },
        ]}
        page={{
          name: (
            <>
              <span>{edit_product?.data?.sku}</span>
              <strong className="m-l-10 m-r-10">
                {edit_product?.data?.name}
              </strong>
              <span>
                {edit_product?.data?.producer_name}
              </span>
            </>
          ),
          icon: <ProductIcon />,
          breadcrumbs: [
            { name: "Produkty", path: "/products" },
            { name: edit_product?.data?.sku },
          ],
          buttons: (
            <div
              className={`product_status product_status__big ${
                getProductStatus(edit_product?.data?.status)
                  ?.color
              }`}
              style={{ marginLeft: "auto" }}
            >
              <span>
                {
                  getProductStatus(
                    edit_product?.data?.status
                  )?.name
                }
              </span>
            </div>
          ),
        }}
      >
        <ul className="dashboard__tabs">
          {tabs.map(({ id, name }) => (
            <li
              key={id}
              className={`tab dashboard-tab-text ${
                id === params.type ? "active" : ""
              }`}
            >
              <Link to={`/products/${params.id}/${id}`}>
                {name}
              </Link>
            </li>
          ))}
        </ul>
        {params?.type === "basic" && <Basic />}
        {params?.type === "localization" && (
          <Localizations data={edit_product?.data} />
        )}
      </Main>
    );
  }
}

const mapStateToProps = ({ edit_product }) => ({
  edit_product,
});

const mapDispatchToProps = (dispatch) => ({
  getProduct: (id) => dispatch(getProduct(id)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductEdit);
