import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Main } from "layouts";
import { Loader } from "components";

import {
  getAllSpeditors,
  updateSpeditorPrice,
  setFilter,
  uploadSpeditorPrices
} from "actions/speditors";

import dpd_icon from "icons/dpd.png";
import inpost_icon from "icons/inpost.png";
import gls_icon from "icons/gls.png";
import poczta_icon from "icons/poczta-polska.png";
import ups_icon from "icons/ups.png";
import dhl_icon from "icons/dhl.jpg";

import { getCountryNameByCountryCode } from "utils/getCountryName";
import {
  Button,
  ModalUpload,
  Select,
} from "expano-components";

const getIcon = (name) => {
  switch (name) {
    case "DHL":
      return <img src={dhl_icon} height="30" alt="DHL" />;
    case "DPD":
      return <img src={dpd_icon} height="30" alt="DPD" />;
    case "Poczta Polska":
      return (
        <img
          src={poczta_icon}
          height="30"
          alt="Poczta Polska"
        />
      );
    case "InPost":
      return (
        <img
          src={inpost_icon}
          height="30"
          alt="DInPostPD"
        />
      );
    case "GLS":
      return <img src={gls_icon} height="30" alt="GLS" />;
    case "UPS":
      return <img src={ups_icon} height="30" alt="UPS" />;
    default:
      return null;
  }
};

const SpeditorsList = ({
  data,
  options,
  filters,
  status,
  getAllSpeditors,
  setFilter,
  updateSpeditorPrice,
}) => {
  const [
    is_open_upload_modal,
    setOpenUploadModal,
  ] = useState(false);

  useEffect(() => {
    getAllSpeditors();
  }, []);

  const checkIfValueChange = ({
    id,
    key,
    value,
    speditor_id,
  }) => {
    const speditor = data.find(
      ({ id }) => Number(id) === Number(speditor_id)
    );
    if (speditor) {
      const speditor_country_price = speditor.speditor_country_prices.find(
        (item) => Number(item.id) === Number(id)
      );
      return speditor_country_price[key] !== value;
    }
    return false;
  };

  const onBlur = ({
    target: {
      value,
      dataset: { id, key, speditor },
    },
  }) => {
    const has_changed = checkIfValueChange({
      id,
      key,
      value,
      speditor_id: speditor,
    });
    if (has_changed) {
      updateSpeditorPrice(id, speditor, { [key]: value });
    }
  };

  const handleUpload = async(file) => {
    try {
      await uploadSpeditorPrices(file)
      getAllSpeditors()
    } catch (error) {
      console.log(error)
    }
  }

  const onFocus = (e) => e.target.select();

  if (["failure", "invalid", "loading"].includes(status)) {
    return (
      <Main
        page={{
          name: "Spedytorzy",
          icon: null,
          breadcrumbs: [{ name: "Spedytorzy" }],
        }}>
        <div className="dashboard wrapper jc-c p-t-40 p-b-40">
          <Loader />
        </div>
      </Main>
    );
  }

  const speditor_options = [
    { label: "Brak", value: null },
    ...options.speditors,
  ];

  const country_code_options = [
    { label: "Brak", value: null },
    ...options.countries,
  ];

  return (
    <Main
      page={{
        name: "Spedytorzy",
        icon: null,
        breadcrumbs: [{ name: "Spedytorzy" }],
        buttons: (
          <Button
            type="add"
            text="Zaktualizuj ceny (csv)"
            onClick={() => setOpenUploadModal(true)}
          />
        ),
      }}>
      {is_open_upload_modal && (
        <ModalUpload
          handleUpload={handleUpload}
          headerText="Wgraj cennik (.csv)"
          accept=".csv"
          handleClose={() => setOpenUploadModal(false)}
        />
      )}
      <div className="dashboard p-20">
        <div className="row">
          <div className="box-4 p-l-10 p-r-10">
            <Select
              label="Kurier"
              options={speditor_options}
              defaultValue={speditor_options.find(
                ({ value }) => value === filters.speditor
              )}
              handleChange={(value) =>
                setFilter({ speditor: value })
              }
            />
          </div>
          <div className="box-4 p-l-10 p-r-10">
            <Select
              label="Kraj"
              options={country_code_options}
              defaultValue={country_code_options.find(
                ({ value }) =>
                  value === filters.country_code
              )}
              handleChange={(value) =>
                setFilter({ country_code: value })
              }
            />
          </div>
        </div>
      </div>
      {data.map(({ id, name, speditor_country_prices }) => (
        <div className="dashboard" key={id}>
          <header className="dashboard__header dashboard__header--edit">
            <div className="icon__container">
              {getIcon(name)}
            </div>
          </header>
          <div className="speditors-prices">
            <table>
              <thead>
                <tr>
                  <th>Kraj</th>
                  {options.kgs.map(({ label, value }) => (
                    <th
                      onClick={() =>
                        setFilter({
                          kg:
                            filters.kg === value
                              ? null
                              : value,
                        })
                      }
                      style={{ cursor: "pointer" }}
                      key={label}
                      className={
                        filters.kg
                          ? value === filters.kg
                            ? "highlighted"
                            : ""
                          : ""
                      }>
                      {label}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {speditor_country_prices
                  .sort((a, b) =>
                    a.country_code?.localeCompare(
                      b?.country_code
                    )
                  )
                  .map(
                    ({
                      id,
                      country_code,
                      speditor_id,
                      ...kg_values
                    }) => {
                      const country_name = getCountryNameByCountryCode(
                        country_code
                      );
                      return (
                        <tr key={id}>
                          <td>
                            {country_name} ({country_code})
                          </td>
                          {options.kgs.map(({ value }) => (
                            <td
                              key={value}
                              className={
                                filters.kg
                                  ? value === filters.kg
                                    ? "highlighted"
                                    : ""
                                  : ""
                              }>
                              <input
                                data-speditor={speditor_id}
                                data-key={value}
                                data-id={id}
                                defaultValue={
                                  kg_values[value]
                                }
                                onBlur={onBlur}
                                onFocus={onFocus}
                                type="number"
                              />
                            </td>
                          ))}
                        </tr>
                      );
                    }
                  )}
              </tbody>
            </table>
          </div>
        </div>
      ))}
    </Main>
  );
};

export default connect(
  ({ speditors: { status, data, options, filters } }) => ({
    status,
    data,
    options,
    filters,
  }),
  (dispatch) => ({
    getAllSpeditors: () => dispatch(getAllSpeditors()),
    setFilter: (value) => dispatch(setFilter(value)),
    updateSpeditorPrice: (id, speditor_id, values) =>
      dispatch(
        updateSpeditorPrice(id, speditor_id, values)
      ),
  })
)(SpeditorsList);
