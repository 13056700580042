import React, { useState } from "react";
import { connect } from "react-redux"
import { Field, reduxForm } from "redux-form";
import axios from "axios";
import { toastr } from "react-redux-toastr";
import { useQuery } from "@tanstack/react-query";
import { Button, Input } from "expano-components";
import { Main } from "layouts";


import { ReactComponent as InfoIcon } from "icons/info.svg";
// import { AsyncSelect } from "components";
// import getProducts from "utils/queries/getProducts";


const InputField = (props) => <Input {...props} />;

const WeightShow = ({
  submitting,
  pristine,
  change,
  handleSubmit,
  match: {
    params: { id },
  },
}) => {
  const [refetchInterval, setRefetchInterval] = useState(
    1000
  );

  useQuery(
    ["weight"],
    () => axios.get(`http://localhost:5000`),
    {
      onSuccess: ({ data }) => change("weight", data),
      onError: (err) => {
        toastr.error(
          "Błąd",
          err?.response?.data?.message ||
            "Wystąpił błąd podczas pobierania wagi"
        );
        setRefetchInterval(0);
      },
      refetchInterval,
      refetchIntervalInBackground: false,
      retry: false,
    }
  );
  const onSubmit = (values) => console.log(id, values);

  return (
    <Main
      page={{
        name: "Waga",
        icon: null,
        breadcrumbs: [
          {
            name: "Waga",
          },
        ],
      }}>
      <style>
        {`.inp-wrapper .inp-group input{
            font-size: 40px;
            line-height: 50px;
          }`}
      </style>
      <div className="dashboard-wrapper-flex m-t-10">
        <form
          noValidate
          onSubmit={handleSubmit(onSubmit)}
          className="dashboard">
          <header className="dashboard__header">
            <div className="icon__container">
              <InfoIcon />
            </div>
            <h2 className="dashboard__title heading">
              Podstawowe dane
            </h2>
          </header>
          <div className="dashboard__form p-t-20">
            <div className="dashboard-wrapper-flex m-b-20">
              <div className="column-half">
                <Field
                  name="weight"
                  label="Waga"
                  component={InputField}
                />
              </div>
              {/* <div className="column-half">
                <AsyncSelect
                  label="Produkt"
                  isDisabled={false}
                  getData={getProducts}
                  onChange={({ value }) => {
                    change("product_id", value);
                  }}
                />
              </div> */}
            </div>
          </div>
          <div className="dashboard__form__footer">
            <Button
              disabled={pristine || submitting}
              type="save"
              text="Zapisz"
              onClick={handleSubmit(onSubmit)}
            />
          </div>
        </form>
      </div>
    </Main>
  );
};

export default connect(() => ({
  initialValues: {
    weight: 0,
    product_id: null,
  },
}))(
  reduxForm({
    form: "edit-product-weight",
    enableReinitialize: true,
  })(WeightShow)
);
