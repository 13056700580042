import React, { useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { pdf } from "@react-pdf/renderer";
import { StickerCart } from "sticker_templates";
import QRCode from "qrcode";
import { ReactComponent as BackIcon } from "icons/arrow-back.svg";
import { ReactComponent as PacksIcon } from "icons/packs.svg";

const Header = ({ pack_gdns, history }) => {
  const [is_loading, setLoading] = useState(false);

  const generateCartSticker = async ({ id, name, box_size }) => {
    try {
      setLoading(true);
      const code = await QRCode.toDataURL(`cart|${id}`);
      const blob = await pdf(
        <StickerCart
          data={{
            id,
            box_size,
            code,
          }}
        />
      ).toBlob();
      saveAs(blob, `${name}.pdf`);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="packs-topbar">
      <div className="packs-topbar__container">
        <div className="packs-topbar__left">
          <button
            className="btn btn-default btn-back"
            onClick={() => history.push("/packing")}
          >
            <BackIcon />
            <span>Wróć do panelu</span>
          </button>
          <div className="pack-number">
            <div className="pack-number__icon">
              <PacksIcon />
            </div>
            <div className="pack-number__text">
              <p>PAK/{pack_gdns?.data?.id}</p>
              {pack_gdns?.data?.cart?.id ? (
                <p
                className="link"
                  onClick={() =>
                    generateCartSticker({
                      id: pack_gdns.data.cart.id,
                      name: pack_gdns.data.cart.name,
                      box_size: pack_gdns.data.cart.box_size,
                    })
                  }
                >
                  {is_loading
                    ? "Trwa generowanie..."
                    : `WÓZEK/${pack_gdns.data.cart.id}`}
                </p>
              ) : null}
            </div>
          </div>
          <div className="speditor-group">
            <p className="speditor-group__title">Metody dostawy</p>
            <p className="speditor-group__info">
              <span className="delivery-methods">
                {pack_gdns?.data?.delivery_method_name || "-"}
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(({ pack_gdns }) => ({ pack_gdns }))(withRouter(Header));
