import React, { useState } from "react";
import Modal from "./Modal";

const Item = ({ identifier, gdn_pack_packer_full_name, completed_at, gdn_lines }) => {
  const [show_modal, handleShowModal] = useState(false);
  return (
    <>
      {show_modal && <Modal title={identifier} gdn_lines={gdn_lines} handleClose={() => handleShowModal(false)} />}
      <li onClick={() => handleShowModal(true)}>
        <span>{gdn_pack_packer_full_name}</span>
        <span>
          <time className='text-muted'>{completed_at?.split(' ')?.[0]}</time> <time>{completed_at?.split(' ')?.[1]}</time>
        </span>
      </li>
    </>
  );
};

export default Item;
