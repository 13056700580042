import React, { useMemo, useState, useEffect } from "react";
import { Main } from "layouts";
import { client_v2 } from "utils/api";
import { toastr } from "react-redux-toastr";
import Input from "./components/Input";
import { ReactComponent as CheckIcon } from "icons/check.svg";
import { connect } from "react-redux";

const Inventarization = ({ company_id }) => {
  const [
    marking_as_ready_sku,
    setMarkingAsReadySku,
  ] = useState(null);
  const [data, setData] = useState({});
  const [inventorized_skus, setInventorizedSkus] = useState(
    []
  );
  const [is_loading, setLoading] = useState(true);
  const [is_error, setError] = useState(false);

  useEffect(() => getData(), []);

  const getData = async () => {
    try {
      const res = await client_v2.get(
        "/product_main_locations/inventarization_2023"
      );
      setData(res.data.data);
      setInventorizedSkus(res.data.inventorized_skus);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError(true);
      toastr.error(
        "Błąd",
        "Wystąpił błąd podczas pobierania"
      );
    }
  };

  const handleUpdate = ({ sku, id, value }) => {
    const data_copy = { ...data };
    const item = data_copy[sku];
    const item_index = item.indexOf(
      item.find((el) => el.id === id)
    );
    item[item_index].stock_diff = Number(value);
    data_copy[sku] = item;
    setData(data_copy);
  };

  const markAsReady = async (sku) => {
    try {
      setMarkingAsReadySku(sku);
      const res = await client_v2.post(
        "/product_main_locations/mark_as_ready",
        {
          sku,
        }
      );
      setInventorizedSkus(res.data);
    } catch (error) {
      toastr.error("Błąd", "Wystąpił błąd");
    } finally {
      setMarkingAsReadySku(null);
    }
  };

  const table_data = useMemo(
    () =>
      Object.keys(data)
        .map((key) => {
          const warehouses = {};
          data[key].forEach(
            ({
              warehouse_id,
              stock_diff,
              product_id,
              id,
              warehouse_position_id,
            }) => {
              warehouses[`warehouse_${warehouse_id}`] = {
                stock_diff,
                id,
                product_id,
                warehouse_position_id,
              };
            }
          );
          return { sku: key, warehouses };
        })
        .filter(({ warehouses }) =>
          company_id === 1
            ? Object.values(warehouses).length === 2 ||
              Object.values(warehouses)
                ?.map(
                  ({ warehouse_position_id }) =>
                    warehouse_position_id
                )
                .includes(3034)
            : true
        )
        .sort((a, b) =>
          a.sku.localeCompare(b.sku, undefined, {
            numeric: true,
            sensitivity: "base",
          })
        ),
    [data]
  );

  return (
    <Main
      is_loading={is_loading || is_error}
      page={{
        name: "Inwentaryzacja 2023",
      }}
    >
      <div className="inventarization">
        <table>
          <thead>
            <tr>
              <th width="50">#</th>
              <th width="200">SKU</th>
              <th width="100">Główny</th>
              <th width="100">Wysyłkowy</th>
              <th width="60">Status</th>
            </tr>
          </thead>
          <tbody>
            {table_data.map(
              ({ sku, warehouses, stock_diff }, index) => {
                const main_warehouse =
                  warehouses?.[`warehouse_${1}`];
                const send_warehouse =
                  warehouses?.[`warehouse_${2}`];

                const is_done = inventorized_skus.includes(
                  sku
                );
                return (
                  <tr key={`${sku}_${stock_diff}`}>
                    <td>{index + 1}</td>
                    <td>
                      <a
                        className="link"
                        href={`/products/${
                          (main_warehouse || send_warehouse)
                            ?.product_id
                        }/localization`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {sku}
                      </a>
                    </td>
                    <td>
                      {main_warehouse && (
                        <Input
                          handleUpdateField={(value) =>
                            handleUpdate({
                              value,
                              sku,
                              id: main_warehouse?.id,
                            })
                          }
                          id={main_warehouse?.id}
                          stock_diff={
                            main_warehouse?.stock_diff
                          }
                        />
                      )}
                    </td>
                    <td>
                      {send_warehouse && (
                        <Input
                          handleUpdateField={(value) =>
                            handleUpdate({
                              value,
                              sku,
                              id: send_warehouse?.id,
                            })
                          }
                          id={send_warehouse?.id}
                          stock_diff={
                            send_warehouse?.stock_diff
                          }
                        />
                      )}
                    </td>
                    <td>
                      {is_done ? (
                        <CheckIcon />
                      ) : (
                        <button
                          disabled={
                            sku === marking_as_ready_sku
                          }
                          type="button"
                          className="btn btn-sm btn-blue"
                          onClick={() => markAsReady(sku)}
                        >
                          Gotowe
                        </button>
                      )}
                    </td>
                  </tr>
                );
              }
            )}
          </tbody>
        </table>
      </div>
    </Main>
  );
};

export default connect(({ current_user }) => ({
  company_id: current_user?.company?.id,
}))(Inventarization);
