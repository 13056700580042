import React, { useEffect, useState } from "react";
import moment from "moment";
import { toastr } from "react-redux-toastr";
import { Button } from "expano-components";

import { Loader, Modal } from "components";
import { client_v2 } from "utils/api";

import { ReactComponent as LocationIcon } from "icons/location.svg";

const Logs = ({ id, handleClose }) => {
  const [is_loading, setLoading] = useState(true);
  const [logs, setLogs] = useState(null);

  useEffect(() => {
    getLogs(id);
  }, [id]);

  const getLogs = async (id) => {
    try {
      const res = await client_v2.get(
        `/product_main_locations/${id}?context=ui_logs`
      );
      setLogs(res.data.logs);
    } catch (error) {
      toastr.error("Błąd", "Nie udało się pobrać logów lokalizacji");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      size="md"
      handleClose={handleClose}
      header={{
        icon: <LocationIcon />,
        title: "Logi lokalizacji",
      }}
      footer={
        <>
          <Button type="cancel" onClick={handleClose} />
        </>
      }
    >
      {is_loading ? (
        <div className="row jc-c">
          <Loader />
        </div>
      ) : (
        <>
          {logs?.length > 0 ? (
            <ul className="logs-list">
              {logs
                .sort(
                  (item, next) =>
                    new Date(next.created_at).getTime() -
                    new Date(item.created_at).getTime()
                )
                .map(({ id, created_at, content, user }) => (
                  <li className="logs-list__item" key={id}>
                    <p className="title">
                      {created_at
                        ? moment(created_at).format("YYYY-MM-DD HH:mm")
                        : ""}{" "}
                      {user?.first_name} {user?.last_name}
                    </p>
                    <p>{content}</p>
                  </li>
                ))}
            </ul>
          ) : (
            <p>Brak logów</p>
          )}
        </>
      )}
    </Modal>
  );
};

export default Logs;
