import React from "react";
import {
  Document,
  Font,
  Image,
  Page,
  Text,
  View,
  StyleSheet,
} from "@react-pdf/renderer";

import RobotoBlack from "fonts/Roboto-Black.ttf";

Font.register({
  family: "Roboto",
  fonts: [{ src: RobotoBlack, fontWeight: 900 }],
});

const StickerUser = ({ data }) => (
  <Document>
    <Page
      orientation="portrait"
      size={{ width: 255, height: 158 }}
      style={styles.page}>
      <View style={styles.container}>
        <View style={styles.text}>
          <Text style={styles.name}>{data.first_name}</Text>
          <Text style={styles.name}>{data.last_name}</Text>
        </View>
        <View style={styles.image}>
          <View style={styles.image_box}>
            <Image src={data.code} />
          </View>
        </View>
      </View>
    </Page>
  </Document>
);

const styles = StyleSheet.create({
  page: {
    backgroundColor: "#fff",
  },
  container: {
    height: "100%",
    padding: 5,
    flexDirection: "row",
    alignItems: "center",
    border: "1pt solid #000",
  },
  text: {
    flexBasis: "50%",
    textAlign: "center",
  },
  name: {
    fontFamily: "Roboto",
    fontSize: 20,
    fontWeight: 900,
    marginVertical: 3,
  },
  image: {
    flexBasis: "50%",
    alignItems: "center",
  },
  image_box: {
    width: 130,
  },
});

export default StickerUser;
