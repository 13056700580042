import { toastr } from "react-redux-toastr";
import { client_v2, stringifyQuery } from "utils/api";

const getCheapestCourier = async ({
  width,
  height,
  depth,
  weight,
  country_code,
}) => {
  const query = stringifyQuery({
    width,
    height,
    depth,
    weight,
    country_code,
  });
  try {
    const { data } = await client_v2.get(
      `/speditors/cheapest?${query}`
    );
    return data;
  } catch (error) {
    toastr.error(
      "Błąd",
      error?.response?.data?.error?.message ||
        "Wystąpił błąd"
    );
    throw error;
  }
};

export default getCheapestCourier;
