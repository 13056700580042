import React from "react";
import Loader from "react-loader-spinner";

const PageLoader = ({
  color = "#2959F8",
  width = 40,
  height = 40,
}) => {
  return (
    <div className="main-dashboard">
      <div className="page-loader">
        <div className="loader-holder">
          <Loader
            type="Watch"
            color={color}
            height={width}
            width={height}
          />
        </div>
      </div>
    </div>
  );
};

export default PageLoader;
