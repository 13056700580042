import React, { useState } from "react";
import { withRouter } from "react-router";
import { MainList } from "components";

import ModalAddRoom from "./components/ModalAddRoom";
import ModalEditRoom from "./components/ModalEditRoom";
import ModalDeleteRoom from "./components/ModalDeleteRoom";

import { sort } from "./const/data";

const default_state = { type: null, data: null };

const RoomsList = ({
  history,
  match: {
    params: { warehouse_id, warehouse_room_id },
  },
}) => {
  const [rooms_modal, setRoomsModal] = useState(
    default_state
  );

  const onClose = () => setRoomsModal(default_state);

  return (
    <div className="box-3">
      <MainList
        query_key="warehouse_rooms"
        api_path={`/warehouse_rooms`}
        api_ransack={`q[warehouse_id_eq]=${warehouse_id}`}
        title="Lista pomieszczeń"
        sort_column="position"
        sort_direction="asc"
        hide_meta={true}
        sort={sort}
        activeIndexes={(data) => {
          const indexes = [];
          data.forEach((item, index) => {
            if (item.id === warehouse_room_id) {
              indexes.push(index);
            }
          });
          return indexes;
        }}
        handleNavigate={({ id }) =>
          history.push(
            `/warehouses/${warehouse_id}/rooms/${id}`
          )
        }
        renderActions={(item) =>
          [
            {
              type: "edit",
              handleClick: () =>
                setRoomsModal({
                  type: "edit",
                  data: item,
                }),
            },
            item?.warehouse_aisles_count === 0 && {
              type: "remove",
              handleClick: () =>
                setRoomsModal({
                  type: "remove",
                  data: item,
                }),
            },
          ].filter(Boolean)
        }
        renderItem={({ name, identifier }) => (
          <>
            <td>{identifier}</td>
            <td>{name}</td>
          </>
        )}
        bottom_action={{
          name: "Dodaj pomieszczenie",
          onClick: () => setRoomsModal({ type: "create" }),
        }}>
        {rooms_modal?.type === "create" && (
          <ModalAddRoom
            handleClose={onClose}
            initialValues={{
              warehouse_id,
              name: "",
              identifier: "",
            }}
          />
        )}
        {rooms_modal?.type === "edit" && (
          <ModalEditRoom
            handleClose={onClose}
            {...rooms_modal.data}
            initialValues={{
              name: rooms_modal?.data?.name || "",
              identifier:
                rooms_modal?.data?.identifier || "",
            }}
          />
        )}
        {rooms_modal?.type === "remove" && (
          <ModalDeleteRoom
            handleClose={onClose}
            {...rooms_modal.data}
          />
        )}
      </MainList>
    </div>
  );
};

export default withRouter(RoomsList);
