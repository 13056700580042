import user_role_options from "utils/user_role_options";

export const sort = [
  { label: "ID", name: "id", style: { width: "20%" } },
  {
    label: "E-mail",
    name: "email",
    style: { width: "20%" },
  },
  {
    label: "Imię",
    name: "first_name",
    style: { width: "20%" },
  },
  {
    label: "Nazwisko",
    name: "last_name",
    style: { width: "20%" },
  },
  {
    label: "Rola",
    name: "role",
    style: { width: "20%" },
  },
];

export const filters = [
  {
    type: "input",
    name: "id",
    sort_id: "id",
    search_type: "matches",
  },
  {
    type: "input",
    name: "email",
    sort_id: "email",
    search_type: "matches",
  },
  {
    type: "input",
    name: "first_name",
    sort_id: "first_name",
    search_type: "matches",
  },
  {
    type: "input",
    name: "last_name",
    sort_id: "last_name",
    search_type: "matches",
  },
  {
    type: "select",
    name: "role",
    sort_id: "role",
    search_type: "eq",
    options: user_role_options,
  },
];
