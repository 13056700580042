import React, { useEffect } from "react";
import QRCode from "react-qr-code";
import ReactDOM from "react-dom";
import { ReactComponent as ArrowNext } from "icons/arrow-next.svg";

import StyledModalPhoto from "./styles";

const ModalPhoto = ({
  gdn_id,
  getData,
  handleClose,
  sendMessage,
  openSpeditorModal,
}) => {
  useEffect(() => {
    sendMessage(gdn_id);
  }, []);

  return ReactDOM.createPortal(
    <StyledModalPhoto>
      <div className="photo-wrapper">
        <div className="box-qr">
          <QRCode
            size={150}
            value={`gdn_photo|${gdn_id}`}
          />
        </div>
        <div className="box-text">
          <p className="title">Zrób zdjęcie produktom</p>
          <p>
            Zeskanuj QR kod za pomocą telefonu <br /> i zrób
            zdjęcie produktów
          </p>
          <button
            className="btn-success"
            onClick={async () => {
              const gdn_pack = await getData();
              handleClose();
              if (gdn_pack.packages.length === 0) {
                openSpeditorModal(true);
              }
            }}>
            <span>Zrobione</span>
            <ArrowNext />
          </button>
        </div>
      </div>
    </StyledModalPhoto>,
    document.getElementById("root")
  );
};

export default ModalPhoto;
