import React from "react";
import { withRouter } from "react-router-dom";
import classNames from "classnames";
import { convertDate } from "utils/helpers";
import { PackCount } from "components";
import getStatus from "../const/getStatus";

import { ReactComponent as ArrowIcon } from "icons/double-arrow.svg";
import { ReactComponent as PacksIcon } from "icons/packs.svg";
import { ReactComponent as PlayIcon } from "icons/play.svg";
import { ReactComponent as PauseIcon } from "icons/pause.svg";

const TableItem = ({
  id,
  created_at,
  status,
  gdns_count,
  history,
  gdn_lines_total_quantity,
  sendMessage,
  gdns_ready_to_pack_count,
  cart,
  is_disabled = false,
  can_complete_pack,
}) => {
  return (
    <li
      key={id}
      className={classNames("list-item", {
        ready:
          can_complete_pack || gdns_ready_to_pack_count > 0,
        paused:
          !can_complete_pack &&
          gdns_ready_to_pack_count === 0,
        is_disabled,
      })}
      onClick={() => {
        if (["new", "picking"].includes(status)) {
          sendMessage(id);
        }
        history.push(`/packing/gdn_pack/${id}`);
      }}>
      <div className="list-item__identifier">
        <PacksIcon />
        <span>PAK/{id}</span>
      </div>
      <div className="list-item__date">
        {convertDate({ value: created_at })}
      </div>
      <div className="list-item__cart">
        {cart?.id ? (
          <>
            <strong>WÓZEK/{cart.id}</strong>
            <br />
            <span>{cart.box_size}</span>
          </>
        ) : (
          <span>BRAK WÓZKA</span>
        )}
      </div>
      <div className="list-item__full">
        <PackCount
          gdns_count={gdns_count}
          products_quanitity={gdn_lines_total_quantity}
        />
      </div>
      <div className="list-item__status">
        <div
          className={`label label--${
            getStatus(status).color
          }`}>
          {getStatus(status).name}
        </div>
      </div>
      <div className="list-item__arrow">
        <ArrowIcon />
      </div>
      <div className="list-item__action-icon">
        {can_complete_pack || gdns_ready_to_pack_count > 0 ? (
          <PlayIcon />
        ) : (
          <PauseIcon />
        )}
      </div>
    </li>
  );
};

export default withRouter(TableItem);
