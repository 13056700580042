import React from "react";
import {
  Head,
  Loading,
  Filters,
  Pagination,
  Empty,
} from "./components";

const MainTable = ({
  is_loading,
  is_searching,
  data,
  filters,
  filtersAction,
  meta,
  head,
  sort,
  empty_text,
  renderRow,
  filters_open,
  toggleFilters,
  buttons,
  histogram,
  show_histogram,
  histogram_width,
}) => (
  <>
    {filters?.length > 0 && (
      <Filters
        filtersAction={filtersAction}
        filters_open={filters_open}
        toggleFilters={toggleFilters}
        data={filters}
        histogram={histogram}
        show_histogram={show_histogram}
        histogram_width={histogram_width}
      />
    )}
    {buttons && (
      <div className="main-table__buttons">{buttons}</div>
    )}
    <table
      className={`main-table ${
        is_searching ? "searching" : ""
      }`}>
      <Head data={head} sort={sort} />
      {data?.length > 0 && (
        <tbody className="main-table__body">
          {data.map((item) => renderRow?.(item))}
        </tbody>
      )}
    </table>
    {is_loading && <Loading />}
    {!is_loading && data?.length < 1 && <Empty empty_text={empty_text} />}
    {meta && <Pagination {...meta} />}
  </>
);

export default MainTable;
